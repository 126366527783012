import { IRange, RangeDirection, RangeVisitor, RangeBounds, enrichBounds } from "./Range";

import { stringToArray, arrayToString } from "./RangeUtils";

export interface ILiteralRange extends IRange {
  asArray: () => any[][]
  // used to determine interface at runtime
  readonly isLiteralRange: boolean;
}

export default class LiteralRange implements ILiteralRange {
  _r: {
    asArray: any[][],
    width?: number,
    height?: number
  };

  /**
   * This can be constructed from one of three things.
   * 1. A string
   * 2. A 2d array
   * 3. Another literalRange
   */
  constructor(...args: any) {
    if (typeof arguments[0] === "string") {
      let asArray = stringToArray(arguments[0])
      if (!asArray)
        throw new Error('Can not create a new LiteralRange from ' + arguments[0]);

      this._r = {
        asArray: asArray
      };
    } else if (arguments[0] instanceof LiteralRange) {
      this._r = arguments[0]._r;
    } else if (Array.isArray(arguments[0])) {
      // This is a special case where range is just used as a 'rect'
      this._r = {
        asArray: arguments[0]
      };
    } else {
      throw new Error(
        "invalid constructor arguments. Use either a range string or (left, top, right, bottom)"
      );
    }

    this._r.width = 1;
    this._r.height = this._r.asArray.length;
      // We expect a rect so we use the width of the first one
      for (let i=0; i<this._r.asArray.length; i++) {
        let row = this._r.asArray[i];
        this._r.width = Math.max(this._r.width, row.length);
      }
  }

  get left() {
    return 1; // ranges are 1 based
  }

  get top() {
    return 1  // ranges are 1 based;
  }

  get right() {
    return this._r.width;
  }

  get bottom() {
    return this._r.height;
  }

  get leftFixed() {
    return false;
  }

  get topFixed() {
    return false;
  }

  get rightFixed() {
    return false;
  }

  get bottomFixed() {
    return false;
  }

  get width() {
    return this._r.width;
  }

  get height() {
    return this._r.height;
  }

  /*
   This may be null. This should be interpreted as current
  */
  get sheetName() {
    return null;
  }

  asArray() {
    let retValue = null;
    if (this._r.asArray) {
        retValue = [];
        for (let i=0; i<this._r.asArray.length; i++) {
            retValue.push([...this._r.asArray[i]]);
        }
    }
    return retValue;
  }

  // used to determine interface at runtime
  get isLiteralRange(): boolean {
    return true;
  }

  toString() {
    return arrayToString(this._r.asArray);
  }

  scan(visitor: RangeVisitor, bounds?: RangeBounds) {
    let boundsEnriched = enrichBounds(this, bounds);
    let visits = [];
    for (let i=boundsEnriched.top; i<boundsEnriched.bottom; i++) {
      for (let j=boundsEnriched.left; j<boundsEnriched.right; j++) {
        visits.push({
          columnsVisited: boundsEnriched.left - j,
          rowsVisited: boundsEnriched.top - i,
          currentColumnIndex: j,
          currentRowIndex: i,
          currentRange: this
        });
      }
    }
    if (boundsEnriched.reverse)
    visits = visits.reverse();

    let continueVisit = undefined;
    for (let i=0; continueVisit !== false && i<visits.length; i++) {
      continueVisit = visitor(
        this.sheetName,
        visits[i].columnsVisited,
        visits[i].rowsVisited,
        visits[i].currentColumnIndex,
        visits[i].currentRowIndex,
        visits[i].currentRange
      );
    }
  }

  slice(offset: number, direction: RangeDirection | undefined) {
    if (direction === undefined) {
      if (this.width === 1 && this.height > 1) direction = RangeDirection.row;
      else if (this.height === 1 && this.width > 1) direction = RangeDirection.column;
      else direction = this.height > this.width ? RangeDirection.column : RangeDirection.row;
    }
    if (direction === RangeDirection.column) {
      return this.subRange(
        this.left + offset,
        this.top,
        this.left + offset,
        this.bottom);
    } else {
      return this.subRange(
        this.left,
        this.top + offset,
        this.right,
        this.top + offset
      );
    }
  }

  subRange(left: number, top: number, right: number, bottom: number) {
    const leftSub = Math.max(left, this.left);
    const rightSub = Math.min(right, this.right);
    if (rightSub < leftSub) {
      return null;
    }
  
    const topSub = Math.max(top, this.top);
    const bottomSub = Math.min(bottom, this.bottom);
    if (bottomSub < topSub) {
      return null;
    }

    // let args = {
    //   left: leftSub,
    //   top: topSub,
    //   bottom: bottomSub,
    //   right: rightSub,
    //   leftFixed: this.leftFixed,
    //   topFixed: this.topFixed,
    //   rightFixed: this.rightFixed,
    //   bottomFixed: this.bottomFixed,
    //   sheetName: this.sheetName
    // };

    let newArray = [];
    for (let i=topSub; i<=bottomSub; i++) {
      let row = [];
      newArray.push(row);
      for (let j=leftSub; j<=rightSub; j++) {
        row.push(this._r.asArray[i-1][j-1])
      }
    }

    return new LiteralRange(newArray);
  }
}
