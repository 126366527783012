// TODO - remove this import
import { toExcelDate } from "./DateUtils";

export function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function countDecimals(input) {
  if (input == undefined)
    return 0;
  if(Math.floor(input.valueOf()) === input.valueOf()) return 0;
  let split = input.toString().split(".");
  if (split.length <= 1)
    return 0;
  return split[1].length || 0;
}


export function asNumber(value) {
  let updatedValueAsFloat = parseAsNumber(value);
  if (!isNaN(updatedValueAsFloat) && isFinite(updatedValueAsFloat))
    value = updatedValueAsFloat;
  else value = 0;

  return value;
}

function parseAsNumber(value) {
  //https://flaviocopes.com/how-to-convert-string-to-number-javascript/
  // parseAsNumber thinks that '12/31/2018' is a number
  // Number('3e3') works but is 'slowest'
  return value * 1;
}

export function isNumber(value) {
  if (value === undefined) return false;
  let parsed = parseAsNumber(value);
  if (isNaN(parsed) || !isFinite(parsed)) return false;
  return true;
}

export function isNumeric(n) {
  return !isNaN(parseAsNumber(n)) && isFinite(n);
}

export function isNullOrUndefined(value) {
  return value === undefined || value === null;
}

export const roundAccurately = function (number, decimalPlaces = 16) {
  if (number === null || number === undefined) return null;
  // nothing to round
  if (number % 1 === 0) return number;

  var multiplier = Math.pow(10, decimalPlaces || 0);
  return Math.round(number * multiplier) / multiplier;

  //   let sNot = Number(number + "e" + decimalPlaces);
  //   if (isNaN(sNot))
  //     return 0;
  //   return Number(Math.round(sNot) + "e" + decimalPlaces * -1);
};

export function isDefined(value) {
  return !isNullOrUndefined(value);
}

export function cloneObject(value) {
  if (isNullOrUndefined(value)) return value;
  return JSON.parse(JSON.stringify(value));
}

export function arrayEquals(a, b) {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index])
  );
}

export function removeEmptyProperties(obj) {
  Object.keys(obj).forEach(function (key) {
    if (obj[key] && typeof obj[key] === "object")
      removeEmptyProperties(obj[key]);
    //recursive for objects
    else if (obj[key] === null || obj[key] === undefined) delete obj[key]; //remove empty properties
    if (typeof obj[key] === "object" && Object.keys(obj[key]).length === 0)
      delete obj[key]; //remove empty objects
  });
}

export function isEmpty(value) {
  return value === undefined || value === null || value === "";
}

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
export function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export function unListenAll(unListeners) {
  if (!unListeners) return;
  for (let i = 0; i < unListeners.length; i++) {
    if (unListeners[i]) unListeners[i]();
  }
  return [];
}

export function transpose(matrix) {
  //   return matrix[0].map((col, i) => matrix.map(row => row[i]));
  if (!matrix)
    return matrix;
  const rows = matrix.length,
  cols = matrix[0].length;
  const transposed = [];
  for (let j = 0; j < cols; j++) {
    transposed[j] = Array(rows);
  }
  for (let i = 0; i < rows; i++) {
    for (let j = 0; j < cols; j++) {
      transposed[j][i] = matrix[i][j];
    }
  }
  return transposed;
}

export function build2DArray(values, width) {
  let retValue = [];
  for (let i = 0; i < values.length; i++) {
    let value = values[i];
    if (value instanceof Date) value = toExcelDate(value);
    let y = Math.floor(i / width);
    let x = i % width;
    if (retValue[y] === undefined) retValue[y] = [];
    retValue[y][x] = value;
  }

  return retValue;
}

const commonUtils = {
  uuidv4: uuidv4,
  isNumeric: isNumeric,
  asNumber: asNumber,
  countDecimals: countDecimals,
  isNullOrUndefined: isNullOrUndefined,
  isDefined: isDefined,
  cloneObject: cloneObject,
  arrayEquals: arrayEquals,
  removeEmptyProperties: removeEmptyProperties,
  isEmpty: isEmpty,
  isNumber: isNumber,
  roundAccurately: roundAccurately,
  debounce: debounce,
  unListenAll: unListenAll,
  transpose: transpose,
  build2DArray: build2DArray
};

const CommonUtils = Object.freeze(commonUtils);
export default CommonUtils;
