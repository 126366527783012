import CommonUtils from "../../utils/CommonUtils";
import Spline from "../../utils/Spline";

const _mapIdToLineFormattingDefaults = {};
const _mapIdToFillFormattingDefaults = {};
const _mapIdToDataPointDefaults = {};
const _mapIdToRepeatPattern = {};

// Excel charts use tintAndShade
// Here is how this is done, but for now we just interpolate

// http://ciintelligence.blogspot.com/2012/02/converting-excel-theme-color-and-tint.html
//https://docs.microsoft.com/en-us/dotnet/api/documentformat.openxml.spreadsheet.colortype.tint?view=openxml-2.8.1
//https://bitbucket.org/openpyxl/openpyxl/issues/987/add-utility-functions-for-colors-to-help
//https://support.microsoft.com/en-gb/help/29240/how-to-converting-colors-between-rgb-and-hls-hbs

const xFade = [
  1.0,
  2.0,
  3.0,
  4.0,
  5.0,
  6.0,
  7.0,
  8.0,
  9.0,
  10.0,
  13.0,
  17.0,
  35.0,
  120.0,
  Number.MAX_VALUE,
];
const yFade = [
  1.0, 0.46, 0.35, 0.28, 0.23, 0.2, 0.17, 0.16, 0.14, 0.13, 0.1, 0.09, 0.04,
  0.01, 0.0,
];
const _splineFade = new Spline(xFade, yFade);

const toAdjustableTemplate = function(colorKey) {
    if (!colorKey)
        return null;
    let parts = colorKey.split(" ");
    let adjustments = [];
    if (parts.length > 1) {
      let adjustment = {};
      adjustment[parts[0].trim()] = parts[1] ? Math.round(parts[1].trim()) : true;
      adjustments.push(adjustment);
      parts = [parts[2]];
    }

    return {
        val: parts[0],
        adjs: adjustments
    };
  }

class StyleLineFormattingDefaults {
  constructor(axis, gridLineMajor, gridLineMinor, chartArea, other, effect) {
    this.categoryAxis = Object.freeze(toAdjustableTemplate(axis));
    this.valueAxis = Object.freeze(toAdjustableTemplate(axis));
    this.gridLineMajor = Object.freeze(toAdjustableTemplate(gridLineMajor));
    this.gridLineMinor = Object.freeze(toAdjustableTemplate(gridLineMinor));
    this.chartArea = Object.freeze(toAdjustableTemplate(chartArea));
    this.other = Object.freeze(toAdjustableTemplate(other));
    this.effect = Object.freeze(toAdjustableTemplate(effect));
  }
}

class StyleFillFormattingDefaults {
  constructor(chartArea, floorWallsAndPlotArea, effect) {
    this.chartArea = Object.freeze(toAdjustableTemplate(chartArea));
    this.floor = Object.freeze(toAdjustableTemplate(floorWallsAndPlotArea));
    this.walls = Object.freeze(toAdjustableTemplate(floorWallsAndPlotArea));
    this.plotArea = Object.freeze(toAdjustableTemplate(floorWallsAndPlotArea));
    this.effect = Object.freeze(toAdjustableTemplate(effect));
  }
}

class StyleDataPointDefaults {
  constructor(patternFill, colorOrPatternLine, widthLine, patternLine) {
    //this.effect = effect;
    this.patternFill = patternFill;
    this.colorOrPatternLine = colorOrPatternLine;
    this.widthLine = widthLine;
    this.patternLine = patternLine;
  }
}

class StyledEntries {
  constructor() {
    //this.effect = effect;
    this.entries = new Set();
    this.entries.add("axisTitle");
    this.entries.add("categoryAxis");
    this.entries.add("chartArea");
    this.entries.add("dataLabel");
    this.entries.add("dataLabelCallout");
    this.entries.add("dataPoint");
    this.entries.add("dataPoint3D");
    this.entries.add("dataPointLine");
    this.entries.add("dataPointMarker");
    this.entries.add("dataPointMarkerLayout");
    this.entries.add("dataPointWireframe");
    this.entries.add("dataTable");
    this.entries.add("dataTable");
    this.entries.add("downBar");
    this.entries.add("downLine");
    this.entries.add("errorBar");
    this.entries.add("floor");
    this.entries.add("gridLineMajor");
    this.entries.add("gridLineMinor");
    this.entries.add("hiLoLine");
    this.entries.add("leaderLine");
    this.entries.add("legend");
    this.entries.add("plotArea");
    this.entries.add("plotArea3D");
    this.entries.add("seriesAxis");
    this.entries.add("seriesLine");
    this.entries.add("title");
    this.entries.add("trendLine");
    this.entries.add("trendLineLabel");
    this.entries.add("upBar");
    this.entries.add("valueAxis");
    this.entries.add("wall");
  }
}

const _styledEntries = new StyledEntries();

function initColorStyles() {
  for (var i = 1; i <= 32; i++)
    _mapIdToLineFormattingDefaults[i] = new StyleLineFormattingDefaults(
      "tx1",
      "tint 75 tx1",
      "tint 50 tx1",
      null,
      "tx1",
      "subtle"
    );
  //        _mapIdToLineFormattingDefaults.put(String.valueOf(i), new StyleLineFormattingDefaults("tint 75 tx1", "tint 50 tx1", "tint 75 tx1",     "tx1",             "subtle"));
  for (i = 33; i <= 34; i++)
    _mapIdToLineFormattingDefaults[i] = new StyleLineFormattingDefaults(
      "dk1",
      "tint 75 dk1",
      "tint 50 dk1",
      "tint 75 dk1",
      "dk1",
      "subtle"
    );
  for (i = 35; i <= 40; i++)
    _mapIdToLineFormattingDefaults[i] = new StyleLineFormattingDefaults(
      "dk1",
      "tint 75 dk1",
      "tint 50 dk1",
      "tint 75 dk1",
      "dk1",
      "subtle"
    );
  for (i = 41; i <= 48; i++)
    _mapIdToLineFormattingDefaults[i] = new StyleLineFormattingDefaults(
      "dk1",
      "tint 75 dk1",
      "tint 90 dk1",
      "lt1",
      "lt1",
      null
    );

  for (i = 1; i <= 32; i++)
    _mapIdToFillFormattingDefaults[i] = new StyleFillFormattingDefaults(
      null,
      null,
      null
    );
  //        _mapIdToFillFormattingDefaults.put(String.valueOf(i),     new StyleFillFormattingDefaults("bg1",         "bg1",                 null));
  for (i = 33; i <= 34; i++)
    _mapIdToFillFormattingDefaults[i] = new StyleFillFormattingDefaults(
      "lt1",
      "tint 20 dk1",
      "subtle"
    );
  _mapIdToFillFormattingDefaults[35] = new StyleFillFormattingDefaults(
    "lt1",
    "tint 20 accent1",
    "subtle"
  );
  _mapIdToFillFormattingDefaults[36] = new StyleFillFormattingDefaults(
    "lt1",
    "tint 20 accent2",
    "subtle"
  );
  _mapIdToFillFormattingDefaults[37] = new StyleFillFormattingDefaults(
    "lt1",
    "tint 20 accent3",
    "subtle"
  );
  _mapIdToFillFormattingDefaults[38] = new StyleFillFormattingDefaults(
    "lt1",
    "tint 20 accent4",
    "subtle"
  );
  _mapIdToFillFormattingDefaults[39] = new StyleFillFormattingDefaults(
    "lt1",
    "tint 20 accent5",
    "subtle"
  );
  _mapIdToFillFormattingDefaults[40] = new StyleFillFormattingDefaults(
    "lt1",
    "tint 20 accent6",
    "subtle"
  );
  for (i = 41; i <= 48; i++) {
    let t = new StyleFillFormattingDefaults("dk1", "tint 95 dk1", "subtle");
    _mapIdToFillFormattingDefaults[i] = t;
  }

  _mapIdToDataPointDefaults[1] = new StyleDataPointDefaults(
    "pattern 1",
    null,
    3,
    "pattern 1"
  );
  _mapIdToDataPointDefaults[2] = new StyleDataPointDefaults(
    "pattern 2",
    null,
    3,
    "pattern 2"
  );

  _mapIdToDataPointDefaults[3] = new StyleDataPointDefaults(
    "fade accent1",
    null,
    3,
    "fade accent1"
  );
  _mapIdToDataPointDefaults[4] = new StyleDataPointDefaults(
    "fade accent2",
    null,
    3,
    "fade accent2"
  );
  _mapIdToDataPointDefaults[5] = new StyleDataPointDefaults(
    "fade accent3",
    null,
    3,
    "fade accent3"
  );
  _mapIdToDataPointDefaults[6] = new StyleDataPointDefaults(
    "fade accent4",
    null,
    3,
    "fade accent4"
  );
  _mapIdToDataPointDefaults[7] = new StyleDataPointDefaults(
    "fade accent5",
    null,
    3,
    "fade accent5"
  );
  _mapIdToDataPointDefaults[8] = new StyleDataPointDefaults(
    "fade accent6",
    null,
    3,
    "fade accent6"
  );

  _mapIdToDataPointDefaults[9] = new StyleDataPointDefaults(
    "pattern 1",
    "lt1",
    5,
    "pattern 1"
  );
  _mapIdToDataPointDefaults[10] = new StyleDataPointDefaults(
    "pattern 2",
    "lt1",
    5,
    "pattern 2"
  );

  _mapIdToDataPointDefaults[11] = new StyleDataPointDefaults(
    "fade accent1",
    "lt1",
    5,
    "fade accent1"
  );
  _mapIdToDataPointDefaults[12] = new StyleDataPointDefaults(
    "fade accent2",
    "lt1",
    5,
    "fade accent2"
  );
  _mapIdToDataPointDefaults[13] = new StyleDataPointDefaults(
    "fade accent3",
    "lt1",
    5,
    "fade accent3"
  );
  _mapIdToDataPointDefaults[14] = new StyleDataPointDefaults(
    "fade accent4",
    "lt1",
    5,
    "fade accent4"
  );
  _mapIdToDataPointDefaults[15] = new StyleDataPointDefaults(
    "fade accent5",
    "lt1",
    5,
    "fade accent5"
  );
  _mapIdToDataPointDefaults[16] = new StyleDataPointDefaults(
    "fade accent6",
    "lt1",
    5,
    "fade accent6"
  );

  _mapIdToDataPointDefaults[17] = new StyleDataPointDefaults(
    "pattern 1",
    null,
    5,
    "pattern 1"
  );
  _mapIdToDataPointDefaults[18] = new StyleDataPointDefaults(
    "pattern 2",
    null,
    5,
    "pattern 2"
  );

  _mapIdToDataPointDefaults[19] = new StyleDataPointDefaults(
    "fade accent1",
    null,
    5,
    "fade accent1"
  );
  _mapIdToDataPointDefaults[20] = new StyleDataPointDefaults(
    "fade accent2",
    null,
    5,
    "fade accent2"
  );
  _mapIdToDataPointDefaults[21] = new StyleDataPointDefaults(
    "fade accent3",
    null,
    5,
    "fade accent3"
  );
  _mapIdToDataPointDefaults[22] = new StyleDataPointDefaults(
    "fade accent4",
    null,
    5,
    "fade accent4"
  );
  _mapIdToDataPointDefaults[23] = new StyleDataPointDefaults(
    "fade accent5",
    null,
    5,
    "fade accent5"
  );
  _mapIdToDataPointDefaults[24] = new StyleDataPointDefaults(
    "fade accent6",
    null,
    5,
    "fade accent6"
  );

  _mapIdToDataPointDefaults[25] = new StyleDataPointDefaults(
    "pattern 1",
    null,
    7,
    "pattern 1"
  );
  _mapIdToDataPointDefaults[26] = new StyleDataPointDefaults(
    "pattern 2",
    null,
    7,
    "pattern 2"
  );

  _mapIdToDataPointDefaults[27] = new StyleDataPointDefaults(
    "fade accent1",
    null,
    7,
    "fade accent1"
  );
  _mapIdToDataPointDefaults[28] = new StyleDataPointDefaults(
    "fade accent2",
    null,
    7,
    "fade accent2"
  );
  _mapIdToDataPointDefaults[29] = new StyleDataPointDefaults(
    "fade accent3",
    null,
    7,
    "fade accent3"
  );
  _mapIdToDataPointDefaults[30] = new StyleDataPointDefaults(
    "fade accent4",
    null,
    7,
    "fade accent4"
  );
  _mapIdToDataPointDefaults[31] = new StyleDataPointDefaults(
    "fade accent5",
    null,
    7,
    "fade accent5"
  );
  _mapIdToDataPointDefaults[32] = new StyleDataPointDefaults(
    "fade accent6",
    null,
    7,
    "fade accent6"
  );

  _mapIdToDataPointDefaults[33] = new StyleDataPointDefaults(
    "pattern 1",
    "tint 92.5 dk1",
    7,
    "pattern 1"
  );
  _mapIdToDataPointDefaults[34] = new StyleDataPointDefaults(
    "pattern 2",
    "pattern 3",
    7,
    "pattern 2"
  );

  _mapIdToDataPointDefaults[35] = new StyleDataPointDefaults(
    "fade accent1",
    "shade 50 accent1",
    5,
    "fade accent1"
  );
  _mapIdToDataPointDefaults[36] = new StyleDataPointDefaults(
    "fade accent2",
    "shade 50 accent2",
    5,
    "fade accent2"
  );
  _mapIdToDataPointDefaults[37] = new StyleDataPointDefaults(
    "fade accent3",
    "shade 50 accent3",
    5,
    "fade accent3"
  );
  _mapIdToDataPointDefaults[38] = new StyleDataPointDefaults(
    "fade accent4",
    "shade 50 accent4",
    5,
    "fade accent4"
  );
  _mapIdToDataPointDefaults[39] = new StyleDataPointDefaults(
    "fade accent5",
    "shade 50 accent5",
    5,
    "fade accent5"
  );
  _mapIdToDataPointDefaults[40] = new StyleDataPointDefaults(
    "fade accent6",
    "shade 50 accent6",
    5,
    "fade accent6"
  );

  _mapIdToDataPointDefaults[41] = new StyleDataPointDefaults(
    "pattern 4",
    null,
    5,
    "pattern 4"
  );
  _mapIdToDataPointDefaults[42] = new StyleDataPointDefaults(
    "pattern 2",
    null,
    5,
    "pattern 3"
  );

  _mapIdToDataPointDefaults[43] = new StyleDataPointDefaults(
    "fade accent1",
    null,
    5,
    "fade accent1"
  );
  _mapIdToDataPointDefaults[44] = new StyleDataPointDefaults(
    "fade accent2",
    null,
    5,
    "fade accent2"
  );
  _mapIdToDataPointDefaults[45] = new StyleDataPointDefaults(
    "fade accent3",
    null,
    5,
    "fade accent3"
  );
  _mapIdToDataPointDefaults[46] = new StyleDataPointDefaults(
    "fade accent4",
    null,
    5,
    "fade accent4"
  );
  _mapIdToDataPointDefaults[47] = new StyleDataPointDefaults(
    "fade accent5",
    null,
    5,
    "fade accent5"
  );
  _mapIdToDataPointDefaults[48] = new StyleDataPointDefaults(
    "fade accent6",
    null,
    5,
    "fade accent6"
  );

  //        _mapIdToRepeatPattern[1] = ["tint 97 dk1", "tint 72 dk1", "tint 89.5 dk1", "shade 98.5 dk1", "tint 84 dk1", "tint 53 dk1"];
  _mapIdToRepeatPattern[1] = [
    "tint 88 dk1",
    "tint 55 dk1",
    "tint 78 dk1",
    "tint 92.5 dk1",
    "tint 70 dk1",
    "tint 30 dk1",
  ];
  _mapIdToRepeatPattern[2] = [
    "accent1",
    "accent2",
    "accent3",
    "accent4",
    "accent5",
    "accent6",
  ];
  _mapIdToRepeatPattern[3] = [
    "shade 38 accent1",
    "shade 38 accent2",
    "shade 38 accent3",
    "shade 38 accent4",
    "shade 38 accent5",
    "shade 38 accent6",
  ];
  //        _mapIdToRepeatPattern[4] = ["tint 88.5 dk1", "tint 55 dk1", "tint 75 dk1", "tint 98.5 dk1", "tint 30 dk1", "tint 60 dk1"];
  _mapIdToRepeatPattern[4] = [
    "tint 5 dk1",
    "tint 55 dk1",
    "tint 78 dk1",
    "tint 15 dk1",
    "tint 70 dk1",
    "tint 30 dk1",
  ];
  // parse
  let repeatPatternKeys = Object.keys(_mapIdToRepeatPattern);
  for (let i=0; i<repeatPatternKeys.length; i++)
    for (let j=0; j<_mapIdToRepeatPattern[repeatPatternKeys[i]].length; j++)
        _mapIdToRepeatPattern[repeatPatternKeys[i]][j] = Object.freeze(toAdjustableTemplate(_mapIdToRepeatPattern[repeatPatternKeys[i]][j]));
}
initColorStyles();

class ChartStyles {
  constructor(theme, styleId, appContext='powerpoint', colorMap) {
    if (!CommonUtils.isDefined(theme))
      throw new Error('a theme is required');
    this._theme = theme;

    this._styleId = styleId;
    if (this._styleId === undefined) this._styleId = 2;
    else if (this._styleId < 2) this._styleId = 2;
    else if (this._styleId > 48) this._styleId = 48;
    this._appContext = appContext;
  }

  getThemeColor(key) {
    return this._theme.getMappedColor(key);
  }

  // TODO - should we be using bandOffset? This appears to be a bug
  getFadeStep(bandOffset, bandSize) {
    return _splineFade.at(bandSize);
  }

  applyFade(adjs, offset, total) {
    let center = (total - 1) / 2;

    // We make the center the standard color and then we shade to the left
    // and tint to the right. If there are an odd # of series then the center is
    // unadjusted. Else the first axis on the left and right are adjust only 1/2.
    let adjustments;
    if (adjs && adjs.length > 0) {
        adjustments = [...adjs];
    } else
        adjustments = [];

    let stepSize = this.getFadeStep(offset, total);

    let bandSize = total - 1 - center;
    let distance = Math.abs(bandSize - offset);

    let amount = 1 - stepSize * distance;
    let type = null;
    if (offset < center) {
      type = "shade";
    } else if (offset > center) {
      type = "tint";
    }
    if (type !== null) {
      let adjustment = {};
      adjustment[type] = Math.round(100 * amount);
      adjustments.push(adjustment);
      //                 console.log("offset(" + offset + ", " + total + ") = " + adjustment + " " + (Math.round(100000 * amount)));
    }
    return adjustments;
  }

  getPatternPaint(patternKey, offset, total) {
    let repeatPattern = _mapIdToRepeatPattern[patternKey];

    let patternOffset = offset % repeatPattern.length;
    if (repeatPattern[patternOffset] === null)
      throw new Error(
        "unknown color code '" + repeatPattern[patternOffset] + "'."
      );
    let color = {...repeatPattern[patternOffset]};

    color.adjs = this.applyFade(
      color.adjs,
      Math.floor(offset / repeatPattern.length),
      Math.floor(total / repeatPattern.length) + 1
    );
    return color;
  }

  calcColorPaintStyle(colorDef, offset, total) {
    if (colorDef.startsWith("fade ")) {
      let colorKey = colorDef.substring("fade ".length);
      let color = toAdjustableTemplate(colorKey);
      color.adjs = this.applyFade(color.adjs, offset, total);
      return color;
    } else if (colorDef.startsWith("pattern ")) {
      let colorKey = colorDef.substring("pattern ".length);
      return this.getPatternPaint(colorKey, offset, total);
    } else {
      return toAdjustableTemplate(colorDef);
    }
  }

  createSolidFill(color) {
    return {
      solid: color
    };
  }

  createNoneFill() {
    return {
      none: true,
    };
  }

  createNoneStroke() {
    return {
      fill: this.createNoneFill(),
    };
  }

  createDataPoints2DStrokeStyleProperties(offset, total) {
    let defaultValues = _mapIdToDataPointDefaults[this._styleId];
    let style = defaultValues.colorOrPatternLine;
    if (style === null) return this.createNoneStroke();

    let adjustedColor = this.calcColorPaintStyle(style, offset, total);
    let fill = this.createSolidFill(adjustedColor);
    return {
      width: defaultValues.widthLine / 2 || 0.75,
      fill: fill,
    };
  }

  createDataPoints2DFillStyleProperties(offset, total) {
    let defaultValues = _mapIdToDataPointDefaults[this._styleId];
    let style = defaultValues.patternFill;
    if (style === null) return this.createNoneFill();

    let adjustedColor = this.calcColorPaintStyle(style, offset, total);
    let fill = this.createSolidFill(adjustedColor);
    return fill;
  }

  createStrokeStyleProperties(property) {
    let defaultValues = _mapIdToLineFormattingDefaults[this._styleId];
    let style = defaultValues[property];

    // office has the chartArea hardcoded differently for powerpoint
    // Note - the grid lines are actually being read from the ChartStyle (not implemented yet)
    if (this._appContext !== 'powerpoint' && (property === 'chartArea' || property === 'gridLineMajor' || property === 'gridLineMinor')) {
        return {
          width: defaultValues.widthLine / 2 || 0.75,
          fill: this.createSolidFill({
              val: 'tx1',
              adjs: [
                { 'lumMod': 15 },
                { 'lumOff': 85 }
              ]
          }),
        };
    }

    if (
      !property ||
      (!style &&
        (_styledEntries.entries.has(property) ||
          Object.keys(defaultValues).includes(property)))
    )
      return {
        width: 0.75,
        fill: this.createNoneFill(),
      };

    if (style === undefined || !_mapIdToLineFormattingDefaults[this._styleId])
      throw new Error(
        "Invalid property for style : " +
          property +
          " choices are : " +
          Object.keys(defaultValues)
      );

    let fill = this.createSolidFill(style);
    return {
      width: defaultValues.widthLine / 2 || 0.75,
      fill: fill,
    };
  }

  createFillStyleProperties(property) {
    // office has the chartArea hardcoded differently for powerpoint
    if (property === 'chartArea' && this._appContext !== 'powerpoint') {
        return this.createSolidFill({
          val: 'bg1'
        });
    }
    let defaultValues = _mapIdToFillFormattingDefaults[this._styleId];
    let style = defaultValues[property];
    if (
      !property ||
      (!style &&
        (_styledEntries.entries.has(property) ||
          Object.keys(defaultValues).includes(property)))
    )
      return this.createNoneFill();

    if (style === undefined || !_mapIdToFillFormattingDefaults[this._styleId]) {
      throw new Error(
        "Invalid property for style : " +
          property +
          " choices are : " +
          Object.keys(defaultValues)
      );
    }

    let fill = this.createSolidFill(style);
    return fill;
  }

  /*
   * This matches OOXML stylex.xml. For each part you
   *
   * This uses the chartStyleName and the idx to look up the definition from the theme.
   * If will return any defintions that it finds but it does not attemt to resolve placeholder
   * colors.
   */
  createFontFillStyle(chartStyleName) {
    // In OOXML this is CTFontReference
    let fill = this.createSolidFill({
      val: "tx1",
      adjs: [{
            lumMod: 65
        }, {
            lumOff: 35
        }]
    });
    return fill;
  }

  // TODO - add lnRef
  // TODO - add fillRef
  // TODO - add effectRef
  // TODO - add default para
  // TODO - add spPr (does this have priority over refs from theme?)
}

export default ChartStyles;
