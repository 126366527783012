import AbstractModel from "../../../dagm/AbstractModel";
import ArrayModel from "../../../dagm/ArrayModel";


import { isSingleSeries, isLineType, isColumnType } from "../../../utils/ChartUtils";

import ChartTypeDataLabelShape from "./ChartTypeDataLabelShape";

class ChartType extends AbstractModel {
  constructor(options) {
    super();

    let _self = this;

    let _chartShape = options.chartShape;
    this.addProperty("chartShape", {
      isReadOnly: true,
      isTransient: true,
      defaultValue: function () {
        return _chartShape;
      },
    });

    this.addProperty("description", {
      isReadOnly: true,
      isTransient: true,
      defaultValue: function () {
        return "Chart Type";
      },
      inputs: [],
    });

    this.addProperty("type", "column");

    // Returns a filter list of series
    this.addProperty("series", {
      isReadOnly: true,
      isTransient: true,
      defaultValue: function (allSeries) {
        let seriesFiltered = [];
        for (let i = 0; i < allSeries.length; i++) {
          let seriesAt = allSeries.getAt(i);
          if (seriesAt.chartType === _self) seriesFiltered.push(seriesAt);
        }
        return new ArrayModel(seriesFiltered.length, {
          defaultValue: function (index) {
            return seriesFiltered[index];
          },
          inputs: ["$index"],
          persister: null /* we implicitly save models*/,
        });
      },
      inputs: ["chartShape.series"],
    });

    this.addProperty("varyColors", {
      defaultValue: function (type) {
        return isSingleSeries(type);
      },
      inputs: ["type"],
    });

    /**
     * Category Axis only:
     * Specifies the possible groupings for a column/bar chart.
     * Choices:
        <percentStacked> (100% Stacked) Specifies that the chart series are drawn next to each other along the value axis and scaled to total 100%.
        <stacked> (Stacked) Specifies that the chart series are drawn next to each other on the value axis.
        <clustered> (Clustered) Specifies that the chart series are drawn next to each other but are sitting on the value axis.
        <standard> (Standard) Specifies that the chart series are drawn on the value axis.
    */
    // TODO - why does openxml have to groupings one for bar and one for other?
    this.addProperty("grouping", {
      defaultValue: function (type) {
        if (isColumnType(type) && !isLineType(type))
          return "clustered";
        return "standard";
      },
      inputs: ["type"]
    });

    /**
     * Bar/Column only:
     * Specifies the contents of this attribute will contain an integer between -100 and 100, whose contents are a percentage.
     * This specifieds how much bars and columns shall overlap on 2-D charts.
     */
    // TODO - This seems to be also driven by style.
    this.addProperty("overlap", {
      defaultValue: function (type, grouping) {
        if (grouping === "stacked" || grouping === "percentStacked") return 1.0;
        if (type === "bar") return 0;
        else if (type === "column") return -0.27;
        return 0;
      },
      inputs: ["type", "grouping"],
    });

    /**
     * Bar/Column only:
     * Specifies that the contents of this attribute will contain a gap amount between 0 and 500.
     * This is will give how much spacing as a percent of the tick area is provided for empty space.
     */
    // TODO - This seems to be also driven by style.
    this.addProperty("gapWidth", {
      defaultValue: function (type, grouping) {
        if (type === "bar") return 1.82;
        else if (type === "column") {
          if (grouping === "stacked" || grouping === "percentStacked")
            return 1.5;
          else return 2.19;
        }
        return 0;
      },
      inputs: ["type", "grouping"],
    });

    // for pie
    this.addProperty("startAngle", 0);
    this.addProperty("explosion", 0);
    this.addProperty("holeSize", 0);

    // TODO - implement
    this.addProperty("maxAbsSize", {
      defaultValue: function (values) {
        return 5;
        // return CommonFunctors.max(values);
      },
      isReadOnly: true,
    });

    // TODO - implement
    this.addProperty("minAbsSize", {
      defaultValue: function (values) {
        return 5;
        // return CommonFunctors.min(values);
      },
      isReadOnly: true,
    });

    // for scatter
    this.addProperty("scatterStyle", {
      // 'none', 'line', 'lineMarker, 'marker, 'smooth', 'smoothMarker'
      defaultValue: function (type) {
        if (type === "scatter") return "marker";
        return "none";
      },
      inputs: ["type"],
    });

    this.addProperty("dataLabels", {
      defaultValue: function () {
        return new ChartTypeDataLabelShape(
          {
            chartShape: _self.chartShape,
            chartType: _self,
            appContext: options.appContext
          },
          "dataLabel"
        );
      },
      // inputs: ["chartShape"],
      persister: null /* we implicitly save models*/,
    });

    // for bubble
    this.addProperty("bubbleScale", 0);
    this.addProperty("bubbleSizeRepresentsWidth", false);
    this.addProperty("showNegativeBubbleValues", true);

    this.addProperty("offsetXAxis", 0);
    this.addProperty("offsetYAxis", {
      // Note - should we remove this? Excel UI doesn't allow the but the format does.
      defaultValue: function (offsetXAxis) {
        return offsetXAxis;
      },
      inputs: ["offsetXAxis"],
    });
  }

  get className() {
    return "ChartType";
  }

}

export default ChartType;
