import { ManualLayoutPersister, manualLayoutSetter } from "../primitives/Coordinates";

import ChartPartShape from "./ChartPartShape";

class ChartPlotAreaShape extends ChartPartShape {
  constructor(options) {
    super(options, "plotArea");

    this.addProperty("description", {
      isReadOnly: true,
      isTransient: true,
      defaultValue: function () {
        return "Plot Area";
      },
      inputs: [],
    });

    /**
     * This is implements as a rect (x,y,width,height) with percentanges of the containing elmement.
     * If null then no manualLayout is applied
     * Note -
     * This is to spec see :
     * https://docs.microsoft.com/en-us/openspecs/office_standards/ms-oi29500/bb8565e7-dfea-453f-868b-ae680ab8a823?redirectedfrom=MSDN
     */
    // TODO - implement rendering
    this.addProperty("manualLayout", {
      setValue: manualLayoutSetter,
      defaultValue: function () {
        return null;
      },
      persister: new ManualLayoutPersister(),
    });
  }

  get className() {
    return "ChartPlotAreaShape";
  }

}

export default ChartPlotAreaShape;
