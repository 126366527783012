import { createFillProperty } from "../../AbstractShape";

import { Font, fontSetter, FontPersister} from "../../text/Font";

import ChartTextShape from "../ChartTextShape";

class ChartSeriesTitleTextShape extends ChartTextShape {
  constructor(options, propDefaultText, styleKey) {
    super(options, propDefaultText, styleKey);
    const _self = this;

    // fill
    createFillProperty(this, this.styleKey, 'fill', "chartShape.chartStyle", function(style, styleKey, delegate) {
        return delegate;
    }, ["chartShape.legend.labels.text.fill"]);

    this.overrideProperty("font", {
      defaultValue: function (delegate) {
        return delegate;
      },
      setValue: fontSetter,
      persister: new FontPersister("font"),
      inputs: ["chartShape.legend.labels.text.font"],
    });

    this.overrideProperty("size", {
      defaultValue: function (delegate) {
        return delegate;
      },
      inputs: ["chartShape.legend.labels.text.size"],
    });

    this.overrideProperty("weight", {
      defaultValue: function (delegate) {
        return delegate;
      },
      inputs: ["chartShape.legend.labels.text.weight"],
    });

    this.overrideProperty("italic", {
      defaultValue: function (delegate) {
        return delegate;
      },
      inputs: ["chartShape.legend.labels.text.italic"],
    });

    this.overrideProperty("strike", {
      defaultValue: function (delegate) {
        return delegate;
      },
      inputs: ["chartShape.legend.labels.text.strike"],
    });

    this.overrideProperty("underline", {
      defaultValue: function (delegate) {
        return delegate;
      },
      inputs: ["chartShape.legend.labels.text.underline"],
    });
  }

  get className() {
    return "ChartSeriesTitleTextShape";
  }

}

export default ChartSeriesTitleTextShape;
