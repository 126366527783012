import DAGM from "../../../dagm/Dagm";
import PropertyPersister from "../../../dagm/PropertyPersister";

import AbstractShape from "../../AbstractShape";
import { createFillProperty } from "../../AbstractShape";

import { MARKER_KEYS } from "../../../utils/ChartUtils";

class ChartDataPointMarkerShape extends AbstractShape {
  constructor(options, offset, length, seriesOffset, seriesLength) {
    super(Object.assign(options, { noDefaultProperties: true }), null/*style*/); // With a null style the styling properties are not unnecessarily defined

    let _chartShape = options.chartShape;
    this.addProperty("chartShape", {
      isReadOnly: true,
      isTransient: true,
      defaultValue: function () {
        return _chartShape;
      },
    });

    let _seriesMarkers = options.seriesMarkers;
    this.addProperty("seriesMarkers", {
      isReadOnly: true,
      isTransient: true,
      defaultValue: function () {
        return _seriesMarkers;
      },
    });

    this.addProperty("type", {
      defaultValue: function (seriesMarkerType, chartStyle, varyColors) {
        if (
          seriesMarkerType !== "none" &&
          seriesLength === 1 &&
          varyColors &&
          !this.getPropertyValue("seriesMarkers.type").isExplicit
        )
          return MARKER_KEYS[offset % MARKER_KEYS.length];
        else return seriesMarkerType;
      },
      inputs: [
        "seriesMarkers.type",
        "chartShape.chartStyle",
        "seriesMarkers.chartType.varyColors",
      ],
    });

    this.addProperty("size", {
      defaultValue: (inherited) => {
        return inherited;
      },
      inputs: ["seriesMarkers.size"],
    });

    // fill
    createFillProperty(this, this.styleKey, 'fill', "chartShape.chartStyle", function(style, _styleKey, seriesMarkerFill, varyColors) {
      if (seriesLength === 1 &&
        varyColors &&
        !this.getPropertyValue("seriesMarkers.fill").isExplicit)
        return style.createDataPoints2DFillStyleProperties(
          offset,
          length
        );
      else return seriesMarkerFill;
    }, [
      "seriesMarkers.fill",
      "seriesMarkers.chartType.varyColors"
    ], 'fill');

    // strokeFill
    createFillProperty(this, this.styleKey, 'strokeFill', "chartShape.chartStyle", function(style, _styleKey, seriesMarkerStrokeFill, varyColors) {
        if (seriesLength === 1 &&
          varyColors &&
          !this.getProperty("seriesMarkers.strokeFill").isExplicit)
          return style.createDataPoints2DStrokeStyleProperties(
            offset,
            length
          ).fill;
        else return seriesMarkerStrokeFill;
    }, [
      "seriesMarkers.strokeFill",
      "seriesMarkers.chartType.varyColors"
    ], 'stroke.fill');


    this.addProperty("strokeWidth", {
      defaultValue: function (strokeWidth) {
        return strokeWidth;
      },
      inputs: ["seriesMarkers.strokeWidth"],
      persister: new PropertyPersister("stroke.width"),
    });

    this.addProperty("strokeLineJoin", {
      defaultValue: function (strokeLineJoin) {
        return strokeLineJoin;
      },
      inputs: ["seriesMarkers.strokeLineJoin"],
    });

    this.addProperty("strokeLineCap", {
      defaultValue: function (strokeLineCap) {
        return strokeLineCap;
      },
      inputs: ["seriesMarkers.strokeLineCap"],
    });

    this.addProperty("strokeDash", {
      defaultValue: function (strokeDash) {
        return strokeDash;
      },
      inputs: ["seriesMarkers.strokeDash"],
    });

    // rendering not implemented
    // this.addProperty("strokeCompound", {
    //   defaultValue: function(strokeCompound) {
    //     return strokeCompound;
    //   },
    //   inputs: ["seriesMarkers.strokeCompound"]
    // });
  }

  get className() {
    return "ChartDataPointMarkerShape";
  }

}

export default ChartDataPointMarkerShape;

export class ChartDataPointMarkerShapePersistor extends PropertyPersister {
  constructor(path, options) {
    super(path);
    this.options = options;
  }

  toJSON(value) {
    if (value instanceof ChartDataPointMarkerShape) {
      return value.toJSON();
    }

    return value;
  }

  fromJSON(jsonValue, index) {
    let _self = this;
    return DAGM.newFromJSON(jsonValue, () => {
      let retValue = new ChartDataPointMarkerShape(
        {
          chartShape: _self.options.chartShape,
          seriesMarkers: _self.options.seriesMarkers,
          pointX: _self.options.getPointX(index),
          pointVal: _self.options.getPointVal(index),
          appContext: _self.options.appContext
        },
        null, // styleKey
      );
      return retValue;
    });
  }
}