import PropertyPersister from "../../../dagm/PropertyPersister";

import CommonFunctors from "../../../utils/CommonFunctors";
import { scatterHasLine } from "../../../utils/ChartUtils";

import { createFillProperty } from "../../AbstractShape";

import ChartSeriesShape from "./ChartSeriesShape";

class ChartScatterSeriesShape extends ChartSeriesShape {
  constructor(options, offset, seriesLength) {
    super(options, offset, seriesLength);
    let _self = this;

    // this.addProperty("maxX", {
    //   defaultValue: function (values) {
    //     return CommonFunctors.max(values);
    //   },
    //   isReadOnly: true,
    //   inputs: [], // todo use valRange to find if needed
    // });

    // this.addProperty("minX", {
    //   defaultValue: function (values) {
    //     return CommonFunctors.min(values);
    //   },
    //   isReadOnly: true,
    //   inputs: [], // todo use valRange to find if needed
    // });
    // strokeFill
    createFillProperty(this, this.styleKey, 'strokeFill', "chartShape.chartStyle", function(style, _styleKey, scatterStyle) {
      if (scatterHasLine(scatterStyle)) {
        return style.createDataPoints2DFillStyleProperties(
          offset,
          seriesLength
        );
      } else {
        return style.createNoneFill();
      }
    }, [
      "chartType.scatterStyle"
    ], 'stroke.fill');

    this.overrideProperty("strokeWidth", {
      defaultValue: function (chartStyle, scatterStyle) {
        if (scatterHasLine(scatterStyle)) {
          let strokeWidth = chartStyle.createDataPoints2DStrokeStyleProperties(
            offset,
            seriesLength
          ).width;
          // We use the fill for fill for strokes for lines
          // TODO - hack to look like ppt for now. We need to read the from the chartStyles
          if (strokeWidth === undefined) strokeWidth = 2.25;
          return strokeWidth;
        } else {
          return 0.75;
        }
      },
      inputs: ["chartShape.chartStyle", "chartType.scatterStyle"],
      persister: new PropertyPersister("stroke.width"),
    });
  }


  get className() {
    return "ChartScatterSeriesShape";
  }
}

export default ChartScatterSeriesShape;
