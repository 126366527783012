import AbstractShape from "../AbstractShape";

import { AdjustableColorPersister, createAdjustableColorSetter } from "../primitives/Persisters";
import { resolveAdjustableColor, AdjustableColorDef } from "./AdjustableColor";

import { Font, fontSetter, FontPersister} from "../text/Font";

export type DocumentThemeColorMap = {
  bg1?: string,
  bg2?: string,
  tx1?: string,
  tx2?: string,
  accent1?: string,
  accent2?: string,
  accent3?: string,
  accent4?: string,
  accent5?: string,
  accent6?: string,
  hlink?: string,
  folHlink?: string,
};

export type DocumentThemeOptions = {
  colorMap?: DocumentThemeColorMap,
  themeDef?: {
    name?: string | AdjustableColorDef,
    dk1?: string | AdjustableColorDef,
    lt1?: string | AdjustableColorDef,
    dk2?: string | AdjustableColorDef,
    lt2?: string | AdjustableColorDef,
    accent1?: string | AdjustableColorDef,
    accent2?: string | AdjustableColorDef,
    accent3?: string | AdjustableColorDef,
    accent4?: string | AdjustableColorDef,
    accent5?: string | AdjustableColorDef,
    accent6?: string | AdjustableColorDef,
    hlink?: string | AdjustableColorDef,
    folHlink?: string | AdjustableColorDef,
    majorFontLatin?: string | AdjustableColorDef,
    minorFontLatin?: string | AdjustableColorDef,
  }
}

export default class DocumentTheme extends AbstractShape {
  _colorMap:DocumentThemeColorMap | null = null;
  schemeLookup: any;
  constructor(options?: DocumentThemeOptions) {
    super();
//     console.log('creating theme', this);

      //https://docs.microsoft.com/en-us/dotnet/api/documentformat.openxml.drawing.colormap?view=openxml-2.8.1
      // default color map.
      // TODO - support custom color maps
      // <p:clrMap bg1="dk2" tx1="lt2" bg2="dk1" tx2="lt2" accent1="accent1" accent2="accent2" accent3="accent3" accent4="accent4" accent5="accent5" accent6="accent6" hlink="hlink" folHlink="folHlink"/>
    this._colorMap = {
      bg1:"lt1",
      bg2:"lt2",
      tx1:"dk1",
      tx2:"dk2",
      accent1:"accent1",
      accent2:"accent2",
      accent3:"accent3",
      accent4:"accent4",
      accent5:"accent5",
      accent6:"accent6",
      hlink:"hlink",
      folHlink:"folHlink"
    }

    if (options?.colorMap) {
      this._colorMap = Object.assign(this._colorMap, options?.colorMap);
    }

    let defaultThemeDef = {
      name: 'Standard Office',
      dk1: 'windowText',
      lt1: 'window',
      dk2: '44546A',
      lt2: 'E7E6E6',
      accent1: '4472C4',
      accent2: 'ED7D31',
      accent3: 'A5A5A5',
      accent4: 'FFC000',
      accent5: '5B9BD5',
      accent6: '70AD47',
      hlink: '0563C1',
      folHlink: '954F72',
      majorFontLatin: 'Calibri',
      minorFontLatin: 'Calibri'
    };

    if (options?.themeDef) {
      defaultThemeDef = Object.assign(defaultThemeDef, options?.themeDef);
    }

    this.schemeLookup = function(valLookup: string) {
      let themeColor = this.getMappedColor(valLookup);
      if (!themeColor)
        return null;

      return themeColor.toRGBAColor();
    }.bind(this);
    const selfThemeLookup = this.schemeLookup;

    this.addProperty("name", {
      defaultValue: function() {
        return defaultThemeDef.name;
      },
    });

    this.addProperty("dk1", {
      defaultValue: function() {
        return resolveAdjustableColor(defaultThemeDef.dk1, selfThemeLookup);
      },
      setValue: createAdjustableColorSetter(),
      persister: new AdjustableColorPersister(selfThemeLookup)
    });

    this.addProperty("lt1", {
      defaultValue: function() {
        return resolveAdjustableColor(defaultThemeDef.lt1, selfThemeLookup);
      },
      setValue: createAdjustableColorSetter(),
      persister: new AdjustableColorPersister(selfThemeLookup)
    });

    this.addProperty("dk2", {
      defaultValue: function() {
        return resolveAdjustableColor(defaultThemeDef.dk2, selfThemeLookup);
      },
      setValue: createAdjustableColorSetter(),
      persister: new AdjustableColorPersister(selfThemeLookup)
    });

    this.addProperty("lt2", {
      defaultValue: function() {
        return resolveAdjustableColor(defaultThemeDef.lt2, selfThemeLookup);
      },
      setValue: createAdjustableColorSetter(),
      persister: new AdjustableColorPersister(selfThemeLookup)
    });

    this.addProperty("accent1", {
      defaultValue: function() {
        return resolveAdjustableColor(defaultThemeDef.accent1, selfThemeLookup);
      },
      setValue: createAdjustableColorSetter(),
      persister: new AdjustableColorPersister(selfThemeLookup)
    });

    this.addProperty("accent2", {
      defaultValue: function() {
        return resolveAdjustableColor(defaultThemeDef.accent2, selfThemeLookup);
      },
      setValue: createAdjustableColorSetter(),
      persister: new AdjustableColorPersister(selfThemeLookup)
    });

    this.addProperty("accent3", {
      defaultValue: function() {
        return resolveAdjustableColor(defaultThemeDef.accent3, selfThemeLookup);
      },
      setValue: createAdjustableColorSetter(),
      persister: new AdjustableColorPersister(selfThemeLookup)
    });

    this.addProperty("accent4", {
      defaultValue: function() {
        return resolveAdjustableColor(defaultThemeDef.accent4, selfThemeLookup);
      },
      setValue: createAdjustableColorSetter(),
      persister: new AdjustableColorPersister(selfThemeLookup)
    });

    this.addProperty("accent5", {
      defaultValue: function() {
        return resolveAdjustableColor(defaultThemeDef.accent5, selfThemeLookup);
      },
      setValue: createAdjustableColorSetter(),
      persister: new AdjustableColorPersister(selfThemeLookup)
    });

    this.addProperty("accent6", {
      defaultValue: function() {
        return resolveAdjustableColor(defaultThemeDef.accent6, selfThemeLookup);
      },
      setValue: createAdjustableColorSetter(),
      persister: new AdjustableColorPersister(selfThemeLookup)
    });

    this.addProperty("hlink", {
      defaultValue: function() {
        return resolveAdjustableColor(defaultThemeDef.hlink, selfThemeLookup);
      },
      setValue: createAdjustableColorSetter(),
      persister: new AdjustableColorPersister(selfThemeLookup)
    });

    this.addProperty("folHlink", {
      defaultValue: function() {
        return resolveAdjustableColor(defaultThemeDef.folHlink, selfThemeLookup);
      },
      setValue: createAdjustableColorSetter(),
      persister: new AdjustableColorPersister(selfThemeLookup)
    });

    this.addProperty("majorFontLatin", {
      defaultValue: function () {
        return new Font(defaultThemeDef.majorFontLatin);
      },
      setValue: fontSetter,
      persister: new FontPersister("majorFontLatin"),
    });

    this.addProperty("minorFontLatin", {
      defaultValue: function () {
        return new Font(defaultThemeDef.minorFontLatin);
      },
      setValue: fontSetter,
      persister: new FontPersister("minorFontLatin"),
    });
  }

  getMappedColor(key: string) {
    return this[this._colorMap[key] || key];
  }


}
