
export interface RangeBounds {
  readonly left?: number,
  readonly top?: number,
  readonly bottom?: number,
  readonly right?: number,
  readonly reverse?: boolean
  readonly sheetName?: string
};

/**
 *
 * @return either nothing or false to indicate that should stop visiting
 *
 * @param sheetName the name of the sheet visited
 * @param x the number of x values visited
 * @param y the number of y values visited
 * @param xAbs the x value of the range visited
 * @param yAbs the y value of the range visited
 * @param range the currentRange that is being visited
 */

export type RangeVisitor = (sheetName: string, x: number, y: number, xAbs: number, yAbs: number, range: Range) => boolean | void;

export interface IRange {
  readonly left: number;
  readonly leftFixed: boolean;
  readonly top: number;
  readonly topFixed: boolean;
  readonly right: number;
  readonly rightFixed: boolean;
  readonly bottom: number;
  readonly bottomFixed: boolean;

  readonly sheetName?: string;

  /**
   * The width and height may be different that the RangeBounds would indicate because empty rows/columns are skipped
   */
   readonly width: number;
   readonly height: number;

  /**
   * scan will walk elements from the top/left to the bottom/right
   * @param visitor the visitor for scanning
   */
  scan: (visitor: RangeVisitor, bounds?: RangeBounds) => void;

  /**
   * Return a single row/column that contains data. 
   * @param offset This is the visited offset with data. If excludes empty spaces
   */
  
  slice: (offset: number, direction: RangeDirection | undefined) => IRange | null;

  /**
   * Find the intersection of ranges within the given coordinates. These are in absolute
   */
  subRange: (left: number, top: number, right: number, bottom: number) => IRange | null;

  /**
   * Returns a stringified version of Ranges.
   */
  toString: () => string;
};

export const enrichBounds = function(range: IRange, bounds?: RangeBounds) {
  let boundsEnriched = bounds ? {...bounds} : {};
  if (boundsEnriched.top === undefined)
    boundsEnriched.top = range.top;
  if (boundsEnriched.left === undefined)
    boundsEnriched.left = range.left;
  if (boundsEnriched.right === undefined)
    boundsEnriched.right = range.right;
  if (boundsEnriched.bottom === undefined)
    boundsEnriched.bottom = range.bottom;

  return boundsEnriched;
}

export enum RangeDirection {
  row = "r",
  column = "c"
};
