import ScopeContextStack from "./ScopeContextStack";
import ExplicitResolver from "./ExplicitResolver";
import AbstractModel from "./AbstractModel";

class DagmAPI {
  newFromJSON(jsonFrom, creator) {
    if (jsonFrom === undefined || jsonFrom === null)
      jsonFrom = {};
    if (typeof jsonFrom !== "object") {
      console.warn(
        "newFromJSON was not provided a valid json. An empty json is being used.",
        jsonFrom
      );
      jsonFrom = {};
    }
    let pushed = ScopeContextStack.pushScopeContext({
      explicitLookup: new ExplicitResolver(jsonFrom),
    });

    try {
      let retValue = creator();
      if (!(retValue instanceof AbstractModel)) {
        throw new Error("DAGM newFromJSON can only return an AbstractModel. But found: ", retValue);
      }
      return retValue;
    } catch (error) {
      console.warn("Unable to load", error);
      throw error;
    } finally {
      if (pushed) ScopeContextStack.popScopeContext();
    }
  }
}

const DAGM = new DagmAPI();

export default DAGM;
