import ChartPartShape from "./ChartPartShape";

class ChartGridlinesShape extends ChartPartShape {
  constructor(options) {
    super(options, options.isMajor ? "gridLineMajor" : "gridLineMinor");
    let _chartAxis = options.chartAxis;
    this._chartAxis = _chartAxis;
    let _isMajor = !!options.isMajor;
    this._isMajor = _isMajor;

    this.overrideProperty("shown", {
      defaultValue: function () {
        return _isMajor && _chartAxis.axisType === "val";
      },
    });

    let _chartType = options.chartType;
    this.addProperty("chartType", {
      isReadOnly: true,
      isTransient: true,
      defaultValue: function () {
        return _chartType;
      },
    });

    this.addProperty("axis", {
      isReadOnly: true,
      isTransient: true,
      defaultValue: function () {
        return _chartAxis;
      },
    });

    this.addProperty("description", {
      isReadOnly: true,
      isTransient: true,
      defaultValue: function (axisDescription) {
        return (
          axisDescription + " " + (_isMajor ? "Major" : "Minor") + " Gridlines"
        );
      },
      inputs: ["axis.description"],
    });
  }

  get isMajor() {
    return this._isMajor;
  }

  get className() {
    return "ChartGridlinesShape";
  }

  get typeId() {
    return this.className + ":" + this._chartAxis.direction + ":" + this.isMajor;
  }
}

export default ChartGridlinesShape;
