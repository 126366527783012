import PropertyPersister from "../../../dagm/PropertyPersister";
import FetchedPropertyValue from "../../../dagm/FetchedPropertyValue";

import * as UnitCalcs from "../../../utils/UnitCalcs";
import * as OrdUnitCalcs from "../../../utils/OrdUnitCalcs";

import ChartCatAxisShape from "./ChartCatAxisShape";

class ChartOrdAxisShape extends ChartCatAxisShape {
  constructor(options) {
    super(options, "ord");
    const _self = this;

    this.addProperty("labelMultiLevel", {
      defaultValue: function (xValues) {
        return xValues.asPrimitives.length > 1;
      },
      inputs: ["xValues"],
    });

    this.addProperty("limits", {
      isReadOnly: true,
      isTransient: true,
      defaultValue: function (
        valuesPrimitive,
        explicitLabelInterval,
        tickMarkSkip,
        crossBetween,
        dims,
        horizontal,
        isMultiLevel,
        labels
      ) {
        let level =
          valuesPrimitive.length < 1 ? 0 : valuesPrimitive.length - 1; // Multilevel is always the last value in the array
        let values = valuesPrimitive[level];

        let pvRotation = this.getPropertyValue("rotation");
        let explictRotation = pvRotation.isExplicit
          ? pvRotation.value
          : undefined;

        let retValue = OrdUnitCalcs.calcAxisLimitsOrd(
          values,
          explicitLabelInterval /*eInterval*/,
          explictRotation /*eRotation*/,
          dims /*dims*/,
          horizontal /*horizontal*/,
          !isMultiLevel /*allowOverlap*/,
          tickMarkSkip,
          crossBetween /*crossBetween*/,
          labels
        );

        retValue.autoZero = UnitCalcs.autoZero(retValue.min, retValue.max);

        retValue.fitToTick = function (x, offset) {
          return offset;
        };

        return retValue;
      },
      inputs: [
        "xValues.asPrimitives",
        "explicitLabelInterval",
        "tickMarkSkip",
        "crossBetween",
        "dims",
        "horizontal",
        "labelMultiLevel",
        "labels.*",
        "labels.text.*",
        "labels.rotation",
      ], // we listen to labels.text due to a DAGM limitation
    });

    this.addProperty("explicitLabelInterval", {
      isWritable: false,
      defaultValue: null,
      persister: new PropertyPersister("labelInterval"),
    });

    /**
     * Specifies how many tick labels to skip between label that is drawn.
     * A value greater than or equal to 1
     * OOXML - tickLblSkip
     */
    this.addProperty("labelInterval", {
      defaultValue: function (limits) {
        return limits.labelInterval;
      },
      setValue: function (value) {
        if (value !== undefined && value < 1) return;
        if (value !== undefined) value = parseInt(value);
        _self.setPropertyValue("explicitLabelInterval", value);
        return undefined;
      },
      valueCreator: function (source, value, property, isExplicit, defaultValue) {
        let explicit = _self.getPropertyValue("explicitLabelInterval");
        return new FetchedPropertyValue(
          source,
          value,
          property,
          explicit.isExplicit,
          defaultValue
        );
      },
      inputs: ["limits", "explicitLabelInterval"],
      persister: null /* we persist explict values */,
    });

    /**
     * Specifies how many tick marks shall be skipped before the next one shall be drawn.
     * A value greater than or equal to 1
     */
    this.addProperty("tickMarkSkip", {
      defaultValue: function () {
        return 1;
      },
      setValue: function (value) {
        if (value !== undefined && value < 1) return;
        if (value !== undefined) value = parseInt(value);
        return value;
      },
    });
  } // end of constructor

  get className() {
    return "ChartOrdAxisShape";
  }

}

export default ChartOrdAxisShape;
