import PropertyPersister from "../../../dagm/PropertyPersister";
import FetchedPropertyValue from "../../../dagm/FetchedPropertyValue";

import CommonFunctors from "../../../utils/CommonFunctors";
import * as UnitCalcs from "../../../utils/UnitCalcs";

import { flatten2DArray } from "../../range/RangeUtils";

import {
  numberSetter,
  builtInDisplayUnitsKeys,
  getValueAsDisplayNumber,
} from "../../../utils/ChartUtils";

import ChartAxisShape from "./ChartAxisShape";
import ChartAxisDisplayUnitLabelShape from "./ChartAxisDisplayUnitLabelShape";

function visitBuckets(buckets, seriesXs, seriesVals, funcBucket, funcAgg) {
  for (let i = 0; i < seriesVals.length; i++) {
    for (let j = 0; j < seriesVals[i].length; j++) {
      let x = funcBucket ? funcBucket(seriesXs ? seriesXs[i][j] : j, j) : j;
      let val = seriesVals[i][j];
      if (!buckets[x]) buckets[x] = {};
      funcAgg(buckets[x], val, i, j, funcAgg);
    }
  }
  return buckets;
}

// TODO - move to a ui chart utils (not existing since this is for models)
class PointMap {
  constructor() {
    this._map = [];
  }
  addPoint(seriesOff, valueOff, point) {
    let valueMap = this._map[seriesOff];
    if (!valueMap) {
      valueMap = {};
      this._map[seriesOff] = valueMap;
    }
    valueMap[valueOff] = point;
  }

  getPoint(seriesOff, valueOff) {
    let valueMap = this._map[seriesOff];
    if (!valueMap) return undefined;
    return valueMap[valueOff];
  }
}

class ChartValAxisShape extends ChartAxisShape {
  constructor(options) {
    super(options, "val");

    let _self = this;
    let _direction = options.direction;

    let _xAxis = options.xAxis || null;
    this.addProperty("xAxis", {
      isReadOnly: true,
      isTransient: true,
      defaultValue: function () {
        return _xAxis;
      },
    });

    // Note - Scatter unions all series but cat just returns the first one.
    this.addProperty("xRange", {
      isReadOnly: true,
      isTransient: true,
      // setValue: createRangeSelectionSetter(true/*singleLength*/, false/*alignRanges*/, true/*forceFixed*/),
      defaultValue: function (xRangesChartShape, xRangeSeries0) {
        if (xRangesChartShape && xRangesChartShape.getXRange())
            return xRangesChartShape.getXRange();
        return xRangeSeries0;
      },
      inputs: ["chartShape.ranges", "chartShape.series[0].xRange"],
      // persister: new RangeSelectionPersister(),
    });

    this.overrideProperty("scaleType", { defaultValue: "linear" });
    this.addProperty("logBase", {
      defaultValue: function () {
        return 10;
      },
      setValue: function (value) {
        if (value < 2 || value > 1000) throw new Error("invalid range");
        return value;
      },
    });

    this.addProperty("displayUnits", {
      setValue: numberSetter(
        builtInDisplayUnitsKeys,
        null,
        null,
        function (value) {
          if (value === 0) throw new Error("zero is invalid");
        }
      ),
      defaultValue: "none",
    });

    this.addProperty("displayUnitsLabel", {
      isReadOnly: true,
      defaultValue: function () {
        return new ChartAxisDisplayUnitLabelShape({
          chartShape: options.chartShape,
          chartAxis: _self,
          appContext: options.appContext
        });
      },
      persister: null /* we implicitly save models*/,
    });

    this.addProperty("explicitMax", {
      isWritable: false,
      defaultValue: null,
      persister: new PropertyPersister("max"),
    });
    this.addProperty("explicitMin", {
      isWritable: false,
      defaultValue: null,
      persister: new PropertyPersister("min"),
    });
    this.addProperty("explicitMajorUnits", {
      isWritable: false,
      defaultValue: null,
      persister: new PropertyPersister("majorUnits"),
    });
    this.addProperty("explicitMinorUnits", {
      isWritable: false,
      defaultValue: null,
      persister: new PropertyPersister("minorUnits"),
    });


    let groupInfoInputs = [
      "chartShape.dispNaAsBlank",
      "chartShape.dispBlanksAs",
      "scaleType",
      "logBase",
      "chartType.type",
      "series[*].xValues.asPrimitives",
    ];
    // Note - Only the y direction watches the xlimits and the yValues
    if (_direction === "y") {
      groupInfoInputs.push("series[*].valValues.asPrimitives");
      groupInfoInputs.push("xAxis.limits");
      groupInfoInputs.push("chartType.grouping");
    }

    function firstRowOnly(series) {
        if (!series)
            return null;
        let retValue = [];
        for (let i=0; i<series.length; i++) {
            if (series[i] && series[i].length > 0 && series[i][0] !== null)
                retValue.push(series[i][series[i].length-1]);
        }
        if (retValue.length === 0)
            return null;
        return retValue;
    }

    function asSingleDim(series) {
        if (!series)
            return null;
        let retValue = [];
        for (let i=0; i<series.length; i++) {
            if (series[i] && series[i].length > 0 && series[i][0] !== null)
                retValue.push(flatten2DArray(series[i]));
        }
        if (retValue.length === 0)
            return null;
        return retValue;
    }

    //TODO - remove this as a property this is really a 'precalc for limits'
    this.addProperty("groupInfo", {
      defaultValue: function (
        dispNaAsBlank,
        dispBlanksAs,
        scaleType,
        logBase,
        chartTypeType,
        seriesXs,
        seriesVals,
        xLimits,
        grouping
      ) {
        let retValue = {
        };

        let pointMap = new PointMap();
        retValue.pointMap = pointMap;

        let newSeriesXs = firstRowOnly(seriesXs);
        let newSeriesVals = asSingleDim(seriesVals);

        seriesXs = newSeriesXs;// pointXs;
        seriesVals = newSeriesVals;//pointVals;
        let series = _direction === "x" ? seriesXs : seriesVals;
        let funcBucket = xLimits ? xLimits.fitToTick : null;

        if (grouping === "stacked" || grouping === "percentStacked") {
          let stackFuncAdd = function (bucket, val, seriesOff, dataOff) {
            bucket.values = bucket.values || [];
            let prevValue;
            let value =
              getValueAsDisplayNumber(
                val,
                dispNaAsBlank,
                dispBlanksAs,
                scaleType
              ) || 0;
            if (value === null) {
              pointMap.addPoint(seriesOff, dataOff, {
                value: null,
              });
              bucket.values.push(null);
              return;
            }
            let valueRange;
            UnitCalcs.adjust(bucket, "minUnstacked", value, Math.min);
            UnitCalcs.adjust(bucket, "maxUnstacked", value, Math.max);
            if (value >= 0) {
              prevValue = bucket.pos || 0;
              UnitCalcs.adjust(bucket, "pos", value, (o, n) => o + n);
              valueRange = {
                low: prevValue,
                high: prevValue + value,
                value: prevValue + value,
              };
            } else {
              prevValue = bucket.neg || 0;
              UnitCalcs.adjust(bucket, "neg", value, (o, n) => o + n);
              valueRange = {
                low: prevValue + value,
                high: prevValue,
                value: prevValue + value,
              };
            }
            pointMap.addPoint(seriesOff, dataOff, valueRange);
            bucket.values.push(valueRange);
          };
          let buckets;
          if (chartTypeType === "line" || chartTypeType === "area") {
            let newVals = [];
            for (let i = 0; i < seriesVals.length; i++) {
              newVals[i] = [];
              for (let j = 0; j < seriesVals[i].length; j++) {
                let value =
                  getValueAsDisplayNumber(
                    seriesVals[i][j],
                    dispNaAsBlank,
                    dispBlanksAs,
                    scaleType
                  ) || 0; // even gaps are zero for line additional
                let runningValue = (i === 0 ? 0 : newVals[i - 1][j]) + value;
                newVals[i][j] = runningValue;
              }
            }

            buckets = visitBuckets(
              {},
              seriesXs,
              newVals,
              funcBucket,
              function (bucket, val, seriesOff, dataOff) {
                bucket.values = bucket.values || [];
                let value = getValueAsDisplayNumber(
                  val,
                  dispNaAsBlank,
                  dispBlanksAs,
                  scaleType
                );
//                 if (value === null) {
//                   pointMap.addPoint(seriesOff, dataOff, null);
//                   bucket.values.push(null);
//                   return;
//                 }
                let valueRange;
                if (value === null) {
                  valueRange = { value: value };
                } else if (value >= 0) {
                  UnitCalcs.adjust(bucket, "pos", value, Math.max);
                  valueRange = { low: 0, high: value, value: value };
                } else {
                  UnitCalcs.adjust(bucket, "neg", value, Math.min);
                  valueRange = { low: value, high: 0, value: value };
                }
                pointMap.addPoint(seriesOff, dataOff, valueRange);
                bucket.values.push(valueRange);
              }
            );
            if (grouping === "percentStacked") {
              buckets = visitBuckets(
                buckets,
                seriesXs,
                seriesVals,
                funcBucket,
                function (bucket, val) {
                  let value = getValueAsDisplayNumber(
                    val,
                    dispNaAsBlank,
                    dispBlanksAs,
                    scaleType
                  );
                  UnitCalcs.adjust(
                    bucket,
                    "total",
                    Math.abs(value || 0),
                    function (o, n) {
                      return Math.abs(o) + Math.abs(n);
                    }
                  );
                }
              );
            }
          } else {
            buckets = visitBuckets(
              {},
              seriesXs,
              seriesVals,
              funcBucket,
              stackFuncAdd
            );
          }

          retValue.buckets = buckets;
          Object.keys(buckets).forEach(function (key) {
            var bucket = buckets[key];

            UnitCalcs.adjust(
              retValue,
              "minUnstacked",
              bucket.minUnstacked,
              Math.min
            );
            UnitCalcs.adjust(
              retValue,
              "maxUnstacked",
              bucket.maxUnstacked,
              Math.max
            );

            if (grouping === "stacked") {
              UnitCalcs.adjust(
                retValue,
                "min",
                bucket.neg || bucket.pos,
                Math.min
              );
              UnitCalcs.adjust(
                retValue,
                "max",
                bucket.pos || bucket.neg,
                Math.max
              );
            } else {
              // grouping === 'percentStacked'
              let denominator = 1;
              if (chartTypeType === "line" || chartTypeType === "area") {
                denominator = bucket.total;
              } else {
                denominator = Math.abs(bucket.neg || 0) + (bucket.pos || 0);
              }
              bucket.factor = 1 / (denominator || 1);

              if (bucket.neg !== undefined) {
                UnitCalcs.adjust(
                  bucket,
                  "minPercent",
                  bucket.neg * bucket.factor,
                  Math.min
                );
              }
              if (bucket.pos !== undefined) {
                UnitCalcs.adjust(
                  bucket,
                  "maxPercent",
                  bucket.pos * bucket.factor,
                  Math.max
                );
              }
              UnitCalcs.adjust(
                retValue,
                "min",
                bucket.minPercent || 0,
                Math.min
              );
              UnitCalcs.adjust(
                retValue,
                "max",
                bucket.maxPercent || 0,
                Math.max
              );
              for (let i = 0; i < bucket.values.length; i++) {
                bucket.values[i].low = bucket.values[i].low * bucket.factor;
                bucket.values[i].high = bucket.values[i].high * bucket.factor;
                bucket.values[i].value = bucket.values[i].value * bucket.factor;
              }
            }
          });

          // OOXML uses a strange algo (discovered via observation).
          // It looks at the first series for the min value.
          // It looks at the remaining series for the max value.
          // The min limit is the the signifiant digits between the two.
          // Note - If the series.length <= 1 then it is hardcoded to min= 1 and max to logbase.
          if (scaleType === "log") {
            if (seriesVals.length <= 1) {
              retValue.logPercentMin = 1;
              retValue.logPercentMax = logBase;
            } else {
              // let minValue = 1;
              // let maxValue = 1;
              let logMinBuckets = {};
              for (let j = 0; j < seriesVals[0].length; j++) {
                let value = getValueAsDisplayNumber(
                  seriesVals[0][j],
                  dispNaAsBlank,
                  dispBlanksAs,
                  scaleType
                );
                if (value === null) continue;
                logMinBuckets[j] = { min: value };
              }
              for (let i = 1; i < seriesVals.length; i++) {
                for (let j = 0; j < seriesVals[i].length; j++) {
                  let value = getValueAsDisplayNumber(
                    seriesVals[i][j],
                    dispNaAsBlank,
                    dispBlanksAs,
                    scaleType
                  );
                  if (value === null) continue;
                  let logMinBucket = logMinBuckets[j];
                  if (!logMinBucket)
                    // for none rectangular series selections or empty values
                    logMinBucket = logMinBuckets[j] = {};
                  if (logMinBucket.max === undefined) logMinBucket.max = value;
                  else logMinBucket.max = Math.max(logMinBucket.max, value);
                }
              }
              let largestDistance = 0;
              Object.keys(logMinBuckets).forEach(function (key) {
                var bucket = logMinBuckets[key];
                // now find distance and is larger override
                let minDigits = Math.floor(
                  Math.log(bucket.min || 0) / Math.log(logBase)
                );
                let maxDigits = Math.floor(
                  Math.log(bucket.max || 0) / Math.log(logBase)
                );
                let distance = maxDigits - minDigits;
                largestDistance = Math.max(distance, largestDistance);
              });

              retValue.logPercentMin =
                0.1 / Math.max(1, Math.pow(logBase, largestDistance));
              retValue.logPercentMax = 1;
            }
          } else if (grouping === "stacked") {
            // If stacked values don't cross aixs 0 force them too (log does this for 1)
            if (retValue.max > 0 && retValue.min > 0) retValue.min = 0;
            if (retValue.max < 0 && retValue.min < 0) retValue.max = 0;
          }
          retValue.stacked = true;
          return retValue;
        }

        let containsText = false;
        if (_direction === "x") {
          for (let i = 0; !containsText && i < series.length; i++) {
            containsText = CommonFunctors.containsText(series[i]);
          }
        }
        if (containsText) {
          retValue.useOffset = true;
          retValue.min = 1;
          retValue.max = 1;
        }
        for (let i = 0; i < series.length; i++) {
          if (containsText) {
            retValue.max = Math.max(retValue.max, series[i].length);
          } else {
            let valueList = [];
            for (let j = 0; j < series[i].length; j++)
              valueList.push(
                getValueAsDisplayNumber(
                  series[i][j],
                  dispNaAsBlank,
                  dispBlanksAs,
                  scaleType
                )
              );
            UnitCalcs.adjust(
              retValue,
              "min",
              CommonFunctors.min(valueList),
              Math.min
            );
            UnitCalcs.adjust(
              retValue,
              "max",
              CommonFunctors.max(valueList),
              Math.max
            );
          }
        }

        let funcAdd = function (bucket, val, seriesOff, dataOff) {
          bucket.values = bucket.values || [];
          let value = getValueAsDisplayNumber(
            val,
            dispNaAsBlank,
            dispBlanksAs,
            scaleType
          );
          let valueRange;
          if (value === null) {
              valueRange = { value: value };
          } else if (value >= 0) {
            UnitCalcs.adjust(bucket, "pos", val, (o, n) => o + n);
            valueRange = { low: 0, high: value, value: value };
          } else {
            UnitCalcs.adjust(bucket, "neg", val, (o, n) => o + n);
            valueRange = { low: value, high: 0, value: value };
          }
          pointMap.addPoint(seriesOff, dataOff, valueRange);
          bucket.values.push(valueRange);
        };

        if (_direction === "x" || (xLimits && xLimits.isBubbleOrScatter)) {
          retValue.isBubbleOrScatter = true;
        } else
          retValue.buckets = visitBuckets(
            {},
            seriesXs,
            seriesVals,
            funcBucket,
            funcAdd
          );

        return retValue;
      },
      isReadOnly: true,
      isTransient: true,
      inputs: groupInfoInputs,
    });

    this.addProperty("limits", {
      isReadOnly: true,
      isTransient: true,
      defaultValue: function (
        groupInfo,
        explicitMin,
        explicitMax,
        explicitMinorUnits,
        explicitMajorUnits,
        scaleType,
        logBase,
        dims,
        chartType,
        grouping,
        horizontal,
        labels
      ) {
        let axisLimits;
        if (scaleType === "log") {
          let min = groupInfo.min;
          let max = groupInfo.max;
          if (grouping === "percentStacked") {
            min = groupInfo.logPercentMin || 0.1;
            max = groupInfo.logPercentMax || groupInfo.max;
            //min = 0.01;
          }
          let generateTicks = explicitMajorUnits || dims === null;
          axisLimits = UnitCalcs.calcAxisLimitsLog(
            min,
            max,
            explicitMin,
            explicitMax,
            explicitMinorUnits,
            explicitMajorUnits,
            logBase,
            generateTicks
          );
        } else {
          if (grouping === "percentStacked") {
            axisLimits = UnitCalcs.calcAxisLimitsPercent(
              groupInfo.min,
              groupInfo.max,
              explicitMin,
              explicitMax,
              explicitMinorUnits,
              explicitMajorUnits
            );
          } else {
            axisLimits = UnitCalcs.calcAxisLimitsLinear(
              groupInfo.min,
              groupInfo.max,
              explicitMin,
              explicitMax,
              explicitMinorUnits,
              explicitMajorUnits,
              !!groupInfo.isBubbleOrScatter
            );
          }
          //             if (dims === null) {
          //                 axisLimits.majorTicks = UnitCalcs.buildLinearTicks(axisLimits.min, axisLimits.max, axisLimits.majorUnits);
          //                 axisLimits.minorTicks = UnitCalcs.buildLinearTicks(axisLimits.min, axisLimits.max, axisLimits.minorUnits);
          //             }
        }

        let retValue = {
          minVal: groupInfo.min,
          maxVal: groupInfo.max,
          explicitMin: explicitMin,
          explicitMax: explicitMax,
          explicitMinorUnits: explicitMinorUnits,
          explicitMajorUnits: explicitMajorUnits,
          min: axisLimits.min,
          max: axisLimits.max,
          minorUnits: axisLimits.minorUnits,
          majorUnits: axisLimits.majorUnits,
          majorTicks: axisLimits.majorTicks,
          minorTicks: axisLimits.minorTicks,
          ticks: axisLimits.ticks,
          autoZero: axisLimits.autoZero,
          scaleType: scaleType,
          logBase: logBase,
          pointMap: groupInfo.pointMap,
          isBubbleOrScatter: !!groupInfo.isBubbleOrScatter,
        };

        if (dims !== null) {
          if (scaleType === "log") {
            // TODO - Do we need to also adjustlogaxisforpointradious?
            //retValue = UnitCalcs.adjustAxisForPointRadius(chartType, retValue, dims);
            retValue = UnitCalcs.cullLogToFit(
              horizontal,
              labels,
              retValue,
              dims
            );
          } else {
            retValue = UnitCalcs.adjustAxisForPointRadius(
              chartType,
              retValue,
              dims
            );
            retValue = UnitCalcs.cullLinearToFit(
              grouping,
              chartType.type,
              horizontal,
              labels,
              retValue,
              dims
            );
            retValue.majorTicks = UnitCalcs.buildLinearTicks(
              retValue.min,
              retValue.max,
              retValue.majorUnits
            );
            retValue.minorTicks = UnitCalcs.buildLinearTicks(
              retValue.min,
              retValue.max,
              retValue.minorUnits
            );
            retValue.ticks = retValue.majorTicks;
            retValue.labelTicks = retValue.majorTicks;
          }
        }

        if (_direction === "x") {
          retValue.fitToTick = function (valueIn, offset) {
            if (groupInfo.useOffset) return offset + 1;
            let value = Number(valueIn);
            if ((isNaN(value) || !isFinite(value)) && offset !== undefined)
              value = offset + 1;

            return value;
          };
        }

        return retValue;
      },
      inputs: [
        "groupInfo",
        "explicitMin",
        "explicitMax",
        "explicitMinorUnits",
        "explicitMajorUnits",
        "scaleType",
        "logBase",
        "dims",
        "chartType",
        "chartType.grouping",
        "horizontal",
        "labels.*",
        "labels.text.*",
      ], // we listen to labels.text due to a DAGM limitation
    });

    this.addProperty("max", {
      defaultValue: function (limits) {
        return limits.max;
      },
      setValue: function (value, auxSetPropertyValue) {
        if (value !== undefined) {
          let explicitMin = _self.getPropertyValue("explicitMin");
          if (explicitMin.value >= value)
            auxSetPropertyValue("explicitMin", undefined);
        }
        auxSetPropertyValue("explicitMax", value);
        return undefined;
      },
      valueCreator: function (source, value, property, isExplicit, defaultValue) {
        let explicit = _self.getPropertyValue("explicitMax");
        return new FetchedPropertyValue(
          source,
          value,
          property,
          explicit.isExplicit,
          defaultValue
        );
      },
      inputs: ["limits", "explicitMax"],
      persister: null /* we persist explict values */,
    });
    this.addProperty("min", {
      defaultValue: function (limits) {
        return limits.min;
      },
      setValue: function (value, auxSetPropertyValue) {
        // We don't allow max to be set. Rather we set explicitValue
        if (value !== undefined) {
          let explicitMax = _self.getPropertyValue("explicitMax");
          if (explicitMax.value <= value)
            auxSetPropertyValue("explicitMax", undefined);
        }

        auxSetPropertyValue("explicitMin", value);
        return undefined;
      },
      valueCreator: function (source, value, property, isExplicit, defaultValue) {
        let explicit = _self.getPropertyValue("explicitMin");
        return new FetchedPropertyValue(
          source,
          value,
          property,
          explicit.isExplicit,
          defaultValue
        );
      },
      inputs: ["limits", "explicitMin"],
      persister: null /* we persist explict values */,
    });
    this.addProperty("majorUnits", {
      defaultValue: function (limits) {
        return limits.majorUnits;
      },
      setValue: function (value, auxSetPropertyValue) {
        if (value !== undefined && value <= 0) return;
        let scaleType = _self.getPropertyValue("scaleType");
        if (scaleType.value === "log") {
          let logBase = _self.getPropertyValue("logBase").value;
          if (value < logBase) return;
        }
        auxSetPropertyValue("explicitMajorUnits", value);
        return undefined;
      },
      valueCreator: function (source, value, property, isExplicit, defaultValue) {
        let explicit = _self.getPropertyValue("explicitMajorUnits");
        return new FetchedPropertyValue(
          source,
          value,
          property,
          explicit.isExplicit,
          defaultValue
        );
      },
      inputs: ["limits", "explicitMajorUnits"],
      persister: null /* we persist explict values */,
    });

    this.addProperty("minorUnits", {
      defaultValue: function (limits) {
        return limits.minorUnits;
      },
      setValue: function (value, auxSetPropertyValue) {
        if (value !== undefined && value <= 0) return;
        let scaleType = _self.getPropertyValue("scaleType");
        if (scaleType.value === "log") {
          let logBase = _self.getPropertyValue("logBase").value;
          if (value < logBase) return;
        }
        auxSetPropertyValue("explicitMinorUnits", value);
        return undefined;
      },
      valueCreator: function (source, value, property, isExplicit, defaultValue) {
        let explicit = _self.getPropertyValue("explicitMinorUnits");
        return new FetchedPropertyValue(
          source,
          value,
          property,
          explicit.isExplicit,
          defaultValue
        );
      },
      inputs: ["limits", "explicitMinorUnits"],
      persister: null /* we persist explict values */,
    });

    this.overrideProperty("defaultAxisFormat", {
      defaultValue: function (grouping) {
        if (grouping === "percentStacked")
          return "0%"; // note - if percentStacked then sourceLinked is ignored
        return "General";
      },
      inputs: ["chartType.grouping"]
    });

  } // end of constructor

  get className() {
    return "ChartValAxisShape";
  }

}

export default ChartValAxisShape;
