import { toExcelDate, parseAsDate } from '../../utils/DateUtils';
import { countDecimals, isNumber } from '../../utils/CommonUtils';

import CellRange from "../range/CellRange";
import { encode_col } from '../range/RangeUtils';

import papaparse from "papaparse";

/**
 * These loosely follow - https://github.com/SheetJS/sheetjs#common-spreadsheet-format
 * Note -
 * t: "b" | "n" | "s" | "e" | "z"; // boolean, number, string, error, stub (dates are represented as numbers just like excel)
 */
 export enum CellType {
  Boolean = "b",
  Number = "n",
  String = "s",
  Error = "e",
  Stub = "z",
}

export type CellStyle = {
  align?: string; // left,right/center/justify
  wrapStyle?: string; // wrap, overflow, clip
  border?: string; // left,right,top,bottom, trbl, rlbr
  fill?: any;
  text?: any;
}

/**
 * This loosely follows
 * https://github.com/SheetJS/sheetjs#common-spreadsheet-format
 *
 * with accomidations from openxml
 * https://c-rex.net/projects/samples/ooxml/e1/Part4/OOXML_P4_DOCX_c_topic_ID0E1XM4.html
 */
 export type Cell = {
  t?: CellType; // if not specified than assume to be of type number
  v: string | number | boolean | null; // raw value
  z?: string; // cell formatting
  c?: string; // comment;
  f?: string; // formula
  s?: CellStyle;
  colSpan?: number;
  rowSpan?: number;
};

export type Row = {
  h?: number;
}

export type Col = {
  w?: number;
}

export type ReferencedCell = {
  columnIndex: number;
  rowIndex: number;
  cell: Cell;
}

export type ISheetSource = {
  getCellAt: (columnIndex: number, rowIndex: number) => Cell | null;
  setCells?: (cells: ReferencedCell[]) => void;
};

export type CellUpdate = {
  columnIndex: number;
  rowIndex: number;
};

export type RemoveListener = () => void;

export type CellListener = (updates: CellUpdate | CellUpdate[] | null) => void;

export interface IUpdatableSheetModel {
  addUpdateListener: (listener: CellListener) => RemoveListener;
}

export type Cells = Record<string, Cell>;
export type Rows = Record<number, Row>;
export type Cols = Record<number, Col>;

export type SheetSourceData = {
  cells: Cells;
  r?:Rows;
  c?:Col;
}

export type cellCreator = (value: any) => Cell | null;

export function createCellFromStringValue(strValue: string): Cell | null {
  let value: any = strValue;
  let type: CellType = CellType.String;
  let z = null;
  if (strValue === "" || strValue === null || strValue === undefined) {
    return null;
  }

  if (isNumber(value)) {
    value = value * 1;
    type = CellType.Number;
  } else if (strValue.endsWith('%')) {
     let testValue:any = strValue.substring(0, strValue.length - 1);
     if (!isNaN(testValue * 1)) {
         let decimals = countDecimals(testValue);
         value = (testValue * 1) / 100;
         if (decimals > 0)
            z = '0.' + '0'.repeat(2) + '%'; // always 2.
         else
            z = '0%';
         type = CellType.Number;
     }
  } else if (strValue.startsWith('$')) {
     let testValue:any = strValue.substring(1, strValue.length);
     // TODO - internationalize
     if (!isNaN(testValue * 1)) {
         let decimals = countDecimals(testValue);
         value = (testValue * 1);
         if (decimals > 0)
            z = '$#,##0.00_);[Red]($#,##0.00)';
         else
            z = '$#,##0_);[Red]($#,##0)';
         type = CellType.Number;
     }
  } else {
    let asDate = parseAsDate(value);
    if (asDate) {
      value = toExcelDate(asDate);
      type = CellType.Number;
      z = 'm/d/yyyy';
    }
  }

  let retValue:Cell = {
    v: value,
    t: type,
  };
  if (z)
    retValue.z = z;

  return retValue;
};

export function createCellFromJavascriptValue(value: any): Cell | null {
  if (value === null || value === undefined)
    return null;

  let asCell:Cell = {
    t: CellType.Stub,
    v: null
  }

  if (typeof value === 'number' && !isNaN(value)) {
    asCell.t = CellType.Number;
    asCell.v = value as number;
      //it's a number
  } else if (value instanceof Date && !isNaN(value.valueOf())) {
    asCell.t = CellType.Number;
    asCell.z = 'm/d/yyyy';
    asCell.v = toExcelDate(value as Date);
  } else if (typeof value === 'string' && value.length > 0) {
    asCell.t = CellType.String;
    asCell.v = value as string;
  } else if (typeof value === "boolean") {
    asCell.t = CellType.Boolean;
    asCell.v = value as boolean;
  } else {
    // stub or return null?
    return null;
  }

  return asCell;
}

export function create2DArrayFromString(
  dataCSV: string,
  delimiter = "\t"
): string[][] {
  // TODO - determine if papaparse builds and treeshakes.
  let data:any = papaparse.parse(dataCSV, { delimiter }).data;

  // auto remove first line if empty
  if (
    data.length > 0 &&
    (data[0].length === 0 || (data[0].length === 1 && data[0][0].length === 0))
  ) {
    data.shift();
  }
  // auto remove last line if empty
  if (
    data.length > 0 &&
    (data[data.length - 1].length === 0 ||
      (data[data.length - 1].length === 1 &&
        data[data.length - 1][0].length === 0))
  ) {
    data.pop();
  }

  return data;
}

/*
 * This will use the javascript data type to determine the cell type.
*/
export function createSheetDataFrom2DArray(data: any, creator:cellCreator): Cells {
  if (!creator)
    creator = createCellFromJavascriptValue;
  if (!Array.isArray(data))
    throw new Error('must be a 2d array');
  let retValue:Cells = {};

  for (let i=0;i<data.length;i++) {
    let rowInput = data[i];
    if (!Array.isArray(rowInput))
      throw new Error('must be a 2d array');
    for (let j=0;j<rowInput.length;j++) {
      let cell = createCellFromJavascriptValue(rowInput[j]);
      if (cell === undefined || cell === null)
        continue;
      let key = encode_col(j) + (i+1);
      retValue[key] = cell;
    }
  }

  return retValue;
}

export function createRangeFrom2DArray(data:any[][], sheetName:string=null) {
  let height = data.length;
  let width = 0;

  for (let i = 0; i < height; i++) {
    width = Math.max(width, data[i].length);
  }

  return new CellRange(0, 0, Math.max(0, width - 1), Math.max(0, height - 1), sheetName);
}

export default class SheetSource implements ISheetSource, IUpdatableSheetModel {
  private _data: SheetSourceData;
  private _listeners = new Set<CellListener>(); //WeakSet();

  constructor(data: SheetSourceData | null | undefined) {
    this._data = data;
    if (!this._data)
      this._data = { cells: {} };
  }

  getCellAt(columnIndex: number, rowIndex: number): Cell | null {
    let key = encode_col(columnIndex) + (rowIndex+1);
    let cellFound = this._data.cells[key];
    if (!cellFound) return null;
    return cellFound;
  }

  setCells(cells: ReferencedCell[]): void {
    if (!cells)
      return;

    // Note - We currently update this inline. Should we move to an immutable model? - It's the way
    let updates:CellUpdate[] = [];
    for (let i = 0; i < cells.length; i++) {
      updates.push({ columnIndex: cells[i].columnIndex, rowIndex: cells[i].rowIndex });

      let key = encode_col(cells[i].columnIndex) + (cells[i].rowIndex+1);
      if (cells[i].cell) {
        this._data.cells[key] = cells[i].cell;
      } else {
        delete this._data.cells[key];
      }
    }

    if (updates.length > 0) this.notifyUpdateListeners(updates);
  }

  // This is public so that external classes can notify too
  notifyUpdateListeners(updates: CellUpdate | CellUpdate[] | undefined | null) {
    this._listeners.forEach(function (listener: CellListener) {
      listener(updates);
    });
  }

  addUpdateListener(listener: CellListener): RemoveListener {
    // This function takes a function that is called when a value is updated
    // The listener will have pass an array of (x,y values)
    // This function will return a listener that
    let _listeners = this._listeners;
    let unListener:RemoveListener = function () {
      _listeners.delete(listener);
    };
    _listeners.add(listener);
    return unListener;
  }

  toJSON(): SheetSourceData {
    return this._data;
  }

}