import CommonUtils from "./CommonUtils";

import * as UnitCalcs from "./UnitCalcs";
//import { RangeDirection } from "../models/Range";

import LiteralRange from "../models/range/LiteralRange";
import LiteralValues from "../models/range/LiteralValues";

import SSF from "./10_ssf";

const regExNotWhiteSpace = /(\s)/;

export function getPointsPlotting(valRanges) {
  const pointValueRanges = []
  const pointValueDirection = [];

  const addValRange = function(range) {
     let numOfPoints = Math.max(range.width, range.height);
     for (let i=0; i<numOfPoints; i++) {
        pointValueDirection.push(range.width === 1 ? "c" : "r");
        pointValueRanges.push(range.slice(i));
     }
  }

  // We currently just use the first series to determine default x.
  let valRange = valRanges && valRanges.length > 0 ? valRanges[0] : null;
  if (Array.isArray(valRange)) {
     for (let i=0; i<valRange.length; i++)
         addValRange(valRange[i]);
  } else if (valRange)
     addValRange(valRange);

  let numOfPoints = pointValueRanges.length;

  return {
    numOfPoints,
    pointValueRanges,
    pointValueDirection
  }
}

export function getXValues(xRange, sheet, pointPlotting) {
  if (!xRange)
    xRange = new LiteralRange(generateOffsetArray(pointPlotting.numOfPoints));
  else if (xRange.isLiteralRange) {
    // This is a very special case where a literal was specified but didn't provide eneough values
    let asArray = xRange.asArray();
    if (asArray[0].length < pointPlotting.numOfPoints) {
        for (let i=asArray[0].length; i<pointPlotting.numOfPoints; i++) {
          asArray[0].push(null);
        }
        xRange = new LiteralRange(asArray);
    }
  }

  if (sheet === null) {
    return new LiteralValues(new LiteralRange([[null]]));
  }

  return sheet.valuesFromRange(xRange, function (valuesArray, rangesArray) {
      if (pointPlotting.pointValueDirection.length === 0)
        return null;
      /*
       * Note - We always want to return the xValues as an array of columns where if it
                is not multi level there is just one row.
                But to merge it's easier to deal with an array of rows
      */
      if (pointPlotting.pointValueDirection[0] === 'r') {
        for (let i=0; i<valuesArray.length; i++) {
          valuesArray[i] = CommonUtils.transpose(valuesArray[i]);
        }
      }

      let retValue = [];
      let maxWidth = 0;
      for (let i=0; i<valuesArray.length; i++) {
          for (let j=0; j<valuesArray[i].length; j++) {
              maxWidth = Math.max(maxWidth, valuesArray[i][j].length);
          }
          retValue = retValue.concat(valuesArray[i]);
      }
      for (let i=0; i<retValue.length; i++) {
          while (retValue[i].length < maxWidth)
              retValue[i].unshift(null);
      }

      // Always return in colum orientation
      retValue = CommonUtils.transpose(retValue);

      // Ensure there are enough points
      for (let i=0;i<retValue.length; i++) {
          for (let j=retValue[i].length;j<pointPlotting.numOfPoints; j++) {
              retValue[i].push(null);
          }
      }

      return retValue;
    }.bind(this));

}

export function generateOffsetArray(length, direction='r') {
  let offsets = [];
  for (let i=0; i<length; i++) {
      if (direction === 'c')
        offsets.push([i+1]);
      else
        offsets.push(i+1);
  }
  if (direction === 'r')
    offsets = [offsets];
  return offsets;
}

export function findLargestWord(text, fontMeasurer) {
  if (!text) return 0;
  const wordList = text.split(regExNotWhiteSpace);
  let largestSize = 0;
  for (let i = 0; i < wordList.length; i++) {
    let word = wordList[i].trim();
    if (word.length === 0) continue;
    let size = fontMeasurer(word);
    if (size > largestSize) largestSize = size;
  }
  return largestSize;
}

function offsets(values) {
  if (!values) return [1];
  const offsets = [];
  for (let i = 0; i < values.length; i++) offsets.push(i);
  return offsets;
}

function adjustLabelWidths(tickInfo, dims, horizontal, labels) {
  if (
    tickInfo.rotation === 0 &&
    horizontal &&
    tickInfo.labelValues.length > 1 &&
    dims
  ) {
    let insets = labels.text.insets;
    let axisDistance =
      (horizontal ? dims.axis.width : dims.axis.height) -
      (insets.left + insets.right);
    let naturalWidth = axisDistance / tickInfo.labelValues.length;
    tickInfo.tickWidth = naturalWidth;
  }
  return tickInfo;
}

function generateMinorOrdTicks(ticksMajor = [0]) {
  let minorTicks = [];
  for (let i = 0; i < ticksMajor.length; i++) {
    if (i > 0)
      minorTicks.push(
        (ticksMajor[i] - ticksMajor[i - 1]) / 2 + ticksMajor[i - 1]
      );
    minorTicks.push(ticksMajor[i]);
  }
  return minorTicks;
}

function addCulledItems(
  retValue,
  offsetValues,
  labelInterval,
  tickMarkSkip,
  crossBetween
) {
  offsetValues = offsetValues || [0];

  let offsets = offsetValues;
  if (crossBetween === "between" && offsetValues.length > 1) {
    offsets = [...offsetValues];
    offsets.unshift(offsets[0] - (offsets[1] - offsets[0]) / 2);
    offsets.push(
      offsets[offsets.length - 1] +
        (offsets[offsets.length - 1] - offsets[offsets.length - 2]) / 2
    );
  }

  retValue.plotValues = offsetValues;
  retValue.labelInterval = labelInterval;
  retValue.labelValues = UnitCalcs.generateMajorOrdTicks(
    offsetValues[0],
    offsetValues[offsetValues.length - 1],
    1
  );
  retValue.labelTicks = retValue.labelValues; //UnitCalcs.generateMajorOrdTicks(offsetValues[0], offsetValues[offsetValues.length-1], labelInterval);

  retValue.tickInterval = tickMarkSkip;
  retValue.ticks = offsets;
  retValue.majorTicks = UnitCalcs.generateMajorOrdTicks(
    offsets[0],
    offsets[offsets.length - 1],
    tickMarkSkip
  );
  retValue.minorTicks = generateMinorOrdTicks(retValue.majorTicks);
  retValue.min = 1; //offsetValues[0] + 1;
  retValue.max = retValue.labelTicks.length + 1; //Math.max(1, offsetValues[offsetValues.length-1]) + 1;

  return retValue;
}

export function calcAxisLimitsOrd(
  values,
  eInterval,
  eRotation,
  dims,
  horizontal = true,
  allowOverlap = true,
  tickMarkSkip = 1,
  crossBetween = "between",
  labels,
  mockCharWidth /*for unit tests*/
) {
  eRotation = CommonUtils.isDefined(eRotation) ? eRotation : undefined;
  eInterval = CommonUtils.isDefined(eInterval) ? eInterval : undefined;
  let impliedInterval = eInterval || 1;
  let offsetValues = offsets(values || [0]);

  let retValue = {
    values: values,
    crossBetween: crossBetween,
    rotation: eRotation || 0,
  };

  if (dims === null || dims === undefined) {
    // just for testing
    dims = {
      axis: {
        width: 1000,
        height: 30,
      },
    };
  }

  if (values.length < 2) {
    return adjustLabelWidths(
      addCulledItems(
        retValue,
        offsetValues,
        impliedInterval,
        tickMarkSkip,
        crossBetween
      ),
      dims,
      horizontal,
      labels
    );
  }

  let axisDistance = horizontal ? dims.axis.width : dims.axis.height;

  // TODO -
  // When label text is larger than 50% of chart they 'collapse'.
  // If horizontal then just collapses to 0 rotation with no width (ugly but easy)

  // If vertically then:
  // a. Turn on wordwrapping
  // b. Make labels width = plotBounds.width * 0.5 (see code below)
  // c. Make sure label height is maxheight of two lines
  // TODO - for vertical we need to also find the largest word without wrapping
  //     if (largestLabelWidth > plotBounds.width * 0.50) {
  //         tickWidth = plotBounds.width * 0.50;
  //         tickMaxHeight = 2 lines;
  //         tickOverflow = '...';
  //     }

  let metrics = UnitCalcs.getMetricsForLabel(values[0], labels, horizontal);

  // If horizontal we see if we can wrap them
  if (horizontal) {
    let naturalWidth = axisDistance / (values.length / impliedInterval);
    let fitsNaturally = true;
    for (
      let i = 0;
      fitsNaturally && i < values.length;
      i = i + impliedInterval
    ) {
      // Note - We end of formatting twice. We could be smarter about this.
      let value = values[i];
      try {
        value = SSF.format(labels.formatCode, value);
      } catch (error) {
        console.warn("Can't format '" + value + "'.", error);
      }
      let largestWord = findLargestWord(value, function (text) {
        if (mockCharWidth) return text.length * mockCharWidth;
        else
          return UnitCalcs.getMetricsForLabel(text, labels, horizontal).width;
      });
      if (largestWord + 1 > naturalWidth) {
        // We add one for 'rounding errors'
        fitsNaturally = false;
      }
    }
    if (fitsNaturally) {
      return adjustLabelWidths(
        addCulledItems(
          retValue,
          offsetValues,
          impliedInterval,
          tickMarkSkip,
          crossBetween
        ),
        dims,
        horizontal,
        labels
      ); // It fits at 0.
    }
  }

  let rotationsToCheck = [];
  if (eRotation !== undefined) {
    let rotationCustom = labels.rotation;
    rotationsToCheck.push({
      deg: rotationCustom,
      distance: UnitCalcs.calcDistanceNeededAtAxis(
        axisDistance,
        metrics.height,
        eRotation
      ),
    });
  } else {
    if (horizontal) {
      if (allowOverlap)
        rotationsToCheck.push({
          deg: -45,
          distance: UnitCalcs.calcDistanceNeededAtAxis(
            axisDistance,
            metrics.height,
            -45
          ),
        });
      rotationsToCheck.push({
        deg: -90,
        distance: UnitCalcs.calcDistanceNeededAtAxis(
          axisDistance,
          metrics.height,
          -90
        ),
      });
    } else {
      // vertical only culls against 0
      rotationsToCheck.push({
        deg: 0,
        distance: metrics.height, //UnitCalcs.calcDistanceNeededAtAxis(axisDistance, metrics.width, 0)
      });
    }
  }

  // this will just keep doubling the intervals until we are greater than the values count or it fits
  let rotationCurrent = undefined;
  let intervalLabelsCurrent = impliedInterval;
  if (eRotation === undefined || eInterval === undefined) {
    do {
      for (
        let i = 0;
        rotationCurrent === undefined && i < rotationsToCheck.length;
        i++
      ) {
        let distanceCheck = rotationsToCheck[i].distance;
        if (
          axisDistance / (values.length / intervalLabelsCurrent) >
          distanceCheck
        )
          rotationCurrent = rotationsToCheck[i].deg;
      }
      // If an explict interval was specified than just take the last rotation
      if (
        eInterval !== undefined ||
        intervalLabelsCurrent + 1 >= values.length / 2
      ) {
        rotationCurrent = rotationsToCheck[rotationsToCheck.length - 1].deg;
      } else if (rotationCurrent === undefined)
        intervalLabelsCurrent = intervalLabelsCurrent + 1; //* 2;
    } while (rotationCurrent === undefined);
  }
  // We cycled through rotation to find the best interval but we need to return the user's inputs
  if (eRotation !== undefined) rotationCurrent = eRotation;
  if (eInterval !== undefined) intervalLabelsCurrent = eInterval;

  retValue.rotation = rotationCurrent;

  return adjustLabelWidths(
    addCulledItems(
      retValue,
      offsetValues,
      intervalLabelsCurrent,
      tickMarkSkip,
      crossBetween
    ),
    dims,
    horizontal,
    labels
  );
}
