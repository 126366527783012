import DAGM from "../../../dagm/Dagm";
import PropertyPersister from "../../../dagm/PropertyPersister";

import { createFillProperty } from "../../AbstractShape";

import {
  isLineType,
  isSingleSeries,
  scatterHasLine,
} from "../../../utils/ChartUtils";

import ChartDataLabelShape from "../ChartDataLabelShape";

class ChartDataPointDataLabelShape extends ChartDataLabelShape {
  constructor(options, styleKey) {
    super(Object.assign(options, { noDefaultProperties: true }), null/*style*/); // With a null style the styling properties are not unnecessarily defined
    this._styleKey = styleKey;
    const _self = this;

    const _seriesLabels = options.seriesLabels;
    this.addProperty("seriesLabels", {
      isReadOnly: true,
      isTransient: true,
      defaultValue: function () {
        return _seriesLabels;
      },
    });

    this.addProperty("shown", { defaultValue: true });
    this.addProperty("rotation", { defaultValue: 0.0 });

    let _pointVal = options.pointVal;
    this.addProperty("pointVal", {
      isReadOnly: true,
      isTransient: true,
      defaultValue: function () {
        return _pointVal;
      },
    });

    let _pointX = options.pointX;
    this.addProperty("pointX", {
      isReadOnly: true,
      isTransient: true,
      defaultValue: function () {
        return _pointX;
      },
    });

    this.addProperty("sourceLinked", {
      defaultValue: function (inherited) {
        return inherited;
      },
      inputs: ["seriesLabels.sourceLinked"],
    });

    // Note - We listen to the point value to know that the cell has changed.
    //        At the moment there is no difference between a cellchange and a cellvalue change.
    this.addProperty("formatCode", {
      defaultValue: function (inherited, sourceLinked, grouping, asCells) {
        if (this.getPropertyValue("seriesLabels.formatCode").isExplicit) {
            return inherited;
        }
        // only axis labels do this
        // if (grouping === "percentStacked")
        //   return "0%"; // note - if percentStacked then sourceLinked is ignored
        let z = "General";
        if (sourceLinked && asCells && asCells.length > 0 && asCells[0].length > 0) {
          let cell =  asCells[0][0];
          if (cell && cell.z) {
            z = cell.z;
          }
        }
        return z;
      }, inputs: ["seriesLabels.formatCode", "sourceLinked", "seriesLabels.series.chartType.grouping", "pointVal.asCells"]
    });

    this.overrideProperty("shown", {
      defaultValue: function (inherited) {
        return inherited;
      },
      inputs: ["seriesLabels.shown"],
    });

//     this.addProperty("valudFromCells", { defaultValue: false });
    this.addProperty("showPercentage", {
      defaultValue: function (inherited) {
        return inherited;
      },
      inputs: ["seriesLabels.showPercentage"],
    });
    this.addProperty("showSerName", {
      defaultValue: function (inherited) {
        return inherited;
      },
      inputs: ["seriesLabels.showSerName"],
    });
    this.addProperty("showCatName", {
      defaultValue: function (inherited) {
        return inherited;
      },
      inputs: ["seriesLabels.showCatName"],
    });
    this.addProperty("showVal", {
      defaultValue: function (inherited) {
        return inherited;
      },
      inputs: ["seriesLabels.showVal"],
    });
    this.addProperty("showLeaderLines", {
      defaultValue: function (inherited) {
        return inherited;
      },
      inputs: ["seriesLabels.showLeaderLines"],
    });
    this.addProperty("showLegendKey", {
      defaultValue: function (inherited) {
        return inherited;
      },
      inputs: ["seriesLabels.showLegendKey"],
    });
    this.addProperty("showBubbleSize", {
      defaultValue: function (inherited) {
        return inherited;
      },
      inputs: ["seriesLabels.showBubbleSize"],
    });
    this.addProperty("separator", {
      defaultValue: function (inherited) {
        return inherited;
      },
      inputs: ["seriesLabels.separator"],
    });

    /*
    * https://c-rex.net/projects/samples/ooxml/e1/Part4/OOXML_P4_DOCX_ST_DLblPos_topic_ID0EJ3QRB.html#topic_ID0EJ3QRB
    */

    this.addProperty("position", {
      defaultValue: function (inherited) {
        return inherited;
      },
      inputs: ["seriesLabels.position"],
    });

    // delegate shapeProperties

    // fill
    createFillProperty(this, this.styleKey, 'fill', "chartShape.chartStyle", function(style, styleKey, seriesFill, varyColors, chartType) {
      if ((_self.seriesLength === 1 || isSingleSeries(chartType)) &&
        varyColors && !this.getPropertyValue("seriesLabels.fill").isExplicit)
        return style.createDataPoints2DFillStyleProperties(
          _self.offset,
          _self.length
        );
      else return seriesFill;
    }, [
      "seriesLabels.fill",
      "seriesLabels.series.chartType.varyColors",
      "seriesLabels.series.chartType.type",
    ], 'fill');

    // strokeFill
    createFillProperty(this, this.styleKey, 'strokeFill', "chartShape.chartStyle", function(style, styleKey,
        strokeFill,
        varyColors,
        chartType,
        scatterStyle) {
        if (
          (_self.seriesLength === 1 || isSingleSeries(chartType)) &&
          varyColors &&
          !this.getPropertyValue("seriesLabels.strokeFill").isExplicit
        ) {
          if (isLineType(style, scatterStyle)) {
            if (scatterHasLine(scatterStyle)) {
              return chartStyle.createDataPoints2DFillStyleProperties(
                _self.offset,
                _self.length
              );
            } else {
              return style.createNoneFill();
            }
          } else {
            return style.createDataPoints2DStrokeStyleProperties(
              _self.offset,
              _self.length
            ).fill;
          }
        } else return strokeFill;
    }, [
        "seriesLabels.strokeFill",
        "seriesLabels.series.chartType.varyColors",
        "seriesLabels.series.chartType.type",
        "seriesLabels.series.chartType.scatterStyle",
    ], 'stroke.fill');

    this.addProperty("strokeWidth", {
      defaultValue: function (strokeWidth) {
        return strokeWidth;
      },
      inputs: ["seriesLabels.strokeWidth"],
      persister: new PropertyPersister("stroke.width"),
    });

    this.addProperty("strokeLineJoin", {
      defaultValue: function (strokeLineJoin) {
        return strokeLineJoin;
      },
      inputs: ["seriesLabels.strokeLineJoin"],
    });

    this.addProperty("strokeLineCap", {
      defaultValue: function (strokeLineCap) {
        return strokeLineCap;
      },
      inputs: ["seriesLabels.strokeLineCap"],
    });

    this.addProperty("strokeDash", {
      defaultValue: function (strokeDash) {
        return strokeDash;
      },
      inputs: ["seriesLabels.strokeDash"],
    });

    // rendering not implemented
    // this.addProperty("strokeCompound", {
    //   defaultValue: function(strokeCompound) {
    //     return strokeCompound;
    //   },
    //   inputs: ["seriesLabels.strokeCompound"]
    // });
  }

  get className() {
    return "ChartDataPointDataLabelShape";
  }

  get typeId() {
    return this.className + ":" + this._styleKey;
  }
}

export default ChartDataPointDataLabelShape;


export class ChartDataPointDataLabelShapePersistor extends PropertyPersister {
  constructor(path, options) {
    super(path);
    this.options = options;
  }

  toJSON(value) {
    if (value instanceof ChartDataPointDataLabelShape) {
      return value.toJSON();
    }

    return value;
  }

  fromJSON(jsonValue, index) {
    let _self = this;
    return DAGM.newFromJSON(jsonValue, () => {
      let retValue = new ChartDataPointDataLabelShape(
        {
          chartShape: _self.options.chartShape,
          seriesLabels: _self.options.seriesLabels,
          pointX: _self.options.getPointX(index),
          pointVal: _self.options.getPointVal(index),
          appContext: _self.options.appContext
        },
        "dataLabel", // styleKey
      );
      return retValue;
    });
  }
}