import { ImmutableRectangle } from './Coordinates';
import AdjustableColor, { resolveAdjustableColor } from '../colors/AdjustableColor';
import { SchemeColorLookup } from '../colors/AdjustableColor';

export default abstract class Fill {
  _private: any
  constructor() {
    this._private = {}
  }

  get isRotatedWithShape():boolean {
    return !!this._private.isRotatedWithShape;
  }
  readonly abstract type: string;
  abstract toJSON(): any;
}

export class Tile {
  _private: any;
  constructor(json: any) {
    this._private = {
        json : json
    }
  }
  toJSON() {
    return this._private.json;
  }
  get align() {
    return this._private.json.align;
  }
  get mirror() {
    return this._private.json.mirror;
  }
  get bounds() {
    if (!this._private.bounds && this._private.json.bounds)
      this._private.bounds = new ImmutableRectangle(this._private.json.bounds);

    return this._private.bounds;
  }
}

export class NoneFill extends Fill {
  toJSON() {
    return {
      none: true
    }
  }
  get type() {
    return 'none';
  }
}
export class BackgroundFill extends Fill {
  toJSON() {
    return {
      background: true
    }
  }
  get type() {
    return 'background';
  }
}
export class GroupFill extends Fill {
  toJSON() {
    return {
      group: true
    }
  }
  get type() {
    return 'group';
  }
}

export class SolidFill extends Fill {
  constructor(json: any, schemeLookup?: SchemeColorLookup) {
    super();
    this._private.color = resolveAdjustableColor(json, schemeLookup);
    this._private.json = this._private.color.toJSON();
  }

  get color() {
    return this._private.color;
  }

  toRGBAColor() {
    return this._private.color.toRGBAColor();
  }

  toJSON() {
    return {
      solid : this._private.json
    }
  }
  get type() {
    return 'solid';
  }
}

export class PatternFill extends Fill {
  constructor(fill: any, schemeLookup?: SchemeColorLookup) {
    super();
    this._private.json = fill;
    this._private.foreground = resolveAdjustableColor(fill.foreground, schemeLookup);
    this._private.background = resolveAdjustableColor(fill.background, schemeLookup);
  }

  get patternType():string {
    return this._private.json.type;
  }

  get foreground():AdjustableColor {
    return this._private.foreground;
  }

  get background():AdjustableColor {
    return this._private.background;
  }

  toJSON() {
    return {
      pattern : this._private.json
    }
  }
  get type() {
    return 'pattern';
  }
}


export class GradientStop {
  _offset: number;
  _color: AdjustableColor;

  constructor(offset: number, color: AdjustableColor) {
    this._offset = offset;
    this._color = color;
  }
  get offset() {
    return this._offset;
  }
  get color() {
    return this._color;
  }
}
/**
 * TODO - fill to and tile are treated differently in office but are similiar
          concepts for both gradient and image. We need to review and rationalize
*/
export class GradientFill extends Fill {
  constructor(json: any, schemeLookup?: SchemeColorLookup) {
    super();

    this._private.json = json;
    this._private.stops = [];
    for (let i=0; i< json.stops.length; i++) {
      this._private.stops.push(new GradientStop(json.stops[i].offset, resolveAdjustableColor(json.stops[i].color, schemeLookup)));
    }
  }

  get stops():GradientStop[] {
    return this._private.stops;
  }
  get gradientType():string {
    return this._private.json.type;
  }
  get angle():number {
    return this._private.json.angle;
  }

  get fillTo():ImmutableRectangle {
    if (!this._private.fillTo && this._private.json.fillTo)
      this._private.fillTo = new ImmutableRectangle(this._private.json.fillTo);

    return this._private.fillTo
  }

  get tile():Tile {
    if (!this._private.tile && this._private.json.tile)
      this._private.tile = new Tile(this._private.json.tile);

    return this._private.tile
  }

  toJSON() {
    return {
      gradient : this._private.json
    }
  }
  get type() {
    return 'gradient';
  }
}


export class ImageFill extends Fill {
  constructor(json: any, schemeLookup?: SchemeColorLookup) {
    super();
    this._private.json = json;
  }

  get ref():string {
    return this._private.json.ref;
  }

  get alpha():number {
    return this._private.json.alpha;
  }

  get insets():ImmutableRectangle {
    if (!this._private.insets && this._private.json.insets)
      this._private.insets = new ImmutableRectangle(this._private.json.insets);

    return this._private.insets
  }

  get stretch():ImmutableRectangle {
    if (!this._private.stretch && this._private.json.stretch)
      this._private.stretch = new ImmutableRectangle(this._private.json.stretch);

    return this._private.stretch
  }

  get tile():Tile {
    if (!this._private.tile && this._private.json.tile)
      this._private.tile = new Tile(this._private.json.tile);

    return this._private.tile
  }

  toJSON() {
    return {
      image : this._private.json
    }
  }
  get type() {
    return 'image';
  }
}

const isObject = function(obj: any) {
  return obj !== undefined && obj !== null && obj.constructor == Object;
}

export const resolveFill = (fill: any, schemeLookup: SchemeColorLookup ): Fill | null => {
  if (!fill) {
    throw new Error('No fill');
  }

  if (fill instanceof Fill) {
    fill = fill.toJSON();
  }
  // assume a solid json fill
  if (typeof fill === "string") {
      fill = { solid: fill };
  }

  if (!isObject(fill))
    throw new Error('Fill is incorrect type: ' + fill);

  if (fill.none)
    return new NoneFill();
  else if (fill.background)
    return new BackgroundFill();
  else if (fill.group)
    return new GroupFill();
  else if (fill.solid)
    return new SolidFill(fill.solid, schemeLookup);
  else if (fill.gradient)
    return new GradientFill(fill.gradient, schemeLookup);
  else if (fill.pattern)
    return new PatternFill(fill.pattern, schemeLookup);
  else if (fill.image)
    return new ImageFill(fill.image, schemeLookup);

  console.warn('invalid fill type', fill);
  return null;
}