import { displayUnitsText } from "../../../utils/ChartUtils";

import { ManualLayoutPersister, manualLayoutSetter } from "../../primitives/Coordinates";

import { stringFromCells } from "../../range/RangeUtils";
import { RangeSelectionPersister, createRangeSelectionSetter } from "../../range/MultiRange";
import LiteralRange from "../../range/LiteralRange";
import LiteralValues from "../../range/LiteralValues";

import ChartPartShape from "../ChartPartShape";
import ChartTextShape from "../ChartTextShape";
class ChartAxisDisplayUnitLabelShape extends ChartPartShape {
  constructor(options) {
    super(options, "axisTitle"); // This key is used for styling
    const _self = this;

    const getDefaultDisplayUnitLabel = options?.getDefaultDisplayUnitLabel ||  null;

    let _chartAxis = options.chartAxis;
    this._chartAxis = _chartAxis;
    this.addProperty("axis", {
      isReadOnly: true,
      isTransient: true,
      defaultValue: function () {
        return _chartAxis;
      },
    });

    // TODO - remove this?
    this.addProperty("description", {
      isReadOnly: true,
      isTransient: true,
      defaultValue: function (horizontal, offset, simpleRun) {
        let retValue = horizontal ? "Horizontal" : "Vertical";
        if (offset === 1) retValue += " Secondary";
        else if (offset > 1) retValue += " Additional (" + (offset + 1) + ")";
        retValue += ' Axis Display Label "' + simpleRun + '"';
        return retValue;
      },
      inputs: ["axis.horizontal", "axis.offset", "text.simpleRun"],
    });

    this.overrideProperty("shown", {
      defaultValue: function (displayUnit) {
        if (displayUnit === null || displayUnit === "none") return false;

        return true;
      },
      inputs: ["axis.displayUnits"],
    });

    this.addProperty("overlay", {
      defaultValue: false,
    });

    this.overrideProperty("rotation", {
      setValue: function (value) {
        // Office doesn't allow from UI but does from datamodel
        // if (value !== undefined)
        //   throw new Error('For compatibility with Office rotation is not allowed.');
        return value;
      },
      defaultValue: function (horizontal) {
        // Note - I think it would be nicer if the title rotation was based on placement (for example on right it would rotation 90 but on left -90)
        if (!horizontal) return -90.0;
        return 0.0;
      },
      inputs: ["axis.horizontal"],
    });

    /**
     * This is implements as a rect (x,y,width,height) with percentanges of the containing elmement.
     * If null then no manualLayout is applied
     * Note -
     * This is to spec see :
     * https://docs.microsoft.com/en-us/openspecs/office_standards/ms-oi29500/bb8565e7-dfea-453f-868b-ae680ab8a823?redirectedfrom=MSDN
     */
    this.addProperty("manualLayout", {
      setValue: manualLayoutSetter,
      defaultValue: function () {
        return null;
      },
      persister: new ManualLayoutPersister(),
    });

    this.addProperty("range", {
      setValue: createRangeSelectionSetter(true/*singleLength*/, false/*alignRanges*/, true/*forceFixed*/),
      defaultValue: function () {
        let retValue = null;
        if (!retValue && getDefaultDisplayUnitLabel) {
          const defaultLiteralValue = getDefaultDisplayUnitLabel(_self);
          if (defaultLiteralValue !== null && defaultLiteralValue !== undefined) {
            return new LiteralRange([[defaultLiteralValue]]);
          }
        }
        return retValue;
      },
      inputs: [],
      persister: new RangeSelectionPersister(),
    });

    this.addProperty("values", {
      isReadOnly: true,
      defaultValue: function (sheet, titleRange) {
        if (sheet === null) {
          return new LiteralValues(new LiteralRange([[null]]));
        }
        // Note - Office doesn't allow for literal ranges for titles. We do
        return sheet.valuesFromRange(titleRange);
      },
      inputs: ["chartShape.sheet", "range"],
    });

    this.addProperty("defaultText", {
      isReadOnly: true,
      isTransient: true,
      defaultValue: function (displayUnit, asCells) {
        if (!asCells || asCells.length === 0) {
          return displayUnitsText(displayUnit);
        }

        return stringFromCells(asCells);
      },
      inputs: ["axis.displayUnits", "values.asCells"],
    });

    this.addProperty("text", {
      defaultValue: function () {
        return new ChartTextShape(
          Object.assign({}, options, { parent: _self}),
          this.getProperty('defaultText'),
          _self._styleKey,
          13.3
        );
      },
      persister: null /* we implicitly save models*/,
      inputs: ["defaultText"],
    });

  }

  get className() {
    return "ChartAxisDisplayUnitLabelShape";
  }

  get typeId() {
    return this.className + ":" + this.direction + ":" + this.offset;
  }

}

export default ChartAxisDisplayUnitLabelShape;
