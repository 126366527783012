let _scopeContext = [];

export function pushScopeContext(scopeContext) {
  _scopeContext.push(scopeContext);
  return _scopeContext.length;
}

export function popScopeContext() {
  return _scopeContext.pop();
}

export function getScopeContext() {
  return _scopeContext.length > 0
    ? _scopeContext[_scopeContext.length - 1]
    : null;
}

const scopeContextStack = {
  pushScopeContext: pushScopeContext,
  popScopeContext: popScopeContext,
  getScopeContext: getScopeContext,
};

const ScopeContextStack = Object.freeze(scopeContextStack);
export default ScopeContextStack;
