import ChartPieDataPointShape from "../point/ChartPieDataPointShape";

import ChartSeriesShape from "./ChartSeriesShape";

class ChartPieSeriesShape extends ChartSeriesShape {
  constructor(options, offset, seriesLength) {
    super(options, offset, seriesLength);
  }

  _internalCreateDataPoint(
    chartShape,
    _self,
    pointXRange,
    pointValueRange,
    index,
    length,
    offset,
    seriesLength
  ) {
    return new ChartPieDataPointShape(
      chartShape,
      _self,
      pointXRange,
      pointValueRange,
      index,
      length,
      offset,
      seriesLength
    );
  }

  get className() {
    return "ChartPieSeriesShape";
  }
}

export default ChartPieSeriesShape;
