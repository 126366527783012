import AbstractModel from "../../../dagm/AbstractModel";

import { getPointsPlotting, getXValues } from "../../../utils/OrdUnitCalcs";

import { isDateFormatter } from "../../../utils/DateUtils";
class ChartCatAxisPreferenceModel extends AbstractModel {
  constructor(chartShape, xRange) {
    super();
    const _self = this;

    this.addProperty("chartShape", {
      isReadOnly: true,
      isTransient: true,
      defaultValue: function () {
        return chartShape;
      },
    });

    this.addProperty('pointPlotting', {
      isTransient: true,
      defaultValue: function (valRanges) {
       return getPointsPlotting(valRanges);
     },
     inputs: ["chartShape.series[*].valRange"],
    });

    this.addProperty("xRange", {
      isReadOnly: true,
      isTransient: true,
      defaultValue: function () {
        return xRange;
      },
    });

    this.addProperty("xValues", {
      isReadOnly: true,
      defaultValue: function (xRange, sheet, pointPlotting) {
        return getXValues(xRange, sheet, pointPlotting);
      },
      inputs: ["xRange", "chartShape.sheet", "pointPlotting"],
    });

    this.addProperty("isDateAxisEligable", {
      isReadOnly: true,
      isTransient: true,
      defaultValue: function (valuesCells) {
        if (valuesCells.length > 1) // multilevel axis can not be date
            return false;

         let level = 0;
         for (let i = 0; i < valuesCells[level].length; i++) {
          let value = valuesCells[level][i];
          if (!value)
            continue;
          if (value.t !== 'n' || !value.z)
            return false;
          if (!isDateFormatter(value.z))
            return false;
         }
        return true;
      },
      inputs: ["xValues.asCells"],
    });

    this.addProperty("type", {
      defaultValue: function (isDateAxisEligable) {
        if (isDateAxisEligable) return "date";
        return "ord";
      },
      inputs: ["isDateAxisEligable"],
    });
  }

  get className() {
    return "ChartCatAxisPreferenceModel";
  }

}

export default ChartCatAxisPreferenceModel;
