import { stringFromCells } from "../../range/RangeUtils";

import { RangeSelectionPersister, createRangeSelectionSetter } from "../../range/MultiRange";
import LiteralRange from "../../range/LiteralRange";

import ChartPartShape from "../ChartPartShape";
import ChartSeriesTitleTextShape from "./ChartSeriesTitleTextShape";

class ChartSeriesTitleShape extends ChartPartShape {
  constructor(options) {
    super(options, "legend"); // ooxml uses the series title for legend entries
    const _self = this;

    let _series = options.series;
    const getDefaultSeriesLabel = options?.getDefaultSeriesLabel || null;

    this.addProperty("series", {
      isReadOnly: true,
      isTransient: true,
      defaultValue: function () {
        return _series;
      },
    });

    this.addProperty("range", {
      setValue: createRangeSelectionSetter(true/*singleLength*/, false/*alignRanges*/, true/*forceFixed*/),
      defaultValue: function (ranges, valRange, offset, seriesLength) {
        let retValue = ranges.getTitleRangeAt(offset);
        if (!retValue && valRange && getDefaultSeriesLabel) {
          // hack - because we don't have a clear valrange and valRange persistors not convert
          if (Array.isArray(valRange)) {
            valRange = valRange[0];
          }
          const defaultSeriesLabel = getDefaultSeriesLabel(valRange, offset, seriesLength);
          if (defaultSeriesLabel) {
            return new LiteralRange([[defaultSeriesLabel]]);
          }
        }
        return retValue;
      },
      inputs: ["chartShape.ranges", "series.valRange", "series.offset", "series.seriesLength"],
      persister: new RangeSelectionPersister(),
    });

    this.addProperty("values", {
      isReadOnly: true,
      defaultValue: function (sheet, titleRange) {
        // Note - Office doesn't allow for literal ranges for titles. We do
        if (sheet === null)
          return null;
        return sheet.valuesFromRange(titleRange);
      },
      inputs: ["chartShape.sheet", "range"],
    });

    this.addProperty("defaultText", {
      isReadOnly: true,
      isTransient: true,
      defaultValue: function (asCells, idx) {
        if (!asCells || asCells.length ==0)
          return "Series " + (idx + 1);
        return stringFromCells(asCells);
      },
      inputs: ["values.asCells", "series.idx"],
    });

    this.addProperty("text", {
      defaultValue: function () {
        return new ChartSeriesTitleTextShape(
          Object.assign({}, options, { parent: _self}),
          this.getProperty('defaultText'),
          _self._styleKey
        );
      },
      persister: null /* we implicitly save models*/,
      inputs: ["defaultText"],
    });
  }

  get className() {
    return "ChartSeriesTitleShape";
  }

  get typeId() {
    return this.className + ":" + (this._offset + 1) + ":title";
  }
}

export default ChartSeriesTitleShape;
