import ArrayModel from "../../../dagm/ArrayModel";

import ChartDataLabelShape, { addDataLabelShown } from "../ChartDataLabelShape";
import { ChartDataPointDataLabelShapePersistor } from "../point/ChartDataPointDataLabelShape";

import ChartDataPointDataLabelShape from "../point/ChartDataPointDataLabelShape";

class ChartSeriesDataLabelShape extends ChartDataLabelShape {
  constructor(options, styleKey) {
    super(options, styleKey);
    const _self = this;

    let _series = options.series;
    let _pointsData = options.points;

    this.addProperty("series", {
      isReadOnly: true,
      isTransient: true,
      defaultValue: function () {
        return _series;
      },
    });

    // TODO - default from chartShape or chartType
    this.addProperty("sourceLinked", {
      defaultValue: function () {
        return true;
      },
    });

    // This is used to watch for the first value for formatCode
    this.addProperty("firstCell", {
      isReadOnly: true,
      isTransient: true,
      defaultValue: function (asCells) {
          if (!asCells || asCells.length === 0 || asCells[0].length === 0)
            return null;

          return asCells[0][0];
      },
      inputs: ["series.valValues.asCells"]
    });

    // Note - We listen to the point value to know that the cell has changed.
    //        At the moment there is no difference between a cellchange and a cellvalue change.
    let formatInputs = ["sourceLinked", "series.chartType.grouping"];
    formatInputs.push("series.valRange");
    formatInputs.push("firstCell");

    this.addProperty("formatCode", {
      defaultValue: function (sourceLinked, grouping, range, firstCell) {
        // only axis labels do this
        // if (grouping === "percentStacked")
        //   return "0%"; // note - if percentStacked then sourceLinked is ignored
        let z = "General";
        if (sourceLinked && range && range.isCellRange) {
          if (firstCell && firstCell.z) {
            z = firstCell.z;
          }
        }
        return z;
      }, inputs: formatInputs
    });


//     this.addProperty("valudFromCells", { defaultValue: false });
    this.addProperty("showPercentage", {
      defaultValue: function (inherited) {
        return inherited;
      },
      inputs: ["series.chartType.dataLabels.showPercentage"],
    });
    this.addProperty("showSerName", {
      defaultValue: function (inherited) {
        return inherited;
      },
      inputs: ["series.chartType.dataLabels.showSerName"],
    });
    this.addProperty("showCatName", {
      defaultValue: function (inherited) {
        return inherited;
      },
      inputs: ["series.chartType.dataLabels.showCatName"],
    });
    this.addProperty("showVal", {
      defaultValue: function (inherited) {
        return inherited;
      },
      inputs: ["series.chartType.dataLabels.showVal"],
    });

    // only for bubble
    this.addProperty("showBubbleSize", {
      defaultValue: function (inherited) {
        return inherited;
      },
      inputs: ["series.chartType.dataLabels.showBubbleSize"],
    });

    // Not supported
    this.addProperty("showLeaderLines", {
      defaultValue: function (inherited) {
        return inherited;
      },
      inputs: ["series.chartType.dataLabels.showLeaderLines"],
    });

    this.addProperty("showLegendKey", {
      defaultValue: function (inherited) {
        return inherited;
      },
      inputs: ["series.chartType.dataLabels.showLegendKey"],
    });

    addDataLabelShown(this);

    this.addProperty("separator", {
      defaultValue: function (inherited) {
        return inherited;
      },
      inputs: ["series.chartType.dataLabels.separator"],
    });

    /*
    * https://c-rex.net/projects/samples/ooxml/e1/Part4/OOXML_P4_DOCX_ST_DLblPos_topic_ID0EJ3QRB.html#topic_ID0EJ3QRB
    */
    this.addProperty("position", {
      defaultValue: function (inherited, grouping) {
        if (grouping === "percentStacked" || grouping === "stacked")
          return "ctr"; // note - if percentStacked then center to fit
        return inherited;
      },
      inputs: ["series.chartType.dataLabels.position", "series.chartType.grouping"],
    });

    this.addProperty("points", {
      isReadOnly: true,
      defaultValue: function (shown) {
        if (!shown)
          return null;

        let retValue = new ArrayModel(_pointsData ? _pointsData.length : 0, {
          defaultValue: function (index, length) {
            return null;
          },
          inputs: ["$index", "length"],
          persister: new ChartDataPointDataLabelShapePersistor(null, {
            chartShape: _self.chartShape,
            seriesLabels: _self,
            getPointX : function(index) {
              let point = _pointsData[index];
              if (!point)
                return null;
              return point.pointX;
            },
            getPointVal : function(index) {
              let point = _pointsData[index];
              if (!point)
                return null;
              return point.pointVal;
            },
            appContext: _self._shapeOptions.appContext
          })
        });
        return retValue;
      },
      persister: null /* we implicitly save models*/,
      inputs: ["shown"],
    });

    this.createDataPointLabel = function(index, readonly=false) {
      const pointData = _pointsData[index];
      if (!pointData)
        return null;

      const retValue = new ChartDataPointDataLabelShape({
          chartShape: _self._shapeOptions.chartShape,
          seriesLabels: _self,
          pointX: pointData.pointX,
          pointVal: pointData.pointVal,
          appContext: _self._shapeOptions.appContext,
          readonly: readonly
        });
      return retValue;
    }

    this.addProperty("renderedPoints", {
      isReadOnly: true,
      defaultValue: function (points) {
        if (points === null)
          return null;

        let retValue = [];
        for (let i=0; i<points.length; i++) {
          let pointLabel = points.getAt(i);
          if (pointLabel === null) {
            pointLabel = _self.createDataPointLabel(i, true/*readonly*/);
          }
          retValue.push(pointLabel);
        }
        return retValue;
      },
      persister: null /* we implicitly save models*/,
      inputs: [
        "points",
        "points[*]",
        "formatCode",
        "showSerName",
        "showCatName",
        "showVal",
        "showPercentage",
        "showBubbleSize",
        "showLeaderLines",
        "showLegendKey",
        "separator",
        "position"
      ],
    });
  }

  get className() {
    return "ChartSeriesDataLabelShape";
  }

  get typeId() {
    return this.className + ":" + this._styleKey;
  }
}

export default ChartSeriesDataLabelShape;
