import { Font, IFont } from "./Font";

/**
 * This list is curatored from a number
 * of safe-font websites. There does not
 * seem to be a standardized list
 * like: https://kbpdfstudio.qoppa.com/standard-14-pdf-fonts/
 */
const defaultFonts = [
  "Arial",
  "Arial Black",
  // "Bradley Hand",
  "Brush Script MT",
  // "Bookman Old Style",
  "Calibri",
  "Comic Sans MS",
  "Courier New",
//   "Didot",
  "Garamond",
  "Georgia",
  // "Helvetica",
  "Impact",
  // "Lucida",
  // "Luminari",
  // "Monaco",
  "Palatino Linotype",
  // "Roboto",
  "Tahoma",
  "Times",
  "Times New Roman",
  "Trebuchet MS",
  "Verdana",
];

export interface FontHandle {
  fontRef:IFont;
  loaded:boolean;
}

let registeredFonts:any = [];
setFontList(defaultFonts);

const map1 = new Map();

export function setFontList(fontList: IFont[] | string[]) {
  if (!fontList)
    setFontList(defaultFonts);

  let newRegisteredFonts:FontHandle[] = [];
  for (let i=0; i<fontList.length; i++) {
    let fontKey = fontList[i];
    if (typeof fontKey === "string") {
      fontKey = new Font(fontKey);
      let loaded = true;
      // Because we run in node environments we trick the compilers
      const self = 'document';
      try {
         loaded = eval(`${self}.fonts.check('12px ${fontKey.name}')`);
      } catch (error) {
          // console.log(error);
      }

      newRegisteredFonts.push({
         fontRef: fontKey,
         loaded: loaded
      });
    } 
  }

  registeredFonts = Object.freeze(newRegisteredFonts);
}

export function getFontList() : FontHandle[] {
  return registeredFonts;
}