import AbstractModel from "./AbstractModel";
import ModelProperty from "./ModelProperty";
import ArrayElementProperty from "./ArrayElementProperty";
import PropertyPersister from "./PropertyPersister";

/**
    List Model is like a regular model with a few additional concepts.

    1. It has two arguments in the constructor.
        defaultFunctionElement - used for creating a default value for an element
        defaultFunctionLength - used for create a default length.

    3. Elements can be add/removed dynamically
    4. property listeners can be added to non existent properties since they can be add/removed.

    Note - arrays are interesting because if an element is added all the keys need to update
*/

// TODO - try to overload [] https://stackoverflow.com/questions/1711357/how-would-you-overload-the-operator-in-javascript
//        (another option would be to define a property for every offset)
// TODO - make length not enumerable. (to act like native arrays)

let DEFAULT_PERSISTOR = new PropertyPersister();
class CollectingWildCardProperty extends ModelProperty {
  constructor(instance, pathCollection) {
    super(instance, "*", {
      isReadOnly: true,
      isDelegate: true,
      persister: null,
      inputs: [],
    });
    this._instance = instance;
    this._pathCollection = pathCollection;
    this._retValue;
  }

  get value() {
    if (this._retValue) return this._retValue;
    this._retValue = this.getPropertyValue(this._pathCollection).value;
    return this._retValue;
  }

  getPropertyValue(propertyPath) {
    let retValue = [];
    let property = this;

    let lastElement = property;
    let lastValue = this;
    // simple collector
    for (let i = 0; i < this._instance.length; i++) {
      if (propertyPath) {
        property = new CollectingWildCardProperty(this.instance, propertyPath);
        lastElement = this._instance.getAt(i);
        retValue.push(lastElement);
        if (lastElement instanceof AbstractModel && propertyPath.length > 0) {
          lastValue = lastElement.getPropertyValue(propertyPath);
          if (lastValue === null) { // could not resolve to full depth.
            return this.createPropertyValue(
              lastElement,
              null,
              lastElement.getProperty(propertyPath),
              false,
              undefined/*unresolvable*/);
          } else if (lastValue)
            retValue[retValue.length - 1] = lastValue.value;
        }
      } else {
        retValue.push(this._instance.getAt(i));
      }
    }
    return this.createPropertyValue(
      this.instance,
      retValue,
      property,
      lastValue.isExplicit,
      lastValue.defaultValue);
  }

  set value(explicitValue) {
    throw Error("WildCardModelProperty is a virtual readonly property.");
  }
}

class ArrayModel extends AbstractModel {
  constructor(defaultLength, defaultElementOptions) {
    super();
    this._defaultElementOptions = defaultElementOptions;
    this._defaultFunctionLength = defaultLength;

    if (!this._defaultElementOptions) this._defaultElementOptions = {};
    if (!this._defaultElementOptions.inputs)
      this._defaultElementOptions.inputs = [];
    if (!this._defaultElementOptions.defaultValue) {
      // Note - This is the same behavior as not defining anything (except a tad slower)
      this._defaultElementOptions.defaultValue = function () {
        return undefined;
      };
      this._defaultElementOptions.inputs = [];
    }

    this._defaultElementOptions.inputs = this.enrichInputs(
      this._defaultElementOptions.inputs
    );

    let lengthInputs = [];
    let lengthFunctor = function () {
      return 0;
    };
    if (Number.isInteger(defaultLength) && defaultLength >= 0)
      lengthFunctor = function () {
        return defaultLength;
      };
    else if (defaultLength instanceof ModelProperty) {
      lengthFunctor = function (length) {
        if (!Number.isInteger(Number(length)))
          throw new Error("Default length is not an integer", length);
        return length;
      };
      lengthInputs = [defaultLength];
    }

    // TODO - create the onUpdateValue callback that is called for explicit and defaulted.
    let propLength = this.addProperty("length", {
      defaultValue: lengthFunctor,
      inputs: lengthInputs,
    });

    let optionsSpecific = {
      isReadOnly:
        defaultElementOptions && defaultElementOptions.isReadOnly !== undefined
          ? defaultElementOptions.isReadOnly
          : false,
      isWritable:
        defaultElementOptions && defaultElementOptions.isWritable !== undefined
          ? defaultElementOptions.isWritable
          : true,
      persister:
        defaultElementOptions && defaultElementOptions.persister !== undefined
          ? defaultElementOptions.persister
          : DEFAULT_PERSISTOR,
    };

    // We create an special $element property that is a dynamic elment
    // that tracks the functor accross all elementintances
    let prop$Element = new ArrayElementProperty(
      this,
      "$element",
      {
        isReadOnly: true,
        // isDelegate: true, // This is not a delegate as currently defined. Formalize this.
        defaultValue: this._defaultElementOptions.defaultValue,
        inputs: this._defaultElementOptions.inputs,
        persister: null, // Note - we don't persist element
      },
      optionsSpecific,
      propLength
    );

    // Override WildCardProperty
    this._wildCardProperty = new CollectingWildCardProperty(this);

    this._elementProperty = prop$Element;
    this._properties["$element"] = prop$Element;
    prop$Element.recalcProperty();
  }

  getProperty(propertyName) {
    if (!Array.isArray(propertyName)) propertyName = [propertyName];

    if (propertyName[0] === "*") {
      return new CollectingWildCardProperty(this, propertyName.splice(1));
    }

    if (Array.isArray(propertyName) && propertyName.length === 1)
      return this.lookupPropertyDef(propertyName[0]);

    // TODO - turn this into a for loop and don't clone (nominally faster)
    // TODO - rationalize getProperty with modelproperty.getProperty
    let propertyPath = [...propertyName];

    let remainingPropertyPath = propertyPath.splice(1);
    let lastValue = this.getPropertyValue(propertyPath[0]);
    // if (!lastValue || !(lastValue.value instanceof AbstractModel))
    //   return undefined;

    if (!lastValue)
        return undefined;
    if (lastValue.value === null) {
        return this.lookupPropertyDef(propertyName[0]);
    }
    if (!(lastValue.value instanceof AbstractModel))
     return undefined;
    if (lastValue) return lastValue.value.getProperty(remainingPropertyPath);
  }

  isArray() {
    return Array.isArray(this._json);
  }

  // persisted default value (undefined, null)

  // Note - clear/append/insert/remove all manually override the length (what is the explicit value for this? is it the empty array. I suppose)
  clear() {}

  append(element) {}

  insertAt(key, element) {}

  remove(key) {}

  // TODO - support for setter like modelproperty
  // TODO - support [] overloading but for now
  setAt(index, value) {
    this.setPropertyValueAt(index, value);
  }

  // TODO - support [] overloading but for now
  getAt(index) {
    let propertyValue = this.getPropertyValueAt([index]);
    if (!propertyValue) return;
    return propertyValue.value;
  }

  lookupPropertyDef(propertyName) {
    if (propertyName === "*") {
      return this._wildCardProperty;
    } else return super.lookupPropertyDef(propertyName);
  }

  setPropertyValueAt(index, explicitValue) {
    super.setPropertyValue(index.toString(), explicitValue);
  }

  getPropertyValueAt(propertyPath) {
    if (Number.isInteger(propertyPath)) propertyPath = [propertyPath];
    if (!Array.isArray(propertyPath))
      propertyPath = ObjectPath.parse(propertyPath);

    let propertyName = propertyPath[0];

    let propertyDef = this.lookupPropertyDef(propertyName);
    if (!propertyDef)
      // We don't throw an error because javascript arrays do not.
      return undefined;

    let currentValue = this.lookupPropertyDef(propertyName).evaled.resolved;
    if (propertyPath.length === 1 || currentValue.value === undefined)
      return currentValue;

    if (!(currentValue.value instanceof AbstractModel))
      throw Error(
        "A property chain was specified for a type that is not a model property: [" +
          propertyPath +
          "] ."
      );

    let propertyPathFollow = [...propertyPath].splice(1);
    return currentValue.value.getPropertyValue(propertyPathFollow);
  }
}

export default ArrayModel;
