import SSF from "../../utils/10_ssf";
import { asNumber } from '../../utils/CommonUtils';

/**
 * Reg ex for range.
 * reg ex doesn't catch trailing ':'
 */
export const REGEX_CELL_RANGE = /^([$]?[a-zA-Z]+[$]?[0-9]+)[:]?([$]?[a-zA-Z]+[$]?[0-9]+)?$/;

export const REGEX_LITERAL_ARRAY = /{([^}]*)}/;

export const flatten2DArray = function(array) {
  if (!array)
      return null;
  let retValue = [];
  for (let i=0; i<array.length; i++) {
      for (let j=0; j<array[i].length; j++) {
          retValue.push(array[i][j]);
      }
  }
  return retValue;
}

export const stringFromCells = function(cells, separator=" ") {
  let retValue = "";
  let flattened = flatten2DArray(cells);
  for (let i = 0; i < flattened.length; i++) {
    let input = flattened[i];
    if (input !== null && input !== undefined) retValue += SSF.format(input.z || 'General', input.v);
    if (i < flattened.length - 1) retValue += separator;
  }
  return retValue;
}

export const stringToArray = function(input) {
  if (typeof input === 'string') {
    let matched = input.match(REGEX_LITERAL_ARRAY);
    if (matched && matched[1]) {
      let results = [];
      let literal = matched[1];
      let literalRows = literal.split(';');
      let width = 0;
      for (let i=0; i<literalRows.length; i++) {
        let literalCols = literalRows[i].split(',');
        let row = [];
        results.push(row);
        for (let j=0; j<literalCols.length; j++) {
          let col = literalCols[j].trim();
          if (col === 'null' || col === '') {
              col = null;
          } else if (typeof col === 'string') {
            if ((col[0] === "'") || (col[0] === '"')) {
                 if (col.length === 1 || col[0] !== col[col.length - 1])
                    return null;
                col = col.substring(1, col.length - 1);
              } else {
                col = asNumber(col);
              }
          }
          row.push(col);
          width = Math.max(width, j);
        }
      }
      return results;
    }
  }
  return null;
}

export const arrayToString = function(input) {
  if (input === null || input === undefined)
    return null;

  let retValue = '{';
  for (let i=0; i<input.length; i++) {
    let row = input[i];
    if (i > 0)
      retValue += ';';
    for (let j=0; j<row.length; j++) {
      if (j > 0)
        retValue += ',';
      let value = row[j];
      if (typeof value === 'string' && !(value[0] === "'" && value[value.length - 1] === "'")) {
        value = "'" + value + "'";
      } else if (value === null || value === undefined)
        value = '';
      retValue += value;
    }
  }
  retValue += '}';
  return retValue;
}

/** Matches just the cell portion of a range */

export function matchCellRange(range) {
  let partsRange = range.match(REGEX_CELL_RANGE);
  if (range.endsWith(':')) // reg ex doesn't catch trailing ':'
    partsRange = null

  return partsRange;
}

export function decode_range(range) {
  if (!range)
    throw new Error('no range specified');
  let sheetName = null;
  let idx = range.lastIndexOf("!");
  if (idx !== -1) {
    sheetName = range.slice(0, idx);
    range = range.slice(idx + 1).toUpperCase();
  }
  // sheet = validateSheetName(sheet);
  let decoded = decode_cellrange(range);
  decoded.sheetName = sheetName;

  return decoded;
}

/**
 * Validates sheet name.
 *
 * <p>
 * The character count <tt>MUST</tt> be greater than or equal to 1 and less than or equal to 31.
 * The string MUST NOT contain the any of the following characters:
 * <ul>
 * <li> 0x0000 </li>
 * <li> 0x0003 </li>
 * <li> colon (:) </li>
 * <li> backslash (\) </li>
 * <li> asterisk (*) </li>
 * <li> question mark (?) </li>
 * <li> forward slash (/) </li>
 * <li> opening square bracket ([) </li>
 * <li> closing square bracket (]) </li>
 * </ul>
 * The string MUST NOT begin or end with the single quote (') character.
 * </p>
 *
 * @param sheetName the name to validate
 * @throws IllegalArgumentException if validation fails
 */
export function validateSheetName(sheetName) {
  if (sheetName == null) {
      throw new Error("sheetName must not be null");
  }

  let len = sheetName.length();
  if (len < 1 || len > 31) {
      throw new Error("sheetName '" + sheetName
              + "' is invalid - character count MUST be greater than or equal to 1 and less than or equal to 31");
  }

  for (let i=0; i<len; i++) {
      let ch = sheetName.charAt(i);
      switch (ch) {
          case '/':
          case '\\':
          case '?':
          case '*':
          case ']':
          case '[':
          case ':':
              break;
          default:
              // all other chars OK
              continue;
      }
      throw new Error("Invalid char (" + ch
              + ") found at index (" + i + ") in sheet name '" + sheetName + "'");
  }
  if (sheetName.charAt(0) == '\'' || sheetName.charAt(len-1) == '\'') {
      throw new Error("Invalid sheet name '" + sheetName
              + "'. Sheet names must not begin or end with (').");
  }
  return sheetName;
}

export function decode_row(rowstr) {
  return parseInt(unfix_row(rowstr), 10) - 1;
}
export function encode_row(row) {
  return "" + (row + 1);
}
export function fix_row(cstr) {
  return cstr.replace(/([A-Z]|^)(\d+)$/, "$1$$$2");
}
export function unfix_row(cstr) {
  return cstr.replace(/\$(\d+)$/, "$1");
}

export function decode_col(colstr) {
  let c = unfix_col(colstr),
    d = 0,
    i = 0;
  for (; i !== c.length; ++i) d = 26 * d + c.charCodeAt(i) - 64;
  return d - 1;
}

export function encode_col(col) {
  if (col < 0) {
    debugger;
    throw new Error("invalid column " + col);
  }
  let s = "";
  for (++col; col; col = Math.floor((col - 1) / 26))
    s = String.fromCharCode(((col - 1) % 26) + 65) + s;
  return s;
}
export function fix_col(cstr) {
  return cstr.replace(/^([A-Z])/, "$$$1");
}
export function unfix_col(cstr) {
  return cstr.replace(/^\$([A-Z])/, "$1");
}

export function split_cell(cstr) {
  return cstr.replace(/(\$?[A-Z]*)(\$?\d*)/, "$1,$2").split(",");
}

export function decode_cell(cstr) {
  const cstrUnfixed = unfix_col(unfix_row(cstr));
  let splitParts = split_cell(cstr);
  let R = 0,
    C = 0;
  for (let i = 0; i < cstrUnfixed.length; ++i) {
    let cc = cstrUnfixed.charCodeAt(i);
    if (cc >= 48 && cc <= 57) R = 10 * R + (cc - 48);
    else if (cc >= 65 && cc <= 90) C = 26 * C + (cc - 64);
  }
  return {
    c: C - 1,
    cf: splitParts[0].charAt(0) === '$',
    r: R - 1,
    rf: splitParts[1].charAt(0) === '$'
  };
}
//function encode_cell(cell) { return encode_col(cell.c) + encode_row(cell.r); }
export function encode_cell(cell) {
  let col = cell.c + 1;
  let s = "";
  for (; col; col = ((col - 1) / 26) | 0)
    s = String.fromCharCode(((col - 1) % 26) + 65) + s;
  return (cell.cf ? '$' : '') + s + (cell.rf ? '$' : '') + (cell.r + 1);
}
export function decode_cellrange(range) {
  const partsRange = matchCellRange(range);
  if (!partsRange)
    throw new Error("invalid range '" + range + "'");

  return {
    s: decode_cell(partsRange[1]),
    e: decode_cell(partsRange[2] ? partsRange[2] : partsRange[1]),
  };
}
export function encode_range(cs, ce) {
  if (typeof ce === "undefined" || typeof ce === "number") {
    return encode_range(cs.s, cs.e);
  }
  if (typeof cs !== "string") cs = encode_cell(cs);
  if (typeof ce !== "string") ce = encode_cell(ce);
  return cs === ce ? cs : cs + ":" + ce;
}

/**
 * This is a very fast way to decode ranges but has no error checking. If does not support fixed cells or sheets
 */
export function safe_decode_cellrange(range) {
  let o = { s: { c: 0, r: 0 }, e: { c: 0, r: 0 } };
  let idx = 0,
    i = 0,
    cc = 0;
  let len = range.length;
  for (idx = 0; i < len; ++i) {
    if ((cc = range.charCodeAt(i) - 64) < 1 || cc > 26) break;
    idx = 26 * idx + cc;
  }
  o.s.c = --idx;

  for (idx = 0; i < len; ++i) {
    if ((cc = range.charCodeAt(i) - 48) < 0 || cc > 9) break;
    idx = 10 * idx + cc;
  }
  o.s.r = --idx;

  if (i === len || range.charCodeAt(++i) === 58) {
    o.e.c = o.s.c;
    o.e.r = o.s.r;
    return o;
  }

  for (idx = 0; i != len; ++i) {
    if ((cc = range.charCodeAt(i) - 64) < 1 || cc > 26) break;
    idx = 26 * idx + cc;
  }
  o.e.c = --idx;

  for (idx = 0; i != len; ++i) {
    if ((cc = range.charCodeAt(i) - 48) < 0 || cc > 9) break;
    idx = 10 * idx + cc;
  }
  o.e.r = --idx;
  return o;
}
