import CommonUtils from "../utils/CommonUtils";

/*
 * Only one method is for public use
 * @see listen
 */
class NestedPropertyListener {
  constructor(options) {
    if (options) {
      if (options._thisRef) {
        this._thisRef = options._thisRef;
      }
      if (options.beforeNotify) {
        this._beforeNotify = options.beforeNotify;
      }
      if (options.afterNotify) {
        this._afterNotify = options.afterNotify;
      }
      if (options.syncNotify) {
        this._syncNotify = options.syncNotify;
      }
    }
    this._suspended = 0;
  }

  suspend() {
    this._suspended++;
  }

  resume() {
    this._suspended--;
  }

  /**
   * This listens to an array of propertyPaths to a DAGM model.
   *
   * The callback argument has two arguments
   * event from the DAGM propertyListener and a listenerReference.
   * A listenerReference can be passes as a fourth argument to allow
   * for property listeners to be nested.
   *
   * @returns an unListen function that will unListen the property
   *
   * If a listenerReference is used then:
   *. 1. If the listenerReference is changed then the currentBind will
   *     not be called and will become unbound.
   *  2. if the listenerReference's unListen function is called then this will also be unListened.
   *
   * Note - The can be nested an arbitrary level.
   *
   */
  listen(elementShapeBind, paths, callback, listenerRefParent) {
    if (!elementShapeBind) throw new Error("model must not be null");

    let listenerRefSelf = {
      paths: paths, // for debugging
    };

    if (listenerRefParent) {
      if (listenerRefParent._ancestors)
        listenerRefSelf._ancestors = [...listenerRefParent._ancestors];
      else listenerRefSelf._ancestors = [];
      listenerRefSelf._ancestors.push(listenerRefParent);
      if (!listenerRefParent._descendants)
        listenerRefParent._descendants = new Set();
      listenerRefParent._descendants.add(listenerRefSelf);
    }

    const _thisRef = this._thisRef;
    listenerRefSelf._boundCallBack = (event) => {
      if (callback) {
        try {
          callback.call(_thisRef, event, listenerRefSelf);
        } catch (error) {
          if (process.env.NODE_ENV !== "production")
            console.warn('error on callback', error);
        }
      }
    };
    let handler = function (event) {
      // the property listeners fire for removals too but we don't want these.
      if (
        !event.newValue ||
        (event.newValue.length === 1 && event.newValue[0] === undefined)
      )
        return;

      // schedule call
      this.schedulePropertyCallback(listenerRefSelf, event);
    }.bind(this);

    listenerRefSelf._unlistenSelf = elementShapeBind.addPropertyListener(
      paths,
      handler
    );
    listenerRefSelf._unlistenDescendants = function () {
      if (listenerRefSelf._descendants) {
        listenerRefSelf._descendants.forEach(function (descendant) {
          if (!descendant || !descendant.unListen) {
            debugger;
            return;
          }
          descendant.unListen();
        });
        delete listenerRefSelf._descendants;
      }
    };
    listenerRefSelf._dispose = function (ref) {
      if (listenerRefSelf._disposables) {
        for (let i = 0; i < listenerRefSelf._disposables.length; i++) {
          if (listenerRefSelf._disposables[i])
            listenerRefSelf._disposables[i].call(_thisRef, ref);
        }
        delete listenerRefSelf._disposables;
      }
    };

    listenerRefSelf.addDisposable = function (disposable) {
      if (!listenerRefSelf._disposables) listenerRefSelf._disposables = [];
      listenerRefSelf._disposables.push(disposable);
    };

    listenerRefSelf.unListen = function () {
      // unlisten all children
      listenerRefSelf._unlistenDescendants();
      listenerRefSelf._dispose(listenerRefSelf);

      // remove from parents
      if (listenerRefSelf._ancestors) {
        for (let i = 0; i < listenerRefSelf._ancestors.length; i++) {
          listenerRefSelf._ancestors[i]._descendants.delete(listenerRefSelf);
        }
        delete listenerRefSelf._ancestors;
      }

      if (listenerRefSelf._unlistenSelf) {
        // console.log('unlisten', paths);
        listenerRefSelf._unlistenSelf();
        delete listenerRefSelf._unlistenSelf;
      }
    };

    let _self = this;
    if (_self._beforeNotify) _self._beforeNotify();
    listenerRefSelf._boundCallBack(null, listenerRefSelf);
    if (_self._afterNotify) _self._afterNotify();
    return listenerRefSelf.unListen;
  }

  /*
   * This will read the points from a series and render them on the chart.
   * This depends on the seriesInfo been populated but is safe to call multiple
   * times or during setup.
   */
  schedulePropertyCallback(listenerRef, event) {
    if (this._suspended > 0) return;
    if (!this._propertiesSchedule) {
      this._propertiesSchedule = new Map();
      let _self = this;
      const callback = function () {
        if (_self._beforeNotify) _self._beforeNotify();
        const toNotify = [];
        _self._propertiesSchedule.forEach(function (event, listenerRef) {
          let foundAncestor = false;
          if (listenerRef._ancestors) {
            for (
              let i = 0;
              !foundAncestor && i < listenerRef._ancestors.length;
              i++
            ) {
              if (_self._propertiesSchedule.has(listenerRef._ancestors[i])) {
                foundAncestor = true;
              }
            }
          }
          if (!foundAncestor) {
            toNotify.push(listenerRef);
          }
        });
        for (let i = 0; i < toNotify.length; i++) {
          toNotify[i]._unlistenDescendants();
        }
        for (let i = 0; i < toNotify.length; i++) {
          let event = _self._propertiesSchedule.get(toNotify[i]);
          toNotify[i]._dispose(toNotify[i]);
          toNotify[i]._boundCallBack(event, toNotify[i]);
        }
        if (_self._afterNotify) _self._afterNotify();

        _self._propertiesSchedule.clear();
      };
      if (this._syncNotify) this.addSchedulePropertyCallback = callback;
      else this.addSchedulePropertyCallback = CommonUtils.debounce(callback);
    }

    this._propertiesSchedule.set(listenerRef, event);
    this.addSchedulePropertyCallback();
  }
}

export default NestedPropertyListener;
