import ChartDataPointShape from "./ChartDataPointShape";

class ChartPieDataPointShape extends ChartDataPointShape {
  constructor(
    chartShape,
    series,
    xRange,
    valueRange,
    offset,
    length,
    seriesOffset,
    seriesLength
  ) {
    super(
      chartShape,
      series,
      xRange,
      valueRange,
      offset,
      length,
      seriesOffset,
      seriesLength
    );
    const _self = this;

    this.addProperty("explosion", {
      defaultValue: function (explosion) {
        return explosion;
      },
      inputs: ["series.chartType.explosion"],
    });
  }

  get className() {
    return "ChartPieDataPointShape";
  }
}

export default ChartPieDataPointShape;
