import PropertyPersister from "../../../dagm/PropertyPersister";
import ArrayModel from "../../../dagm/ArrayModel";

import { isColumnType, numberSetter } from "../../../utils/ChartUtils";

import { createFillProperty } from "../../AbstractShape";

import ChartPartShape from "../ChartPartShape";
import ChartGridLinesShape from "../ChartGridLinesShape";

import ChartAxisTitleShape from "./ChartAxisTitleShape";
import ChartAxisLabelShape from "./ChartAxisLabelShape";


/*
    The base class for all chart axis
*/

class ChartAxisShape extends ChartPartShape {
  constructor(options, axisType) {
    super(options, axisType === "ord" ? "categoryAxis" : "valueAxis"); // Note - SheetXL uses ord but ooxml uses categoryAxis

    let _self = this;
    this._axisType = axisType;

    let _isHorizontal = options.isHorizontal;
    this.addProperty("horizontal", {
      isReadOnly: true,
      isTransient: true,
      defaultValue: function () {
        return _isHorizontal;
      },
    });

    let _direction = options.direction;
    this.addProperty("direction", {
      isReadOnly: true,
      isTransient: true,
      defaultValue: function () {
        return _direction;
      },
    });

    let _offset = options.offset || 0;
    this.addProperty("offset", {
      isReadOnly: true,
      isTransient: true,
      defaultValue: function () {
        return _offset;
      },
    });

    this.addProperty("labelAlign", { defaultValue: "center" });
    this.addProperty("labelPosition", { defaultValue: "nextTo" });
    this.addProperty("labelOffset", {
      // tickLblPos
      setValue: function (value) {
        if (value < 0 || value > 1000) throw new Error("invalid range");
        return value;
      },
      defaultValue: function (horizontal) {
        return horizontal ? 100 : 100; // 120
      },
      inputs: ["horizontal"],
    });

    // This allows for observing opposite axis values. See 'crosses'
    let crossesInput =
      _direction === "x"
        ? "chartShape.yAxes[" + _offset + "]"
        : "chartShape.xAxes[" + _offset + "]";
    this.addProperty("crossAx", {
      isReadOnly: true,
      isTransient: true,
      defaultValue: function (crossAx) {
        return crossAx;
      },
      inputs: [crossesInput],
    });

    let _seriesReferences = options.seriesReferences;
    this.addProperty("series", {
      isReadOnly: true,
      isTransient: true,
      defaultValue: function () {
        return new ArrayModel(_seriesReferences.length, {
          defaultValue: function (index) {
            return _seriesReferences[index];
          },
          inputs: ["$index"],
          persister: null /* we implicitly save models*/,
        });
      },
    });

    let _chartType = options.chartType;
    this.addProperty("chartType", {
      isReadOnly: true,
      isTransient: true,
      defaultValue: function () {
        return _chartType;
      },
    });

    /*
        Use by rendering component to provide coodinates
      */
    this.addProperty("dims", {
      isTransient: true,
      setValue: function (value) {
        if (!value) return {};
        // for a deepcopy
        return JSON.parse(JSON.stringify(value));
      },
      defaultValue: function () {
        return null;
      },
    });

    // fill
    createFillProperty(this, axisType, 'fill', "chartShape.chartStyle", function(style, styleKey) {
      return style.createNoneFill();
    });

    // strokeFill;
    createFillProperty(this, axisType, 'strokeFill', "chartShape.chartStyle", function(style, styleKey, chartMainType) {
      // Note - The OOXML open xml spec has an axis lookup but from observation it seems to use the gridLinesMajor 'axis');
      if (styleKey === "val" && isColumnType(chartMainType)) {
        return style.createNoneFill();
      }
      return style.createStrokeStyleProperties("gridLineMajor").fill;
    }, ["chartShape.mainType"], 'stroke.fill');

    // Note - The OOXML spec has an axis lookup but from observation it seems to use the gridLinesMajor 'axis');
    this.overrideProperty("strokeWidth", {
      defaultValue: function (chartStyle, chartMainType) {
        return chartStyle.createStrokeStyleProperties("gridLineMajor").width;
      },
      inputs: ["chartShape.chartStyle", "chartShape.mainType"],
      persister: new PropertyPersister("stroke.width"),
    });

    this.addProperty("description", {
      isReadOnly: true,
      isTransient: true,
      defaultValue: function (horizontal, offset, simpleRun) {
        // TODO - OOXML shows cat/value
        let retValue = horizontal ? "Horizontal" : "Vertical";
        if (offset === 1) retValue += " Secondary";
        else if (offset > 1) retValue += " Additional (" + (offset + 1) + ")";
        retValue += " Axis";
        if (this.getPropertyValue("title.text.simpleRun").isExplicit)
          retValue += " '" + simpleRun + "' ";
        return retValue;
      },
      inputs: ["horizontal", "offset", "title.text.simpleRun"],
    });

    /*
       Orientation is the postion of the labels relative to to the axis line.
       Note - This is determined by crosses
       */
    this.addProperty("orientation", {
      defaultValue: function (labelPosition, crosses, inverted) {
        let retValue;
        if (_isHorizontal) {
          retValue = "bottom";
          if (crosses === "max" || labelPosition === "high") retValue = "top";
          if (inverted) retValue = retValue === "bottom" ? "top" : "bottom";
        } else {
          retValue = "left";
          if (crosses === "max" || labelPosition === "high") retValue = "right";
          if (inverted) retValue = retValue === "left" ? "right" : "left";
        }
        return retValue;
      },
      inputs: ["labelPosition", "crosses", "crossAx.inverted"],
    });

    this.addProperty("title", {
      isReadOnly: true,
      defaultValue: function () {
        return new ChartAxisTitleShape({
          chartShape: options.chartShape,
          chartAxis: _self,
          appContext: options.appContext
        });
      },
      persister: null /* we implicitly save models*/,
    });

    this.addProperty("gridLinesMajor", {
      defaultValue: function () {
        return new ChartGridLinesShape({
          chartShape: options.chartShape,
          chartAxis: _self,
          isMajor: true,
          appContext: options.appContext
        });
      },
      persister: null /* we implicitly save models*/,
    });

    this.addProperty("gridLinesMinor", {
      isReadOnly: true,
      defaultValue: function () {
        return new ChartGridLinesShape({
          chartShape: options.chartShape,
          chartAxis: _self,
          isMajor: false,
          appContext: options.appContext
        });
      },
      persister: null /* we implicitly save models*/,
    });

    this.addProperty("defaultAxisFormat", { defaultValue: "General" });

    this.addProperty("labels", {
      defaultValue: function (chartshape) {
        return new ChartAxisLabelShape(
          Object.assign({ axisShape: _self }, options),
          "dataLabel",
          "chartAxisLabel:" + _self.direction + ":" + _self.offset
        ); //
      },
      inputs: ["chartShape"],
      persister: null /* we implicitly save models*/,
    });

    /**
        Choices: 'none', 'in', 'out', 'cross'
          <none> (None) There are no tick marks on the axis line
          <in> (Inside) There are no tick marks on the opposite side of the line as the labels.
          <out> (Outside) There are no tick marks on the same side of the line as the labels.
          <cross> (Both inside and Outside) There are no tick marks on both sides of the axis line.
      */
    this.addProperty("majorTickMarks", { defaultValue: "none" });
    /**
        Choices: 'none', 'in', 'out', 'cross'
          <none> (None) There are no tick marks on the axis line
          <in> (Inside) There are no tick marks on the opposite side of the line as the labels.
          <out> (Outside) There are no tick marks on the same side of the line as the labels.
          <cross> (Both inside and Outside) There are no tick marks on both sides of the axis line.
      */
    this.addProperty("minorTickMarks", { defaultValue: "none" });

    this.addProperty("inverted", { defaultValue: false }); // In OOXML this is 'maxMin', 'minMax'

    /**
       * Specifies the possible crossing points for an axis.
       * Choices:
          <autoZero> (Axis Crosses at Zero) The category axis crosses at the zero point of the value axis (if possible), or the minimum value (if the minimum is greater than zero) or the maximum (if the maximum is less than zero).
          <max> (Maximum) The axis crosses at the maximum value.
          <min> (Minimum) Axis crosses at the minimum value of the chart.
       */
    this.addProperty("crosses", {
      setValue: numberSetter(["autoZero", "max", "min"]), // 'min' (in spec but not supported in OOXML)
      defaultValue: function (offset) {
        let isPrimaryAxis = offset === 0;
        if (isPrimaryAxis) return "autoZero";
        else return "max";
      },
      inputs: ["offset"],
    });

    this.addProperty("id", {
      setValue: function (value) {
        return value;
      },
    });

    // TODO - scaleType - determine if this is an ordinal or a date/val axis (auto switched between the two so maybe just one)
    this.addProperty("scaleType", { defaultValue: undefined });

  } // end of constructor

  get axisType() {
    return this._axisType;
  }

  get className() {
    return "ChartAxisShape";
  }

  get typeId() {
    return this.className + ":" + this.direction + ":" + this.offset;
  }
}

export default ChartAxisShape;
