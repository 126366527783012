import * as ColorUtils from './ColorUtils';
import PresetColors from './PresetColors';
import findSchemaDefinition from './SchemeColors';

export type AdjustableColorDef = {
  val: string;
  adjustments?: any[]
}

/**
 * Note - all percentages are expresses as 0-100 except alpha with is 0-1
 * (this is an attempt to be like css)
 */

// TODO - leverage lastColor? (This is available for system colors)
export class ColorAdjustment {
    _private: {
        type: string, // TODO - make this an enum?
        amount?: number
    }
    constructor(type: string, amount?: number) {
        this._private = { type, amount };
    }

    get type() {
        return this._private.type;
    }
    get amount() {
        return this._private.amount;
    }
}

export class RGBAColor {
    _private: {
        red: number,
        green: number,
        blue: number,
        alpha: number
    }
    constructor(red: number, green: number, blue: number, alpha: number = 1) {
        this._private = {
            red, green, blue, alpha
        };
    }
    get red() {
        return this._private.red;
    }
    get green() {
        return this._private.green;
    }
    get blue() {
        return this._private.blue;
    }
    get alpha() {
        return this._private.alpha;
    }
    toString() {
        return 'rgba(' + this.red + ',' + this.green + ',' + this.blue + ',' + this.alpha + ')';
    }
    toJSON() {
        return { r: this.red, g: this.green, b: this.blue, a: this.alpha }
    }
};

interface IAdjustableColor {
    readonly val: string;
    adjustments(): ColorAdjustment[]
    toRGBAColor(): RGBAColor;
    toJSON(): any
}

export const jsonToColorAdjustments = function(json: any = null): ColorAdjustment[] | null {
  if (json === null)
    return null;
  if (!Array.isArray(json))
    throw new Error('ColorAdjustments must be an array: '+ json);

  let retValue: ColorAdjustment[] = [];
  let jsonArr = json as [];
  for (let i=0; i<jsonArr.length; i++) {
    let keys = Object.keys(jsonArr[i]);
    if (keys.length !== 1) {
      throw new Error('ColorAdjustments must be an array of objects all with one key: '+ json);
    }

    let type = keys[0];
    let amount: number | boolean = jsonArr[i][type];
    if (amount === true)
      amount = undefined; // We only pass numbers
    if (amount === false)
      continue; // this should never happen but...
    retValue.push(new ColorAdjustment(type, amount as number));
  }

  return retValue;
}

/**
 * Implemented on theme and colormapping to allow for schemevals to be looked up
*/
export type SchemeColorLookup = (val: string) => RGBAColor;

const isObject = function(obj: any) {
  return obj !== undefined && obj !== null && obj.constructor == Object;
}

export const resolveAdjustableColor = function(valColor: any, schemeLookup: SchemeColorLookup) {
  let val:string;
  let adjs:ColorAdjustment[] | null;
  let last:any;

  if (valColor instanceof AdjustableColor) {
      return valColor;
  }
  if (typeof valColor === "string") {
      val = valColor
  } else if (isObject(valColor)) {
      val = valColor.val;
      adjs = jsonToColorAdjustments(valColor.adjs);
      last = valColor.last;
  }

  return new AdjustableColor(val, adjs, schemeLookup , last);
}

type drgbAlpha = {
  drgb: number[],
  alpha: number
}
const presetColor = function(val: string) : RGBAColor | null {
    let preset = PresetColors.valueOfOoxmlId(val);
    if (!preset)
        return null;
    return new RGBAColor(
        preset.rgbaColor.r,
        preset.rgbaColor.g,
        preset.rgbaColor.b,
        preset.rgbaColor.a);
}

const RGB2DRGBAlpha = function(rgbaColor: RGBAColor | undefined | null): drgbAlpha | null {
  if (!rgbaColor)
    return null;

  return {
    drgb: [
      rgbaColor.red / 255,
      rgbaColor.green / 255,
      rgbaColor.blue  / 255
    ],
    alpha: rgbaColor.alpha !== undefined ? rgbaColor.alpha : 1
  };
}
const parseVal = function(val: string, schemeLookup: SchemeColorLookup = null, last: RGBAColor = null) : drgbAlpha
   {
    // look for presets (Not this looks for both preset and system colors)
    let baseColor:drgbAlpha = RGB2DRGBAlpha(presetColor(val));
    // look for schemeLookup
    if (baseColor === null) {
        if (findSchemaDefinition(val)) {
            if (!schemeLookup)
                throw new Error('a scheme val was used but a schemeLookup was not provided');
            baseColor = RGB2DRGBAlpha(schemeLookup(val));
        }
    }
    // hex (this is similiar to rgb)
    if (baseColor === null) {
        let parts = val.match(ColorUtils.REGEX_HEX) || val.match(ColorUtils.REGEX_HEXA);
        if (parts) {
            // because hex alpha is in front need to check
            let alpha = 1;
            let input:number[];
            if (parts.length === 4) // no alpha
                input = [
                    parseInt(parts[1], 16),
                    parseInt(parts[2], 16),
                    parseInt(parts[3], 16)
                ];
            else {
                input = [
                    parseInt(parts[2], 16),
                    parseInt(parts[3], 16),
                    parseInt(parts[4], 16)
                ];
                alpha = ColorUtils.clampAlpha(parseInt(parts[1], 16) / 255);
            }
            input = ColorUtils.divRGB(input);
            input = ColorUtils.clampRGB(input);
            baseColor = {
              drgb: input,
              alpha: alpha
            };
        }
    }
    // rgb(a)
    if (baseColor === null) {
        let parts = val.match(ColorUtils.REGEX_RGB) || val.match(ColorUtils.REGEX_RGBA);
        if (parts) {
            let input = ColorUtils.parseParts(parts[1], parts[2], parts[3]);
            input = ColorUtils.divRGB(input);
            input = ColorUtils.clampRGB(input);
            baseColor = {
              drgb: input,
              alpha: parts[4] ? ColorUtils.clampAlpha(parseFloat(parts[4])) : 1
            };
        }
    }
    // lgbr(a)
    if (baseColor === null) {
        let parts = val.match(ColorUtils.REGEX_LRGB) || val.match(ColorUtils.REGEX_LRGBA);
        if (parts) {
            let input = ColorUtils.parseParts(parts[1], parts[2], parts[3]);
            input = ColorUtils.divRGB(input, 100.0); // expressed as percent
            input = ColorUtils.lin2srgb(input);
            input = ColorUtils.clampRGB(input);
            baseColor = {
              drgb: input,
              alpha: parts[4] ? ColorUtils.clampAlpha(parseFloat(parts[4])) : 1
            };
        }
    }
    // hsl(a)
    if (baseColor === null) {
        let parts = val.match(ColorUtils.REGEX_HSL) || val.match(ColorUtils.REGEX_HSLA);
        if (parts) {
            let input = ColorUtils.parseParts(parts[1], parts[2], parts[3]);
            input = ColorUtils.HSL2RGB(input);
            input = ColorUtils.clampRGB(input);
            baseColor = {
              drgb: input,
              alpha: parts[4] ? ColorUtils.clampAlpha(parseFloat(parts[4])) : 1
            };
        }
    }

    if (baseColor === null) {
        throw new Error('Not a valid color format: ' + val);
    }
    return baseColor;
}

const applyColorTransforms = function (drgbAlpha: drgbAlpha, adjustments: ColorAdjustment[]):drgbAlpha {
    let drgb = [...drgbAlpha.drgb];
    let alpha = drgbAlpha.alpha !== undefined ? drgbAlpha.alpha : 1;
    for (var i = 0; adjustments && i < adjustments.length; i++) {
      let adjustment = adjustments[i];
      let type = adjustment.type || Object.keys(adjustment)[0];
      let amount = adjustment.amount !== undefined ? adjustment.amount : adjustment[type];
      if (type === "alpha") {
        alpha = (amount / 100.);
        alpha = Math.max(0, Math.min(1, alpha));
      } else if (type === "alphaOff") {
        alpha += (amount / 100.);
        alpha = Math.max(0, Math.min(1, alpha));
      } else if (type === "alphaMod") {
        alpha *= (amount / 100.);
        alpha = Math.max(0, Math.min(1, alpha));
      } else if (type === "hue") {
        drgb = ColorUtils.applyHSL(ColorUtils.HSLComponent.HUE, drgb, amount);
      } else if (type === "sat") {
        drgb = ColorUtils.applyHSL(ColorUtils.HSLComponent.SATURATION, drgb, amount);
      } else if (type === "lum") {
        drgb = ColorUtils.applyHSL(ColorUtils.HSLComponent.LUNINANCE, drgb, amount);
      } else if (type === "hueOff") {
        drgb = ColorUtils.applyHSLOff(ColorUtils.HSLComponent.HUE, drgb, amount);
      } else if (type === "satOff") {
        drgb = ColorUtils.applyHSLOff(ColorUtils.HSLComponent.SATURATION, drgb, amount);
      } else if (type === "lumOff") {
        drgb = ColorUtils.applyHSLOff(ColorUtils.HSLComponent.LUNINANCE, drgb, amount);
      } else if (type === "hueMod") {
        drgb = ColorUtils.applyHSLMod(ColorUtils.HSLComponent.HUE, drgb, amount);
      } else if (type === "satMod") {
        drgb = ColorUtils.applyHSLMod(ColorUtils.HSLComponent.SATURATION, drgb, amount);
      } else if (type === "lumMod") {
        drgb = ColorUtils.applyHSLMod(ColorUtils.HSLComponent.LUNINANCE, drgb, amount);
      } else if (type === "red") {
        drgb = ColorUtils.applyRGB(ColorUtils.RGBAComponent.RED, drgb, amount, ColorUtils.ColorShiftType.SET);
      } else if (type === "green") {
        drgb = ColorUtils.applyRGB(ColorUtils.RGBAComponent.GREEN, drgb, amount, ColorUtils.ColorShiftType.SET);
      } else if (type === "blue") {
        drgb = ColorUtils.applyRGB(ColorUtils.RGBAComponent.BLUE, drgb, amount, ColorUtils.ColorShiftType.SET);
      } else if (type === "redOff") {
        drgb = ColorUtils.applyRGB(ColorUtils.RGBAComponent.RED, drgb, amount, ColorUtils.ColorShiftType.OFF);
      } else if (type === "greenOff") {
        drgb = ColorUtils.applyRGB(ColorUtils.RGBAComponent.GREEN, drgb, amount, ColorUtils.ColorShiftType.OFF);
      } else if (type === "blueOff") {
        drgb = ColorUtils.applyRGB(ColorUtils.RGBAComponent.BLUE, drgb, amount, ColorUtils.ColorShiftType.OFF);
      } else if (type === "redMod") {
        drgb = ColorUtils.applyRGB(ColorUtils.RGBAComponent.RED, drgb, amount, ColorUtils.ColorShiftType.MOD);
      } else if (type === "greenMod") {
        drgb = ColorUtils.applyRGB(ColorUtils.RGBAComponent.GREEN, drgb, amount, ColorUtils.ColorShiftType.MOD);
      } else if (type === "blueMod") {
        drgb = ColorUtils.applyRGB(ColorUtils.RGBAComponent.BLUE, drgb, amount, ColorUtils.ColorShiftType.MOD);
      } else if (type === "shade") {
        drgb = ColorUtils.applyShade(drgb, amount);
      } else if (type === "tint") {
        drgb = ColorUtils.applyTint(drgb, amount);
      } else if (type === "gray") {
        drgb = ColorUtils.applyGray(drgb);
      } else if (type === "comp") {
        drgb = ColorUtils.applyComplement(drgb);
      } else if (type === "inv") {
        drgb = ColorUtils.applyInverse(drgb);
      } else if (type === "gamma") {
        drgb = ColorUtils.applyGamma(drgb);
      } else if (type === "invGamma") {
        drgb = ColorUtils.applyInverseGamma(drgb);
      } else {
        console.warn('unknown adjustmentType: ' + type);
        // unknown/unsupported? adjustment type
      }
    }

    return {
      drgb,
      alpha
    }
  }

export default class AdjustableColor implements IAdjustableColor {
    _private: {
      val: string,
      last?: RGBAColor,
      adjs?: ColorAdjustment[],
      resolved: RGBAColor,
      applied: drgbAlpha
    }

    constructor(val: string, adjustments: ColorAdjustment[] = null, schemeLookup?: SchemeColorLookup, last: RGBAColor = null) {
      let baseColor = parseVal(val, schemeLookup, last);
      let applied:drgbAlpha = applyColorTransforms(baseColor, adjustments);

      let drgb = ColorUtils.multRGB(applied.drgb);
      drgb = ColorUtils.roundRGB(drgb);
      let alpha = ColorUtils.clampAlpha(applied.alpha);

      this._private = {
        val: val,
        adjs: adjustments,
        applied: applied,
        resolved: new RGBAColor(drgb[0], drgb[1], drgb[2], alpha),
      };
    }

    get val() {
      return this._private.val;
    }

    adjustments() {
      return this._private.adjs;
    }

    toJSON(): any {
      let retValue: any = {
          val: this._private.val
      }
      if (this._private.adjs && this._private.adjs.length > 0) {
        retValue.adjs = [];
        for (let i = 0; i < this._private.adjs.length; i++) {
          let adjustment = this._private.adjs[i];
          let jsonAdjust = {};
          let type = adjustment.type || Object.keys(adjustment)[0];
          let amount = adjustment.amount !== undefined ? adjustment.amount : adjustment[type];
          jsonAdjust[type] = amount !== undefined ? amount : true
          retValue.adjs.push(jsonAdjust);
        }
      }
      if (this._private.last)
        retValue.last = this._private.last;
      return retValue;
    }

    toRGBAColor() {
      return this._private.resolved;
    }

    toHSLAColor() {
      let asHSL = ColorUtils.RGB2HSL(this._private.applied.drgb);
      let alpha = ColorUtils.clampAlpha(this._private.applied.alpha);

      return {
        h: asHSL[0],
        s: asHSL[1],
        l: asHSL[2],
        a: alpha
      }
    }

    toString() {
      let retValue = this.val;
      let adjs = this.adjustments();
      if (adjs)
        retValue += ':' + adjs;
      return 'AdjustableColor(' + retValue + ')';
    }
}