import AbstractModel from "../../dagm/AbstractModel";

import CommonUtils from "../../utils/CommonUtils";
import { flatten2DArray } from "../range/RangeUtils";

/*
  An Model that allows for watching values from CellRanges.
  Note -
  asCells and asPrimitives are both:
  
  * immutable values.
  * in a 2d format that often does not reflect the shape of the ranges.

*/

const defaultcellsTransformer = function(valuesArray, rangesArray) {
    let singleRow = [];
    for (let i =0;i<valuesArray.length;i++) {
        singleRow = singleRow.concat(flatten2DArray(valuesArray[i]));
    }
    return [singleRow];
}

class CellValues extends AbstractModel {
  constructor(sheet, range, cellsTransformer=defaultcellsTransformer) {
    super();
    if (!range || !sheet)
      throw new Error('must have a sheet to use');

    let _self = this; // closure

    this.inputsRange = [];

    /* A list of all rangeOffset */
    this.rangePoints = [];

    function addRange(rangeAdding, offset) {
      let length= 0;
      rangeAdding.scan((sheetName, _x, _y, xAbs, yAbs) => {
          _self.inputsRange.push(sheet.getPropertyAt(sheetName, xAbs, yAbs));
          length++;
      });
      _self.rangePoints.push({ range: rangeAdding, length: length});
    }

    if (Array.isArray(range)) {
        for (let i =0; i<range.length; i++)
            addRange(range[i], i);
    } else {
        addRange(range, 0);
    }

    /*
      Return the resolved cells as a 2d array (or an array of 2d arrays)
    */
    this.addProperty("asCells", {
      isReadOnly: true,
      defaultValue: function () {
        if (this.getInputLength() === 0) return null;

        let valuesList = [];
        let rangesList = [];
        let offset = 0;
        for (let i=0; i<_self.rangePoints.length; i++) {
            let values = [];
            let rp = _self.rangePoints[i];
            for (let j=0; j<rp.length; j++) {
                values.push(this.getInput(offset++));
            }
            let as2D = CommonUtils.build2DArray(values, rp.range.width)
            valuesList.push(as2D);
            rangesList.push(rp.range);
        }

        let retValue = cellsTransformer(valuesList, rangesList);
        if (retValue !== null && !Array.isArray(retValue)) {
            cellsTransformer(asRangeList);
            console.warn('cellsTransformer returned an type', asRangeList);
            return null;
        }
        // Must always be a 2d array
        return retValue;
      },
      inputs: this.inputsRange
    });

    /*
      Return the resolved values as a 2d.
    */
    this.addProperty("asPrimitives", {
      isReadOnly: true,
      defaultValue: function (cells) {
        if (!cells)
            return null;
        let primitives = [];
        for (let i=0;i<cells.length; i++) {
            let row = [];
            primitives.push(row);
            for (let j=0;j<cells[i].length; j++) {
                let cell = cells[i][j];
                row.push(cell ? cell.v : null);
            }
        }

        return primitives;
      },
      inputs: ['asCells']
    });

    this.addProperty("width", {
      isReadOnly: true,
      defaultValue: function (asPrimitives) {
        if (!asPrimitives || asPrimitives.length == 0)
            return 0;
        return asPrimitives[0].length;
      },
      inputs: ['asPrimitives']
    });

    this.addProperty("height", {
      isReadOnly: true,
      defaultValue: function (asPrimitives) {
        if (!asPrimitives)
            return 0;
        return asPrimitives.length;
      },
      inputs: ['asPrimitives']
    });
  }
}

export default CellValues;
