import ChartDataLabelShape, { addDataLabelShown } from "../ChartDataLabelShape";

class ChartTypeDataLabelShape extends ChartDataLabelShape {
  constructor(options, styleKey) {
    super(Object.assign(options, { noDefaultProperties: true }), null/*style*/); // With a null style the styling properties are not unnecessarily defined
    this._styleKey = styleKey;
    const _self = this;

    let _chartType = options.chartType;
    this.addProperty("chartType", {
      isReadOnly: true,
      isTransient: true,
      defaultValue: function () {
        return _chartType;
      },
    });

    this.addProperty("formatCode", {
      defaultValue: function (grouping) {
        if (grouping === "percentStacked")
          return "0%";
        let z = "General";
        return z;
      }, inputs: ["chartType.grouping"]
    });

    // not implemented - (would need a range editor, only takes first value)
    // this.addProperty("valueFromCells", { defaultValue: false });
    // only for pie charts
    this.addProperty("showPercentage", { defaultValue: false });

    this.addProperty("showSerName", { defaultValue: false });
    this.addProperty("showCatName", { defaultValue: false });
    this.addProperty("showVal", { defaultValue: false });

    // only for bubble
    this.addProperty("showBubbleSize", { defaultValue: false });
    // Not supported
    this.addProperty("showLeaderLines", { defaultValue: false });
    this.addProperty("showLegendKey", { defaultValue: false });

    addDataLabelShown(this);

    this.addProperty("separator", { defaultValue: ", " });

    // add since other noDefaultProperties are set
    this.addProperty("rotation", { defaultValue: 0.0 });

    // TODO - check for stacked percenage (these labels are centered).
    this.addProperty("position", {
      defaultValue: function (type) {
        if (type === 'line' || type === 'scatter')
          return 't';
        else if (type === 'bar' || type === 'column')
          return 'outEnd';

        return 'ctr';
      }, inputs: ['chartType.type']
    });
  }

  get className() {
    return "ChartTypeDataLabelShape";
  }

  get typeId() {
    return this.className + ":" + this._styleKey;
  }
}

export default ChartTypeDataLabelShape;
