import { ManualLayoutPersister, manualLayoutSetter } from "../primitives/Coordinates";

import { isSingleSeries } from "../../utils/ChartUtils";

import { stringFromCells } from "../range/RangeUtils";
import { RangeSelectionPersister, createRangeSelectionSetter } from "../range/MultiRange";
import LiteralRange from "../range/LiteralRange";
import LiteralValues from "../range/LiteralValues";

import ChartPartShape from "./ChartPartShape";
import ChartTextShape from "./ChartTextShape";


class ChartTitleShape extends ChartPartShape {
  constructor(options) {
    super(options, "title");
    const _self = this;

    const getDefaultChartTile = options?.getDefaultChartTile || null;
    const defaultChartTitleFontSize = options?.defaultChartTitleFontSize ||  18.6;
    const defaultChartTitleDescription = options?.defaultChartTitleDescription || "Chart Title";

    this.addProperty("description", {
      isReadOnly: true,
      isTransient: true,
      defaultValue: function () {
        return defaultChartTitleDescription;
      },
      inputs: [],
    });

    this.addProperty("overlay", {
      defaultValue: false,
    });

    /**
     * This is implemented as a rect (x,y,width,height) with percentanges of the containing elmement.
     * If null then no manualLayout is applied
     * Note -
     * This is to spec :
     * https://docs.microsoft.com/en-us/openspecs/office_standards/ms-oi29500/bb8565e7-dfea-453f-868b-ae680ab8a823?redirectedfrom=MSDN
     */
    this.addProperty("manualLayout", {
      setValue: manualLayoutSetter,
      defaultValue: function () {
        return null;
      },
      persister: new ManualLayoutPersister(),
    });

    this.addProperty("range", {
      setValue: createRangeSelectionSetter(true/*singleLength*/, false/*alignRanges*/, true/*forceFixed*/),
      defaultValue: function (series, mainType, _seriesTitleRanges) {
        let retValue = null;
        if (series.length === 1 || (isSingleSeries(mainType))) {
          retValue = series[0].title.range; // We watch them all
        }

        if (!retValue && getDefaultChartTile) {
          const defaultChartTile = getDefaultChartTile(_self);
          if (defaultChartTile) {
            return new LiteralRange([[defaultChartTile]]);
          }
        }
        return retValue;
      },
      inputs: ["chartShape.series.*", "chartShape.mainType", "chartShape.series[*].title.range"],
      persister: new RangeSelectionPersister(),
    });

    this.addProperty("values", {
      isReadOnly: true,
      defaultValue: function (sheet, titleRange) {
        if (sheet === null) {
          return new LiteralValues(new LiteralRange([[null]]));
        }
        // Note - Office doesn't allow for literal ranges for titles. We do
        return sheet.valuesFromRange(titleRange);
      },
      inputs: ["chartShape.sheet", "range"],
    });

    this.addProperty("defaultText", {
      isReadOnly: true,
      isTransient: true,
      defaultValue: function (asCells) {
        if (!asCells || asCells.length === 0) {
          return "Chart Title";
        }

        return stringFromCells(asCells);
      },
      inputs: ["values.asCells"],
    });

    this.addProperty("text", {
      defaultValue: function (defaultTextValue) {
        return new ChartTextShape(
          Object.assign({}, options, { parent: _self}),
          this.getProperty('defaultText'),
          _self._styleKey,
          defaultChartTitleFontSize
        );
      },
      persister: null /* we implicitly save models*/,
      inputs: ["defaultText"],
    });
  }

  get className() {
    return "ChartTitleShape";
  }

}

export default ChartTitleShape;
