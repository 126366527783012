import ChartPartShape from "./ChartPartShape";
import ChartTextShape from "./ChartTextShape";
import FetchedPropertyValue from "../../dagm/FetchedPropertyValue";

const showProperties = [
  "showSerName",
  "showCatName",
  "showVal",
  "showPercentage",
  "showBubbleSize",
  "showLeaderLines",
  "showLegendKey"
];

export const addDataLabelShown = function(dataLabel) {
  let addMethod = 'addProperty';
  if (dataLabel.getProperty("shown"))
    addMethod = 'overrideProperty';
  dataLabel[addMethod]("shown", {
    isTransient: true,
    setValue: function (value, auxSetPropertyValue) {
      // if value === true and all values are false then set showVal.
      if (value === true)
        for (let i=0; i<showProperties.length; i++) {
          auxSetPropertyValue("showVal", value);
        }
      else {
        for (let i=0; i<showProperties.length; i++) {
          // note - we could check and only set if true
          auxSetPropertyValue(showProperties[i], value); // could be false or clear
        }
      }
    },
    defaultValue: function () {
      let retValue = false;
      for (let i=0; !retValue && i< this.getInputLength(); i++) {
        if (this.getInput(i))
          retValue = true;
      }
      return retValue;
    },
    valueCreator: function (source, value, property, isExplicit, defaultValue) {
      let isExplicitDerived = false;
      for (let i=0; i< showProperties.length; i++) {
        isExplicitDerived = isExplicitDerived || dataLabel.getPropertyValue(showProperties[i]).isExplicit;
      }

      return new FetchedPropertyValue(
        source,
        value,
        property,
        isExplicitDerived,
        defaultValue
      );
    },
    inputs: showProperties,
  });
}

class ChartDataLabelShape extends ChartPartShape {
  constructor(options, styleKey, defaultShown) {
    super(options, styleKey);
    const _self = this;

    // we don't add text to read only data labels since they can not be different than the oridinal
    // this is a performance
    if (!options.readonly) {
      this.addProperty("text", {
        defaultValue: function () {
          const dataLabelOptons = {...options};
          dataLabelOptons.appContext = dataLabelOptons.appContext;
          dataLabelOptons.parent = _self;
          return _self._createDataLabelTextShape(dataLabelOptons, _self.styleKey);
        },
        persister: null /* we implicitly save models*/,
      });
    }
  }

  _createDataLabelTextShape(dataLabelOptons, styleKey) {
    return new ChartTextShape(
      dataLabelOptons,
      null,
      styleKey
    );
  }

  get className() {
    return "ChartDataLabelShape";
  }

  get typeId() {
    return this.className + ":" + this._styleKey;
  }


}

export default ChartDataLabelShape;
