export function containsText(values) {
  for (let i = 0; i < values.length; i++) {
    let value = Number(values[i]);
    if (value === undefined || value === null) continue;
    // empty strings are ignored too.
    if (
      (typeof value === "string" || value instanceof String) &&
      value.length === 0
    )
      continue;
    if (isNaN(value) || !isFinite(value)) return true;
  }

  return false;
}

export function max(values) {
  if (!Array.isArray(values))
    return values;
  let found = undefined;
  for (let i = 0; i < values.length; i++) {
    if (values[i] === null || values[i] == null) continue;
    let value = Number(values[i]);
    if (isNaN(value) || !isFinite(value)) continue;

    if (found === undefined) found = value;
    else if (value > found) found = value;
  }
  return found;
}

export function min(values) {
  if (!Array.isArray(values))
    return values;
  let found = undefined;
  for (let i = 0; i < values.length; i++) {
    if (values[i] === null || values[i] == null) continue;
    let value = Number(values[i]);
    if (isNaN(value) || !isFinite(value)) continue;

    if (found === undefined) found = value;
    else if (value < found) found = value;
  }
  return found;
}

export function logX(num, logBase) {
  return Math.log(num) / Math.log(logBase);
}


const commonFunctors = {
  containsText: containsText,
  max: max,
  min: min,
  logX: logX
};

const CommonFunctors = Object.freeze(commonFunctors);
export default CommonFunctors;
