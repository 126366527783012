import ArrayModel from "../../../dagm/ArrayModel";
import PropertyPersister from "../../../dagm/PropertyPersister";

import ChartDataPointMarkerShape from "../point/ChartDataPointMarkerShape";
import { ChartDataPointMarkerShapePersistor } from "../point/ChartDataPointMarkerShape";

import { createFillProperty } from "../../AbstractShape";

import ChartPartShape from "../ChartPartShape";
import {
  MARKER_KEYS,
  DEFAULT_MARKER_SIZE,
  isLineType,
} from "../../../utils/ChartUtils";

class ChartSeriesMarkerShape extends ChartPartShape {
  constructor(options, offset, length) {
    super(options);
    const _self = this;

    const _series = options.series;
    const _pointsData = options.points;
    const _chartType = options.chartType;

    this.addProperty("chartType", {
      isReadOnly: true,
      isTransient: true,
      defaultValue: function () {
        return _chartType;
      },
    });

    this.addProperty("series", {
      isReadOnly: true,
      isTransient: true,
      defaultValue: function () {
        return _series;
      },
    });

    this.addProperty("offset", {
      isReadOnly: true,
      isTransient: true,
      defaultValue: function () {
        return offset;
      },
    });

    this.addProperty("seriesLength", {
      isReadOnly: true,
      isTransient: true,
      defaultValue: function () {
        return length;
      },
    });

    this.addProperty("type", {
      defaultValue: (scatterStyle, type) => {
        if (
          isLineType(type) &&
          scatterStyle &&
          scatterStyle.toLowerCase().includes("marker")
        )
          return MARKER_KEYS[offset % MARKER_KEYS.length];
        return "none";
      },
      inputs: ["chartType.scatterStyle", "chartType.type"],
    });

    this.addProperty("size", {
      defaultValue: () => {
        return DEFAULT_MARKER_SIZE;
      },
    });

    // fill
    createFillProperty(this, this.styleKey, 'fill', "chartShape.chartStyle", function(style, styleKey, chartType) {
        return style.createDataPoints2DFillStyleProperties(offset, length);
    }, ["chartType.type"]);

    // stroke
    createFillProperty(this, this.styleKey, 'strokeFill', "chartShape.chartStyle", function(style, styleKey, chartType) {
        // Note - markers use the fill style instead of the stroke style
        return style.createDataPoints2DFillStyleProperties(offset, length);
        // return chartStyle.createDataPoints2DStrokeStyleProperties(offset, length).fill;
    }, ["chartType.type"], 'stroke.fill');

    this.overrideProperty("strokeWidth", {
      defaultValue: function () {
        return 0.75;
      },
      persister: new PropertyPersister("stroke.width"),
    });

    this.addProperty("points", {
      isReadOnly: true,
      defaultValue: function (type) {
        if (type === "none")
          return null;

        let retValue = new ArrayModel(_pointsData ? _pointsData.length : 0, {
          defaultValue: function (index, length) {
            return null;
          },
          inputs: ["$index", "length"],
          persister: new ChartDataPointMarkerShapePersistor(null, {
            chartShape: _self.chartShape,
            seriesMarkers: _self,
            getPointX : function(index) {
              let point = _pointsData[index];
              if (!point)
                return null;
              return point.pointX;
            },
            getPointVal : function(index) {
              let point = _pointsData[index];
              if (!point)
                return null;
              return point.pointVal;
            },
            appContext: _self._shapeOptions.appContext
          })
        });
        return retValue;
      },
      persister: null /* we implicitly save models*/,
      inputs: ["type"],
    });

    this.createDataPointMarker = function(index, readonly=false) {
      const pointData = _pointsData[index];
      if (!pointData)
        return null;

      const retValue = new ChartDataPointMarkerShape({
        chartShape: _self._shapeOptions.chartShape,
        seriesMarkers: _self,
        pointX: pointData.pointX,
        pointVal: pointData.pointVal,
        appContext: _self._shapeOptions.appContext,
        readonly: readonly
      });
      return retValue;
    }

    this.addProperty("renderedPoints", {
      isReadOnly: true,
      defaultValue: function (points) {
        if (points === null)
          return null;

        let retValue = [];
        for (let i=0; i<points.length; i++) {
          let pointCustom = points.getAt(i);
          if (pointCustom === null) {
            pointCustom = _self.createDataPointMarker(i, true/*readonly*/);
          }
          retValue.push(pointCustom);
        }
        return retValue;
      },
      persister: null /* we implicitly save models*/,
      inputs: [
        "points",
        "points[*]",
        "type",
        "size"
      ],
    });
  }

  get className() {
    return "ChartSeriesMarkerShape";
  }

}

export default ChartSeriesMarkerShape;
