let _udateContext = [];

export function setUpdateContext(u) {
  _udateContext = u;
}

export function hasUpdateContext() {
  return !!_udateContext;
}

export function getUpdateContext() {
  return _udateContext;
}

const updateContext = {
  setUpdateContext: setUpdateContext,
  getUpdateContext: getUpdateContext,
  hasUpdateContext: hasUpdateContext,
};

const UpdateContext = Object.freeze(updateContext);
export default UpdateContext;
