import ModelProperty from "./ModelProperty";

import ObjectPath from "../utils/ObjectPath";
import CommonUtils from "../utils/CommonUtils";

import UpdateContext from "./UpdateContext";
import ScopeContextStack from "./ScopeContextStack";
import ExplicitResolver from "./ExplicitResolver";

/*
 A model is a collection of properties.

 There are three things that are tracked at the model level.
 1. Property Definitions
 2. Calculated Values
 3. Depedencies

 There are two types of properties;

 Atomic/SimpleProperties and Modelled properties.

 Model Properties are instances that are reused between getDefaultValue calls and have an implict input of '*'.

 Every model has an implict read-only '*' property that can have listeners registered against it.

 Every model has an implicit '*' readonly property that is dependant on all defined properties.
 This can be listened to for any change. This will be fired
 if there are changes to any properties
*/

class WildCardProperty extends ModelProperty {
  constructor(instance) {
    super(instance, "*", {
      isReadOnly: true,
      isDelegate: true,
      persister: null,
      defaultValue: function () {
        return instance;
      },
      inputs: [],
    });
  }
}

const getPropertyDef = function(instance, property) {
  if (property instanceof ModelProperty)
    return property;
  else {
    let propertyDef = instance.getPropertyValue(property);
    if (!propertyDef) {
//       console.warn(property + " was not resolvable to skipped");
      return null;
    }
    return propertyDef.property;
  }
}

const addToMarkedList = function(instance, visitedMap, visitedStack, markedList, property, explicitValue) {
  if (!property)
      return;

  let updates = [];
  const auxSetPropertyValue = function(propertyNameAux, explicitValueAux) {
    let propertyDef = getPropertyDef(instance, propertyNameAux);
    if (propertyDef)
      updates.push({ propertyDef : propertyDef, explicitValue: explicitValueAux });
  }

  let propertyDef = getPropertyDef(instance, property);
  if (!propertyDef)
    return;

  explicitValue = propertyDef._options.setValue(explicitValue, auxSetPropertyValue);
  updates.unshift({ propertyDef : propertyDef, explicitValue: explicitValue });

  for (let i=0; i< updates.length; i++) {
  if (updates[i].propertyDef.evaled.explicit === updates[i].explicitValue) continue;
    instance._explicitLookup.setExplictValue(updates[i].propertyDef, updates[i].explicitValue);
    updates[i].propertyDef.walkDependants(markedList, visitedMap, visitedStack);
  }
}

class AbstractModel {
  constructor(options) {
    if (new.target === AbstractModel)
      throw TypeError("can not create an abstract class AbstractModel");
    if (options) {
       this._readonly = !!options.readonly;
    }

    this._disposes = [];

    this._propertyListeners;
    this._properties = {};

    let scopeContext = ScopeContextStack.getScopeContext();
    if (scopeContext) {
      // this.scope = scopeContext.scope;
      this.__propertyFrom = scopeContext.propertyFrom;
      this._explicitLookup = scopeContext.explicitLookup;
    } else {
      // Was created outside of a scopecontext so the explict lookup
      this._explicitLookup = new ExplicitResolver({});
    }

    this._dependencies = {};

    this._wildCardProperty = new WildCardProperty(this);
    this._properties["*"] = this._wildCardProperty;
    this._properties["**"] = this._wildCardProperty;
    this._wildCardProperty.recalcProperty();
  }

  visitModel(nodePath, visitedMap, visitor) {
    if (visitedMap.has(this)) return;
    visitedMap.set(this, true);
    let propertyNames = this.propertiesNames;
    // TODO - should we sort the key names? Does json serialization do this?
    for (let i = 0; i < propertyNames.length; i++) {
      let propertyDef = this.lookupPropertyDef(propertyNames[i]);
      if (propertyDef)
        propertyDef.visitProperty(nodePath, visitedMap, visitor);
    }
  }

  getOrCreatePath(nodePath, jsonRoot) {
    let currentNode = jsonRoot;
    for (let i = 0; i < nodePath.length; i++) {
      if (currentNode[nodePath[i]] === undefined) currentNode[nodePath[i]] = {};
      currentNode = currentNode[nodePath[i]];
    }
    return currentNode;
  }

  toJSON(filterUnused = true) {
    if (filterUnused) {
      let retValue = {};
      this.visitModel(
        [],
        new Map(),
        function (property, value, path) {
          if (property.evaled.explicit !== undefined) {
            let jsonCurrent = this.getOrCreatePath(path, retValue);
            if (property._options.persister)
              property._options.persister.save(property, jsonCurrent, value);
          }
        }.bind(this)
      );
      return retValue;
    } else {
      let node = {};
      if (this.__propertyFrom) {
        let root = this._explicitLookup.getRootForProperty(this.__propertyFrom);
        let nodeparent = this._explicitLookup.getOrCreatePath(root.path);
        node = nodeparent[this.__propertyFrom.persistKey];
      } else {
        node = this._explicitLookup._jsonRoot;
      }
      let retValue = node ? CommonUtils.cloneObject(node) : {};
      CommonUtils.removeEmptyProperties(retValue);
      return retValue;
    }
  }

  get propertiesNames() {
    let retValue = [];
    let propKeys = Object.keys(this._properties);
    for (let i = 0; i < propKeys.length; i++) {
      if (!this._properties[propKeys[i]]._options.isDelegate)
        retValue.push(propKeys[i]);
    }
    return retValue;
  }

  lookupPropertyDef(propertyName) {
    return this._properties[propertyName];
  }

  isProperty(propertyName) {
    return !!this.lookupPropertyDef(propertyName);
  }

  getProperty(propertyName) {
    if (!Array.isArray(propertyName))
      return this.lookupPropertyDef(propertyName);
    else if (Array.isArray(propertyName) && propertyName.length === 1)
      return this.lookupPropertyDef(propertyName[0]);

    let propertyPath = [...propertyName];

    let remainingPropertyPath = propertyPath.splice(1);
    let lastValue = this.getPropertyValue(propertyPath[0]);

//     if (!lastValue || !(lastValue.value instanceof AbstractModel))
//       return undefined;

    if (!lastValue)
        return undefined;
    if (lastValue.value === null) {
        return this.lookupPropertyDef(propertyName[0]);
    }
    if (!(lastValue.value instanceof AbstractModel))
     return undefined;
    if (lastValue) return lastValue.value.getProperty(remainingPropertyPath);
  }

  enrichInputs(inputs) {
    if (inputs === undefined || inputs === null)
      return [];
    if (!Array.isArray(inputs)) inputs = [inputs];

    let inputsCloneds = [...inputs];
    // Ensure that all the inputs are model properties or string arrays. Strings are resolved as 'local propertyPath.
    for (let i = 0; i < inputsCloneds.length; i++) {
      if (inputsCloneds[i] instanceof ModelProperty) {
        continue;
      }
      let path = inputsCloneds[i];
      if (path === undefined)
        throw new Error(
          "input is undefined, this is usually do to an extra comma in the input array"
        );
      if (!Array.isArray(path)) path = ObjectPath.parse(path);
      inputsCloneds[i] = path;
    }
    return inputsCloneds;
  }

  overrideProperty(propertyName, options) {
    // TODO
    // overrideProperty should look at the inputs.
    // if there is super than it needs to chain
    // This implementation just replaces
    let existingPropertyDef = this.lookupPropertyDef(propertyName);
    if (!existingPropertyDef)
      throw new Error(
        "'" +
          propertyName +
          "' is not defined. Unabled to override property for type " +
          this.constructor.name +
          "."
      );

    return this.addProperty(
      propertyName,
      Object.assign(
        {
          override: true,
        },
        options || {}
      ),
      existingPropertyDef
    );
  }

  addProperty(propertyName, options, existingPropertyDef) {
    if (!existingPropertyDef)
      existingPropertyDef = this.lookupPropertyDef(propertyName);
    if (existingPropertyDef && (!options || !options.override)) {
      throw new Error(
        "'" +
          propertyName +
          "' is already a defined property for type " +
          this.constructor.name +
          "."
      );
    }
    // if the options
    if (typeof options === undefined)
      options = function () {
        return undefined;
      };

    if (typeof options !== "object" && options !== null) {
      let optionValue = options;
      options = function () {
        return optionValue;
      };
    }

    if (typeof options === "function") {
      options = {
        defaultValue: options,
      };
    }

    let optionsEnriched = Object.assign(
      {
        isReadOnly: false,
        isWritable: true,
        inputs: [],
      },
      options || {}
    );

    optionsEnriched.inputs = this.enrichInputs(optionsEnriched.inputs);

    if (typeof optionsEnriched.defaultValue !== "function") {
      let value = optionsEnriched.defaultValue;
      optionsEnriched.defaultValue = function () {
        return value;
      };
    }

    if (!this._readonly) {
      let propOriginal = this._properties[propertyName];
      if (propOriginal) {
        propOriginal.removeDependencies(propOriginal.deps);
      }
    }

    let prop = new ModelProperty(this, propertyName, optionsEnriched);
    this._properties[propertyName] = prop;

    let markedList = [];
    prop.walkDependants(markedList);
    if (this._readonly && (markedList.length > 1 || markedList[0] !== prop)) {
      // Note - We don't need to walk dependant in prod but then need to error in dev
      debugger;
    }

    this.recalcCycle(markedList, false /*notify*/);

    let spec = {
      configurable: true,
    };

    if (optionsEnriched.isWritable)
      spec.get = function () {
        let propertyDef = this.lookupPropertyDef(propertyName);
        if (!propertyDef)
          throw Error(
            "'" +
              propertyName +
              "' is an invalid property for " +
              this.constructor.name
          );
        return propertyDef.value;
      };
    if (!optionsEnriched.isReadOnly)
      spec.set = function (newValue) {
        this.setPropertyValue(propertyName, newValue);
      };
    Object.defineProperty(this, propertyName, spec);

    return prop;
  }

  recalcCycle(markedList, notifyListeners = true) {
    let updatedList;
    if (notifyListeners) {
      updatedList = [];
      UpdateContext.setUpdateContext(updatedList);
    }
    // We walk in reverse to ensure that all the dependencies have been calced
    markedList = markedList.reverse();
    try {
      // we sweep twice. First to mark everything as calcing
      for (let i = 0; i < markedList.length; i++) {
        if (markedList[i].evaled) markedList[i].evaled.calcing = true;
      }
      for (let i = 0; i < markedList.length; i++) {
        markedList[i].recalcProperty();
      }
    } catch (error) {
      // console.log('Unable to resolve dependencies', error);
      throw error;
    } finally {
    }
    if (notifyListeners) {
      // Note -
      // We guarantee to only fire listeners once but the order is
      // based on the graph order not the model/node distance
      this.notifyPropertyListeners(updatedList);
    }
  }


  setPropertyValue(propertyName, explicitValue) {
    let visitedMap = new Map();
    let visitedStack = [];
    let markedList = [];

    if (this._readonly)
      throw new Error("Can not set readonly model", propertyName);

    addToMarkedList(this, visitedMap, visitedStack, markedList, propertyName, explicitValue);

    try {
      this.recalcCycle(markedList);
    } catch (error) {
      console.log("Unable to resolve dependencies", error);
      throw error;
    } finally {
    }
  }

  /*
      This is a list of propertyName to explictValues
      [ { property : 'path', explicitValue : value } ]
    */
  setPropertyValues(updates) {
    // property, explicitValue) {
    let visitedMap = new Map();
    let visitedStack = [];
    let markedList = [];

    if (!Array.isArray(updates))
      throw new Error(
        "updates must be an array of propertyPath, explicitValues"
      );
    if (updates.length === 0) return;
    if (Array.isArray(updates)) {
      for (let i = 0; i < updates.length; i++) {
        let update = updates[i];
        if (
          !("property" in update) ||
          (!("explicitValue" in update) && !("value" in update))
        )
          throw new Error(
            "an array was found but elements but be an object with and x and y int"
          );

        let explicitValue =
          "explicitValue" in update ? update.explicitValue : update.value;
        addToMarkedList(this, visitedMap, visitedStack, markedList, update.property, explicitValue);
        // let propertyDef;
        // if (update.property instanceof ModelProperty)
        //   propertyDef = update.property;
        // else {
        //   propertyDef = this.getPropertyValue(update.property);
        //   if (!propertyDef) {
        //     console.warn(update.property + " was not resolvable to skipped");
        //     continue;
        //   }
        //   propertyDef = propertyDef.property;
        // }

        // let explicitValue =
        //   "explicitValue" in update ? update.explicitValue : update.value;
        // explicitValue = propertyDef._options.setValue(explicitValue);
        // if (propertyDef.evaled.explicit === explicitValue) continue;

        // this._explicitLookup.setExplictValue(propertyDef, explicitValue);
        // propertyDef.walkDependants(markedList, visitedMap, visitedStack);
      }
    } else {
      throw new Error(
        "updates must be an array of coordinates with an x and y int or no arguments"
      );
    }

    this.recalcCycle(markedList);
  }

  getPropertyValue(propertyPath) {
    if (!Array.isArray(propertyPath))
      propertyPath = ObjectPath.parse(propertyPath);

    let propertyName = propertyPath[0];
    let propertyDef = this.lookupPropertyDef(propertyName);

    if (!propertyDef) {
      return undefined;
    }

    let currentPropertyValue =  propertyDef.getPropertyValue();

    if (propertyPath.length === 1) {
        return currentPropertyValue;
    }
    if (currentPropertyValue === undefined) {
      // console.log('warn', propertyPath)
      return undefined;
    }

    if (currentPropertyValue.value === null) {
        return currentPropertyValue;
    }

    let propertyPathFollow = [...propertyPath].splice(1);
    return propertyDef.getPropertyValue(propertyPathFollow);
  }

  // Note - The input is either another modelproperty or a string array
  addDependencies(input, adder) {
    if (input instanceof ModelProperty) {
      adder(input.instance, input.propertyName);
    } else if (Array.isArray(input)) {
      // walk the chain add add instances
      let currentInstance = this;
      let currentProp;
      for (let i = 0; i < input.length; i++) {
        let currentPropName = input[i];
        adder(currentInstance, currentPropName);

        if (currentPropName === "*" && i < input.length - 1) {
          let filterPath = [...input];
          let propertyNames = currentInstance.propertiesNames;
          for (let j = 0; j < propertyNames.length; j++) {
            filterPath[i] = propertyNames[j];
            this.addDependencies(filterPath, adder);
          }
          return;
        }

        try {
          currentProp = currentInstance.lookupPropertyDef(currentPropName);
        } catch (error) {}
        if (!currentProp) {
          // unresolvable
          return;
        }
        if (!(currentProp.value instanceof AbstractModel)) return;
        currentInstance = currentProp.value;
      }
    } else throw new Error("input is unknown type.");
  }

  collectListeners(propertyName) {
    let listeners = {};
    if (!this._propertyListeners) return listeners;

    Object.keys(this._propertyListeners[propertyName] || {}).forEach(
      function (keyUUID) {
        listeners[keyUUID] = this._propertyListeners[propertyName][keyUUID];
      }.bind(this)
    );
    return listeners;
  }

  /*
   * The add listener allows to listen to either a named property or a wildcard.
   * If the propertyName is null or '*' we fire both named and wildcard notifications.
   */
  notifyPropertyListeners(updates) {
    let listenersCollected = new Map();
    let instancesVisited = new Set();

    // Now look for wildcards.
    let instanceFiredWild = new Set();
    let listenersFiredWild = new Set();

    for (let i = 0; i < updates.length; i++) {
      // for (let j=0; j<updates[i].length; j++) {
      let change = updates[i]; //[j];
      let property = change.property;
      let propertyName = property.propertyName;
      let instance = property.instance;
      instancesVisited.add(instance);
      if (instance._listenerDependencies) {
        let listeners = instance._listenerDependencies[propertyName];
        if (listeners) {
          Object.keys(listeners).forEach(function (key) {
            let listenerFound = listeners[key];
            let listenerCollected = listenersCollected.get(listenerFound.uuid);
            if (!listenerCollected) {
              listenerCollected = {
                listenerInfo: listenerFound,
                changes: [],
              };
              listenersCollected.set(listenerFound.uuid, listenerCollected);
            }
            listenerCollected.changes.push(change);
          });
        }
      }

      // }
    }

    listenersCollected.forEach((listenerCollected) => {
      listenerCollected.listenerInfo.updateDependencies();
      let event = {
        previousValue: listenerCollected.listenerInfo.previousValue,
        newValue: listenerCollected.listenerInfo.resolvedValue,
      };
      let inputsChanged = false;
      for (
        let i = 0;
        !inputsChanged &&
        i < listenerCollected.listenerInfo.resolvedValue.length;
        i++
      ) {
        let inputValue = undefined;
        if (!listenerCollected.listenerInfo.resolvedValue[i]) {
          if (listenerCollected.listenerInfo.previousValue[i])
            inputsChanged = true;
          continue;
        }

        instancesVisited.add(
          listenerCollected.listenerInfo.resolvedValue[i].property.instance
        );
        inputValue = listenerCollected.listenerInfo.resolvedValue[i].value;
        let currentExplicit =
          listenerCollected.listenerInfo.resolvedValue[i].isExplicit;
        let previousExplicit = undefined;
        let previousValue;
        if (listenerCollected.listenerInfo.previousValue[i]) {
          previousValue = listenerCollected.listenerInfo.previousValue[i].value;
          previousExplicit =
            listenerCollected.listenerInfo.previousValue[i].isExplicit;
        }

        if (currentExplicit !== previousExplicit) inputsChanged = true;
        if (
          !inputsChanged &&
          inputValue !== previousValue &&
          !CommonUtils.arrayEquals(inputValue, previousValue)
        ) {
          inputsChanged = true;
        }
      }
      if (inputsChanged) {
        listenerCollected.listenerInfo.listener(event);
        listenersFiredWild.add(listenerCollected.listenerInfo);
      }
    });

    instancesVisited.forEach(function (instanceVisit) {
      if (instanceFiredWild.has(instanceVisit)) return;

      let notify = instanceVisit;
      let count = 0;
      let propertyNameCurrent = undefined;
      do {
        count++;
        if (count > 50) {
          debugger;
          return;
        }

        let listeners;
        if (notify._listenerDependencies)
          listeners = notify._listenerDependencies["**"];
        if (listeners) {
          // fire wildcard.
          Object.keys(listeners).forEach(function (key) {
            let listenerFound = listeners[key];
            // TODO - could build a walked path but need to track from property not from instance
            let event = {
              propertyName: "*",
              previousValue: listenerFound.instance,
              newValue: listenerFound.instance,
            };
            if (!listenersFiredWild.has(listenerFound)) {
              listenerFound.listener(event);
              listenersFiredWild.add(listenerFound);
            }
          });
        }

        if (notify.__propertyFrom && !notify.__propertyFrom.isTransient) {
          propertyNameCurrent = notify.__propertyFrom.propertyName;
          notify = notify.__propertyFrom.instance;
        } else {
          notify = null;
        }
        instanceFiredWild.add(instanceVisit);
      } while (notify);
    });
  }

  addPropertyListener(inputs, listener) {
    if (!this._listeners) this._listeners = new Map();

    let enrichedInputs = this.enrichInputs(inputs);
    for (let i = 0; i < enrichedInputs.length; i++) {
      // If the last (or only) path part is a wildcard we treat is as an unfiltered lister that changes regardless of update equality
      if (
        enrichedInputs[i].length > 0 &&
        enrichedInputs[i][enrichedInputs[i].length - 1] == "*"
      )
        enrichedInputs[i][enrichedInputs[i].length - 1] = "**";
    }
    var uuid = CommonUtils.uuidv4();

    var unListener = function () {
      let listenerInfo;
      if (this._listeners) listenerInfo = this._listeners.get(uuid);
      if (listenerInfo) {
        this._listeners.delete(uuid);
        this.removeListenerDependencies(listenerInfo.deps);
      } else console.warn("unlistener was called for a second time.");

      if (this._listeners && this._listeners.size === 0) delete this._listeners;
    }.bind(this);

    var updateDependencies = function () {
      let previous = listenerInfo.resolvedValue;
      if (!previous) {
        previous = [];
        for (let i = 0; i < enrichedInputs.length; i++)
          previous.push(undefined);
      }

      this.removeListenerDependencies(listenerInfo.deps);
      listenerInfo.deps = [];
      let adder = function (instance, propertyName) {
        listenerInfo.deps.push({
          instance: instance,
          propertyName: propertyName,
          listenerInfo: listenerInfo,
        });

        this.registerListenerDep(instance, propertyName, listenerInfo);
      }.bind(this);
      listenerInfo.previousValue = previous;
      listenerInfo.resolvedValue = [];
      for (let i = 0; i < enrichedInputs.length; i++) {
        listenerInfo.resolvedValue.push(
          this.getPropertyValue(enrichedInputs[i])
        );
      }

      for (let i = 0; i < enrichedInputs.length; i++) {
        this.addDependencies(enrichedInputs[i], adder);
      }
    }.bind(this);

    let listenerInfo = {
      uuid: uuid,
      instance: this,
      enrichedInputs: enrichedInputs,
      deps: [],
      updateDependencies: updateDependencies,
      unListener: unListener,
      listener: listener,
    };

    listenerInfo.updateDependencies();

    // Note - get current values so that we can return 'previousValues' if we need/want to
    this._listeners.set(uuid, listenerInfo);

    return unListener;
  }

  removeListenerDependencies(deps) {
    if (!deps) return;
    for (let i = 0; i < deps.length; i++) {
      this.unregisterListenerDep(
        deps[i].instance,
        deps[i].propertyName,
        deps[i].listenerInfo
      );
    }
  }

  registerListenerDep(instance, propertyName, listenerInfo) {
    if (!instance._listenerDependencies) instance._listenerDependencies = {};
    if (!instance._listenerDependencies[propertyName])
      instance._listenerDependencies[propertyName] = {};
    let dependencies = instance._listenerDependencies[propertyName];

    dependencies[listenerInfo.uuid] = listenerInfo;
  }

  unregisterListenerDep(instance, propertyName, listenerInfo) {
    let dependencies = instance._listenerDependencies[propertyName];
    if (!dependencies) return;

    delete dependencies[listenerInfo.uuid];
    if (Object.keys(dependencies).length === 0) {
      delete instance._listenerDependencies[propertyName];
    }
  }

  toString() {
    return "" + new.target; //"AbstractModel";
  }

  // TODO - change this to just walk the children. (Are there others?)
  dispose() {
    for (let i = 0; i < this._disposes.length; i++) {
      if (this._disposes[i]) {
        console.log("dispose", this);
        this._disposes[i]();
      }
    }
  }

  addDispose(dispose) {
    this._disposes.push(dispose);
  }
}

export default AbstractModel;
