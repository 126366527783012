import * as RangeUtils from "./RangeUtils";

import { IRange, RangeDirection, RangeVisitor, RangeBounds, enrichBounds } from "./Range";

export default class CellRange implements IRange {
  _r: {
    left: number,
    top: number,
    right: number,
    bottom: number,
    leftFixed: boolean,
    topFixed: boolean,
    rightFixed: boolean,
    bottomFixed: boolean,
    sheetName: string
  };

  constructor(...args: any) {
    if (typeof arguments[0] === "string") {
      let decoded = RangeUtils.decode_range(arguments[0]);

      this._r = {
        left: Number(decoded.s.c),
        top: Number(decoded.s.r),
        right: Number(decoded.e.c),
        bottom: Number(decoded.e.r),
        leftFixed: decoded.s.cf,
        topFixed: decoded.s.rf,
        rightFixed: decoded.e.cf,
        bottomFixed: decoded.e.rf,
        sheetName: decoded.sheetName
      };
    } else if (arguments[0] instanceof CellRange) {
      this._r = {
        left: arguments[0].left,
        top: arguments[0].top,
        right: arguments[0].right,
        bottom: arguments[0].bottom,
        leftFixed: arguments[0].leftFixed,
        topFixed: arguments[0].topFixed,
        rightFixed: arguments[0].rightFixed,
        bottomFixed: arguments[0].bottomFixed,
        sheetName: arguments[0].sheetName
      };
    } else if (arguments.length === 8 || arguments.length === 9) {
      // This is a special case where range is just used as a 'rect'
      this._r = {
        left: Number(arguments[0]),
        top: Number(arguments[1]),
        right: Number(arguments[2]),
        bottom: Number(arguments[3]),
        leftFixed: arguments[4],
        topFixed: arguments[5],
        rightFixed: arguments[6],
        bottomFixed: arguments[7],
        sheetName: arguments[8]
      };
    } else if (arguments.length === 4 || arguments.length === 5) {
      // This is a special case where range is just used as a 'rect'
      this._r = {
        left: Number(arguments[0]),
        top: Number(arguments[1]),
        right: Number(arguments[2]),
        bottom: Number(arguments[3]),
        leftFixed: false,
        topFixed: false,
        rightFixed: false,
        bottomFixed: false,
        sheetName: arguments[4]
      };
    } else if (
      arguments.length === 1 &&
      arguments[0].left !== undefined &&
      arguments[0].top !== undefined &&
      arguments[0].right !== undefined &&
      arguments[0].bottom !== undefined
    ) {
      this._r = {
        left: Number(arguments[0].left),
        top: Number(arguments[0].top),
        right: Number(arguments[0].right),
        bottom: Number(arguments[0].bottom),
        leftFixed: !!arguments[0].leftFixed,
        topFixed: !!arguments[0].topFixed,
        rightFixed: !!arguments[0].rightFixed,
        bottomFixed: !!arguments[0].bottomFixed,
        sheetName: (arguments[0].sheetName ? arguments[0].sheetName : null),
      };
    } else {
      throw new Error(
        "invalid constructor arguments. Use either a range string or (left, top, right, bottom)"
      );
    }
    if (
      this._r.left < 0 ||
      this._r.left > this._r.right ||
      this._r.top < 0 ||
      this._r.top > this._r.bottom
    )
      throw new Error("invalid range arguments");
  }

  get left() {
    return this._r.left;
  }

  get top() {
    return this._r.top;
  }

  get right() {
    return this._r.right;
  }

  get bottom() {
    return this._r.bottom;
  }

  get leftFixed() {
    return this._r.leftFixed;
  }

  get topFixed() {
    return this._r.topFixed;
  }

  get rightFixed() {
    return this._r.rightFixed;
  }

  get bottomFixed() {
    return this._r.bottomFixed;
  }

  get width() {
    return this.right - this.left + 1;
  }

  get height() {
    return this.bottom - this.top + 1;
  }

  /*
   This may be null. This should be interpreted as current
  */
  get sheetName() {
    return this._r.sheetName || null;
  }

  get isCellRange() {
    return true;
  }

  toString() {
    return (this.sheetName ? (this.sheetName + '!') : '') + RangeUtils.encode_range({
      s: RangeUtils.encode_cell({ c: this.left, cf: this.leftFixed, r: this.top, rf: this.topFixed }),
      e: RangeUtils.encode_cell({ c: this.right, cf: this.rightFixed, r: this.bottom, rf: this.bottomFixed }),
    });
  }

  scan(visitor: RangeVisitor, bounds?: RangeBounds) {
    let boundsEnriched = enrichBounds(this, bounds);
    let visits = [];
    for (let i=boundsEnriched.top; i<=boundsEnriched.bottom; i++) {
      for (let j=boundsEnriched.left; j<=boundsEnriched.right; j++) {
        visits.push({
          columnsVisited: boundsEnriched.left - j,
          rowsVisited: boundsEnriched.top - i,
          currentColumnIndex: j,
          currentRowIndex: i,
          currentRange: this
        });
      }
    }
    if (boundsEnriched.reverse)
    visits = visits.reverse();

    let continueVisit = undefined;
    for (let i=0; continueVisit !== false && i<visits.length; i++) {
      continueVisit = visitor(
        this.sheetName,
        visits[i].columnsVisited,
        visits[i].rowsVisited,
        visits[i].currentColumnIndex,
        visits[i].currentRowIndex,
        visits[i].currentRange
      );
    }
  }

  slice(offset: number, direction: RangeDirection | undefined) {
    if (direction === undefined) {
      if (this.width === 1 && this.height > 1) direction = RangeDirection.row;
      else if (this.height === 1 && this.width > 1) direction = RangeDirection.column;
      else direction = this.height > this.width ? RangeDirection.column : RangeDirection.row;
    }
    if (direction === RangeDirection.column) {
      return this.subRange(
        this.left + offset,
        this.top,
        this.left + offset,
        this.bottom);
    } else {
      return this.subRange(
        this.left,
        this.top + offset,
        this.right,
        this.top + offset
      );
    }
  }

  subRange(left: number, top: number, right: number, bottom: number) {
    const leftSub = Math.max(left, this.left);
    const rightSub = Math.min(right, this.right);
    if (rightSub < leftSub) {
      return null;
    }

    const topSub = Math.max(top, this.top);
    const bottomSub = Math.min(bottom, this.bottom);
    if (bottomSub < topSub) {
      return null;
    }

    let args = {
      left: leftSub,
      top: topSub,
      bottom: bottomSub,
      right: rightSub,
      leftFixed: this.leftFixed,
      topFixed: this.topFixed,
      rightFixed: this.rightFixed,
      bottomFixed: this.bottomFixed,
      sheetName: this.sheetName
    };

    return new CellRange(args);
  }
}
