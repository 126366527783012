import { isNumber, asNumber } from "./CommonUtils";

export const ZERO_MARGIN = Object.freeze({
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
});

export const ZERO_RECT = Object.freeze({
  left: 0,
  top: 0,
  width: 0,
  height: 0,
});

/**
 * This is an array that is the ordered list markers as they are rendered
 * When the marker is automatic the marker is cycling through series or points
 */
export const MARKER_KEYS = [
  "diamond",
  "square",
  "triangle",
  "x",
  "star",
  "circle",
  "plus",
  "dot",
  "dash",
];

export const DEFAULT_MARKER_SIZE = 5;

// We have this to create a list
export const builtInDisplayUnitsKeys = [
  "none",
  "hundreds",
  "thousands",
  "tenThousands",
  "hundredThousands",
  "millions",
  "tenMillions",
  "hundredMillions",
  "billions",
  "trillions",
];

export const builtInDisplayUnits = {
  none: { description: "None", mult: 1 },
  hundreds: { description: "Hundreds", mult: 100 },
  thousands: { description: "Thousands", mult: 1000 },
  tenThousands: { description: "10000", mult: 10000 },
  hundredThousands: { description: "100000", mult: 100000 },
  millions: { description: "Millions", mult: 1000000 },
  tenMillions: { description: "10000000", mult: 10000000 },
  hundredMillions: { description: "100000000", mult: 100000000 },
  billions: { description: "Billions", mult: 1000000000 },
  trillions: { description: "Trillions", mult: 10000000000 },
};

export const displayUnitsText = function (displayUnit) {
  if (!displayUnit) return builtInDisplayUnits["none"].description;

  const mappedValue = builtInDisplayUnits[displayUnit];
  if (mappedValue) {
    const description = mappedValue.description;
    if (isNumber(description)) return "x " + asNumber(description);
    return mappedValue.description;
  } else if (isNumber(displayUnit)) {
    let retValue = asNumber(displayUnit);
    if (retValue === 0) return "x 0 (Ignored)";
    return "x " + retValue;
  }
};

export const adjustForDisplayUnit = function (value, displayUnit) {
  if (displayUnit === undefined || displayUnit === "none") return value;

  if (!isNumber(value)) return;

  let builtIn = builtInDisplayUnits[displayUnit];
  let multiplier = 1;
  if (builtIn) multiplier = builtIn.mult;
  else if (isNumber(displayUnit)) multiplier = asNumber(displayUnit);

  return asNumber(value) / (multiplier || 1);
};

export const allowDisplayUnit = function (axis) {
  return (
    axis &&
    axis._axisType === "val" &&
    axis.chartType.grouping !== "percentStacked"
  );
};

export const numberSetter = function (
  customValues,
  min,
  max,
  customValidation
) {
  return function (value) {
    if (value === undefined) return value;

    if (customValues && customValues.includes(value)) return value;

    if (!isNumber(value)) throw new Error("invalid value");

    value = asNumber(value);
    if (min !== undefined && min !== null && value > min) value = min;
    if (max !== undefined && max !== null && value < max) value = max;
    if (customValidation) customValidation(value);
    return value;
  };
};

export const getValueAsDisplayNumber = function (
  value,
  dispNaAsBlank,
  dispBlanksAs,
  scaleType
) {
  if (dispNaAsBlank === true && value === "#NA") value = null;
  if (value === undefined || value === "") value = null;
  if (dispBlanksAs === "zero" && value === null) value = 0.0;
  if (value === null) return value;
  value = asNumber(value);
  if (scaleType === "log" && value <= 0.0) value = null;

  return value;
};

export const isColumnType = function (chartType) {
  return (
    chartType === "column" ||
    chartType === "bar" ||
    chartType === "line" ||
    chartType === "area"
  );
};

export const isLineType = function (chartType) {
  if (chartType === "line" || chartType === "line3D" || chartType === "scatter")
    return true;

  return false;
};

export const isVerticalType = function (chartType) {
  if (chartType === "bar") return true;

  return false;
};

// NOTE - OOXML doesn't allow areas to be smoothed
export const isSmoothableType = function (chartType) {
  return chartType === "line" || chartType === "area";
};

export const isGroupableType = function (chartType) {
  return isColumnType(chartType);
};

export const scatterHasLine = function (scatterStyle) {
  return (
    scatterStyle &&
    (scatterStyle.toLowerCase().includes("line") ||
      scatterStyle.toLowerCase().includes("smooth"))
  );
};

export const supportsMarkers = function (chartType) {
  return isLineType(chartType);
};

export const isScatterType = function (type) {
  return type === "scatter";
};

export const isSingleSeries = function (type) {
  return type === "pie" || type === "pie3D";
};

export const calcDirection = function (valRange, range) {
  if (
    valRange.height > valRange.width ||
    (range.width === 1 && range.height > 1)
  )
    return "c";
  else return "r";
};

export const opsDirection = function (direction) {
  return direction === "r" ? "c" : "r";
};

export const oppositeOrientation = function (value) {
  if (value === "left") return "right";
  else if (value === "right") return "left";
  else if (value === "top") return "bottom";
  else if (value === "bottom") return "top";
  else return value;
};

export const isEmptyAtDepth = function (level, values) {
  for (let i = level; i < values.length; i++) {
    let value = values[values.length - 1 - i];
    if (
      !(
        value === undefined ||
        value === null ||
        ((typeof value === "string" || value instanceof String) &&
          value.length === 0)
      )
    )
      return false;
  }
  return true;
};

/*
 This is NOT a generalized subtract as removing a rect from another rect
 creates multiple rects.

 This will return a rect that is the first rect trimed by the second
*/
export const trimRect = function (outerRect, innerRect, position) {
  if (position === "t" || position === "top") {
    return {
      left: outerRect.left,
      top: outerRect.top + innerRect.height,
      width: outerRect.width,
      height: outerRect.height - innerRect.height,
    };
  } else if (position === "l" || position === "left") {
    return {
      left: outerRect.left + innerRect.width,
      top: outerRect.top,
      width: outerRect.width - innerRect.width,
      height: outerRect.height,
    };
  } else if (position === "b" || position === "bottom") {
    return {
      left: outerRect.left,
      top: outerRect.top,
      width: outerRect.width,
      height: outerRect.height - innerRect.height,
    };
  } else if (position === "r" || position === "right" || position === "tr") {
    return {
      left: outerRect.left,
      top: outerRect.top,
      width: outerRect.width - innerRect.width,
      height: outerRect.height,
    };
  } else {
    return outerRect;
  }
};

/*
 This is NOT a generalized subtract as removing a rect from another rect
 creates multiple rects.

 This will return a rect that is the first rect trimed by the second
*/
export const expandRect = function (outerRect, amount, position) {
  if (position === "t" || position === "top") {
    return {
      left: outerRect.left,
      top: outerRect.top + (outerRect.height - amount),
      width: outerRect.width,
      height: amount,
    };
  } else if (position === "l" || position === "left") {
    return {
      left: outerRect.left + (outerRect.width - amount),
      top: outerRect.top,
      width: amount,
      height: outerRect.height,
    };
  } else if (position === "b" || position === "bottom") {
    return {
      left: outerRect.left,
      top: outerRect.top,
      width: outerRect.width,
      height: amount,
    };
  } else if (position === "r" || position === "right" || position === "tr") {
    return {
      left: outerRect.left,
      top: outerRect.top,
      width: amount,
      height: outerRect.height,
    };
  } else {
    return outerRect;
  }
};

export const maxMargin = function (margin1, margin2) {
  return {
    top: Math.max(margin1.top, margin2.top),
    right: Math.max(margin1.right, margin2.right),
    bottom: Math.max(margin1.bottom, margin2.bottom),
    left: Math.max(margin1.left, margin2.left),
  };
};

export const applyMarginRect = function (bounds, margin) {
  return {
    left: bounds.left + margin.left,
    top: bounds.top + margin.top,
    width: bounds.width - (margin.left + margin.right),
    height: bounds.height - (margin.top + margin.bottom),
  };
};

export const applyAlignment = function (
  widthBounds,
  offsetBounds,
  widthElement,
  alignment
) {
  if (alignment == "left") {
    return offsetBounds;
  } else if (alignment == "right") {
    return widthBounds - widthElement + offsetBounds;
  } else {
    // center
    return (widthBounds - widthElement) / 2 + offsetBounds;
  }
};

export const oppositeAlignment = function (alignment) {
  if (alignment === "left") return "right";
  else if (alignment === "right") return "left";
  return alignment;
};

export const adjustPositionMargin = function (position, margin, bounds) {
  if (position === "t") {
    return {
      left: margin.left,
      top: Math.max(0, margin.top - bounds.height),
      right: margin.right,
      bottom: margin.bottom,
    };
  } else if (position === "l") {
    margin.left = 0;
  } else if (position === "b") {
    margin.bottom = 0;
  } else if (position === "r" || position === "tr") {
    margin.right = 0;
  }

  return margin;
};

export const zeroPositionMargin = function (position, margin) {
  if (position === "t") {
    margin.top = 0;
  } else if (position === "l") {
    margin.left = 0;
  } else if (position === "b") {
    margin.bottom = 0;
  } else if (position === "r" || position === "tr") {
    margin.right = 0;
  }

  return margin;
};

export const calcAxisCrossValue = function (crosses, min, max, autoZero) {
  let value;
  if (crosses === "max") {
    value = max;
  } else if (crosses === "min") {
    value = min;
  } else if (crosses === "autoZero") {
    value = autoZero;
  } else {
    value = asNumber(crosses);
  }

  return value || 0;
};

export const calcPercent = function (value, min, max) {
  return Math.max(0, Math.min(1, ((value || 0) - min) / (max - min || 1)));
};

export const unionRect = function (r1, r2) {
  let left = Math.min(r1.left, r2.left);
  let top = Math.min(r1.top, r2.top);
  let width = Math.max(r1.width + r1.left, r2.width + r2.left) - left;
  let height = Math.max(r1.height + r1.top, r2.height + r2.top) - top;

  return {
    left: left,
    top: top,
    width: width,
    height: height,
  };
};

