import ArrayModel from "./ArrayModel";
import ModelProperty from "./ModelProperty";

import CommonUtils from "../utils/CommonUtils";
import UpdateContext from "./UpdateContext";

/*
 * This is a special template property for ArrayModels.

   This property must be defined against an ArrayModel and there must be exactly one.

   This has a dependency against all of the properties that are registered in the default in addition to length.
   It also checks to determine if there an index specified and if so it also creates a virtual index.

   This property listens to all of the inputs registered (except '$index' and always length).
   It add / remove SpecificArrayElementProperties depending on the length size.

 */

const INDEX_KEY = "$index";
class IndexProperty extends ModelProperty {
  constructor(instance, propertyName, options, offset) {
    super(instance, propertyName, options);
    this._offset = offset;
    this._lookupKey = INDEX_KEY;
  }

  get value() {
    return this._offset;
  }

  set value(explicitValue) {
    throw Error("IndexProperty is a virtual readonly property.");
  }
}

class ArrayElementProperty extends ModelProperty {
  constructor(instance, propertyPath, options, optionsSpecific, propLength) {
    super(instance, propertyPath, options);
    if (!(instance instanceof ArrayModel))
      throw Error(
        "ArrayElementProperty can only be associated for an Arraymodel."
      );
    this._optionsSpecific = optionsSpecific;
    this._propLength = propLength;
  }

  createIndexProperty(i) {
    return new IndexProperty(
      this.instance,
      "$index_" + i,
      {
        isReadOnly: true,
        defaultValue: function () {
          return i;
        },
      },
      i
    );
  }
  /*
   * This property is managed as a template. The inputs are tracked and if any are updated then all values will
   * need to be recalced. $index and length dependencies are treated as unique values for each element
   *
   * The recalcProperty actually recalcs two properties:
   * 1. the $index_xxx property (which can be used as an input)
   * 2. the $element_xxx property (which is the calculated value)
   */
  recalcProperty() {
    // If there is no calculated value
    let needsRecalc = !this.evaled;
    let previousEval = this.evaled || {
      inputs: [],
      resolved: 0,
    };
    if (!previousEval) {
      needsRecalc = true;
    }

    if (!needsRecalc) needsRecalc = !!this._options.isDelegate;

    let prevLastIndex = previousEval.resolved;
    let currentLength = this.lookupPropertyDef(this.instance, "length").value;
    let calcStart = prevLastIndex;
    let calcEnd = currentLength;

    if (!needsRecalc && prevLastIndex !== currentLength) needsRecalc = true;

    // Array elements inputs only have a dependency on the $element and the $element has a dependency on the inputs.
    // This ensures that all of the inputs are calculated only once and before the array is resolved.

    this.removeDependencies(this.deps);
    this.deps = [];

    let indexLocations = [];
    let inputProps = [];
    this.unresolvedProps = false;
    for (let i = 0; i < this.inputs.length; i++) {
      let inputProp = undefined;
      if (this.inputs[i] instanceof ModelProperty) {
        inputProp = this.inputs[i]; //this.createScopedProperty(this, this.inputs[i]);
      } else if (this.instance._elementProperty.inputs[i][0] === INDEX_KEY) {
        indexLocations.push(i); // mimic a set
        inputProp = undefined;
      } else {
        inputProp = this.instance.lookupPropertyDef(this.inputs[i]);
        this.unresolvedProps =
          this.unresolvedProps || !inputProp || inputProp.isDestroyed;
      }

      inputProps.push(inputProp);
    }

    this.registerDep(this.instance, "length", this.deps);

    let unresolvedValueProps = [];
    let inputValues = new Array(inputProps.length);
    for (let i = 0; i < inputProps.length; i++) {
      if (inputProps[i] !== undefined) {
        if (inputProps[i].evaled && inputProps[i].evaled.calcing) {
          inputProps[i].recalcProperty();
        }
        inputValues[i] = inputProps[i].value;
      }
      if (
        !needsRecalc &&
        previousEval.inputs[i] !== inputValues[i] &&
        !CommonUtils.arrayEquals(previousEval.inputs[i], inputValues[i])
      ) {
        needsRecalc = true;
        // calcStart = 0;
        // calcEnd = 0;
      }

      if (inputProps[i]) {
        let valueTest = inputProps[i].value;
        if (valueTest === undefined) unresolvedValueProps.push(inputProps[i]);
      }
    }

    // After we resolve dependencies look for cyclical values
    for (let i = 0; i < unresolvedValueProps.length; i++) {
      // If an input is unresolved it may be due to a cyclical dependency so we walk
      // to force a check
      unresolvedValueProps[i].walkDependants([], {}, [], this);
    }

    if (!needsRecalc) {
      this.evaled.calcing = false;
      return;
    }

    // Since this is a virtual property is can't be access directly
    let newIndexEval = {
      inputs: inputValues,
      resolved: currentLength,
      calcing: false,
    };

    // Remove extra items
    for (let i = prevLastIndex - 1; i >= calcEnd; i--) {
      let keyElement = "" + i;

      if (UpdateContext.hasUpdateContext()) {
        UpdateContext.getUpdateContext().push({
          property: this.instance._properties[keyElement],
          previousValue: this.instance._properties[keyElement].evaled.resolved,
          newValue: undefined,
        });
      }

      let propertyDef = this.instance._properties[keyElement];
      if (propertyDef) {
        this.instance._wildCardProperty.unregisterDep(
          this.instance,
          keyElement
        );
        propertyDef.destroy();
      }
    }

    // Add extra items
    for (let i = calcStart; i < currentLength; i++) {
      let keyElement = "" + i;
      // Add the dynamic elementProperty
      if (!this.instance._properties[keyElement]) {
        let inputPropsEffective = inputProps;

        if (indexLocations.length > 0) {
          // If there is an index than each element will point to a different
          // indexProp so we need to clone the inputs
          inputPropsEffective = [...inputProps];
          let propIndex = this.createIndexProperty(i);
          // replace inputProps
          for (let i = 0; i < indexLocations.length; i++) {
            inputPropsEffective[indexLocations[i]] = propIndex;
          }
        }

        let prop = new ModelProperty(this.instance, keyElement, {
          isReadOnly: this._optionsSpecific.isReadOnly,
          isWritable: this._optionsSpecific.isWritable,
          defaultValue: this._options.defaultValue,
          persister: this._optionsSpecific.persister,
          inputs: inputPropsEffective,
        });
        this.instance._properties[keyElement] = prop;

        prop.registerDep(this.instance, this.propertyName, this.deps);
        this.instance._wildCardProperty.registerDep(this.instance, keyElement);

        this.instance._properties[keyElement].recalcProperty();
      }
    }

    this.instance._elementProperty.evaled = newIndexEval;
  }
}

export default ArrayElementProperty;
