import PropertyPersister from "../../dagm/PropertyPersister";

export const PRESET_DASH_DEFINITIONS = [
  /** Solid (continuous) pen - native 1 */
  ["solid", "Solid", null, 1, 1],
  /** square dot style - native 6 */
  ["dot", "Dot", [1,1], 6, 2],
  /** dash style - native 7 */
  ["dash", "Dash", [3,4], 7, 3],
  /** dash short dash - native 9*/
  ["lgDash", "Long Dash", [4,3,1,3], 9, 5],
  /** long dash style - native 8 */
  ["dashDot", "Dash Dot", [8,3], 8, 4],
  /** long dash short dash - native 10 */
  ["lgDashDot", "Long Dash Dot", [8,3,1,3], 10, 6],
  /** long dash short dash short dash - native 11 */
  ["lgDashDotDot", "Long Dash Dot Dot", [8,3,1,3,1,3], 11, 7],
  /** PS_DASH system dash style - native 2 */
  ["sysDash", "Square Dot", [2,2], 2, 8],
  /** PS_DOT system dash style - native 3 */
  ["sysDot", "Round Dot", [1,1], 3, 9],
  /** PS_DASHDOT system dash style - native 4 */
  ["sysDashDot", "System Dash Dot", [2,2,1,1], 4, 10],
  /** PS_DASHDOTDOT system dash style / native 5 */
  ["sysDashDotDot", "System Dash Dot Dot", [2,2,1,1,1,1], 5, 11]
]
type DashResolved = {
  key: string,
  description: string,
  dashArray: readonly number[],
  nativeId: number,
  ooxmlId: number,
  preset: boolean
}

const _lookupKey = new Map<string, DashResolved>();
const _lookupOoxmlId = new Map<number, DashResolved>();
const _lookupNativeId = new Map<number, DashResolved>();

/** read only dash */
export class Dash {
  _private: any;
  constructor(dash: string) {
    this._private = {
        json : dash
    }

    if (dash === undefined || dash === null || dash === 'none')
      dash = 'solid';
    if (typeof dash === 'string') {
      let dashResolved:DashResolved;
      dashResolved = _lookupKey.get(dash);
      if (!dashResolved) { // look for custom
        // a custom dash is an even # of positive numbers
        let dashArrayParts = dash.split(',');
        if (dashArrayParts.length === 1)
          dashArrayParts = dash.split(' ');
        let dashArray:number[] = [];
        let isValid = dashArrayParts.length % 2 === 0;
        for (let i=0; isValid && i<dashArrayParts.length; i++) {
          let num:number = Number(dashArrayParts[i]);
          isValid = (num !== Infinity && String(dashArrayParts[i]) === dashArrayParts[i] && num >= 0);
          if (isValid)
            dashArray.push(num);
        }
        if (isValid) {
          let formatedDash = '';
          for (let i=0;i<dashArray.length;i++) {
            formatedDash += dashArray[i];
            if (i < dashArray.length - 1) formatedDash += ",";
          }
          dashResolved = {
              key: formatedDash,
              description: "Custom (" + formatedDash + ")",
              dashArray: Object.freeze(dashArray),
              nativeId: -1,
              ooxmlId: -1,
              preset: false
          }
        }
      }
      if (!dashResolved)
        throw new Error('unable to resolve dash format: ' + dash);
      this._private.resolved = dashResolved;
    }
  }
  toJSON() {
    return this.toString();
  }
  get key() {
    return this._private.resolved.key;
  }
  get isPreset() {
    return this._private.resolved.preset;
  }
  get description() {
    return this._private.resolved.description;
  }
  get dashArray() {
    return this._private.resolved.dashArray;
  }

  /*
    returns null if this is a solid or lineWidth <=> 0
  */
  toSVGPath(lineWidth:number):string {
    let dashDef:number[] = this._private.resolved.dashArray;
    if (lineWidth <= 0 || !dashDef || dashDef.length === 0) return null;

    let lineDashScaled = "";
    for (let i = 0; i < dashDef.length; i++) {
      lineDashScaled += dashDef[i] * lineWidth;
      if (i < dashDef.length - 1) lineDashScaled += ",";
    }
    return lineDashScaled;
  }

  toString() {
    return this._private.resolved.key;
  }
}

let _lookupKeysBuild:string[] = [];
const _lookupPreset = new Map<string, Dash>();
for (let i=0; i<PRESET_DASH_DEFINITIONS.length; i++) {
  let definition = PRESET_DASH_DEFINITIONS[i];

  let presetDash:DashResolved = {
      key: definition[0] as string,
      description: definition[1] as string,
      dashArray: definition[2] as number[],
      nativeId: definition[3] as number,
      ooxmlId: definition[4] as number,
      preset: true
  }

  _lookupKey.set(presetDash.key, presetDash);
  _lookupOoxmlId.set(presetDash.ooxmlId, presetDash);
  _lookupNativeId.set(presetDash.nativeId, presetDash);
  _lookupPreset.set(presetDash.key, new Dash(presetDash.key));
  _lookupKeysBuild.push(presetDash.key);
}

const _lookupKeys:readonly string[] = Object.freeze(_lookupKeysBuild);

export const getPresetKeys = function(): readonly string[] {
  return _lookupKeys;
}

export const getPresetDashFromKey = function(key: string): Dash | undefined {
  return _lookupPreset.get(key);
}

export const getPresetDashFromOoxmlId = function(ooxmlId: number): Dash | undefined {
  let presetDef = _lookupOoxmlId.get(ooxmlId);
  if (!presetDef)
    return;
  return _lookupPreset.get(presetDef.key);
}

export const getPresetDashFromNativeId = function(nativeId: number): Dash | undefined {
  let presetDef = _lookupNativeId.get(nativeId);
  if (!presetDef)
    return;
  return _lookupPreset.get(presetDef.key);
}


const dashSetter = function (value: string | Dash) {
  if (value === undefined) return value;
  if (value instanceof Dash) return value;
  return new Dash(value);
};

class DashPersister extends PropertyPersister {
  constructor(path: any) {
    super(path);
  }

  toJSON(value: any) {
    if (value && value.toJSON)
        return value.toJSON();
    return value.toString();
  }

  fromJSON(jsonValue: any) {
    return new Dash(jsonValue);
  }
}

export { dashSetter, DashPersister };
