
export type SchemeColor = {
    ooxmlId: string,
    description: string,
}

const SCHEME_COLOR_DEFINITIONS = new Map<string, SchemeColor>();

SCHEME_COLOR_DEFINITIONS.set('dk1', {
    ooxmlId: 'dk1',
    description: 'Text/Background - Dark 1',
});
SCHEME_COLOR_DEFINITIONS.set('lt1', {
    ooxmlId: 'lt1',
    description: 'Text/Background - Light 1'
});
SCHEME_COLOR_DEFINITIONS.set('dk2', {
    ooxmlId: 'dk2',
    description: 'Text/Background - Dark 2'
});
SCHEME_COLOR_DEFINITIONS.set('lt2', {
    ooxmlId: 'lt2',
    description: 'Text/Background - Light 2'
});

SCHEME_COLOR_DEFINITIONS.set('accent1', {
    ooxmlId: 'accent1',
    description: 'Accent 1'
});
SCHEME_COLOR_DEFINITIONS.set('accent2', {
    ooxmlId: 'accent2',
    description: 'Accent 2'
});
SCHEME_COLOR_DEFINITIONS.set('accent3', {
    ooxmlId: 'accent3',
    description: 'Accent 3'
});
SCHEME_COLOR_DEFINITIONS.set('accent4', {
    ooxmlId: 'accent4',
    description: 'Accent 4'
});
SCHEME_COLOR_DEFINITIONS.set('accent5', {
    ooxmlId: 'accent5',
    description: 'Accent 5'
});
SCHEME_COLOR_DEFINITIONS.set('accent6', {
    ooxmlId: 'accent6',
    description: 'Accent 6'
});


SCHEME_COLOR_DEFINITIONS.set('hlink', {
    ooxmlId: 'hlink',
    description: 'Hyperlink'
});
SCHEME_COLOR_DEFINITIONS.set('folHlink', {
    ooxmlId: 'folHlink',
    description: 'Followed Hyperlnnk'
});

// These are not part of a theme definition but are part of a color map
// and are added here because the color definitions are used to validate
// color keys
SCHEME_COLOR_DEFINITIONS.set('tx1', {
    ooxmlId: 'tx1',
    description: 'First Text Color'
});
SCHEME_COLOR_DEFINITIONS.set('tx2', {
    ooxmlId: 'lt1',
    description: 'Second Text Color'
});
SCHEME_COLOR_DEFINITIONS.set('bg1', {
    ooxmlId: 'dk2',
    description: 'First Background Color'
});
SCHEME_COLOR_DEFINITIONS.set('bg2', {
    ooxmlId: 'lt2',
    description: 'Second Background Color'
});
const findSchemaDefinition = function(ooxmlId: string): SchemeColor {
    return SCHEME_COLOR_DEFINITIONS.get(ooxmlId);
}

export default findSchemaDefinition;
