import { isDateFormatter } from "../../utils/DateUtils";

import { RangeDirection, IRange } from "../../models/range/Range";
import { createRangeSelection, IMultiRange } from "../../models/range/MultiRange";

import CellRange from "../../models/range/CellRange";

import {
  calcDirection,
  opsDirection
} from "../../utils/ChartUtils";

const alignsHorizontally = function(rangea: IRange, rangeb: IRange) : boolean {
  return (
    rangea.top === rangeb.top &&
    rangea.bottom === rangeb.bottom
  );
};

const alignsVertically = function(rangea: IRange, rangeb: IRange) : boolean {
  return (
    rangea.left === rangeb.left &&
    rangea.right === rangeb.right
  );
};

const colAlignment = function(rangea: IRange, rangeb: IRange) : string | false {
  let isHorz = alignsHorizontally(rangea, rangeb);
  let isVert = alignsVertically(rangea, rangeb);
  if (isHorz && !isVert)
    return 'h';
  else if (!isHorz && isVert)
    return 'v'
  else
    return false;
}

const unroll2DArray = function(arr: any[] | null) {
  if (!arr)
    return arr;
  let unrolled = [];
  for (let i=0;i<arr.length; i++)
    for (let j=0;j<arr[i].length; j++)
      if (arr[i][j] != null)
        unrolled.push(arr[i][j]);
  return unrolled;
}

/**
 * This algo will look for aligned ranges and attempt to merge them
 * into the fews ranges possible.
 *
 * If the ranges are not aligned it will return false.
 * A single range will return it's
 *
 * @param 2d array of ranges

 */

const joinRangesOneDirection = function(ranges: IRange[], alignment:(string | false)=false) {
  if (!ranges || ranges.length <= 1)
    return {
      rangesJoined: ranges,
      alignment
    };

  let rangesJoined = [];
  rangesJoined.push(ranges[0]);
  for (let i=1; i<ranges.length; i++) {
    let rangeCurrent = rangesJoined[rangesJoined.length-1];
    if (rangeCurrent === null || ranges[i] === null)
      continue;
    let alignmentCurrent = colAlignment(rangeCurrent, ranges[i]);
    if (alignment && alignment !== alignmentCurrent)
      return false;
    alignment = alignmentCurrent;
    if (alignment === 'h') {
      if (ranges[i].left === rangeCurrent.right + 1) {
        // TODO - add extend to IRange
        rangesJoined[rangesJoined.length-1] = new CellRange(
          rangeCurrent.left,
          rangeCurrent.top,
          ranges[i].right,
          rangeCurrent.bottom,
          rangeCurrent.leftFixed,
          rangeCurrent.topFixed,
          rangeCurrent.rightFixed,
          rangeCurrent.bottomFixed,
          rangeCurrent.sheetName);
      } else {
        rangesJoined.push(ranges[i]);
      }
    } else if (alignmentCurrent === 'v') {
      if (ranges[i].top === rangeCurrent.bottom + 1) {
        // TODO - add extend to IRange
        rangesJoined[rangesJoined.length-1] = new CellRange(
          rangeCurrent.left,
          rangeCurrent.top,
          rangeCurrent.right,
          ranges[i].bottom,
          rangeCurrent.leftFixed,
          rangeCurrent.topFixed,
          rangeCurrent.rightFixed,
          rangeCurrent.bottomFixed,
          rangeCurrent.sheetName);
      } else {
        rangesJoined.push(ranges[i]);
      }
    }
  }

  return {
    rangesJoined,
    alignment
  }
}


/*
 * This will walk a list of ranges and attempt to union then.
 * This will only work if all of the ranges are aligned.
 * Note -
 * This algo makes an assumption that the ranges are a two dimensional array.
 * This is because the use case is that these are a set of ranges collected by series.
 * This limits the usefulness of this function but makes it easier to implement
 * and solves our specific usecase.
 */
export const joinRanges = function(ranges: IRange[][] | null): IRange[] | false {

  // First go through all ranges and try to join them
  // Then will join them again in the opposite direction

  // Our new 2d array of ranges.
  let rangesJoinedOneWay = [];
  let alignment:(string | false) = false;
  let rangeLength = 0;
  for (let i=0; i<ranges.length; i++) {
    let rangeInner = ranges[i];
    let currentInnerJoined = joinRangesOneDirection(rangeInner, alignment);
    if (!currentInnerJoined ||
        (alignment && currentInnerJoined.alignment !== alignment) ||
        (rangeLength && currentInnerJoined.rangesJoined.length !== rangeLength)) {
      return false;
    }
    alignment = currentInnerJoined.alignment;
    rangeLength = currentInnerJoined.rangesJoined.length;
    rangesJoinedOneWay.push(currentInnerJoined.rangesJoined);
  }

  let rangesJoined = [];
  if (alignment)
    alignment = (alignment === 'v' ? 'h' : 'v');
  rangeLength = 0;
  // We walk in the opposite direction we know that the 2d array is 'balanced'

  let lengthOpp = rangesJoinedOneWay.length > 0 ? rangesJoinedOneWay[0].length : 0;
  for (let i=0; i<lengthOpp; i++) {
    // We build an array in opposite direction to join
    let rangesOpp = [];
    for (let j=0; j<rangesJoinedOneWay.length; j++) {
      rangesOpp.push(rangesJoinedOneWay[j][i]);
    }
    let currentOutterJoined = joinRangesOneDirection(rangesOpp, alignment);
    if (!currentOutterJoined ||
        (alignment && currentOutterJoined.alignment !== alignment) ||
        (rangeLength && currentOutterJoined.rangesJoined.length !== rangeLength)) {
      return false;
    }
    alignment = currentOutterJoined.alignment;
    rangeLength = currentOutterJoined.rangesJoined.length;
    // Now unpack/transpose array back into outer dimension
    for (let j=0; j<currentOutterJoined.rangesJoined.length; j++) {
      if (!rangesJoined[j]) rangesJoined[j] = [];
      rangesJoined[j].push(currentOutterJoined.rangesJoined[j]);
    }
  }

  rangesJoined = unroll2DArray(rangesJoined);
  return rangesJoined;
}

type RangeFinder = (offset: number) => IRange | IMultiRange | IRange[] | IMultiRange[] | null;


export const collectRangeArrays = function(ranges: any[], rangeInput: IRange | IMultiRange | IRange[] | IMultiRange[]): void {
    let rangeArray: any[] | null;
    if (rangeInput && (rangeInput as IMultiRange).asRanges) {
      rangeArray = [];
      let asRanges = (rangeInput as IMultiRange).asRanges();
      for (let j=0; j<asRanges.length; j++)
        if ((asRanges[j] as CellRange).isCellRange)
            rangeArray.push(asRanges[j]);
    } else if (rangeInput && (rangeInput as CellRange).isCellRange) {
      rangeArray = [rangeInput];
    } else if (Array.isArray(rangeInput)) {
      for (let j=0; j<rangeInput.length; j++)
        collectRangeArrays(ranges, rangeInput[j]);
    }
    if (rangeArray)
        ranges.push(rangeArray);
}

export const collectRanges = function(length: number, rangeFunc: RangeFinder): IRange[] {
  let ranges = [];
  for (let i=0; i<length; i++) {
    let rangeInput = rangeFunc(i);
    collectRangeArrays(ranges, rangeInput);
  }

  let joined:IRange[] | false = joinRanges(ranges);
  if (joined)
    return (joined as IRange[]);
  return [];
}

export function deriveSeriesDirection(seriesLength: number, firstValRange: IRange, firstTitleRange: IRange, firstXRange: IRange) : RangeDirection {
  let retValue = RangeDirection.column;
  if (seriesLength === 0)
    return retValue;

  let isOneValue = firstValRange.height === 1 && firstValRange.width === 1;
 // We are row if the width is greater than one
  // or there is only one value
  // or we are on the same row as the title
  // or we are on the same col as the x
  if ((firstValRange.width > 1) ||
     (isOneValue && firstTitleRange === null && firstXRange === null) ||
     (isOneValue && firstTitleRange !== null &&
       firstTitleRange.top === firstValRange.top && firstTitleRange.bottom === firstValRange.bottom) ||
     (isOneValue && firstXRange !== null &&
       firstXRange.left === firstValRange.left && firstXRange.right === firstValRange.right)) {
         retValue = RangeDirection.row;
  }
  return retValue;
}

/**
 * This is a condensed version of the IChartSelection. That show
 * the various ranges and a minimul set of range selections.
 * This is useful for displaying the entire charts selections.
 * Note -
 * This only works for simple ranges or aligned ranges.
 * (This is consistent with ms office). If it is a non-aligned
 * set of ranges it returns an empty set.
 */
export interface IChartSelectionSummary {
  xRanges: IRange[];
  valRanges:  IRange[];
  sizeRanges:  IRange[];
  titleRanges:  IRange[];

  seriesDirection: RangeDirection;
  seriesLength: number;
}

/*
 * Returns an IChartSelectionSummary that describes a simplied version of all the Series Ranges
*/
export const generateChartSelectionSummary = function(chartSelection: IChartSelection) : IChartSelectionSummary {
  let retValue = {
    xRanges: collectRanges(1, chartSelection.getXRange.bind(chartSelection)),
    valRanges: collectRanges(chartSelection.seriesLength, chartSelection.getValRangeAt.bind(chartSelection)),
    sizeRanges: [] as IRange[],
    titleRanges: collectRanges(chartSelection.seriesLength, chartSelection.getTitleRangeAt.bind(chartSelection)),
    seriesDirection: RangeDirection.column,
    seriesLength: chartSelection.seriesLength
  }

  if (retValue.seriesLength > 0) {
    let firstValRange = chartSelection.getValRangeAt(0);
    let firstTitleRange = chartSelection.getTitleRangeAt(0);
    let firstXRange = chartSelection.getXRange();
    retValue.seriesDirection = deriveSeriesDirection(retValue.seriesLength, firstValRange, firstTitleRange, firstXRange);
  }

  return retValue;
}

export interface IChartSelection {
  direction: RangeDirection;
  /**
   * Returns a stringified version of of a seriesFormula.
   */
  toString: () => string;

  readonly seriesDirection: RangeDirection;
  readonly axisDirection: RangeDirection;
  readonly seriesLength: number;

  // XRanges are shared so this doesn't have an offset
  getXRange: () => IRange | null;
  getValRangeAt: (offset: number) => IRange | null;
  getSizeRangeAt: (offset: number) => IRange | null;
  getTitleRangeAt: (offset: number) => IRange | null;
}

function isCellEmpty(cell) {
  if (!cell)
    return true;
  if (cell.t === 'z' || cell.v === undefined || cell.v === null)
    return true;
  return false;
}

function isCellDateOrString(cell) {
  if (!cell)
    return false;

  if (cell.t === 's')
    return true;

  if (cell.t === 'n' && cell.z && isDateFormatter(cell?.z))
    return true;

  return false;
}
/*
  https://bettersolutions.com/excel/charts/data-source-series-formula.htm
  https://peltiertech.com/Excel/ChartsHowTo/ChartSeriesFormula.html
 */
export default class ChartSelection implements IChartSelection {
  _private: {
    inputRanges?: IRange[]
    sheet?: any // Do we need to hold a referenced to this?
    transposed: boolean,
    mainType: string,

    seriesDirection: RangeDirection,
    axisDirection: RangeDirection,
    axisRange: IRange,
    seriesXByOffset: IRange[],
    seriesValByOffset: IRange[],
    seriesTitleByOffset: IRange[],
  };

  constructor(input: IRange[] | IRange | null, sheet: any, transposed: boolean = false, mainType: string = null) {
    this._private = {
      transposed: transposed,
      mainType: mainType,
      seriesDirection: RangeDirection.column,
      axisDirection: opsDirection(RangeDirection.column) as RangeDirection,
      axisRange: null,
      seriesXByOffset: [],
      seriesValByOffset: [],
      seriesTitleByOffset: [],
    };

    if (input)
      input = createRangeSelection(input, false/*singleLength*/, true/*alignRanges*/, true/*forceFixed*/);

    let primaryRange: IRange;
    if (!input) {
      primaryRange = null;
      this._private.inputRanges = [];
    } else if (Array.isArray(input)) {
      this._private.inputRanges = input as IRange[];
      primaryRange = this._private.inputRanges[0];
    } else {
      primaryRange = input as CellRange;
      this._private.inputRanges = [primaryRange];
    }

    if (!primaryRange)
        return;

    // data Anchors are where the numbers for val series starts. (We use a variety of techniques
    // to determien the top left of the dataSet (excluding whitespaces and axis/series labels))
    let dataAnchorX = -1;
    let dataAnchorY = -1;

    // scan for empty values
    primaryRange.scan((sheetName: string, x: number, y: number, xAbs: number, yAbs: number) => {
      if (sheet !== null && isCellEmpty(sheet.getPropertyAt(sheetName, xAbs, yAbs).value)
      ) {
        dataAnchorX = xAbs;
        dataAnchorY = yAbs;
      } else
        return false /*stop visit*/;
    }, {
      left: primaryRange.left,
      top: primaryRange.top,
      bottom: primaryRange.top,
      right: primaryRange.right
    });

    let emptyFirstRow = dataAnchorX === primaryRange.right && primaryRange.height > 1;
    if (emptyFirstRow) {
      dataAnchorX = primaryRange.left;
    }

    // If the first row is empty than OOXML only checks the first column for space
    // now scan each row until end
    if (dataAnchorX >= primaryRange.left) {
      primaryRange.scan((sheetName: string, x: number, y: number, xAbs: number, yAbs: number) => {
        if (sheet !== null && !isCellEmpty(sheet.getPropertyAt(sheetName, xAbs, yAbs).value)) {
          return false /*stop scan*/
        } else if (xAbs === dataAnchorX)
          dataAnchorY = yAbs;
      }, {
        left: primaryRange.left,
        top: primaryRange.top + 1,
        bottom: primaryRange.bottom,
        right: dataAnchorX
      });
    }

    // If there is an emptyFirstRow && emptyFirstColum then just a length of 1 for each
    let emptyFirstCol = dataAnchorY === primaryRange.bottom && primaryRange.width > 1;
    if (emptyFirstRow && emptyFirstCol)
      dataAnchorY = primaryRange.top;

    // If there are no empty values at top left then start at bottom right and scan for anything that is not empty and a string
    if (dataAnchorY === -1) {
      let lastRow = primaryRange.bottom;
      dataAnchorX = primaryRange.right;
      dataAnchorY = primaryRange.bottom;
      primaryRange.scan((sheetName: string, x: number, y: number, xAbs: number, yAbs: number) => {
        if (sheet !== null && !isCellEmpty(sheet.getPropertyAt(sheetName, xAbs, yAbs).value)) {
          return false /*stop scan*/
        } else if (xAbs === dataAnchorX) {
          dataAnchorY = yAbs;
        }
      }, {
        left: primaryRange.left,
        top: lastRow,
        bottom: lastRow,
        right: dataAnchorX
      });

      // We want to scan in reverse
      let scannedCols = [];
      primaryRange.scan((sheetName: string, x: number, y: number, xAbs: number, yAbs: number) => {
        scannedCols.push(xAbs);
      }, {
        left: primaryRange.left,
        top: lastRow,
        bottom: lastRow,
        right: primaryRange.right
      });

      let scan = true;
      for (let colOffset = scannedCols.length - 1; scan && colOffset >= 0; colOffset--) {
        // Note we don't actually scan the bottom/right corner cell
        let col = scannedCols[colOffset];
        let value = sheet ? sheet.getPropertyAt(primaryRange.sheetName, col, lastRow).value : null;
        if (isCellDateOrString(value)) scan = false;
        else dataAnchorX = col - 1;
      }

      let scannedRows = [];
      primaryRange.scan((sheetName: string, x: number, y: number, xAbs: number, yAbs: number) => {
        scannedRows.push(yAbs);
      }, {
        left: primaryRange.right,
        top: primaryRange.top,
        bottom: primaryRange.bottom,
        right: primaryRange.right,
      });

      scan = true;
      for (let rowOffset = scannedRows.length - 1; scan && rowOffset >= 0; rowOffset--) {
        // Note we don't actually scan the bottom/right corner cell
        let row = scannedRows[rowOffset];
        let value = sheet ? sheet.getPropertyAt(primaryRange.sheetName, primaryRange.right, row).value : null;
        if (isCellDateOrString(value)) scan = false;
        else dataAnchorY = row - 1;
      }
    }

    // If the anchor is the bottom right then there is no datavalue we require at least 1.
    if (dataAnchorX === primaryRange.right && dataAnchorY === primaryRange.bottom) {
      dataAnchorX = primaryRange.right - 1;
      dataAnchorY = primaryRange.bottom - 1;
    }

    let rowHeaderRange = null;
    if (dataAnchorX >= primaryRange.left) {
      rowHeaderRange = primaryRange.subRange(
        primaryRange.left,
        dataAnchorY + 1,
        Math.max(primaryRange.left, dataAnchorX),
        primaryRange.bottom
      );
    }
    let colHeaderRange = null;
    if (dataAnchorY >= primaryRange.top) {
      colHeaderRange = primaryRange.subRange(
        dataAnchorX + 1,
        primaryRange.top,
        primaryRange.right,
        Math.max(primaryRange.top, dataAnchorY)
      );
    }

    let valRange: IRange = primaryRange.subRange(
      dataAnchorX + 1,
      dataAnchorY + 1,
      primaryRange.right,
      primaryRange.bottom
    );
    let direction: RangeDirection = calcDirection(valRange, primaryRange) as RangeDirection;
    if (transposed) direction = opsDirection(direction) as RangeDirection;

    let seriesTitle = rowHeaderRange;
    let axisRange = colHeaderRange;
    if (direction === RangeDirection.column) {
      seriesTitle = colHeaderRange;
      axisRange = rowHeaderRange;
    }

    // TODO - move this logic to scatter series?
    if (mainType === "scatter" && !axisRange) {
      // Then if there is more than one series we will shift use the first data row
      let seriesCount =
        direction === RangeDirection.column ? valRange.width : valRange.height;
      if (seriesCount > 1) {
        if (direction === RangeDirection.column) {
          axisRange = primaryRange.subRange(
            valRange.left,
            valRange.top,
            valRange.left /*just firstl*/,
            valRange.bottom
          );
          valRange = primaryRange.subRange(
            valRange.left + 1,
            valRange.top,
            valRange.right,
            valRange.bottom
          );
        } else {
          axisRange = primaryRange.subRange(
            valRange.left,
            valRange.top,
            valRange.right,
            valRange.top /*just firstl*/
          );
          valRange = primaryRange.subRange(
            valRange.left,
            valRange.top + 1,
            valRange.right,
            valRange.bottom
          );
        }
      }
    }

    // If there is more than one range then the first range is treated as a template
    // Each additional range is assumed to have to axis select and a title selection
    // that matches the first
    let headerLength = (direction === RangeDirection.column ? primaryRange.height - valRange.height : primaryRange.width - valRange.width);
    this._private.seriesValByOffset = [];
    this._private.seriesTitleByOffset = [];

    for (let i = 0; i < this._private.inputRanges.length; i++) {
      let range = this._private.inputRanges[i];
      let seriesInRange = 0;
      let valRangeCurrent: IRange;
      let titleRangeCurrent: IRange;
      if (i === 0) {
        valRangeCurrent = valRange;
        titleRangeCurrent = seriesTitle;
        seriesInRange = (direction === RangeDirection.column ? valRange.width : valRange.height);
      } else if (direction === RangeDirection.column) {
        valRangeCurrent = range.subRange(
          range.left,
          range.top + headerLength,
          range.right,
          range.bottom
        );
        titleRangeCurrent = range.subRange(
          range.left,
          range.top,
          range.right,
          range.top + headerLength - 1
        );
        seriesInRange = valRangeCurrent ? valRangeCurrent.width : 0;
      } else {
        valRangeCurrent = range.subRange(
          range.left + headerLength,
          range.top,
          range.right,
          range.bottom
        );
        titleRangeCurrent = range.subRange(
          range.left,
          range.top,
          range.left + headerLength - 1,
          range.bottom
        );
        seriesInRange = valRangeCurrent ? valRangeCurrent.height : 0;
      }

      for (let j = 0; j < seriesInRange; j++) {
        this._private.seriesValByOffset.push(valRangeCurrent.slice(j, direction));
        if (titleRangeCurrent !== null)
        this._private.seriesTitleByOffset.push(titleRangeCurrent.slice(j, direction));
      }
    }

    this._private.axisRange = axisRange || null;
    this._private.seriesDirection = direction;
    this._private.axisDirection = opsDirection(direction) as RangeDirection;
  }

  getXRange() : IRange | null {
    return this._private.axisRange;
  }

  getValRangeAt(offset: number) : IRange | null {
    if (offset < 0 || this._private.seriesValByOffset.length <= offset) return null;
    return this._private.seriesValByOffset[offset];
  }

  getSizeRangeAt(offset: number) : IRange | null {
    return null;
  }

  getTitleRangeAt(offset: number) : IRange | null {
    if (offset < 0 || this._private.seriesTitleByOffset.length <= offset) return null;
    return this._private.seriesTitleByOffset[offset];
  }

  get seriesDirection() {
    return this._private.seriesDirection;
  }

  get axisDirection() {
    return this._private.axisDirection;
  }

  get seriesLength() {
    return this._private.seriesValByOffset.length;
  }

  get direction(): RangeDirection {
    return RangeDirection.column;
  }

  toString(): string {
    return 'nothing yet';
  }
}