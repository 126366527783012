import { createCellFromJavascriptValue } from "../..";
import AbstractModel from "../../dagm/AbstractModel";

import CommonUtils from "../../utils/CommonUtils";

/*
  Exposes a 2d array as a model. Useful for literals that want to use CellValues
  @CellValues
*/

class LiteralValues extends AbstractModel {
  constructor(literalRange, transpose) {
    super();

    this.addProperty("width", {
      isReadOnly: true,
      defaultValue: function () {
        return (transpose ? literalRange.height : literalRange.width);
      },
    });

    this.addProperty("height", {
      isReadOnly: true,
      defaultValue: function () {
        return (transpose ? literalRange.width : literalRange.height);
      },
    });

    /*
      Returns a two dimensional array that represents that values of the range
    */
    this.addProperty("asPrimitives", {
      isReadOnly: true,
      defaultValue: function () {
        let retValue = literalRange.asArray();
        if (transpose) {
          retValue = CommonUtils.transpose(retValue);
        }
        return retValue;
      }
    });


    /*
      LiteralValues derive their cell values from primitives which is the opposite of cellValues
    */
      this.addProperty("asCells", {
        isReadOnly: true,
        defaultValue: function (primitives) {
          let asCells = [];
          for (let i=0;i<primitives.length; i++) {
              let row = [];
              asCells.push(row);
              for (let j=0;j<primitives[i].length; j++) {
                  let primitive = primitives[i][j];
                  row.push(createCellFromJavascriptValue(primitive));
              }
          }
  
          return asCells;
        },
        inputs: ['asPrimitives']
      });
  
  }
}

export default LiteralValues;
