
/**
 * Preset colors defined in DrawingML aka known/system colors
 *
 * @see <a href="https://msdn.microsoft.com/library/system.drawing.knowncolor.aspx">KnownColor Enumeration</a>
 * @see <a href="https://msdn.microsoft.com/library/system.windows.media.colors.aspx">Colors Class</a>
 */

type rgba = {
        r: number,
        g: number,
        b: number,
        a?: number
}

type PresetColor = {
    nativeId: number,
    ooxmlId: string,
    rgbaColor: rgba
    system : boolean
}

const PRESET_COLOR_DEFINITIONS = [
    /** The system-defined color of the active window's border. */
    [0xffb4b4b4,   1, "activeBorder", "system"],
    /** The system-defined color of the background of the active window's title bar. */
    [0xff99b4d1,   2, "activeCaption", "system"],
    /** The system-defined color of the text in the active window's title bar. */
    [0xff000000,   3, "captionText", "system"],
    /** The application workspace is the area in a multiple-document view that is not being occupied by documents. */
    [0xffababab,   4, "appWorkspace", "system"],
    /** The system-defined face color of a 3-D element. */
    [0xfff0f0f0,   5, "btnFace", "system"],
    /** The system-defined shadow color of a 3-D element. The shadow color is applied to parts of a 3-D element that face away from the light source. */
    [0xff696969,   6, "btnShadow", "system"],
    /** The system-defined color that is the dark shadow color of a 3-D element. The dark shadow color is applied to the parts of a 3-D element that are the darkest color. */
    [0xff000000,   7, "3dDkShadow", "system"],
    /** The system-defined color that is the light color of a 3-D element. The light color is applied to parts of a 3-D element that face the light source. */
    [0xffe3e3e3,   8, "btnHighlight", "system"],
    /** The system-defined highlight color of a 3-D element. The highlight color is applied to the parts of a 3-D element that are the lightest color. */
    [0xffe3e3e3,   9, "3dLight", "system"],
    /** The system-defined color of text in a 3-D element. */
    [0xff000000,  10, "btnText", "system"],
    /** The system-defined color of the desktop. */
    [0xff000000,  11, "background", "system"],
    /** The system-defined color of dimmed text. Items in a list that are disabled are displayed in dimmed text. */
    [0xff6d6d6d,  12, "grayText", "system"],
    /** The system-defined color of the background of selected items. This includes selected menu items as well as selected text. */
    [0xff3399ff,  13, "highlight", "system"],
    /** The system-defined color of the text of selected items. */
    [0xffffffff,  14, "highlightText", "system"],
    /** The system-defined color used to designate a hot-tracked item. Single-clicking a hot-tracked item executes the item. */
    [0xff0066cc,  15, "hotLight", "system"],
    /** The system-defined color of an inactive window's border. */
    [0xfff4f7fc,  16, "inactiveBorder", "system"],
    /** The system-defined color of the background of an inactive window's title bar. */
    [0xffbfcddb,  17, "inactiveCaption", "system"],
    /** The system-defined color of the text in an inactive window's title bar. */
    [0xff000000,  18, "inactiveCaptionText", "system"],
    /** The system-defined color of the background of a ToolTip. */
    [0xffffffe1,  19, "infoBk", "system"],
    /** The system-defined color of the text of a ToolTip. */
    [0xff000000,  20, "infoText", "system"],
    /** The system-defined color of a menu's background. */
    [0xfff0f0f0,  21, "menu", "system"],
    /** The system-defined color of a menu's text. */
    [0xff000000,  22, "menuText", "system"],
    /** The system-defined color of the background of a scroll bar. */
    [0xffc8c8c8,  23, "scrollBar", "system"],
    /** The system-defined color of the background in the client area of a window. */
    [0xffffffff,  24, "window", "system"],
    /** The system-defined color of a window frame. */
    [0xff646464,  25, "windowFrame", "system"],
    /** The system-defined color of the text in the client area of a window. */
    [0xff000000,  26, "windowText", "system"],
    [0x00ffffff,  27, "transparent"],
    [0xfff0f8ff,  28, "aliceBlue"],
    [0xfffaebd7,  29, "antiqueWhite"],
    [0xff00ffff,  30, "aqua"],
    [0xff7fffd4,  31, "aquamarine"],
    [0xfff0ffff,  32, "azure"],
    [0xfff5f5dc,  33, "beige"],
    [0xffffe4c4,  34, "bisque"],
    [0xff000000,  35, "black"],
    [0xffffebcd,  36, "blanchedAlmond"],
    [0xff0000ff,  37, "blue"],
    [0xff8a2be2,  38, "blueViolet"],
    [0xffa52a2a,  39, "brown"],
    [0xffdeb887,  40, "burlyWood"],
    [0xff5f9ea0,  41, "cadetBlue"],
    [0xff7fff00,  42, "chartreuse"],
    [0xffd2691e,  43, "chocolate"],
    [0xffff7f50,  44, "coral"],
    [0xff6495ed,  45, "cornflowerBlue"],
    [0xfffff8dc,  46, "cornsilk"],
    [0xffdc143c,  47, "crimson"],
    [0xff00ffff,  48, "cyan"],
    [0xff00008b,  49, "dkBlue"],
    [0xff008b8b,  50, "dkCyan"],
    [0xffb8860b,  51, "dkGoldenrod"],
    [0xffa9a9a9,  52, "dkGray"],
    [0xff006400,  53, "dkGreen"],
    [0xffbdb76b,  54, "dkKhaki"],
    [0xff8b008b,  55, "dkMagenta"],
    [0xff556b2f,  56, "dkOliveGreen"],
    [0xffff8c00,  57, "dkOrange"],
    [0xff9932cc,  58, "dkOrchid"],
    [0xff8b0000,  59, "dkRed"],
    [0xffe9967a,  60, "dkSalmon"],
    [0xff8fbc8b,  61, "dkSeaGreen"],
    [0xff483d8b,  62, "dkSlateBlue"],
    [0xff2f4f4f,  63, "dkSlateGray"],
    [0xff00ced1,  64, "dkTurquoise"],
    [0xff9400d3,  65, "dkViolet"],
    [0xffff1493,  66, "deepPink"],
    [0xff00bfff,  67, "deepSkyBlue"],
    [0xff696969,  68, "dimGray"],
    [0xff1e90ff,  69, "dodgerBlue"],
    [0xffb22222,  70, "firebrick"],
    [0xfffffaf0,  71, "floralWhite"],
    [0xff228b22,  72, "forestGreen"],
    [0xffff00ff,  73, "fuchsia"],
    [0xffdcdcdc,  74, "gainsboro"],
    [0xfff8f8ff,  75, "ghostWhite"],
    [0xffffd700,  76, "gold"],
    [0xffdaa520,  77, "goldenrod"],
    [0xff808080,  78, "gray"],
    [0xff008000,  79, "green"],
    [0xffadff2f,  80, "greenYellow"],
    [0xfff0fff0,  81, "honeydew"],
    [0xffff69b4,  82, "hotPink"],
    [0xffcd5c5c,  83, "indianRed"],
    [0xff4b0082,  84, "indigo"],
    [0xfffffff0,  85, "ivory"],
    [0xfff0e68c,  86, "khaki"],
    [0xffe6e6fa,  87, "lavender"],
    [0xfffff0f5,  88, "lavenderBlush"],
    [0xff7cfc00,  89, "lawnGreen"],
    [0xfffffacd,  90, "lemonChiffon"],
    [0xffadd8e6,  91, "ltBlue"],
    [0xfff08080,  92, "ltCoral"],
    [0xffe0ffff,  93, "ltCyan"],
    [0xfffafa78,  94, "ltGoldenrodYellow"],
    [0xffd3d3d3,  95, "ltGray"],
    [0xff90ee90,  96, "ltGreen"],
    [0xffffb6c1,  97, "ltPink"],
    [0xffffa07a,  98, "ltSalmon"],
    [0xff20b2aa,  99, "ltSeaGreen"],
    [0xff87cefa, 100, "ltSkyBlue"],
    [0xff778899, 101, "ltSlateGray"],
    [0xffb0c4de, 102, "ltSteelBlue"],
    [0xffffffe0, 103, "ltYellow"],
    [0xff00ff00, 104, "lime"],
    [0xff32cd32, 105, "limeGreen"],
    [0xfffaf0e6, 106, "linen"],
    [0xffff00ff, 107, "magenta"],
    [0xff800000, 108, "maroon"],
    [0xff66cdaa, 109, "medAquamarine"],
    [0xff0000cd, 110, "medBlue"],
    [0xffba55d3, 111, "medOrchid"],
    [0xff9370db, 112, "medPurple"],
    [0xff3cb371, 113, "medSeaGreen"],
    [0xff7b68ee, 114, "medSlateBlue"],
    [0xff00fa9a, 115, "medSpringGreen"],
    [0xff48d1cc, 116, "medTurquoise"],
    [0xffc71585, 117, "medVioletRed"],
    [0xff191970, 118, "midnightBlue"],
    [0xfff5fffa, 119, "mintCream"],
    [0xffffe4e1, 120, "mistyRose"],
    [0xffffe4b5, 121, "moccasin"],
    [0xffffdead, 122, "navajoWhite"],
    [0xff000080, 123, "navy"],
    [0xfffdf5e6, 124, "oldLace"],
    [0xff808000, 125, "olive"],
    [0xff6b8e23, 126, "oliveDrab"],
    [0xffffa500, 127, "orange"],
    [0xffff4500, 128, "orangeRed"],
    [0xffda70d6, 129, "orchid"],
    [0xffeee8aa, 130, "paleGoldenrod"],
    [0xff98fb98, 131, "paleGreen"],
    [0xffafeeee, 132, "paleTurquoise"],
    [0xffdb7093, 133, "paleVioletRed"],
    [0xffffefd5, 134, "papayaWhip"],
    [0xffffdab9, 135, "peachPuff"],
    [0xffcd853f, 136, "peru"],
    [0xffffc0cb, 137, "pink"],
    [0xffdda0dd, 138, "plum"],
    [0xffb0e0e6, 139, "powderBlue"],
    [0xff800080, 140, "purple"],
    [0xffff0000, 141, "red"],
    [0xffbc8f8f, 142, "rosyBrown"],
    [0xff4169e1, 143, "royalBlue"],
    [0xff8b4513, 144, "saddleBrown"],
    [0xfffa8072, 145, "salmon"],
    [0xfff4a460, 146, "sandyBrown"],
    [0xff2e8b57, 147, "seaGreen"],
    [0xfffff5ee, 148, "seaShell"],
    [0xffa0522d, 149, "sienna"],
    [0xffc0c0c0, 150, "silver"],
    [0xff87ceeb, 151, "skyBlue"],
    [0xff6a5acd, 152, "slateBlue"],
    [0xff708090, 153, "slateGray"],
    [0xfffffafa, 154, "snow"],
    [0xff00ff7f, 155, "springGreen"],
    [0xff4682b4, 156, "steelBlue"],
    [0xffd2b48c, 157, "tan"],
    [0xff008080, 158, "teal"],
    [0xffd8bfd8, 159, "thistle"],
    [0xffff6347, 160, "tomato"],
    [0xff40e0d0, 161, "turquoise"],
    [0xffee82ee, 162, "violet"],
    [0xfff5deb3, 163, "wheat"],
    [0xffffffff, 164, "white"],
    [0xfff5f5f5, 165, "whiteSmoke"],
    [0xffffff00, 166, "yellow"],
    [0xff9acd32, 167, "yellowGreen"],
    /** The system-defined face color of a 3-D element. */
    [0xfff0f0f0, 168, "buttonFace"],
    /** The system-defined color that is the highlight color of a 3-D element. This color is applied to parts of a 3-D element that face the light source. */
    [0xffffffff, 169, "buttonHighlight"],
    /** The system-defined color that is the shadow color of a 3-D element. This color is applied to parts of a 3-D element that face away from the light source. */
    [0xffa0a0a0, 170, "buttonShadow"],
    /** The system-defined color of the lightest color in the color gradient of an active window's title bar. */
    [0xffb9d1ea, 171, "gradientActiveCaption"],
    /** The system-defined color of the lightest color in the color gradient of an inactive window's title bar. */
    [0xffd7e4f2, 172, "gradientInactiveCaption"],
    /** The system-defined color of the background of a menu bar. */
    [0xfff0f0f0, 173, "menuBar"],
    /** The system-defined color used to highlight menu items when the menu appears as a flat menu. */
    [0xff3399ff, 174, "menuHighlight"]
    ];
class PresetColors {
    // the order of this enum can be found in the definition of .net System.Drawing.KnownColor enumeration
    // or by running the the program in the linked documentation

    // default colors for theme-depending colors taken from ... (last post):
    // https://social.technet.microsoft.com/Forums/windows/en-US/ac76cc56-6ff2-4778-b260-8141d7170a3b/windows-7-highlight-text-color-or-selected-text-color-in-aero

    // see ST_SystemColorVal for system color names
    _lookupOoxmlId: Map<string,PresetColor>;
    _lookupNativeId: Map<number,PresetColor>;
    _lookupRGBA: Map<rgba,PresetColor>;

    constructor() {
        this._lookupOoxmlId = new Map<string, PresetColor>();
        this._lookupNativeId = new Map<number, PresetColor>();
        this._lookupRGBA = new Map<rgba, PresetColor>();

        for (let i=0; i<PRESET_COLOR_DEFINITIONS.length; i++) {
            let definition = PRESET_COLOR_DEFINITIONS[i];
            let rgba = definition[0] as number;//parseInt(definition[0] as string);
            let a = ((rgba >> 24) & 0xFF) / 255;
            let r = (rgba >> 16) & 0xFF;
            let g = (rgba >> 8) & 0xFF;
            let b = (rgba >> 0) & 0xFF;
            let presetColor:PresetColor = {
                nativeId: definition[1] as number,
                ooxmlId: definition[2] as string,
                rgbaColor: { r, g, b, a },
                system : definition.length > 3 as boolean
            }

            this._lookupOoxmlId.set(presetColor.ooxmlId.toLowerCase(), presetColor);
            this._lookupNativeId.set(presetColor.nativeId, presetColor);
            this._lookupRGBA.set(presetColor.rgbaColor, presetColor);
        }
    }

    valueOfOoxmlId(ooxmlId: string): PresetColor {
        return this._lookupOoxmlId.get(ooxmlId ? ooxmlId.toLowerCase() : "transparent");
    }

    valueOfNativeId(nativeId: number) {
        return this._lookupNativeId.get(nativeId || 27);
    }

    valueOfRGB(rgba: rgba) {
        let key:rgba = rgba;
        if (rgba.a === undefined) {
            key = {...rgba};
            key.a = 1;
        }

        return this._lookupRGBA.get(key);
    }
}

export default new PresetColors();