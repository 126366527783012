import CommonUtils from "../utils/CommonUtils";

import AbstractModel from "./AbstractModel";

/**
  This class is used to observe a model instance and track all nodes within
  the graph. Because the graphs are not defined in a top down way and because
  nodes can be shared across instances this class can be used to find
  the path to a model node within an existing graph instance
 */

class ExplicitResolver {
  constructor(jsonRoot) {
    if (typeof jsonRoot !== "object" && jsonRoot !== null)
      throw new Error("Can not create ExplicitResolver with an empty root");

    this._jsonRoot = CommonUtils.cloneObject(jsonRoot);

    // Because we use the jsonRoot to store only persisted values
    // but we want the in-memory model to support AbstractModels
    // we have to track these differently
    this._modelValuesCache = new Map();
  }

  getJsonForPath(pathParts, parent) {
    let node = this._jsonRoot;

    let depth = parent ? pathParts.length - 1 : pathParts.length;
    for (let i = 0; i < depth; i++) {
      if (node === null) return null;
      if (node === undefined || node[pathParts[i]] === undefined) return;
      node = node[pathParts[i]];
    }
    return node;
  }

  // TODO - is this a duplicate & almost the same as getJsonForPath
  getOrCreatePath(nodePath, root) {
    let currentNode = root !== undefined ? root : this._jsonRoot;
    for (let i = 0; i < nodePath.length; i++) {
      if (currentNode[nodePath[i]] === undefined) currentNode[nodePath[i]] = {};
      currentNode = currentNode[nodePath[i]];
    }
    return currentNode;
  }

  getRoot(instanceSelf) {
    let path = [];
    let instance = instanceSelf;
    let count = 0;
    while (instance.__propertyFrom !== undefined) {
      count++;
      if (count > 50) debugger;
      path.unshift(instance.__propertyFrom.persistKey);
      instance = instance.__propertyFrom.instance;
    }
    return {
      instance: instance,
      path: path,
    };
  }

  getRootForProperty(property) {
    return this.getRoot(property.instance);
  }

  getExplictValue(property) {
    let foundCachedValue =
      property._instance._explicitLookup._modelValuesCache.get(property);
    if (foundCachedValue !== undefined) return foundCachedValue;

    if (property._options.persister) {
      let root = this.getRootForProperty(property);
      let jsonForPath = this.getJsonForPath(root.path);
      if (!jsonForPath) return undefined;
      return property._options.persister.load(property, jsonForPath);
    }
    return undefined;
  }

  setExplictValue(property, explicitValue) {
    let root = this.getRootForProperty(property);

    let node = this.getOrCreatePath(root.path);

    // We always clear the oldvalues
    delete node[property.persistKey];
    root.instance._explicitLookup._modelValuesCache.delete(property);

    // if there is a persister we also save them to the json
    if (property.isTransient) {
      root.instance._explicitLookup._modelValuesCache.set(
        property,
        explicitValue
      );
    } else if (property._options.persister) {
      property._options.persister.save(property, node, explicitValue);
    }

    // If we are setting an explict model we need to reanchor.
    // This means move the explict values from the old graph to the
    // new graph (at potentially a different location)
    if (explicitValue instanceof AbstractModel) {
      if (!property.isTransient) {
        // If the value is from another default calculation we set that one to null
        let otherExplictValues = undefined;
        // We need to reanchor explictvalues
        if (explicitValue.__propertyFrom) {
          let otherLookup =
            explicitValue.__propertyFrom._instance._explicitLookup;
          let otherRoot = otherLookup.getRootForProperty(
            explicitValue.__propertyFrom
          );

          let otherExplictValuesParent = otherLookup.getJsonForPath(
            otherRoot.path,
            true
          );
          if (otherExplictValuesParent) {
            otherExplictValues =
              otherExplictValuesParent[explicitValue.__propertyFrom.persistKey];
            delete otherExplictValuesParent[
              explicitValue.__propertyFrom.persistKey
            ];
            otherRoot.instance._explicitLookup._modelValuesCache.delete(
              explicitValue.__propertyFrom
            );
          }

          // Note - This is a private hack to force the other graph to recalc.
          let propOtherOwner =
            explicitValue.__propertyFrom._instance.lookupPropertyDef(
              explicitValue.__propertyFrom.propertyName
            );
          propOtherOwner.evaled = {
            explicit: 0,
            defaulted: undefined,
            resolved: propOtherOwner.createPropertyValue(
              propOtherOwner.instance,
              undefined,
              propOtherOwner,
              false,
              property.evaled ? property.evaled.defaulted : undefined
            ),
          };
          propOtherOwner.value = undefined;
        } else {
          otherExplictValues = explicitValue._explicitLookup._jsonRoot;
        }

        if (otherExplictValues) node[property.persistKey] = otherExplictValues;

        // reanchor lookup
        explicitValue.__propertyFrom = property;
        explicitValue._explicitLookup = property.instance._explicitLookup;
      }
      root.instance._explicitLookup._modelValuesCache.set(
        property,
        explicitValue
      );
    }

    if (
      explicitValue !== undefined &&
      !(explicitValue instanceof AbstractModel) &&
      !property.isTransient &&
      !property._options.persister
    )
      throw Error(
        'Unable to save an explict Value. for "' +
          property.propertyName +
          '". It must be either an implicit AbstractModel or have a persister'
      );
  }
}

export default ExplicitResolver;
