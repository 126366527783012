import AbstractShape, { createFillProperty } from "../AbstractShape";
import PropertyPersister from "../../dagm/PropertyPersister";

class ChartPartShape extends AbstractShape {
  constructor(options, styleKey) {
    super(options);

    this._styleKey = styleKey;

    let _chartShape = options.chartShape;
    this.addProperty("chartShape", {
      isReadOnly: true,
      isTransient: true,
      defaultValue: function () {
        return _chartShape;
      },
    });

    if (styleKey) {
      // fill
      createFillProperty(this, styleKey, 'fill', "chartShape.chartStyle", function(style, styleKey) {
          // TODO - if chartType === pie then behave a little differently
          return {...style.createFillStyleProperties(styleKey)};
      }, null);

      // strokeFill;
      createFillProperty(this, styleKey, 'strokeFill', "chartShape.chartStyle", function(style, styleKey) {
        return {...style.createStrokeStyleProperties(styleKey).fill};
      }, null, 'stroke.fill');

      this.overrideProperty("strokeWidth", {
        defaultValue: function (chartStyle) {
          return chartStyle.createStrokeStyleProperties(styleKey).width;
        },
        inputs: ["chartShape.chartStyle"],
        persister: new PropertyPersister("stroke.width"),
      });
    }

  }

  get className() {
    return "ChartPartShape";
  }

  get styleKey() {
    return this._styleKey;
  }
}

export default ChartPartShape;
