import ArrayModel from "../../../dagm/ArrayModel";
import PropertyPersister from "../../../dagm/PropertyPersister";

import { RangeSelectionPersister, createRangeSelectionSetter } from "../../range/MultiRange";
import { getPointsPlotting, getXValues } from "../../../utils/OrdUnitCalcs";

import LiteralRange from "../../range/LiteralRange";
import LiteralValues from "../../range/LiteralValues";

import { createFillProperty } from "../../AbstractShape";

import { isLineType } from "../../../utils/ChartUtils";

import ChartPartShape from "../ChartPartShape";

import ChartDataPointShape from "../point/ChartDataPointShape";
import { ChartDataPointShapePersistor } from "../point/ChartDataPointShape";

import ChartSeriesTitleShape from "./ChartSeriesTitleShape";
import ChartSeriesMarkerShape from "./ChartSeriesMarkerShape";
import ChartSeriesDataLabelShape from "./ChartSeriesDataLabelShape";

class ChartSeriesShape extends ChartPartShape {
  constructor(options, offset, seriesLength) {
    super(options);
    const _self = this;
    this._offset = offset;

    this.addProperty("description", {
      isReadOnly: true,
      isTransient: true,
      defaultValue: function (simpleRun) {
        if (simpleRun === null || simpleRun.trim().length === 0)
            return 'Series ' + (offset+1);
        return 'Series "' + simpleRun + '"';
      },
      inputs: ["title.text.simpleRun"],
    });

    this.addProperty("offset", {
      isReadOnly: true,
      isTransient: true,
      defaultValue: function () {
        return offset;
      },
    });

    this.addProperty("seriesLength", {
      isReadOnly: true,
      isTransient: true,
      defaultValue: function () {
        return seriesLength;
      },
    });

    this.addProperty("offsetChart", {
      defaultValue: function () {
        return 0;
      },
      inputs: [],
    });

    /**
     ooxml has an order (we use offset) and an index. This is used for the default title
    */
    this.addProperty("idx", {
      defaultValue: function (offset) {
        return offset;
      },
      inputs: ["offset"],
    });

    this.addProperty("chartType", {
      isReadOnly: true,
      isTransient: true,
      defaultValue: function (chartTypes, offsetChart) {
        return chartTypes[offsetChart];
      },
      inputs: ["chartShape.types[*]", "offsetChart"],
    });

    // fill
    createFillProperty(this, 'series', 'fill', "chartShape.chartStyle", function(style, styleKey, chartType) {
      if (isLineType(chartType)) return style.createNoneFill();

      return style.createDataPoints2DFillStyleProperties(
        offset,
        seriesLength
      );
    }, ["chartType.type"]);

    // TODO - replace this with generalized conditional values
    // fillNegative
    createFillProperty(this, this.styleKey, 'fillNegative', "chartShape.chartStyle", function(style, styleKey, fill) {
      return fill;
    }, ["fill"]);

    // strokeFill
    createFillProperty(this, 'series', 'strokeFill', "chartShape.chartStyle", function(style, styleKey, chartType) {
      if (isLineType(chartType)) {
        return style.createDataPoints2DFillStyleProperties(
          offset,
          seriesLength
        );
      } else {
        return style.createDataPoints2DStrokeStyleProperties(
          offset,
          seriesLength
        ).fill;
      }
    }, ["chartType.type"], 'stroke.fill');

    // strokeFillNegative
    createFillProperty(this, this.styleKey, 'strokeFillNegative', "chartShape.chartStyle", function(style, styleKey, strokeFill) {
      return strokeFill;
    }, ["strokeFill"], 'strokeNegative.fill');

    this.overrideProperty("strokeWidth", {
      defaultValue: function (chartStyle, chartType) {
        if (isLineType(chartType)) {
          let strokeWidth = chartStyle.createDataPoints2DStrokeStyleProperties(
            offset,
            seriesLength
          ).width;
          // We use the fill for fill for strokes for lines
          // TODO - hack to look like ppt for now. We need to read the from the chartStyles
          if (strokeWidth === undefined) strokeWidth = 2.25;
          return strokeWidth;
        } else {
          return 0.75;
        }
      },
      inputs: ["chartShape.chartStyle", "chartType.type"],
      persister: new PropertyPersister("stroke.width"),
    });

    this.addProperty("effects", {
      defaultValue: null,
    });

    this.addProperty("valRange", {
      setValue: createRangeSelectionSetter(true/*singleLength*/, false/*alignRanges*/, true/*forceFixed*/),
      defaultValue: function (offset, ranges) {
        return ranges.getValRangeAt(offset);
      },
      inputs: ["offset", "chartShape.ranges"],
      persister: new RangeSelectionPersister(),
    });

    this.addProperty("valValues", {
      setValue: createRangeSelectionSetter(true/*singleLength*/, false/*alignRanges*/, true/*forceFixed*/),
      defaultValue: function (sheet, valRange) {
        if (sheet === null) {
          return new LiteralValues(new LiteralRange([[null]]));
        }

        return sheet.valuesFromRange(valRange);
      },
      inputs: ["chartShape.sheet", "valRange"],
      persister: new RangeSelectionPersister(),
    });

    this.addProperty('pointPlotting', {
      isTransient: true,
      defaultValue: function (valRange) {
       return getPointsPlotting([valRange]);
     },
     inputs: ["valRange"],
   });

    this.addProperty("xRange", {
      setValue: createRangeSelectionSetter(true/*singleLength*/, false/*alignRanges*/, true/*forceFixed*/),
      defaultValue: function (ranges) {
        return ranges.getXRange();
      },
      inputs: ["chartShape.ranges"],
      persister: new RangeSelectionPersister(),
    });

    this.addProperty("xValues", {
      setValue: createRangeSelectionSetter(true/*singleLength*/, false/*alignRanges*/, true/*forceFixed*/),
      defaultValue: function (sheet, range, pointPlotting) {
//         return sheet.valuesFromRange(range);
        return getXValues(range, sheet, pointPlotting);
      },
      inputs: ["chartShape.sheet", "xRange", "pointPlotting"],
      persister: new RangeSelectionPersister(),
    });

    this.addProperty("sizeRange", {
      setValue: createRangeSelectionSetter(true/*singleLength*/, false/*alignRanges*/, true/*forceFixed*/),
      defaultValue: function (ranges, valRange) {
        return ranges.sizeRange || null;
      },
      inputs: ["chartShape.ranges"],
      persister: new RangeSelectionPersister(),
    });


    // TODO -
    // When finishing bubble this logic is identical to rangeValues but all one values.
    // ChartCatAxisShape.rangeValues
    /*
    this.addProperty("sizeValues", {
      isReadOnly: true,
      defaultValue: function (ranges) {
        if (!ranges.sizeRange) {
            let retValue = new LiteralRange([[1]]);
            console.log('retValue', retValue.toString());
            return retValue;
        }

        return ranges.sizeRange || null;
      },
      inputs: ["sizeRange"]
    });
    */

    this.addProperty("title", {
      isReadOnly: true,
      defaultValue: function () {
        return new ChartSeriesTitleShape(Object.assign({
          chartShape: _self.chartShape,
          series: _self,
        }, options));
      },
      inputs: [],
      persister: null /* we implicitly save models*/
    });

    this.addProperty("invertIfNegative", { defaultValue: false });

    this.addProperty("smooth", {
      defaultValue: function (scatterStyle) {
        if (scatterStyle === 'smooth' || scatterStyle === 'smoothMarker')
          return true;
        return false
      },
      inputs: ["chartType.scatterStyle"]
    });

    this.addProperty("pointsPlottingSeries", { // Note - Is this the same info as points plotting?
      isReadonly: true,
      defaultValue: function (valRange, xRange, sheet) {
        // if range is null (note this should NEVER happen)
        if (valRange === null)
          return null;

        // Now we walk accross all value ranges and find every point range. Only the first series direction
        // is used to determine the axis direction.
        let oppositeDirection;
        const pointValueRanges = []

        const addValRange = function(range) {
            if (!oppositeDirection)
              oppositeDirection = (range.width === 1 ? "r" : "c");
            let numOfPoints = sheet ? Math.max(range.width, range.height) : 0;
            for (let i=0; i<numOfPoints; i++) {
                pointValueRanges.push(range.slice(i));
            }
        }
        if (Array.isArray(valRange)) {
            for (let i=0; i<valRange.length; i++)
                addValRange(valRange[i]);
        } else
            addValRange(valRange);

        const pointXRanges = []

        const addXRange = function(range) {
            let numOfPoints = sheet ? oppositeDirection === "r" ? range.height : range.width : 0;
            for (let i=0; i<numOfPoints; i++) {
              if (range && range.isCellRange) {
                pointXRanges.push(range.slice(i, oppositeDirection));
              } else if (range && range.isLiteralRange) {
                // literals are always rows we should put this in the literal since it's 'not special here'
                pointXRanges.push(range.slice(i, 'c'));
              }
            }
        }
        if (Array.isArray(xRange)) {
            for (let i=0; i<xRange.length; i++)
            addXRange(xRange[i]);
        } else if (xRange)
          addXRange(xRange);

        return {
          pointXRanges,
          pointValueRanges
        };
      },
      inputs: [
      "valRange",
      "xRange",
      "chartShape.sheet",
      "xValues.*"
      ]
    });

    this.addProperty("points", {
      isReadOnly: true,
      defaultValue: function (pointsPlottingSeries) {
        // if range is null (note this should NEVER happen)
        if (pointsPlottingSeries === null)
          return null;

        return new ArrayModel(pointsPlottingSeries.pointValueRanges.length, {
          defaultValue: function (index, length) {
            return null;
          },
          inputs: ["$index", "length"],
          persister: new ChartDataPointShapePersistor(null, {
            chartShape: _self.chartShape,
            createDataPoint : function(index) {
              const pointXRange = pointsPlottingSeries.pointXRanges[index] || null;
              const pointValueRange = pointsPlottingSeries.pointValueRanges[index] || null;
              const point = _self._internalCreateDataPoint(
                  Object.assign({}, options, { readonly: false }),
                  _self,
                  pointXRange,
                  pointValueRange,
                  index,
                  pointsPlottingSeries.pointValueRanges.length,
                  offset,
                  seriesLength
                );
              return point;
            }
          })
        });
      },
      inputs: [
        "pointsPlottingSeries"
      ],
      persister: null /* we implicitly save models*/
    });

    this.addProperty("renderedPoints", {
      isReadOnly: true,
      defaultValue: function (pointsPlottingSeries, points) {
        // if pointsPlottingSeries is null (note this should NEVER happen)
        if (pointsPlottingSeries === null)
          return null;

//         console.log('rendering points', offset, points.length);

        let retValue = [];
        for (let i=0; i<points.length; i++) {
          let pointCustom = points.getAt(i);
          if (pointCustom === null) {
          const pointXRange = pointsPlottingSeries.pointXRanges[i] || null;
          const pointValueRange = pointsPlottingSeries.pointValueRanges[i] || null;
          pointCustom = _self._internalCreateDataPoint(
              Object.assign({}, options, { readonly: true }), // options,
              _self,
              pointXRange,
              pointValueRange,
              i,
              points.length,
              offset,
              seriesLength
            );
          }
          retValue.push(pointCustom);
        }
        return retValue;
      },
      inputs: [
        "pointsPlottingSeries",
        "points",
        "points[*]",
        "fill",
        "chartType.varyColors",
        "chartType.type",
        "fillNegative",
        "strokeFill",
        "chartType.varyColors",
        "chartType.type",
        "chartType.scatterStyle",
        "strokeFillNegative",
        "strokeWidth",
        "strokeLineJoin",
        "strokeLineCap",
        "strokeDash",
        // "strokeCompound"
      ],
      persister: null /* we implicitly save models*/
    });

    this.addProperty("labels", {
      isReadOnly: true,
      defaultValue: function (points) {
        return new ChartSeriesDataLabelShape(
          {
            chartShape: _self.chartShape,
            chartType: _self.chartType,
            series: _self,
            points: points,
            appContext: options.appContext
          },
          "dataLabel"
        );
      },
      inputs: ["renderedPoints"],
      persister: null /* we implicitly save models*/,
    });

    this.addProperty("markers", {
      isReadOnly: true,
      defaultValue: function (points) {
        return new ChartSeriesMarkerShape(
          {
            chartShape: _self.chartShape,
            chartType: _self.chartType,
            series: _self,
            points: points,
            appContext: options.appContext
          },
          offset,
          seriesLength
        );
      },
      inputs: ["renderedPoints"],
      persister: null /* we implicitly save models*/,
    });

  } // end constructor

  createDataPoint(index) {
    let pointsPlottingSeries = this.pointsPlottingSeries;
    if (index < 0 || index > pointsPlottingSeries.length)
      return null;

    const pointXRange = pointsPlottingSeries.pointXRanges[index] || null;
    const pointValueRange = pointsPlottingSeries.pointValueRanges[index] || null;
    // TODO - if this series is readonly then don't allow this.
    const point = this._internalCreateDataPoint(
        this._shapeOptions,
        this,
        pointXRange,
        pointValueRange,
        index,
        pointsPlottingSeries.pointValueRanges.length,
        this.offset,
        this.seriesLength
      );
    return point;
  }

  _internalCreateDataPoint(
    options,
    _self,
    pointXRange,
    pointValueRange,
    index,
    length,
    offset,
    seriesLength
  ) {
    return new ChartDataPointShape(
      options,
      _self,
      pointXRange,
      pointValueRange,
      index,
      length,
      offset,
      seriesLength
    );
  }

  get className() {
    return "ChartSeriesShape";
  }

  get typeId() {
    return this.className + ":" + (this._offset + 1);
  }
}

export default ChartSeriesShape;
