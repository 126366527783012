import * as CommonUtils from '../../utils/CommonUtils';

import { REGEX_FLOAT_STR }  from '../primitives/Coordinates';

const RGB_SIGFIGS = 0; // browsers are limited to 8 bit color

export const REGEX_RGB = new RegExp(`^rgb\\(${REGEX_FLOAT_STR},${REGEX_FLOAT_STR},${REGEX_FLOAT_STR}\\)$`);
export const REGEX_RGBA = new RegExp(`^rgba\\(${REGEX_FLOAT_STR},${REGEX_FLOAT_STR},${REGEX_FLOAT_STR},${REGEX_FLOAT_STR}\\)$`);

export const REGEX_LRGB = new RegExp(`^lrgb\\(${REGEX_FLOAT_STR},${REGEX_FLOAT_STR},${REGEX_FLOAT_STR}\\)$`);
export const REGEX_LRGBA = new RegExp(`^lrgba\\(${REGEX_FLOAT_STR},${REGEX_FLOAT_STR},${REGEX_FLOAT_STR},${REGEX_FLOAT_STR}\\)$`);

export const REGEX_HSL = new RegExp(`^hsl\\(${REGEX_FLOAT_STR},${REGEX_FLOAT_STR},${REGEX_FLOAT_STR}\\)$`);
export const REGEX_HSLA = new RegExp(`^hsla\\(${REGEX_FLOAT_STR},${REGEX_FLOAT_STR},${REGEX_FLOAT_STR},${REGEX_FLOAT_STR}\\)$`);

// assumes lowercase, doesn't include alpha
export const REGEX_HEX = /^#?([0-9a-fA-F]{2})([0-9a-fA-F]{2})([0-9a-fA-F]{2})$/
// Note - hex alpha leads
export const REGEX_HEXA= /^#?([0-9a-fA-F]{2})([0-9a-fA-F]{2})([0-9a-fA-F]{2})([0-9a-fA-F]{2})$/

export const parseParts = function (partr: string | number, partg: string | number, partb: string | number,): number[] {
  return [
      parseFloat(partr as string),
      parseFloat(partg as string),
      parseFloat(partb as string)
  ]
}

export const divRGB = function (input: number[], divisor = 255): number[] {
  const retValue = [...input];
  for (var i = 0; i < input.length; i++) {
    retValue[i] = input[i] / divisor;
  }
  return retValue;
}

export const multRGB = function (input: number[], mult = 255): number[] {
  const retValue = [...input];
  for (var i = 0; i < input.length; i++) {
    retValue[i] = input[i] * mult;
  }
  return retValue;
}

export const roundRGB = function (input: number[], sigFigs = RGB_SIGFIGS): number[] {
  const retValue = [...input];
  for (var i = 0; i < input.length; i++) {
    // Office rounds down 0.5 for colors
    if ((CommonUtils.roundAccurately(input[i], sigFigs + 8) % 1) === 0.5)
      retValue[i] = Math.floor(input[i]);
    else
      retValue[i] = CommonUtils.roundAccurately(input[i], sigFigs);
  }
  return retValue;
}

export const clampRGB = function (rgb: number[]): number[] {
  const retValue = [...rgb];
  for (var i = 0; i < rgb.length; i++) {
    retValue[i] = Math.max(0, Math.min(1, rgb[i]));
  }
  return retValue;
}

export const clampAlpha = function (number: number): number {
  return Math.max(0, Math.min(1, number));
}

export const clampHue = function (hue: number): number {
  hue %= 360;
  if (hue < 0)
    hue -= 360;
  return hue;
}

export const srgb2lin = function (rgb: number[]): number[] {
  const retValue = [...rgb];
  for (var i = 0; i < rgb.length; i++) {
    retValue[i] = srgbToLinear(rgb[i]);
  }
  return retValue;
}

export const lin2srgb = function (rgb: number[]): number[] {
  const retValue = [...rgb];
  for (var i = 0; i < rgb.length; i++) {
    retValue[i] = linearToSrgb(rgb[i]);
  }
  return retValue;
}

const srgbToLinear = function (x: number): number {
  if (x < 0.04045) return x / 12.92;
  else return Math.pow((x + 0.055) / 1.055, 2.4);
}

const linearToSrgb = function (x: number): number {
  if (x < 0.0031308) return x * 12.92;
  else return Math.pow(x, 1 / 2.4) * 1.055 - 0.055;
}

/**
*  Convert HSL values to a RGB Color.
*
*  @param h Hue is specified as degrees in the range 0 - 360.
*  @param s Saturation is specified as a percentage in the range 1 - 100.
*  @param l Luminance is specified as a percentage in the range 1 - 100.
*  @param alpha Alpha value between 0 - 1
*
*  @return the RGB Color object
*/
export const HSL2RGB = function (hsl: number[]): number[] {
  let h = hsl[0];
  let s = hsl[1];
  let l = hsl[2];

  //  Formula needs all values between 0 - 1.

  h = h % 360.0;
  h /= 360;
  s /= 100;
  l /= 100;

  let q = (l < 0.5)
    ? l * (1 + s)
    : (l + s) - (s * l);

  let p = 2 * l - q;

  let r = Math.max(0, HUE2RGB(p, q, h + (1.0 / 3.0)));
  let g = Math.max(0, HUE2RGB(p, q, h));
  let b = Math.max(0, HUE2RGB(p, q, h - (1.0 / 3.0)));

  return [r, g, b];
}

export const HUE2RGB = function (p: number, q: number, h: number): number {
  if (h < 0) {
    h += 1;
  }

  if (h > 1) {
    h -= 1;
  }

  if (6 * h < 1) {
    return p + ((q - p) * 6 * h);
  }

  if (2 * h < 1) {
    return q;
  }

  if (3 * h < 2) {
    return p + ((q - p) * 6 * ((2.0 / 3.0) - h));
  }

  return p;
}


/**
 *  Convert a RGB Color to it corresponding HSL values.
 *
 *  @return an array containing the 3 HSL values.
 */
export const RGB2HSL = function (rgb: number[]): number[] {
  //  Get RGB values in the range 0 - 1
  let r = rgb[0];
  let g = rgb[1];
  let b = rgb[2];

  //  Minimum and Maximum RGB values are used in the HSL calculations

  let min = Math.min(r, Math.min(g, b));
  let max = Math.max(r, Math.max(g, b));

  //  Calculate the Hue

  let h = 0;

  if (max == min) {
    h = 0;
  } else if (max == r) {
    h = ((60 * (g - b) / (max - min)) + 360) % 360;
  } else if (max == g) {
    h = (60 * (b - r) / (max - min)) + 120;
  } else if (max == b) {
    h = (60 * (r - g) / (max - min)) + 240;
  }

  //  Calculate the Luminance

  let l = (max + min) / 2;

  //  Calculate the Saturation

  let s = 0;

  if (max == min) {
    s = 0;
  } else if (l <= .5) {
    s = (max - min) / (max + min);
  } else {
    s = (max - min) / (2 - max - min);
  }

  return [h, s * 100, l * 100];
}

// Mutation functions
export enum HSLComponent {
  HUE = 0,
  SATURATION = 1,
  LUNINANCE = 2
};

export enum RGBAComponent {
  RED = 0,
  GREEN = 1,
  BLUE = 2,
  ALPHA = 3
};

export enum ColorShiftType {
  SET = 0,
  OFF = 1,
  MOD = 2
};

export class ColorAdjustment {
  _private: {
    name: string, // TODO - make this an enum?
    amount: number
  }
  constructor(name: string, amount: number) {
    this._private = { name, amount };
  }

  get name() {
    return this._private.name;
  }
  get amount() {
    return this._private.amount;
  }
}
export type CompTransform = (comp: number, lrgb: number[]) => number;

export const applyLRGBTransform = function (srgb: number[], compTransform: CompTransform): number[] {
  let lrgb = srgb2lin(srgb);

  for (let i = 0; i < 3; i++) lrgb[i] = compTransform(lrgb[i], lrgb);

  let retValue = lin2srgb(lrgb);
  retValue = clampRGB(retValue);
  return retValue;
}

export const applyShade = function (srgb: number[], val: number): number[] {
  let fval = val / 100;

  return applyLRGBTransform(srgb, (comp: number, lrgb: number[]): number => {
    return comp * fval;
  });
}

export const applyTint = function (srgb: number[], val: number): number[] {
  let lrgb = srgb2lin(srgb);

  let fval = val / 100;

  for (var i = 0; i < 3; i++) lrgb[i] = lrgb[i] * fval + (1 - fval);

  let retValue = lin2srgb(lrgb);
  retValue = clampRGB(retValue);

  return retValue;
}

/**
 * set HSL component
*/
export const applyHSL = function (space: HSLComponent, srgb: number[], val: number): number[] {
  let hsl = RGB2HSL(srgb);

  let fval = val / 1;
  if (space === HSLComponent.HUE) {
    fval = clampHue(val);
  }

  hsl[space] = fval;

  let retValue = HSL2RGB(hsl);
  retValue = clampRGB(retValue);

  return retValue;
}

export const applyHSLOff = function (space: HSLComponent, srgb: number[], val: number): number[] {
  let hsl = RGB2HSL(srgb);

  if (space == HSLComponent.HUE) {
    let fval = ((val) / 360);
    hsl[HSLComponent.HUE] = ((hsl[HSLComponent.HUE] / 360) + fval) * 360;

    hsl[HSLComponent.HUE] %= 360;
    if (hsl[HSLComponent.HUE] < 0)
      hsl[HSLComponent.HUE] -= 360;
  } else { // SATURATION , LUNINANCE
    let fval = ((val / 0.1));

    hsl[space] = ((hsl[space] / 0.1) + fval) * 0.1;
  }

  let retValue = HSL2RGB(hsl);
  retValue = clampRGB(retValue);

  return retValue;
}

export const applyHSLMod = function (space: HSLComponent, srgb: number[], val: number): number[] {
  let hsl = RGB2HSL(srgb);
  let fval = ((val / 100));

  hsl[space] = hsl[space] * fval;

  if (space == HSLComponent.HUE) {
    hsl[HSLComponent.HUE] = clampHue(hsl[HSLComponent.HUE]);
  }

  let retValue = HSL2RGB(hsl);
  retValue = clampRGB(retValue);

  return retValue;
}

export const applyRGB = function (space: RGBAComponent, srgb: number[], val: number, type: ColorShiftType): number[] {
  let lrgb = srgb2lin(srgb);

  let fval = val / 100;

  if (type == ColorShiftType.SET)
    lrgb[space] = fval;
  else if (type == ColorShiftType.OFF)
    lrgb[space] += fval;
  else if (type == ColorShiftType.MOD)
    lrgb[space] *= fval;

  let retValue = lin2srgb(lrgb);
  retValue = clampRGB(retValue);

  return retValue;
}
export const applyGray = function (srgb: number[]): number[] {
  let lrgb = srgb2lin(srgb);

  let gray = (0.213 * lrgb[RGBAComponent.RED]) + (0.715 * lrgb[RGBAComponent.GREEN]) + (0.072 * lrgb[RGBAComponent.BLUE]);
  for (let i = 0; i < 3; i++)
    lrgb[i] = gray;

  let retValue = lin2srgb(lrgb);
  retValue = clampRGB(retValue);

  return retValue;
}
export const applyComplement = function (srgb: number[]): number[] {
  let hsl = RGB2HSL(srgb);

  // Rotate the HUE by 180
  hsl[HSLComponent.HUE] += 180;

  hsl[HSLComponent.HUE] %= 360;
  if (hsl[HSLComponent.HUE] < 0)
    hsl[HSLComponent.HUE] -= 360;

  let retValue = HSL2RGB(hsl);
  retValue = clampRGB(retValue);

  return retValue;
}
export const applyInverse = function (srgb: number[]): number[] {
  let lrgb = srgb2lin(srgb);

  for (let i = 0; i < 3; i++)
    lrgb[i] = 1 - lrgb[i];

  let retValue = lin2srgb(lrgb);
  retValue = clampRGB(retValue);

  return retValue;
}
export const applyGamma = function (srgb: number[]): number[] {
  // 5.1.2.2.8 gamma (Gamma)
  // This element specifies that the output color rendered by the generating application should be the sRGB gamma
  // shift of the input color.
  let shift = [0, 0, 0];
  for (let i = 0; i < 3; i++)
    shift[i] = linearToSrgb(srgb[i]);

  let retValue = clampRGB(shift);
  return retValue;
}
export const applyInverseGamma = function (srgb: number[]): number[] {
  // 5.1.2.2.8 gamma (Gamma)
  // This element specifies that the output color rendered by the generating application should be the sRGB gamma
  // shift of the input color.
  let shift = [0, 0, 0];
  for (let i = 0; i < 3; i++)
    shift[i] = srgbToLinear(srgb[i]);

  let retValue = clampRGB(shift);
  return retValue;
}

