export function getNativeClass(obj) {
    if (obj === undefined) return "undefined";
    if (obj === null) return "null";
    return Object.prototype.toString.call(obj).match(/^\[object\s(.*)\]$/)[1];
}

export function getAnyClass(obj) {
    if (obj === undefined) return "undefined";
    if (obj === null) return "null";
    return obj.className;
}