import PropertyPersister from "../../dagm/PropertyPersister";

// TODO - This are NOT fonts but rather font references
export interface IFont {
  toJSON(): any;

  readonly name: string | undefined;

  readonly pitch: string | undefined;

  readonly panose:string | undefined;

  toString():string;

  readonly isIFont:true;
}

/** read only font */
export class Font implements IFont {
  _private: any;
  constructor(input: any) {
    this._private = {
      input : input
    }
    if (typeof input === 'string') {
      this._private.name = input;
    } else {
      Object.assign(this._private, input);
    }
  }
  toJSON() {
    return this._private.input;
  }
  get name() : string | undefined {
    return this._private.name;
  }
  get pitch() : string | undefined {
    return this._private.pitch;
  }
  get panose() : string | undefined {
    return this._private.panose;
  }

  toString() {
    if (typeof this._private.input === 'string') {
      return this._private.input;
    }

    return JSON.stringify(this._private.input);
  }

  get isIFont() : true {
    return true;
  }
}

const fontSetter = function (value: string | Font) {
  if (value === undefined) return value;
  if ((value as IFont).isIFont) return value;
  return new Font(value);
};

class FontPersister extends PropertyPersister {
  constructor(path?: any) {
    super(path);
  }

  toJSON(value: any) {
    if (value && value.toJSON)
        return value.toJSON();
    return value.toString();
  }

  fromJSON(jsonValue: any) {
    return new Font(jsonValue);
  }
}

export { fontSetter, FontPersister };
