import ChartAxisShape from "./ChartAxisShape";

import { RangeSelectionPersister, createRangeSelectionSetter } from "../../range/MultiRange";
import LiteralRange  from "../../range/LiteralRange";

import { getPointsPlotting, getXValues } from "../../../utils/OrdUnitCalcs";

class ChartCatAxisShape extends ChartAxisShape {
  constructor(options, type) {
    super(options, type);

    let _self = this;
    const getDefaultAxisLabel = options?.getDefaultAxisLabel;

    this.addProperty('pointPlotting', {
      isTransient: true,
      defaultValue: function (valRanges) {
       return getPointsPlotting(valRanges);
     },
     inputs: ["chartShape.series[*].valRange"],
   });

    // Note - Cat axes require all values to be the same
    let rangeSetter = createRangeSelectionSetter(false/*singleLength*/, false/*alignRanges*/, true/*forceFixed*/);
    this.addProperty("xRange", {
      setValue: function (value, auxSetPropertyValue) {
        let retValue = rangeSetter(value);
        let series = _self.getPropertyValue("chartShape.series[*]");
        if (series && series.value) {
          for (let i=0; i<series.value.length; i++) {
            auxSetPropertyValue("series[" + i + "].xRange", retValue);
          }
        }
        return undefined;
      },
      defaultValue: function (xRangeSeries0, valRange, pointPlotting) {
        if (xRangeSeries0 !== null)
            return xRangeSeries0;

        if (getDefaultAxisLabel) {
            let defaultedLabels = [];
            for (let i=0; i<pointPlotting.numOfPoints; i++) {
                let pointRange = pointPlotting.pointValueRanges[i];
                if (Array.isArray(valRange)) {
                  valRange = valRange[0];
                }
                let defaultedLabel = getDefaultAxisLabel(pointRange, valRange, i, pointPlotting.numOfPoints);
                if (defaultedLabel === null || defaultedLabel === undefined)
                    defaultedLabel = i+1;
                defaultedLabels.push(defaultedLabel);
            }
            return new LiteralRange([defaultedLabels]);
        }

        return xRangeSeries0;
      },
      inputs: ["chartShape.series[0].xRange", "chartShape.series[0].valRange", "pointPlotting"],
      persister: new RangeSelectionPersister(),
    });

    this.addProperty("xValues", {
      isReadOnly: true,
      defaultValue: function (xRange, sheet, pointPlotting) {
        return getXValues(xRange, sheet, pointPlotting);
      },
      inputs: ["xRange", "chartShape.sheet", "pointPlotting"],
    });

    /**
     * Specifies the possible crossing states of an axis
     * Choices:
        <between> (Between) Specifies the value axis shall cross the category axis between data markers.
        <midCat> (Midpoint of Category) Specifies the value axis shall cross the category axis at the midpoint of a category.
     */
    this.addProperty("crossBetween", {
      defaultValue: function (chartTypeType) {
        if (chartTypeType === "area") return "midCat";
        return "between";
      },
      inputs: ["chartType.type"],
    }); // 'between', 'midCat'
  } // end of constructor

  get className() {
    return "ChartCatAxisShape";
  }

}

export default ChartCatAxisShape;
