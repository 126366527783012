import CommonUtils from "./CommonUtils";
import * as UnitCalcs from "./UnitCalcs";

import DateUtils from "./DateUtils";

/**
 * This operates on excel dates.
 * Note - This converts to javascript Date and back. There might be a more effecient algo
 */
export function toBaseUnit(dateValue, baseTimeUnit = "days", date1904 = false) {
  if (baseTimeUnit === "days") return dateValue;

  let asDate = DateUtils.fromExcelDate(dateValue, date1904);
  if (asDate === null) return;
  let newDate;
  if (baseTimeUnit === "years") {
    newDate = new Date(asDate.getFullYear(), 0, 1);
  } else if (baseTimeUnit === "months") {
    newDate = new Date(asDate.getFullYear(), asDate.getMonth(), 1);
  } else {
    console.warn("unable baseTimeUnit", baseTimeUnit);
  }

  return DateUtils.toExcelDate(newDate, date1904);
}

function neededDateTicks(startDate, endDate, baseTimeUnit, unitCount) {
  let tickCount = 1;
  if (baseTimeUnit === "years") {
    tickCount = DateUtils.yearDiff(endDate, startDate) + 1;
  } else if (baseTimeUnit === "months") {
    tickCount = DateUtils.monthDiff(endDate, startDate) + 1;
  } else {
    // is days
    tickCount =
      1 + (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24.0);
  }
  if (unitCount) tickCount /= unitCount;

  return tickCount;
}

/*
 * This scans through a list of SORTED excel dates
 * 1. If all of the dates are unique years than base date is year
 * 2. If all of the dates are unique months than baseDate is months.
 */

export function defaultBaseUnit(datesOrig, sorted = false, date1904 = false) {
  if (!datesOrig || datesOrig.length < 1) return "days";

  let dates = datesOrig;
  if (!sorted) dates = datesOrig.sort(); //(a, b) => a.getTime( ) - b.getTime());

  let duplicateYears = false;
  let largestYear = -1;
  let largestMonth = -1;
  for (let i = 0; i < dates.length; i++) {
    let date = DateUtils.fromExcelDate(dates[i], date1904);
    if (
      i > dates.length - 1 &&
      date.getTime() < DateUtils.fromExcelDate(dates[i + 1], date1904).getTime()
    )
      throw new Error("dates are expected to be sorted");

    let month = date.getFullYear() * 12 + date.getMonth();
    // if we found two dates in the same month then we default to days
    if (month === largestMonth) return "days";
    largestMonth = month;

    let year = date.getFullYear();
    if (year > largestYear) largestYear = year;
    else duplicateYears = true;
  }
  if (duplicateYears) return "months";

  return "years";
}

/*
 * Find the first fit for the given units and rotations for the start and end or false if nothing was found.
 * If units is 0 it will check until it finds at least one. (and never return false)
 */
function fitDateTicks(startDate, endDate, frequencies, rotations) {
  for (let i = 0; i < frequencies.length; i++) {
    let frequency = frequencies[i];
    if (frequency.count === 0) {
      // Start at one and count until it no longer fits
      let lastFit = {
        rotation: 0,
        unit: frequency.unit,
        count: 1,
      };
      do {
        let currentCount = lastFit.count;
        let ticksToUse = neededDateTicks(
          startDate,
          endDate,
          lastFit.unit,
          currentCount
        );
        for (let j = 0; j < rotations.length; j++) {
          let rotation = rotations[j];
          lastFit.rotation = rotation.deg;
          if (ticksToUse <= rotation.maxLabels || rotation.maxLabels === 0) {
            return lastFit;
          }
        }
        lastFit.count = currentCount + 1;
      } while (true); // will return out
    } else {
      let ticksToUse = neededDateTicks(
        startDate,
        endDate,
        frequency.unit,
        frequency.count
      );
      for (let j = 0; j < rotations.length; j++) {
        let rotation = rotations[j];
        if (ticksToUse <= rotation.maxLabels)
          return {
            rotation: rotation.deg,
            unit: frequency.unit,
            count: frequency.count,
          };
      }
    }
  }
}

/*
 * @returns

  {
    min: oaDate,
    max: oaDate,
    minorUnits: { amt : dem : },
    majorUnits: { amt : dem : },
    baseUnits: dem,
    majorTicks: [oaDate],
    minorTicks: [oaDate],
    rotation: rotation
  };

 *
*/
export function fitAxisDateTicks(
  eMin,
  eMax,
  eMinor,
  eMajor,
  eBase,
  dims,
  horizontal = true,
  labels
) {
  let retValue = {
    majorUnitDates: eMajor,
    minorUnitDates: eMinor, // TODO - minorTicks (does this ever default to less what happens if this is explict?)
    majorTicks: null,
    minorTicks: null, // TODO - minorTicks
    rotation: 0,
  };

  let startDate = DateUtils.fromExcelDate(eMin);
  let endDate = DateUtils.fromExcelDate(eMax);

  if (dims === null || dims === undefined) {
    // just for testing
    dims = {
      axis: {
        width: 1000,
        height: 30,
      },
    };
  }
  let axisDistance = horizontal
    ? Math.max(0, dims.axis.width)
    : Math.max(0, dims.axis.height);

  // Use the 'largest date value'
  let metrics = UnitCalcs.getMetricsForLabel(
    DateUtils.toExcelDate(new Date(2000, 11, 13)),
    labels,
    horizontal
  );
  // Determine the dates is a 'best fit'
  let width = metrics.width + 0;
  let height = metrics.height + 0; // Making this 'higher' increases' the distance needed between axis

  // 1. Sort the dates
  // 2. Find baseunit if not provided (scan all dates and look for small diff (days/months/year))

  // 3. Find the major unit
  //    a. find the axis width
  //    b. find the width of a label (for now assume all labels are the same text)

  // TODO - this should be passed in. This is not good design
  let isCustomRotation = labels.getPropertyValue("rotation").isExplicit;
  let rotationsToCheck = [];
  if (isCustomRotation) {
    let rotationCustom = labels.rotation;
    rotationsToCheck.push({
      deg: rotationCustom,
      maxLabels: UnitCalcs.calcMaxLabels(
        axisDistance,
        width,
        height,
        rotationCustom
      ),
    });
  } else {
    if (horizontal) {
      rotationsToCheck.push({
        deg: 0,
        maxLabels: UnitCalcs.calcMaxLabels(axisDistance, width, height, 0),
      });
      rotationsToCheck.push({
        deg: -45,
        maxLabels: UnitCalcs.calcMaxLabels(axisDistance, width, height, -45),
      });
      rotationsToCheck.push({
        deg: -90,
        maxLabels: UnitCalcs.calcMaxLabels(axisDistance, width, height, -90),
      });
    } else {
      rotationsToCheck.push({
        deg: 0,
        maxLabels: UnitCalcs.calcMaxLabels(axisDistance, height, width, 0),
      });
    }
  }

  let foundFit = false;
  if (eMajor !== undefined) {
    let frequencies = [
      {
        unit: eMajor.dem,
        count: eMajor.amt || 1,
      },
    ];
    foundFit = fitDateTicks(startDate, endDate, frequencies, rotationsToCheck);
    if (!foundFit) {
      // we force it
      foundFit = {
        rotation: -45,
        unit: eMajor.dem,
        count: eMajor.amt || 1,
      };
    }
    if (!foundFit) {
      // we force it
      foundFit = {
        rotation: -90,
        unit: eMajor.dem,
        count: eMajor.amt || 1,
      };
    }
  }

  // https://support.microsoft.com/en-za/help/211767/dates-are-made-consecutive-when-you-create-charts-in-microsoft-excel
  if (!foundFit && eBase !== undefined) {
    let frequencies = [
      {
        unit: eBase,
        count: 0,
      },
    ];
    foundFit = fitDateTicks(startDate, endDate, frequencies, rotationsToCheck);
  }

  if (!foundFit) {
    let frequencies = [
      {
        unit: "days",
        count: 1,
      },
      {
        unit: "days",
        count: 2,
      },
      {
        unit: "days",
        count: 7,
      },
    ];
    foundFit = fitDateTicks(startDate, endDate, frequencies, rotationsToCheck);
  }
  if (!foundFit) {
    let frequencies = [
      {
        unit: "months",
        count: 1,
      },
      {
        unit: "month",
        count: 2,
      },
    ];
    foundFit = fitDateTicks(startDate, endDate, frequencies, rotationsToCheck);
  }
  if (!foundFit) {
    let frequencies = [
      {
        unit: "years",
        count: 0,
      },
    ];
    foundFit = fitDateTicks(startDate, endDate, frequencies, rotationsToCheck);
  }

  let ticksMajor = [];
  let boundStartDate = DateUtils.fromExcelDate(
    DateUtils.toExcelDate(startDate) // toBaseUnit(DateUtils.toExcelDate(startDate), foundFit.unit)
  );
  let boundEndDate = DateUtils.fromExcelDate(
    DateUtils.toExcelDate(endDate) // toBaseUnit(DateUtils.toExcelDate(endDate), foundFit.unit)
  );
  let nextDate = boundStartDate;
  do {
    ticksMajor.push(DateUtils.toExcelDate(nextDate));
    nextDate = DateUtils.addDateUnit(
      boundStartDate,
      foundFit.count * ticksMajor.length,
      foundFit.unit
    );
  } while (nextDate <= boundEndDate);

  let minorUnits = eMinor;
  if (!minorUnits) {
    minorUnits = {
      amt: 1,
      dem: foundFit.unit,
    };
  }

  let ticksMinor = [];
  nextDate = boundStartDate;
  do {
    ticksMinor.push(DateUtils.toExcelDate(nextDate));
    nextDate = DateUtils.addDateUnit(
      boundStartDate,
      1 * ticksMinor.length,
      minorUnits.dem
    );
  } while (nextDate <= boundEndDate);

  retValue = {
    majorUnitDates: {
      amt: foundFit.count,
      dem: foundFit.unit,
    },
    minorUnitDates: minorUnits,
    majorTicks: ticksMajor,
    minorTicks: ticksMinor,
    rotation: foundFit.rotation,
  };

  return retValue;
}

/**
 * Every axix limit needs to return:
   1. plot information
   2. label information
   3. tick/gridlines information
*/
function addCulledItems(retValue, crossBetween) {


  retValue.plotValues = retValue.values;
  retValue.labelValues = [...retValue.majorTicks];

  if (crossBetween === 'between' && retValue.majorTicks.length > 1) {
    let shift = retValue.majorTicks[1] - retValue.majorTicks[0];
    for (let i =0; i<retValue.majorTicks.length; i++) {
      retValue.majorTicks[i] = retValue.majorTicks[i] - shift / 2;
    }
    retValue.majorTicks.push(retValue.majorTicks[retValue.majorTicks.length-1] + shift);
  }
  retValue.ticks = retValue.majorTicks;

  //TODO - do this for minorTicks too.
  //TODO - do this for ordinalTicks too.

  retValue.crossBetween = crossBetween;
  return retValue;
}

export function calcAxisLimitsDate(
  explictValues,
  explicitMin,
  explicitMax,
  explicitMinorUnitDates,
  explicitMajorUnitDates,
  explicitBaseUnitDates,
  dims,
  horizontal,
  crossBetween = "between",
  labels
) {
  let eMin = CommonUtils.isDefined(explicitMin)
    ? Number(explicitMin)
    : undefined;
  let eMax = CommonUtils.isDefined(explicitMax)
    ? Number(explicitMax)
    : undefined;
  let eMinor = CommonUtils.isDefined(explicitMinorUnitDates)
    ? explicitMinorUnitDates
    : undefined;
  let eMajor = CommonUtils.isDefined(explicitMajorUnitDates)
    ? explicitMajorUnitDates
    : undefined;
  let eBase = CommonUtils.isDefined(explicitBaseUnitDates)
    ? explicitBaseUnitDates
    : undefined;

  let values = [];
  for (let i = 0; i < explictValues.length; i++) {
    let value = explictValues[i];
    if (value === undefined || value === null) continue;
    if (
      ((eMin === undefined || eMin <= value) && eMax === undefined) ||
      eMax >= value
    )
      values.push(value);
  }
  // Is this possible?
  if (values.length === 0) {
    return {
      min: 0,
      max: 1,
      majorUnitDates: eMajor,
      minUnitDates: eMinor,
      baseUnitDates: eBase,
      autoZero: 0,
    };
  }
  // This can occur if the min/max trim to one value.
  // need all of the followin logic so don't return
//   if (values.length === 1) {
//     let retValue = {
//       min: (eMin !== undefined ? eMin : values[0]),
//       max: (eMax !== undefined ? eMax : values[0]),
//       majorUnitDates: eMajor,
//       minUnitDates: eMinor,
//       baseUnitDates: eBase,
//     };
//     retValue.autoZero = UnitCalcs.autoZero(retValue.min, retValue.max);
//     return addCulledItems(retValue, values, crossBetween);
//   }
  values = values.sort();

  // scan for years and months
  let defaultBase = eBase;
  if (defaultBase === undefined) {
    defaultBase = defaultBaseUnit(values);
    if (eMajor) {
      // If we defaulted the base date and explicitly set the major unit to a smaller base than 'shift down'
      if (
        (defaultBase === "years" &&
          (eMajor.dem === "months" || eMajor.dem === "days")) ||
        (defaultBase === "months" && eMajor.dem === "days")
      )
        defaultBase = eMajor.dem;
    }
  }

  if (eMin !== undefined && eMin < values[0]) {
    values.unshift(eMin);
  }
  if (eMax !== undefined && eMax > values[values.length - 1]) {
    values.push(eMax);
  }

  if (dims === null || dims === undefined) {
    // just for testing
    dims = {
      axis: {
        width: 1000,
        height: 30,
      },
    };
  }

  // generate all of the plotpoints
  // 1. base date
  for (let i = 0; i < values.length; i++) {
    values[i] = toBaseUnit(values[i], defaultBase, undefined /*date1904*/);
  }

  let axisDistance = horizontal ? dims.axis.width : dims.axis.height;
  let totalUnits = DateUtils.dateUnitDiff(
    DateUtils.fromExcelDate(values[0], undefined /*date1904*/),
    DateUtils.fromExcelDate(values[values.length - 1], undefined /*date1904*/),
    defaultBase
  );
  let unitsPerPadding = Math.max(1, totalUnits / axisDistance);
  // Now we need to find the total # of units to plot as a demoninator

  let valuesPlot = [];
  for (let i = 0; i < values.length - 1; i++) {
    let currentValue = values[i];
    let nextValue = values[i + 1];
    let distanceBetween = DateUtils.dateUnitDiff(
      DateUtils.fromExcelDate(currentValue, undefined /*date1904*/),
      DateUtils.fromExcelDate(nextValue, undefined /*date1904*/),
      defaultBase
    );

    valuesPlot.push(currentValue);
    let paddingAmount = 0;
    // place current value
    while (paddingAmount < distanceBetween - 1) {
      paddingAmount = paddingAmount + unitsPerPadding;
      valuesPlot.push(currentValue + paddingAmount);
    }
  }
  valuesPlot.push(values[values.length - 1]);

  // We now convert to dates so we can look for uniform days/years
  //     let valuesAsDates = [];
  //     for (let i = 0; i < values.length; i++) {
  //       valuesAsDates.push(DateUtils.fromExcelDate(values[i], false/*date1904*/));
  //     }
  //     console.log('valuesAsDates', valuesAsDates);
  // let valuesBased = [];
  // TODO - instead of generating a ton of ticks. It should
  //        only create values for each tick and then
  //        also create a set of weights.
  // We don't need this if/else but days are less computation (and there are generaly more so we special case)
  // if (defaultBase === 'days') {
  //    let nextDate = values[0];
  //    do {
  //       valuesBased.push(nextDate);
  //       nextDate = nextDate + 1;;
  //    } while (nextDate <= values[values.length - 1]);
  // } else {
  //     let boundStartDate = DateUtils.fromExcelDate(toBaseUnit(values[0], defaultBase));
  //     let boundEndDate = DateUtils.fromExcelDate(toBaseUnit(values[values.length - 1], defaultBase));
  //     let nextDate = boundStartDate;
  //     do {
  //       valuesBased.push(DateUtils.toExcelDate(nextDate));
  //       nextDate = DateUtils.addDateUnit(boundStartDate, 1 *  valuesBased.length, defaultBase);
  //     } while (nextDate <= boundEndDate);
  // }

  let fitTicksInfo = fitAxisDateTicks(
    valuesPlot[0],
    valuesPlot[valuesPlot.length - 1],
    eMinor,
    eMajor,
    eBase || defaultBase,
    dims,
    horizontal,
    labels
  );

  let retValue = {
    max: eMax !== undefined ? eMax : valuesPlot[valuesPlot.length - 1],
    min: eMin !== undefined ? eMin : valuesPlot[0],
    values: valuesPlot,
    baseUnitDates: eBase || defaultBase,
    majorUnitDates: fitTicksInfo.majorUnitDates,
    minorUnitDates: fitTicksInfo.minorUnitDates,
    majorTicks: fitTicksInfo.majorTicks,
    minorTicks: fitTicksInfo.minorTicks,
    rotation: fitTicksInfo.rotation,
  };
  retValue.autoZero = UnitCalcs.autoZero(retValue.min, retValue.max);
  return addCulledItems(retValue, crossBetween);
}
