/*

 Excel has the following items as a list 

 https://docs.microsoft.com/en-us/dotnet/api/documentformat.openxml.spreadsheet.numberingformats?view=openxml-2.8.1
*/

const ooxmlFormatCodes = [
  { formatCode: "General", formatType: "General", primary: 1 },
  {
    formatCode: "0",
    formatType: "Number",
    args: { precision: 0, separator: false },
  },
  {
    formatCode: "0.00",
    formatType: "Number",
    args: { precision: 0, comma: false },
  },
  {
    formatCode: "#,##0",
    formatType: "Number",
    args: { precision: 2, comma: true },
  },
  {
    formatCode: "#,##0.00",
    formatType: "Number",
    primary: 2,
    args: { precision: 2, comma: true },
  },
  {
    formatCode: "#,##0_);(#,##0)",
    formatType: "Number",
    args: { precision: 0, comma: true },
  },
  {
    formatCode: "#,##0_);[Red](#,##0)",
    formatType: "Number",
    args: { precision: 0, comma: true },
  },
  {
    formatCode: "#,##0.00_);(#,##0.00)",
    formatType: "Number",
    args: { precision: 2, comma: true },
  },
  {
    formatCode: "#,##0.00_);[Red](#,##0.00)",
    formatType: "Number",
    args: { precision: 2, comma: true },
  },
  {
    formatCode: "$#,##0.00",
    formatType: "Currency",
    primary: 3,
    args: { precision: 2, symbol: "$" },
  }, // Excel also does not have this in the list
  {
    formatCode: "$#,##0_);($#,##0)",
    formatType: "Currency",
    args: { precision: 0, symbol: "$" },
  },
  {
    formatCode: "$#,##0_);[Red]($#,##0)",
    formatType: "Currency",
    args: { precision: 0, symbol: "$" },
  },
  {
    formatCode: "$#,##0.00_);($#,##0.00)",
    formatType: "Currency",
    args: { precision: 2, symbol: "$" },
  },
  {
    formatCode: "$#,##0.00_);[Red]($#,##0.00)",
    formatType: "Currency",
    args: { precision: 2, symbol: "$" },
  },
  { formatCode: "0%", formatType: "Percentage", args: { precision: 0 } },
  {
    formatCode: "0.00%",
    formatType: "Percentage",
    primary: 7,
    args: { precision: 2 },
  },
  {
    formatCode: "0.00E+00",
    formatType: "Scientific",
    primary: 9,
    args: { precision: 2 },
  },
  { formatCode: "##0.0E+0", formatType: "Custom", args: { precision: 0 } }, // This seems wrong but is what excel does! (and excel online)
  { formatCode: "# ?/?", formatType: "Fraction", primary: 8 },
  { formatCode: "# ??/??", formatType: "Fraction" },
  { formatCode: "m/d/yyyy", formatType: "Date", primary: 5 }, // Excel has this as date but for us to find it we make it date
  { formatCode: "[$-x-systime]h:mm:ss AM/PM", formatType: "Time", primary: 6 }, // Excel doesn't have this one in it's list but for us to find it we make it date
  { formatCode: "d-mmm-yy", formatType: "Custom" },
  { formatCode: "d-mmm", formatType: "Custom" },
  { formatCode: "mmm-yy", formatType: "Custom" },
  { formatCode: "h:mm AM/PM", formatType: "Custom" },
  { formatCode: "h:mm:ss AM/PM", formatType: "Custom" },
  { formatCode: "h:mm", formatType: "Custom" },
  { formatCode: "h:mm:ss", formatType: "Custom" },
  { formatCode: "m/d/yyyy h:mm", formatType: "Custom" },
  { formatCode: "mm:ss", formatType: "Custom" },
  { formatCode: "mm:ss.0", formatType: "Custom" },
  { formatCode: "@", formatType: "Text", primary: 10 },
  { formatCode: "[h]:mm:ss", formatType: "Custom" }, // This also seems wrong
  {
    formatCode: '_($* #,##0_);_($* (#,##0);_($* "-"_);_(@_)',
    formatType: "Accounting",
  },
  {
    formatCode: '_(* #,##0_);_(* (#,##0);_(* "-"_);_(@_)',
    formatType: "Accounting",
  },
  {
    formatCode: '_($* #,##0.00_);_($* (#,##0.00);_($* "-"??_);_(@_)',
    primary: 4,
    formatType: "Accounting",
  },
  {
    formatCode: '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)',
    formatType: "Accounting",
  },
];

const additionalFormatCodes = [
  { formatCode: "00000", formatType: "Special", primary: 11 },
  { formatCode: "00000-0000", formatType: "Special" },
  { formatCode: "[<=9999999]###-####;(###) ###-####", formatType: "Special" },
  { formatCode: "000-00-0000", formatType: "Special" },
];

const mapFixedLookups = new Map();
for (let i = 0; i < ooxmlFormatCodes.length; i++) {
  let key = ooxmlFormatCodes[i].formatCode.toLowerCase();
  mapFixedLookups.set(key, ooxmlFormatCodes[i]);
}
for (let i = 0; i < additionalFormatCodes.length; i++) {
  let key = additionalFormatCodes[i].formatCode.toLowerCase();
  mapFixedLookups.set(key, additionalFormatCodes[i]);
}

const primaryFormatCodes = [];
mapFixedLookups.forEach(function (value, key, map) {
  if (value.primary) primaryFormatCodes.push(value);
});
primaryFormatCodes.sort(function (a, b) {
  return a.primary - b.primary;
});

export function lookupPrimaryFormatCodes() {
  return primaryFormatCodes;
}

// TODO - numbers have decimals points so these need to be pulled out
// TODO - current symbol but seems to be saved as a $?
// TODO - when matching excel is case insensitive (since 'Red')
// We can be smarter. Office will store additional
export function lookupFormatType(formatCode) {
  // If the formatCode matches a found code we return. (This is case insentitive)

  let found = mapFixedLookups.get(formatCode);
  if (found) return found;

  // TODO - look for custom definitions in the current document (this is what office does)

  // try to match a reg ex
  // Create regex of numbers
  // Create regex for currencies. (similiar to number with comma except it has a space at the beginning)
  // Create regex for accounting (exactly the same)
  return {
    type: "custom",
  };
}
