const _cache = {};

export function getFontMetrics(text, size, family) {
  if (typeof window !== "undefined" && !_cache.contextMeasure) {
    _cache.canvasMeasure = document ? document.createElement("canvas") : null;
    _cache.contextMeasure = _cache.canvasMeasure.getContext("2d");
  }

  const fontSize = size || 12;
  const fontFamily = family || "arial";

  let metrics = {};
  if (_cache.contextMeasure) {
    _cache.contextMeasure.font = fontSize + "px " + fontFamily;
    metrics = _cache.contextMeasure.measureText(text);
  } else {
    metrics.width = 0;
  }
  metrics.height = fontSize;
  return metrics;
}
