import CommonUtils from "./CommonUtils";
import * as FontUtils from "./FontUtils";
import * as GeomUtils from "./GeomUtils";
import SSF from "./10_ssf";

import * as TextUtils from "../models/text/TextUtils";

import { adjustForDisplayUnit } from "./ChartUtils";

const PERC = 0.0000000001;
export function adjust(object, key, value, func) {
  if (value === undefined) return;

  value = CommonUtils.asNumber(value);

  if (object[key] === undefined) {
    object[key] = value;
    return;
  }

  object[key] = func(object[key], value);
}

/*
 * mimic the way that excel calculates the major value
 */
export function calcMajorUnitValue(range) {
  if (range === 0) return 0;

  let absRange = Math.abs(range);
  let sigFigs = Math.log10(absRange);
  let absSigFigs = Math.floor(sigFigs);

  let observeValue = range / Math.pow(10, absSigFigs);
  let factor = 1;
  if (observeValue <= 2) {
    factor = Math.ceil(observeValue) * 0.1;
  } else if (observeValue > 2 && observeValue <= 5) {
    factor = 0.5;
  }

  let retValue = Math.pow(10, absSigFigs) * Math.sign(range) * factor;
  return retValue;
}

export function calcMinorUnitValue(majorUnit) {
  return majorUnit / 5;
}

/*
 */
export function buildLogTicks(units, explictUnits, minAxisValue, maxAxisValue) {
  // build ticks
  let ticks = [];
  let nextTick = minAxisValue;
  while (nextTick <= maxAxisValue) {
    ticks.push(nextTick);
    nextTick = CommonUtils.roundAccurately(
      Math.pow(units, ticks.length) * minAxisValue,
      16
    );
  }
  // special case
  if (ticks.length <= 2 && explictUnits === undefined)
    ticks = [minAxisValue, maxAxisValue];
  return ticks;
}

export function buildMinorLogTicks(
  unitsMinor,
  unitsMinorExplicit,
  minAxisValue,
  maxAxisValue
) {
  let ticksMinorIntervals = buildLogTicks(
    unitsMinor,
    unitsMinorExplicit,
    minAxisValue,
    maxAxisValue
  );

  let ticksMinor = [];
  for (let i = 0; i < ticksMinorIntervals.length; i++) {
    let currentMajorTick = ticksMinorIntervals[i];
    let nextMajorTick = CommonUtils.roundAccurately(
      Math.pow(
        unitsMinor,
        Math.log(currentMajorTick) / Math.log(unitsMinor) + 1
      ),
      16
    );
    let nextMinorTick = currentMajorTick;
    let minorTickIter = 0;
    while (
      nextMajorTick - nextMinorTick > PERC &&
      maxAxisValue - nextMinorTick > PERC
    ) {
      nextMinorTick = CommonUtils.roundAccurately(
        (nextMajorTick / 10) * (minorTickIter + 1),
        16
      );
      minorTickIter++;
      if (
        nextMinorTick - currentMajorTick > PERC &&
        nextMajorTick - nextMinorTick > PERC &&
        maxAxisValue - nextMinorTick > PERC
      )
        ticksMinor.push(nextMinorTick);
    }
    nextMinorTick = nextMajorTick;
  }

  // special case where if the major tick is not on the max/or min we add a minortick
  //     if (ticksMajor[0] !== minAxisValue && ticksMinor[0] !== minAxisValue) {
  //         ticksMinor.unshift(minAxisValue);
  //     }
  if (
    Math.abs(
      ticksMinorIntervals[ticksMinorIntervals.length - 1] - maxAxisValue
    ) > PERC &&
    Math.abs(ticksMinor[ticksMinor.length - 1] - maxAxisValue) > PERC
  ) {
    ticksMinor.push(maxAxisValue);
  }

  return ticksMinor;
}

export function fitMajorUnitLog(
  limitsNew,
  labelDistance,
  axisDistance,
  minTicks = 2
) {
  let logBaseCurrent = limitsNew.logBase;
  let iter = 0;
  while (true) {
    let logBaseTest = Math.pow(logBaseCurrent, iter + 1);
    let ticks = buildLogTicks(
      logBaseTest,
      limitsNew.explicitMajorUnits,
      limitsNew.min,
      limitsNew.max
    );
    if (ticks.length <= minTicks || logBaseTest >= 1000) return logBaseTest;
    let fits = labelDistance < axisDistance / (ticks.length || 1);
    if (fits) return logBaseTest;
    iter++;
  }
}

export function cullLogToFit(horizontal, labels, limits, dimensions) {
  let limitsNew = { ...limits };
  // If the explicit Major units is defined we can't cull.
  if (limitsNew.explicitMajorUnits !== null) return limitsNew;

  let axisDistance = horizontal
    ? dimensions.axis.width
    : dimensions.axis.height; // - 8;

  // OOXML has looks at the largest value (or 1million in the case of percent) to see if they values fit and then rotate by 2, 5, or 10.
  let metrics = getMetricsForLabel(limitsNew.max, labels, horizontal);

  // TODO - test rotation
  //metrics.width = calcDistanceNeededAtAxis(metrics.width, metrics.height, axisModel.labels.rotation || 0);
  let labelDistance = horizontal ? metrics.width : metrics.height;

  // major ticks
  if (
    limitsNew.explictMajorUnits &&
    limitsNew.explictMajorUnits < limitsNew.logBase
  )
    limitsNew.explictMajorUnits = limitsNew.logBase;
  if (
    limitsNew.explicitMinorUnits &&
    limitsNew.explicitMinorUnits < limitsNew.logBase
  )
    limitsNew.explicitMinorUnits = limitsNew.logBase;

  let majorUnitFit =
    limitsNew.explictMajorUnits ||
    fitMajorUnitLog(limitsNew, labelDistance, axisDistance);
  if (!limitsNew.majorTicks || limitsNew.majorUnits !== majorUnitFit) {
    limitsNew.majorUnits = majorUnitFit;
    limitsNew.majorTicks = buildLogTicks(
      limitsNew.majorUnits,
      limitsNew.explictMajorUnits,
      limitsNew.min,
      limitsNew.max
    );
    if (limitsNew.explicitMinorUnits === null)
      limitsNew.minorUnits = limitsNew.majorUnits;
  }

  // minor ticks
  // Note - In Excel If you graph 1 - 10000 if shows two minorgrid intervals.
  //        We can approximate this by setting the minCullsize to 3;
  //        This seems like an excel bug.
  let minCullsize = undefined;
  let distance =
    logX(limitsNew.max, limitsNew.logBase) -
    logX(limitsNew.min, limitsNew.logBase);
  if (distance >= 4) minCullsize = 3;
  let minorUnitFit =
    limitsNew.explicitMinorUnits ||
    limitsNew.explicitMajorUnits ||
    fitMajorUnitLog(limitsNew, labelDistance, axisDistance, minCullsize);
  if (!limitsNew.minorTicks || limitsNew.minorUnits !== minorUnitFit) {
    limitsNew.minorUnits = minorUnitFit;
    limitsNew.minorTicks = buildMinorLogTicks(
      limitsNew.minorUnits,
      limitsNew.explicitMinorUnits,
      limitsNew.min,
      limitsNew.max
    );
  }
  limitsNew.ticks = limitsNew.majorTicks;
  limitsNew.labelTicks = limitsNew.majorTicks;

  return limitsNew;
}

/**
  minIn, maxIn must be the values of the original units. (Not the log values)
*/
export function calcAxisLimitsLog(
  minIn = 1,
  maxIn = 1,
  explicitMin,
  explicitMax,
  explictMinorUnits,
  explictMajorUnits,
  baseUnits = 10,
  generateTicks = true
) {
  minIn = Number(minIn);
  maxIn = Number(maxIn);

  explicitMin = CommonUtils.isDefined(explicitMin)
    ? Number(explicitMin)
    : undefined;
  explicitMax = CommonUtils.isDefined(explicitMax)
    ? Number(explicitMax)
    : undefined;
  explictMajorUnits = CommonUtils.isDefined(explictMajorUnits)
    ? Number(explictMajorUnits)
    : undefined;
  // 0 majorUnits are not allowed
  if (explictMajorUnits !== undefined && explictMajorUnits < baseUnits)
    explictMajorUnits = undefined;

  explictMinorUnits = CommonUtils.isDefined(explictMinorUnits)
    ? Number(explictMinorUnits)
    : explictMajorUnits;
  // 0 minorUnits are not allowed
  if (explictMinorUnits !== undefined && explictMinorUnits < baseUnits)
    explictMinorUnits = undefined;

  if (minIn > maxIn) throw new Error("minIn is greater than maxIn");
  if (minIn <= 0) {
    throw new Error("minValue for log limits must be positive", minIn);
  }

  // If explictMin > explictMax we clear explictMin
  if (
    CommonUtils.isDefined(explicitMin) &&
    CommonUtils.isDefined(explicitMax) &&
    explicitMin >= explicitMax
  )
    explicitMin = undefined;

  let unitsMajor = CommonUtils.isDefined(explictMajorUnits)
    ? explictMajorUnits
    : baseUnits;
  let unitsMinor = CommonUtils.isDefined(explictMinorUnits)
    ? explictMinorUnits
    : baseUnits;
  let minValue = CommonUtils.isDefined(explicitMin) ? explicitMin : minIn;
  let maxValue = CommonUtils.isDefined(explicitMax) ? explicitMax : maxIn;

  let minAxisValue;
  let maxAxisValue;

  if (unitsMajor < baseUnits) unitsMajor = baseUnits;
  if (unitsMinor < baseUnits) unitsMinor = baseUnits;

  // Go towards min.
  // Find min
  if (CommonUtils.isDefined(explicitMin)) {
    minAxisValue = Number(explicitMin);
  } else {
    minAxisValue = 1;
    while (minAxisValue > minValue) {
      minAxisValue = CommonUtils.roundAccurately(minAxisValue / baseUnits, 16);
    }
  }

  // Go towards max
  // Find max
  let iterCountMaxAxis = 0;
  if (CommonUtils.isDefined(explicitMax)) {
    maxAxisValue = Number(explicitMax);
  } else {
    maxAxisValue = 1;
    while (maxAxisValue < Math.max(minValue, maxValue)) {
      // This can happen with explict mins
      maxAxisValue = CommonUtils.roundAccurately(
        Math.pow(baseUnits, iterCountMaxAxis) * 1,
        16
      );
      iterCountMaxAxis++;
    }
  }
  // If the minValue and the maxCalcValue are the same.
  if (minAxisValue === maxAxisValue) {
    maxAxisValue = minValue * baseUnits;
  }

  let retValue = {
    min: CommonUtils.roundAccurately(minAxisValue, 16),
    max: CommonUtils.roundAccurately(maxAxisValue, 16),
    majorUnits: CommonUtils.roundAccurately(unitsMajor, 16),
    minorUnits: CommonUtils.roundAccurately(unitsMinor, 16),
  };

  if (generateTicks) {
    retValue.majorTicks = buildLogTicks(
      unitsMajor,
      explictMajorUnits,
      minAxisValue,
      maxAxisValue
    );
    retValue.minorTicks = buildMinorLogTicks(
      unitsMinor,
      explictMinorUnits,
      minAxisValue,
      maxAxisValue
    );
    retValue.ticks = retValue.majorTicks;
    retValue.labelTicks = retValue.majorTicks;
  }

  // Log always use 1 for autoZero
  retValue.autoZero = 1;
  return retValue;
}

/*
 * Logic was based on:
   https://web.archive.org/web/20120129154714/http://support.microsoft.com/kb/214075
   https://peltiertech.com/how-excel-calculates-automatic-chart-axis-limits/
*/
export function calcAxisLimitsLinear(
  minIn,
  maxIn,
  explicitMin,
  explicitMax,
  explicitMinorUnits,
  explicitMajorUnits,
  isScatterOrBubble
) {
  if (!CommonUtils.isDefined(minIn)) minIn = 0;
  if (!CommonUtils.isDefined(maxIn)) maxIn = 1;

  //     if ( || !CommonUtils.isDefined(maxIn)) {
  //         return {
  //             min: 0,
  //             max: 1,
  //             majorUnits: 0.2,
  //             minorUnits: 0.2 / 5
  //         }
  //     }

  minIn = Number(minIn);
  maxIn = Number(maxIn);

  explicitMin = CommonUtils.isDefined(explicitMin)
    ? Number(explicitMin)
    : undefined;
  explicitMax = CommonUtils.isDefined(explicitMax)
    ? Number(explicitMax)
    : undefined;
  explicitMinorUnits = CommonUtils.isDefined(explicitMinorUnits)
    ? Number(explicitMinorUnits)
    : undefined;
  explicitMajorUnits = CommonUtils.isDefined(explicitMajorUnits)
    ? Number(explicitMajorUnits)
    : undefined;

  if (minIn > maxIn) throw new Error("minIn is greater than maxIn");
  if (explicitMin > explicitMax)
    throw new Error("explicitMin is greater than explicitMax");

  let min = minIn;
  let max = maxIn;

  // 0 majorUnits/minorUnits are not allowed
  if (explicitMajorUnits <= 0) explicitMajorUnits = undefined;
  if (explicitMinorUnits <= 0) explicitMinorUnits = undefined;

  if (
    CommonUtils.isDefined(explicitMin) &&
    CommonUtils.isDefined(explicitMax) &&
    explicitMin >= explicitMax
  )
    explicitMin = undefined;

  if (CommonUtils.isDefined(explicitMin)) min = explicitMin;
  if (CommonUtils.isDefined(explicitMax)) max = explicitMax;

  // Note - Based on some trial and error it seems that excel/ppt use the adjust max for calcing major unit
  let minMajorUnits = min;
  let minAxis = min;

  let maxMajorUnits = max;
  let maxAxis = max;

  if (minIn === maxIn && maxIn === 0) {
    max =
      maxMajorUnits =
      explicitMax =
        CommonUtils.isDefined(explicitMax) ? explicitMax : 1;
  }

  if (maxIn >= 0 && minIn >= 0) {
    // scenario 1 - yMax and yMin values are both positive or equal to zero.

    // check for boundary conditions where explicitValues are outside the value range
    if (explicitMax === 0) {
      maxAxis = maxMajorUnits = 0;
      minAxis = minMajorUnits = -1;
    } else if (explicitMax <= min) {
      maxAxis = maxMajorUnits = explicitMax;
      let adjustedLimit = calcAxisLimitsLinear(
        0,
        Math.abs(explicitMax),
        0,
        Math.abs(explicitMax),
        explicitMinorUnits,
        explicitMajorUnits,
        isScatterOrBubble
      );
      minAxis = minMajorUnits =
        explicitMax -
        Math.ceil(Math.abs(explicitMax) / adjustedLimit.majorUnits) *
          adjustedLimit.majorUnits;
    } else if (explicitMin >= max) {
      minAxis = minMajorUnits = explicitMin;
      let adjustedLimit = calcAxisLimitsLinear(
        0,
        Math.abs(explicitMin),
        0,
        Math.abs(explicitMin),
        explicitMinorUnits,
        explicitMajorUnits,
        isScatterOrBubble
      );
      explicitMax =
        maxAxis =
        maxMajorUnits =
          explicitMin +
          Math.ceil(Math.abs(explicitMin) / adjustedLimit.majorUnits) *
            adjustedLimit.majorUnits;
    } else if (CommonUtils.isDefined(explicitMin)) {
      minMajorUnits = minAxis = explicitMin;
    } else if (
      ((max - min) / max) * 100 > 16.667 - 0.001 ||
      maxIn - minIn === 0 ||
      min === 0
    ) {
      minMajorUnits = minAxis = 0;
    } else {
      minMajorUnits = Math.max(0, min - (maxMajorUnits - min) / 2);
      if (!isScatterOrBubble) minAxis = Math.max(0, min - (max - min) / 2);
    }
    if (!CommonUtils.isDefined(explicitMax)) {
      maxMajorUnits = maxAxis = max + 0.05 * (max - minAxis);
    }
  } else if (minIn <= 0 && maxIn <= 0) {
    // scenario 2 - yMax and yMin values are both negative or equal to zero.

    // check for boundary conditions where explicitValues are outside the value range
    if (explicitMin === 0) {
      maxAxis = maxMajorUnits = 1;
      minAxis = minMajorUnits = 0;
    } else if (explicitMax <= min) {
      maxAxis = maxMajorUnits = explicitMax;
      let adjustedLimit = calcAxisLimitsLinear(
        0,
        Math.abs(explicitMax),
        0,
        Math.abs(explicitMax),
        explicitMinorUnits,
        explicitMajorUnits,
        isScatterOrBubble
      );
      explicitMin =
        minAxis =
        minMajorUnits =
          explicitMax -
          Math.ceil(Math.abs(explicitMax) / adjustedLimit.majorUnits) *
            adjustedLimit.majorUnits;
    } else if (explicitMin >= max) {
      minAxis = minMajorUnits = explicitMin;
      let adjustedLimit = calcAxisLimitsLinear(
        0,
        Math.abs(explicitMin),
        0,
        Math.abs(explicitMin),
        explicitMinorUnits,
        explicitMajorUnits,
        isScatterOrBubble
      );
      maxAxis = maxMajorUnits =
        explicitMin +
        Math.ceil(Math.abs(explicitMin) / adjustedLimit.majorUnits) *
          adjustedLimit.majorUnits;
    } else if (CommonUtils.isDefined(explicitMax)) {
      maxMajorUnits = maxAxis = explicitMax;
    } else if (
      ((min - max) / min) * 100 > 16.667 - 0.001 ||
      maxIn - minIn === 0 ||
      max === 0
    ) {
      maxMajorUnits = maxAxis = 0;
    } else {
      maxMajorUnits = Math.min(0, max - (minMajorUnits - max) / 2);
      if (!isScatterOrBubble) maxAxis = Math.min(0, max - (min - max) / 2);
    }
    if (!CommonUtils.isDefined(explicitMin)) {
      minMajorUnits = minAxis = min + 0.05 * (min - maxAxis);
    }
  } else {
    // scenario 3 - yMax value is positive, and the yMin value is negative.
    if (explicitMin >= max) {
      minAxis = minMajorUnits = explicitMin;
      let adjustedLimit = calcAxisLimitsLinear(
        0,
        Math.abs(explicitMin),
        0,
        Math.abs(explicitMin),
        explicitMinorUnits,
        explicitMajorUnits,
        isScatterOrBubble
      );
      explicitMax =
        maxAxis =
        maxMajorUnits =
          explicitMin +
          Math.ceil(Math.abs(explicitMin) / adjustedLimit.majorUnits) *
            adjustedLimit.majorUnits;
    } else if (explicitMax <= min) {
      maxAxis = maxMajorUnits = explicitMax;
      let adjustedLimit = calcAxisLimitsLinear(
        0,
        Math.abs(explicitMax),
        0,
        Math.abs(explicitMax),
        explicitMinorUnits,
        explicitMajorUnits,
        isScatterOrBubble
      );
      explicitMin =
        minAxis =
        minMajorUnits =
          explicitMax -
          Math.ceil(Math.abs(explicitMax) / adjustedLimit.majorUnits) *
            adjustedLimit.majorUnits;
    }
    if (!CommonUtils.isDefined(explicitMax))
      maxMajorUnits = maxAxis = max + 0.05 * (max - min);
    if (!CommonUtils.isDefined(explicitMin))
      minMajorUnits = minAxis = min + 0.05 * (min - max);
  }

  let majorUnits = explicitMajorUnits;
  if (!CommonUtils.isDefined(majorUnits))
    majorUnits = calcMajorUnitValue(maxMajorUnits - minMajorUnits);
  let minorUnits = explicitMinorUnits;
  if (!CommonUtils.isDefined(minorUnits))
    minorUnits = calcMinorUnitValue(majorUnits);

  // find highest and lowest major unit
  let ticks = buildLinearTicks(
    explicitMin,
    explicitMax,
    majorUnits,
    minIn,
    maxIn,
    minAxis,
    maxAxis
  );

  let retValue = {
    min: CommonUtils.isDefined(explicitMin) ? explicitMin : ticks[0],
    max: CommonUtils.isDefined(explicitMax)
      ? explicitMax
      : ticks[ticks.length - 1],
    majorUnits: majorUnits,
    minorUnits: minorUnits,
  };

  retValue.autoZero = autoZero(retValue.min, retValue.max);
  return retValue;
}

export function fitMajorUnitLinear(
  limitsNew,
  labelDistance,
  axisDistance,
  factors = [1, 2, 5]
) {
  // do scatters have 1, 2, 4, 5?
  let majorUnitsModel = limitsNew.majorUnits;
  let majorUnitsLargest = majorUnitsModel;

  let iter = 0;
  while (true) {
    for (let i = 0; i < factors.length; i++) {
      let majorUnitsTesting = majorUnitsModel * factors[i] * Math.pow(10, iter);
      let ticks = buildLinearTicks(
        limitsNew.min,
        limitsNew.max,
        majorUnitsTesting
      );
      if (ticks <= 2) {
        return majorUnitsLargest;
      } else if (iter > 50) {
        debugger;
        return 1;
      }
      let fits = labelDistance < axisDistance / (ticks.length || 1);
      if (fits) return majorUnitsTesting;
      majorUnitsLargest = majorUnitsTesting;
    }
    iter++;
  }
}

/*
    This takes an a limit, and dimensions and updates the limits and chartType then updates the axispoints
*/
// TODO - This is also calculating the bubbleSize twice. This should be an input but for now....
export function adjustAxisForPointRadius(chartType, limits, dimensions) {
  if (!dimensions) return limits;
  let type = chartType.type;
  if (type !== "scatter" && type !== "bubble" && type !== "bubble3d")
    return limits;

  let newLimits = { ...limits };
  let plotSizingDistance = Math.min(
    dimensions.plotArea.width,
    dimensions.plotArea.height
  );
  let pixelsRadius = 5.01;
  if (type === "scatter") {
    pixelsRadius = 5.01; // power point seems to have this hardcoded to 5. They also DONT shift it 0-5
  } else {
    // a type of bubble
    // TODO - maxAbs & minAbs needs to be calculated on ScatterChartType as a read only value.
    let bubbleMaxSize = chartType.maxAbsSize;
    let bubbleScale = chartType.bubbleScale;
    pixelsRadius = (plotSizingDistance / 2) * 0.25;
    if (bubbleScale !== 100) {
      let area = Math.PI * pixelsRadius * pixelsRadius;
      area = (area * bubbleScale) / 100;
      pixelsRadius = Math.sqrt(area / Math.PI);
      // No idea what powerpoint does here. Also seems to change
      /*

            scale-radius-diameter
            300    1-2
            275    0.96-1.92
            250    0.91-1.82
            225    0.86-1.72
            200    0.8    1.6
            175    0.75-1.5
            150    0.67-1.34
            125    0.59-1.18
            100    0.5    1
            90    0.45-0.9
            80    0.41-0.82
            70    0.37-0.74
            60    0.33-0.66
            50    0.28-0.56
            40    0.23-0.46
            30    0.18-0.36
            20    0.11-0.22
            10    0.06-0.12
*/
    }
    if (bubbleMaxSize) {
      let ratio = pixelsRadius / (bubbleMaxSize || 1);

      // We adjust the values
      // We could do this directly on the data but anychart handles this for us and we would have to reload.
      newLimits.maxBubbleSize = bubbleMaxSize * ratio;
      // TODO - maxAbs & minAbs needs to be calculated on ScatterChartType as a read only value.
      let bubbleMinSize = chartType.minAbsSize;
      if (chartType.bubbleSizeRepresentsWidth) {
        newLimits.minBubbleSize = bubbleMinSize * ratio;
      } else {
        let areaRatio =
          bubbleMaxSize / (bubbleMaxSize * ratio * (bubbleMaxSize * ratio));
        newLimits.minBubbleSize = Math.sqrt(bubbleMinSize / areaRatio);
      }
    }
  }

  let ticks = buildLinearTicks(limits.min, limits.max, limits.majorUnits);

  let pixelPerTick = plotSizingDistance / (ticks.length || 1);
  let unitsNeeded = (limits.majorUnits / pixelPerTick) * pixelsRadius;

  if (newLimits.maxVal - unitsNeeded > 0 || type !== "scatter") {
    // OOXML doesn't overflow scatters with 5 pixels of 0
    while (newLimits.maxVal + unitsNeeded > ticks[ticks.length - 1]) {
      ticks.push(ticks[ticks.length - 1] + newLimits.majorUnits);
    }
    newLimits.max = ticks[ticks.length - 1];
  }

  if (newLimits.minVal - unitsNeeded > 0 || type !== "scatter")
    // OOXML doesn't overflow scatters with 5 pixels of 0
    while (newLimits.minVal - unitsNeeded < newLimits.min) {
      newLimits.min = newLimits.minVal - newLimits.majorUnits;
    }

  let updatedMajorUnits = newLimits.majorUnits; //calcMajorUnitValue(newLimits.max - newLimits.min);
  // update ticks again
  ticks = buildLinearTicks(
    null,
    null,
    updatedMajorUnits,
    newLimits.min,
    newLimits.max,
    newLimits.min,
    newLimits.max
  );
  newLimits.min = Math.min(ticks[0], ticks[ticks.length - 1]);
  newLimits.max = Math.max(ticks[0], ticks[ticks.length - 1]);
  newLimits.majorUnits = updatedMajorUnits;
  newLimits.minorUnits = calcMinorUnitValue(updatedMajorUnits);

  return newLimits;
}

export function getMetricsForLabel(
  valueToFormat,
  labels,
  isHorizontal,
  vFactor = 1.2
) {
  let fontSize = (labels.text.size || 12) * TextUtils.fontScale();
  let fontFamily = labels.text.font.name || 'Calibri';
  let metrics = {
    width: 0,
    height: fontSize * vFactor, // Note - getFontMetrics is not returning the height so we hardcoded
  };
  try {
    if (labels.axisShape.getProperty("displayUnits")) {
      valueToFormat = adjustForDisplayUnit(
        valueToFormat,
        labels.axisShape.displayUnits
      );
    }
    let formatedValue = SSF.format(labels.formatCode, valueToFormat);
    metrics.width = FontUtils.getFontMetrics(
      formatedValue,
      fontSize,
      fontFamily
    ).width;
  } catch (error) {
    console.warn("Can't format '" + valueToFormat + "'.", error);
  }
  let rect = labels.text.insets;
  if (isHorizontal) {
    metrics.width += rect.left + rect.right;
  } else {
    metrics.height += rect.top + rect.bottom;
  }
  return metrics;
}

/* interval must be a positive int */
export function generateMajorOrdTicks(start, end, interval = 1) {
  let majorTicks = [];
  for (let i = start; i <= end; i = i + interval) {
    majorTicks.push(i);
  }
  //     let ticksInterval = [];
  //     let ticksOffset = 0;
  //     do {
  //         ticksInterval.push(ticks[ticksOffset]);
  //         ticksOffset += interval;
  //     } while (ticksOffset < ticks.length);
  return majorTicks;
}

export function cullLinearToFit(
  grouping,
  chartType,
  horizontal,
  labels,
  limits,
  dimensions
) {
  let limitsNew = { ...limits };

  let axisDistance = horizontal
    ? dimensions.axis.width
    : dimensions.axis.height; // - 8;

  // If the explicit Major units is defined we can't cull.
  if (limitsNew.explicitMajorUnits !== null || axisDistance <= 0)
    return limitsNew;

  //let ticks = buildLinearTicks(limitsNew.min, limitsNew.max, limitsNew.majorUnits);

  // OOXML has looks at the largest value (or 1million in the case of percent) to see if they values fit and then rotate by 2, 5, or 10.
  let metrics = getMetricsForLabel(limitsNew.max, labels, horizontal);

  // TODO - test rotation
  //metrics.width = calcDistanceNeededAtAxis(metrics.width, metrics.height, axisModel.labels.rotation || 0);
  let labelDistance = horizontal ? metrics.width : metrics.height;

  let majorUnitsFits = fitMajorUnitLinear(
    limitsNew,
    labelDistance,
    axisDistance
  );

  let axisLimits;
  if (grouping === "percentStacked") {
    axisLimits = calcAxisLimitsPercent(
      limitsNew.minVal,
      limitsNew.maxVal,
      limitsNew.explicitMin,
      limitsNew.explicitMax,
      limitsNew.explicitMinorUnits,
      majorUnitsFits
    );
  } else {
    let isScatter =
      chartType === "scatter" ||
      chartType === "bubble" ||
      chartType === "bubble3d";
    axisLimits = calcAxisLimitsLinear(
      limitsNew.minVal,
      limitsNew.maxVal,
      limitsNew.explicitMin,
      limitsNew.explicitMax,
      limitsNew.explicitMinorUnits,
      majorUnitsFits,
      isScatter
    );
  }

  limitsNew = Object.assign(limitsNew, axisLimits);
  return limitsNew;
}

export function calcAxisLimitsPercent(
  minIn,
  maxIn,
  explicitMin,
  explicitMax,
  explicitMinorUnits,
  explicitMajorUnits
) {
  let maxMajorUnits = CommonUtils.isDefined(explicitMax) ? explicitMax : maxIn;
  let minMajorUnits = CommonUtils.isDefined(explicitMin) ? explicitMin : minIn;

  let majorUnits = explicitMajorUnits;
  if (!CommonUtils.isDefined(majorUnits))
    majorUnits = calcMajorUnitValue(maxMajorUnits - minMajorUnits);
  let minorUnits = explicitMinorUnits;
  if (!CommonUtils.isDefined(minorUnits))
    minorUnits = calcMinorUnitValue(majorUnits);

  let ticks = buildLinearTicks(
    explicitMin,
    explicitMax,
    majorUnits,
    minIn,
    maxIn,
    minIn,
    maxIn
  );

  let retValue = {
    min: CommonUtils.isDefined(explicitMin) ? explicitMin : ticks[0],
    max: CommonUtils.isDefined(explicitMax)
      ? explicitMax
      : ticks[ticks.length - 1],
    majorUnits: majorUnits,
    minorUnits: minorUnits,
  };

  retValue.autoZero = autoZero(retValue.min, retValue.max);
  return retValue;
}

export function autoZero(min, max) {
  if (min > 0)
    // all values above zero
    return min;
  if (max < 0)
    // all values below zero (return max because we are trying to get closes to zero)
    return 0;
  return 0; // we are either on zero or crossing zero
}

export function buildLinearTicks(
  explicitMin,
  explicitMax,
  majorUnits,
  minIn,
  maxIn,
  minAxis,
  maxAxis
) {
  maxAxis = CommonUtils.roundAccurately(maxAxis, 5);
  minAxis = CommonUtils.roundAccurately(minAxis, 5);
  let ticks = [];
  if (
    CommonUtils.isDefined(explicitMax) &&
    !CommonUtils.isDefined(explicitMin)
  ) {
    let nextTick = explicitMax;
    do {
      nextTick = CommonUtils.roundAccurately(
        explicitMax - majorUnits * ticks.length,
        5
      );
      ticks.unshift(nextTick);
    } while (nextTick > minAxis);
  } else {
    if (CommonUtils.isDefined(explicitMin)) {
      minAxis = explicitMin;
    } else {
      let boundry = CommonUtils.roundAccurately(minAxis % majorUnits, 5);
      if (boundry !== 0 && Math.abs(boundry - majorUnits) > 0.0002) {
        minAxis = minAxis - boundry;
        if (minIn < 0)
          // We extend downward for negative values
          minAxis = minAxis - majorUnits;
      }
    }

    if (CommonUtils.isDefined(explicitMax)) {
      maxAxis = explicitMax;
    } else {
      let adjustingMaxAxis = CommonUtils.roundAccurately(
        CommonUtils.isDefined(explicitMax) ? explicitMax : minAxis,
        5
      );
      while (adjustingMaxAxis < maxAxis)
        adjustingMaxAxis = CommonUtils.roundAccurately(
          adjustingMaxAxis + majorUnits,
          5
        );
      maxAxis = adjustingMaxAxis;

      // let boundry = ((maxAxis - (explicitMin || 0)) % majorUnits);
      // if (boundry !== 0 && Math.abs(boundry - majorUnits) > 0.0002) {
      //     maxAxis = maxAxis - boundry;
      //     if (maxIn > 0)
      //         maxAxis = maxAxis + majorUnits;
      // }
    }
    if (maxAxis === minAxis) return [maxAxis];

    let nextTick = minAxis;
    while (nextTick <= maxAxis) {
      ticks.push(nextTick);
      nextTick = CommonUtils.roundAccurately(nextTick + majorUnits, 5);
    }
  }

  return ticks;
}

export function logX(num, logBase) {
  return Math.log(num) / Math.log(logBase);
}

/*
   Labels can overlap.

   To solve this we:

   1. find the four points of a rect. (against 0,0)
   2. rotate them
   3. for each line find where it intersets the axis
   4. Return the maximum interset value * 2
*/
export function calcDistanceNeededAtAxis(width, height, deg) {
  let x1 = -width / 2;
  let y1 = -height / 2;
  let x2 = width / 2;
  let y2 = height / 2;

  let tl = GeomUtils.rotatePoint(x1, y2, deg);
  let bl = GeomUtils.rotatePoint(x1, y1, deg);
  let tr = GeomUtils.rotatePoint(x2, y2, deg);
  let br = GeomUtils.rotatePoint(x2, y1, deg);

  let minMax = {
    min: 0,
    max: 0,
  };
  minMax = GeomUtils.findIntersectMath(tl, bl, minMax.min, minMax.max);
  minMax = GeomUtils.findIntersectMath(bl, br, minMax.min, minMax.max);
  minMax = GeomUtils.findIntersectMath(br, tr, minMax.min, minMax.max);
  minMax = GeomUtils.findIntersectMath(tr, tl, minMax.min, minMax.max);

  return minMax.max - minMax.min;
}

export function calcMaxLabels(
  totalWidth,
  labelWidth,
  labelHeight,
  labelRotation
) {
  let maxUnits =
    totalWidth /
    calcDistanceNeededAtAxis(labelWidth, labelHeight, labelRotation || 1);
  // If rotation then labels can 'stack' so we remove one since there will be space before the first and after the last.
  if ((labelRotation || 0) % 90 !== 0) maxUnits--;
  return Math.floor(maxUnits);
}
