import { isSingleSeries } from "../../utils/ChartUtils";

import { ManualLayoutPersister, manualLayoutSetter } from "../primitives/Coordinates";

import ChartDataLabelShape from "./ChartDataLabelShape";
import ChartPartShape from "./ChartPartShape";

class ChartLegendShape extends ChartPartShape {
  constructor(options) {
    super(options, "legend");
    const _self = this;

    let _chartType = options.chartType;
    this.addProperty("chartType", {
      isReadOnly: true,
      isTransient: true,
      defaultValue: function () {
        return _chartType;
      },
    });

    this.overrideProperty("shown", {
      defaultValue: function (seriesLength, mainType, types, series0ChartType) {
        return seriesLength !== 1 || isSingleSeries(mainType);
        //            if (chartTypes.length === 1 &&
        //(isSingleSeries(chartTypes.getAt(0).type) || chartModel.series.length === 1 && chartTypes.getAt(0).varyColors))
      },
      inputs: [
        "chartShape.series.length",
        "chartShape.mainType",
        "chartShape.types[*].type",
      ], //, 'chartShape.series[0].chartType.type']
    });

    this.addProperty("position", { defaultValue: "b" });
    this.addProperty("overlay", { defaultValue: false });

    /**
     * This is implements as a rect (x,y,width,height) with percentanges of the containing elmement.
     * If null then no manualLayout is applied
     * Note -
     * This is to spec see :
     * https://docs.microsoft.com/en-us/openspecs/office_standards/ms-oi29500/bb8565e7-dfea-453f-868b-ae680ab8a823?redirectedfrom=MSDN
     */
    this.addProperty("manualLayout", {
      setValue: manualLayoutSetter,
      defaultValue: function () {
        return null;
      },
      persister: new ManualLayoutPersister(),
    });

    // Legend Entries are really textShapes (not even a full shape, just the textshape)
    // Figure out. I think they get their values from the series and then the legend (see how ppt does this)
    this.addProperty("labels", {
      isReadOnly: true,
      defaultValue: function () {
        // Note - Legends can be individually modified
        const legendLabelOptons = {...options};
        return new ChartDataLabelShape(legendLabelOptons, _self._styleKey, true);
      },
      persister: null /* we implicitly save models*/,
    });

    this.addProperty("description", {
      isReadOnly: true,
      isTransient: true,
      defaultValue: function () {
        return "Legend";
      },
      inputs: [],
    });
  }

  get description() {
    return "Legend";
  }

  get className() {
    return "ChartLegendShape";
  }

}

export default ChartLegendShape;
