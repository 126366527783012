import DAGM from "../../../dagm/Dagm";
import PropertyPersister from "../../../dagm/PropertyPersister";

import { createFillProperty } from "../../AbstractShape";

import {
  isLineType,
  isSingleSeries,
  scatterHasLine,
} from "../../../utils/ChartUtils";

import ChartPartShape from "../ChartPartShape";

class ChartDataPointShape extends ChartPartShape {
  constructor(
    options,
    series,
    xRange,
    valueRange,
    offset,
    length,
    seriesOffset,
    seriesLength
  ) {
    super(Object.assign(options, { noDefaultProperties: true }), null/*style*/); // With a null style the styling properties are not unnecessarily defined
    this._styleKey = "dataPoint";

    // this.addProperty("shown", { defaultValue: true });
    // this.addProperty("rotation", { defaultValue: 0.0 });
    const _self = this;

    this.addProperty("series", {
      isReadOnly: true,
      isTransient: true,
      defaultValue: function () {
        return series;
      },
    });

    this.addProperty("offset", {
      isReadOnly: true,
      isTransient: true,
      defaultValue: function () {
        return offset;
      },
    });

    this.addProperty("length", {
      isReadOnly: true,
      isTransient: true,
      defaultValue: function () {
        return length;
      },
    });

    this.addProperty("seriesLength", {
      isReadOnly: true,
      isTransient: true,
      defaultValue: function () {
        return seriesLength;
      },
    });

    this.addProperty("seriesOffset", {
      isReadOnly: true,
      isTransient: true,
      defaultValue: function () {
        return seriesOffset;
      },
    });

    let pointX = _self.chartShape.sheet.valuesFromRange(xRange);
    this.addProperty("pointX", {
      isReadOnly: true,
      isTransient: true,
      defaultValue: function () {
        return pointX || null;
      },
    });

    let inputX;
    if (pointX)
        inputX = [pointX.getProperty('asPrimitives')];
    this.addProperty("x", {
      defaultValue: function (primitives) {
        if (primitives === null || primitives === undefined || primitives.length === 0 || primitives[0].length === 0)
            return offset;
        let lastRow = primitives[primitives.length-1];
        return lastRow[lastRow.length -1];
      },
      inputs: inputX,
    });

    let pointVal = _self.chartShape.sheet.valuesFromRange(valueRange);
    this.addProperty("pointVal", {
      isReadOnly: true,
      isTransient: true,
      defaultValue: function () {
        return pointVal || null; // This should never be null
      },
    });

    this.addProperty("val", {
      defaultValue: function (primitives) {
        if (primitives === null || primitives === undefined || primitives.length === 0 || primitives[0].length === 0)
            return null;
        let lastRow = primitives[primitives.length-1];
        return lastRow[lastRow.length -1];
      },
      inputs: [pointVal.getProperty('asPrimitives')],
    });

    // This is a performance optimization. Instead of using the
//     if (this._readonly) {
//         let varyColors = ((_self.seriesLength === 1 || isSingleSeries(_self.series.chartType.type)) &&
//           _self.series.chartType.varyColors && !_self.getPropertyValue("series.fill").isExplicit);
//         if (!varyColors) {
//             // TODO - This sould create a new proprty value with this pointassource
//             this.fill = _self.series.getPropertyValue("fill");
//         }
//     }
//     if (!this.fill)
    // fill
    createFillProperty(this, this.styleKey, 'fill', "chartShape.chartStyle", function(style, styleKey, seriesFill, varyColors, chartType) {
        if ((_self.seriesLength === 1 || isSingleSeries(chartType)) &&
          varyColors && !this.getPropertyValue("series.fill").isExplicit)
          return style.createDataPoints2DFillStyleProperties(
            _self.offset,
            _self.length
          );
        else return seriesFill;
    }, [
      "series.fill",
      "series.chartType.varyColors",
      "series.chartType.type",
    ], 'fill');

    // fillNegative
    createFillProperty(this, this.styleKey, 'fillNegative', "chartShape.chartStyle", function(style, styleKey, seriesFillNegative, fill) {
        if (this.getPropertyValue("series.fillNegative").isExplicit)
          return seriesFillNegative;

        return fill;
    }, [
      "series.fillNegative",
      "fill", // interesting. this should be custom datapoint since these are not changeable.... how to do this?
    ], 'fillNegative');

    // strokeFill
    createFillProperty(this, this.styleKey, 'strokeFill', "chartShape.chartStyle", function(style, styleKey,
        strokeFill,
        varyColors,
        chartType,
        scatterStyle) {
        if (
          (_self.seriesLength === 1 || isSingleSeries(chartType)) &&
          varyColors &&
          !this.getPropertyValue("series.strokeFill").isExplicit
        ) {
          if (isLineType(style, scatterStyle)) {
            if (scatterHasLine(scatterStyle)) {
              return chartStyle.createDataPoints2DFillStyleProperties(
                _self.offset,
                _self.length
              );
            } else {
              return style.createNoneFill();
            }
          } else {
            return style.createDataPoints2DStrokeStyleProperties(
              _self.offset,
              _self.length
            ).fill;
          }
        } else return strokeFill;
    }, [
        "series.strokeFill",
        "series.chartType.varyColors",
        "series.chartType.type",
        "series.chartType.scatterStyle",
    ], 'stroke.fill');

    // strokeFillNegative
    createFillProperty(this, this.styleKey, 'strokeFillNegative', "chartShape.chartStyle", function(style, styleKey, seriesStrokeFillNegative, strokeFill) {
        if (this.getPropertyValue("series.strokeFillNegative").isExplicit)
          return seriesStrokeFillNegative;

        return strokeFill;
    }, [
      "series.strokeFillNegative",
      "strokeFill", // interesting. this should be custom datapoint since these are not changeable.... how to do this?
    ], 'strokeNegative.fill');

    this.addProperty("strokeWidth", {
      defaultValue: function (strokeWidth) {
        return strokeWidth;
      },
      inputs: ["series.strokeWidth"],
      persister: new PropertyPersister("stroke.width"),
    });

    this.addProperty("strokeLineJoin", {
      defaultValue: function (strokeLineJoin) {
        return strokeLineJoin;
      },
      inputs: ["series.strokeLineJoin"],
    });

    this.addProperty("strokeLineCap", {
      defaultValue: function (strokeLineCap) {
        return strokeLineCap;
      },
      inputs: ["series.strokeLineCap"],
    });

    this.addProperty("strokeDash", {
      defaultValue: function (strokeDash) {
        return strokeDash;
      },
      inputs: ["series.strokeDash"],
    });

    // rendering not implemented
    // this.addProperty("strokeCompound", {
    //   defaultValue: function(strokeCompound) {
    //     return strokeCompound;
    //   },
    //   inputs: ["series.strokeCompound"]
    // });
  }

  get className() {
    return "ChartDataPointShape";
  }
}

export default ChartDataPointShape;


export class ChartDataPointShapePersistor extends PropertyPersister {
  constructor(path, options) {
    super(path);
    this.options = options;
  }

  toJSON(value) {
    if (value instanceof ChartDataPointShape) {
      return value.toJSON();
    }

    return value;
  }

  fromJSON(jsonValue, index) {
    let _self = this;
    return DAGM.newFromJSON(jsonValue, () => {
      let retValue = _self.options.createDataPoint(index);
      return retValue;
    });
  }
}
