import PropertyPersister from "../../../dagm/PropertyPersister";
import FetchedPropertyValue from "../../../dagm/FetchedPropertyValue";

import { dateOASetter } from "../../primitives/Persisters";

import CommonUtils from "../../../utils/CommonUtils";
import * as DateUnitCalcs from "../../../utils/DateUnitCalcs";
import * as UnitCalcs from "../../../utils/UnitCalcs";

import ChartCatAxisShape from "./ChartCatAxisShape";

class ChartDateAxisShape extends ChartCatAxisShape {
  constructor(options) {
    super(options, "date");

    let _self = this;

    this.overrideProperty("defaultAxisFormat", { defaultValue: "m/d/yyyy" });

    this.addProperty("explicitMax", {
      isWritable: false,
      defaultValue: null,
      setValue: dateOASetter,
      persister: new PropertyPersister("max"),
    });
    this.addProperty("explicitMin", {
      isWritable: false,
      defaultValue: null,
      setValue: dateOASetter,
      persister: new PropertyPersister("min"),
    });
    this.addProperty("explicitMajorUnitDates", {
      isWritable: false,
      defaultValue: null,
      persister: new PropertyPersister("majorUnitDates"),
    });
    this.addProperty("explicitMinorUnitDates", {
      isWritable: false,
      defaultValue: null,
      persister: new PropertyPersister("minorUnitDates"),
    });
    this.addProperty("explicitBaseUnitDates", {
      isWritable: false,
      defaultValue: null,
      persister: new PropertyPersister("baseUnitDates"),
    });

    this.addProperty("limits", {
      isReadOnly: true,
      isTransient: true,
      defaultValue: function (
        valuesPrimitive,
        explicitMin,
        explicitMax,
        explicitMinorUnitDates,
        explicitMajorUnitDates,
        explicitBaseUnitDates,
        dims,
        horizontal,
        crossBetween,
        labels
      ) {
        // because the xValues is multi-level we remove the second dimension
        let values = [];
        let level = 0;
        for (let i = 0; i < valuesPrimitive[level].length; i++) {
          let value = CommonUtils.asNumber(valuesPrimitive[level][i]);
          if (!isNaN(value) && value > 0 && value < 2958465) values.push(value);
        }

        let retValue = DateUnitCalcs.calcAxisLimitsDate(
          values,
          explicitMin,
          explicitMax,
          explicitMinorUnitDates,
          explicitMajorUnitDates,
          explicitBaseUnitDates,
          dims,
          horizontal,
          crossBetween,
          labels
        );

        retValue.autoZero = UnitCalcs.autoZero(retValue.min, retValue.max);

        retValue.fitToTick = function (value) {
          let bound = DateUnitCalcs.toBaseUnit(value, retValue.baseUnitDates);
          return bound;
        };

        return retValue;
      },
      inputs: [
        "xValues.asPrimitives",
        "explicitMin",
        "explicitMax",
        "explicitMinorUnitDates",
        "explicitMajorUnitDates",
        "explicitBaseUnitDates",
        "dims",
        "horizontal",
        "crossBetween",
        "labels.*",
        "labels.text.*",
        "labels.rotation",
      ], // we listen to labels.text due to a DAGM limitation
    });

    this.addProperty("max", {
      defaultValue: function (limits) {
        return limits.max;
      },
      setValue: function (value) {
        if (value !== undefined) {
          let explicitMin = _self.getPropertyValue("explicitMin");
          if (explicitMin.value >= value)
            _self.setPropertyValue("explicitMin", undefined);
        }
        _self.setPropertyValue("explicitMax", value);
        return undefined;
      },
      valueCreator: function (source, value, property, isExplicit, defaultValue) {
        let explicit = _self.getPropertyValue("explicitMax");
        return new FetchedPropertyValue(
          source,
          value,
          property,
          explicit.isExplicit,
          defaultValue
        );
      },
      inputs: ["limits", "explicitMax"],
      persister: null /* we persist explict values */,
    });
    this.addProperty("min", {
      defaultValue: function (limits) {
        return limits.min;
      },
      setValue: function (value) {
        // We don't allow max to be set. Rather we set explicitValue
        if (value !== undefined) {
          let explicitMax = _self.getPropertyValue("explicitMax");
          if (explicitMax.value <= value)
            _self.setPropertyValue("explicitMax", undefined);
        }

        _self.setPropertyValue("explicitMin", value);
        return undefined;
      },
      valueCreator: function (source, value, property, isExplicit, defaultValue) {
        let explicit = _self.getPropertyValue("explicitMin");
        return new FetchedPropertyValue(
          source,
          value,
          property,
          explicit.isExplicit,
          defaultValue
        );
      },
      inputs: ["limits", "explicitMin"],
      persister: null /* we persist explict values */,
    });
    this.addProperty("majorUnitDates", {
      defaultValue: function (limits) {
        return limits.majorUnitDates;
      },
      setValue: function (value) {
        if (
          value !== undefined &&
          (!CommonUtils.isNumeric(value.amt) || value.amt <= 0)
        )
          return;
        if (value) {
          value = { ...value };
          value.amt = parseInt(value.amt);
        }
        _self.setPropertyValue("explicitMajorUnitDates", value);
        return undefined;
      },
      valueCreator: function (source, value, property, isExplicit, defaultValue) {
        let explicit = _self.getPropertyValue("explicitMajorUnitDates");
        return new FetchedPropertyValue(
          source,
          value,
          property,
          explicit.isExplicit,
          defaultValue
        );
      },
      inputs: ["limits", "explicitMajorUnitDates"],
      persister: null /* we persist explict values */,
    });

    this.addProperty("minorUnitDates", {
      defaultValue: function (limits) {
        return limits.minorUnitDates;
      },
      setValue: function (value) {
        if (
          value !== undefined &&
          (!CommonUtils.isNumeric(value.amt) || value.amt <= 0)
        )
          return;
        if (value) {
          value = { ...value };
          value.amt = parseInt(value.amt);
        }
        _self.setPropertyValue("explicitMinorUnitDates", value);
        return undefined;
      },
      valueCreator: function (source, value, property, isExplicit, defaultValue) {
        let explicit = _self.getPropertyValue("explicitMinorUnitDates");
        return new FetchedPropertyValue(
          source,
          value,
          property,
          explicit.isExplicit,
          defaultValue
        );
      },
      inputs: ["limits", "explicitMinorUnitDates"],
      persister: null /* we persist explict values */,
    });
    this.addProperty("baseUnitDates", {
      defaultValue: function (limits) {
        return limits.baseUnitDates;
      },
      setValue: function (value) {
        if (value !== undefined && value <= 0) return;
        _self.setPropertyValue("explicitBaseUnitDates", value);
        return undefined;
      },
      valueCreator: function (source, value, property, isExplicit, defaultValue) {
        let explicit = _self.getPropertyValue("explicitBaseUnitDates");
        return new FetchedPropertyValue(
          source,
          value,
          property,
          explicit.isExplicit,
          defaultValue
        );
      },
      inputs: ["limits", "explicitBaseUnitDates"],
      persister: null /* we persist explict values */,
    });

    this.addProperty("explicitLabelRotation", {
      isWritable: false,
      defaultValue: null,
      setValue: dateOASetter,
      persister: new PropertyPersister("labels.rotation"),
    });

    this.addProperty("labelRotation", {
      defaultValue: function (limits) {
        return limits.min;
      },
      setValue: function (value) {
        _self.setPropertyValue("explicitLabelRotation", value);
        return undefined;
      },
      valueCreator: function (source, value, property, isExplicit, defaultValue) {
        let explicit = _self.getPropertyValue("explicitLabelRotation");
        return new FetchedPropertyValue(
          source,
          value,
          property,
          explicit.isExplicit,
          defaultValue
        );
      },
      inputs: ["limits", "explicitLabelRotation"],
      persister: null /* we persist explict values */,
    });

    /**
     * @see: ChartAxisShape.majorTickMarks
     */
    this.overrideProperty("majorTickMarks", { defaultValue: "out" });
  } // end of constructor

  get className() {
    return "ChartDateAxisShape";
  }

}

export default ChartDateAxisShape;
