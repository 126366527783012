import * as CommonUtils from '../../utils/CommonUtils';
import PropertyPersister from "../../dagm/PropertyPersister";

export const FLOAT_SEP_STR ='.';

export const REGEX_FLOAT_STR =`\\s*([-]?[0-9e]*[${FLOAT_SEP_STR}]?[0-9]+)`;

export const REGEX_COORDINATES = new RegExp(`^${REGEX_FLOAT_STR},${REGEX_FLOAT_STR},${REGEX_FLOAT_STR},${REGEX_FLOAT_STR}$`);

export const REGEX_COORDINATES_RECT = new RegExp(`^rect\(${REGEX_FLOAT_STR},${REGEX_FLOAT_STR},${REGEX_FLOAT_STR},${REGEX_FLOAT_STR}\)$`);

export const REGEX_COORDINATES_INSET = new RegExp(`^inset\(${REGEX_FLOAT_STR},${REGEX_FLOAT_STR},${REGEX_FLOAT_STR},${REGEX_FLOAT_STR}\)$`);
/** read only rect */
export class ImmutableRectangle {
  _private: any;
  constructor(json: any) {
    this._private = {
        json : json
    }
    if (typeof json === 'string') {
      let parts = json.match(REGEX_COORDINATES_RECT) || json.match(REGEX_COORDINATES);
      if (!parts) {
        throw new Error('invalid rect format: ' + json);
      }

      this._private.x = parseFloat(parts[1]);
      this._private.y = parseFloat(parts[2]);
      this._private.width = parseFloat(parts[3]);
      this._private.height = parseFloat(parts[4]);
    } else {
      this._private.x = parseFloat(json.x || 0);
      this._private.y = parseFloat(json.y || 0);
      this._private.width = parseFloat(json.width || json.w || 0);
      this._private.height = parseFloat(json.height || json.h || 0);
    }
  }
  toJSON() {
    return this.toString();
  }
  get x() {
    return this._private.x;
  }
  get y() {
    return this._private.y;
  }
  get width() {
    return this._private.width;
  }
  get height() {
    return this._private.height;
  }
  toString() {
    return (
      CommonUtils.roundAccurately(this.x, 4) + ',' +
      CommonUtils.roundAccurately(this.y, 4) + ',' +
      CommonUtils.roundAccurately(this.width, 4) + ','+
      CommonUtils.roundAccurately(this.height, 4)
    )
  }
}

/** read only rect */
export class ImmutableInset {
  _private: any;
  constructor(json: any) {
    this._private = {
        json : json
    }
    if (typeof json === 'string') {
      let parts = json.match(REGEX_COORDINATES_INSET) || json.match(REGEX_COORDINATES);
      if (!parts)
        throw new Error('invalid inset format: ' + json);

      this._private.top = parseFloat(parts[1]);
      this._private.right = parseFloat(parts[2]);
      this._private.bottom = parseFloat(parts[3]);
      this._private.left = parseFloat(parts[4]);
    } else {
      this._private.top = parseFloat(json.top || json.t || 0);
      this._private.right = parseFloat(json.right || json.r || 0);
      this._private.bottom = parseFloat(json.bottom || json.b || 0);
      this._private.left = parseFloat(json.left || json.l || 0);
    }
  }
  toJSON() {
    return this.toString();
  }
  get top() {
    return this._private.top;
  }
  get right() {
    return this._private.right;
  }
  get bottom() {
    return this._private.bottom;
  }
  get left() {
    return this._private.left;
  }
  toString() {
    return (
      CommonUtils.roundAccurately(this.top, 4) + ',' +
      CommonUtils.roundAccurately(this.right, 4) + ',' +
      CommonUtils.roundAccurately(this.bottom, 4) + ','+
      CommonUtils.roundAccurately(this.left, 4)
    )
  }
}

/**
 * manual layout
   // https://docs.microsoft.com/en-us/openspecs/office_standards/ms-oe376/2c28b9f6-2128-40c8-8eeb-248651dcd7f3
   // https://c-rex.net/projects/samples/ooxml/e1/Part4/OOXML_P4_DOCX_ST_LayoutMode_topic_ID0EUPSRB.html#topic_ID0EUPSRB
*/
export class ManualLayout {
  _private: any;
  constructor(json: any) {
    this._private = {
        json : json,
        resolved: {}
    }
    if (typeof json === 'string') {
      let parts = json.match(REGEX_COORDINATES_RECT) || json.match(REGEX_COORDINATES);
      if (!parts)
        throw new Error('invalid string layout format: ' + json);

      this._private.resolved.x = CommonUtils.roundAccurately(parseFloat(parts[1]), 4);
      this._private.resolved.y = CommonUtils.roundAccurately(parseFloat(parts[2]), 4);
      this._private.resolved.w = CommonUtils.roundAccurately(parseFloat(parts[3]), 4);
      this._private.resolved.height = CommonUtils.roundAccurately(parseFloat(parts[4]), 4);
    } else {
      if (json.x !== undefined)
        this._private.resolved.x = CommonUtils.roundAccurately(json.x, 4);
      if (json.y !== undefined)
        this._private.resolved.y = CommonUtils.roundAccurately(json.y, 4);
      if (json.w !== undefined)
        this._private.resolved.w = CommonUtils.roundAccurately(json.w, 4);
      if (json.h !== undefined)
        this._private.resolved.h = CommonUtils.roundAccurately(json.h, 4);

      if (json.width !== undefined)
        this._private.resolved.w = CommonUtils.roundAccurately(json.width, 4);
      if (json.height !== undefined)
        this._private.resolved.h = CommonUtils.roundAccurately(json.height, 4);
      if (json.xMode !== undefined)
        this._private.resolved.xMode = json.xMode;
      if (json.yMode !== undefined)
        this._private.resolved.yMode = json.yMode;
      if (json.wMode !== undefined)
        this._private.resolved.wMode = json.wMode;
      if (json.hMode !== undefined)
        this._private.resolved.hMode = json.hMode;
    }
  }

  toJSON() {
    return this._private.resolved;
  }
  get x() {
    return this._private.resolved.x;
  }
  get y() {
    return this._private.resolved.y;
  }
  get w() {
    return this._private.resolved.w;
  }
  get h() {
    return this._private.resolved.h;
  }
  get width() {
    return this._private.resolved.w;
  }
  get height() {
    return this._private.resolved.h;
  }
  get xMode() {
    return this._private.resolved.xMode;
  }
  get yMode() {
    return this._private.resolved.yMode;
  }
  get wMode() {
    return this._private.resolved.wMode;
  }
  get hMode() {
    return this._private.resolved.hMode;
  }
  toString() {
    return JSON.stringify(this._private.resolved);
  }
}

export const manualLayoutSetter = function (value) {
  if (value === undefined) return value;
  return new ManualLayout(value);
};

export class ManualLayoutPersister extends PropertyPersister {
  constructor(path: string) {
    super(path);
  }

  toJSON(value: any) {
    if (value && value.toJSON)
        return value.toJSON();
    return value;
  }

  fromJSON(jsonValue: any) {
    return new ManualLayout(jsonValue);
  }
}