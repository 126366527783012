import { IRange, RangeDirection, RangeVisitor, RangeBounds, enrichBounds} from "./Range";

import { stringToArray } from "./RangeUtils";

// TODO - This should not have a dependency on cellRange or literal range. (This is sort of acting like a factory)
import CellRange from "./CellRange";
import LiteralRange from "./LiteralRange";

import PropertyPersister from "../../dagm/PropertyPersister";

export interface IMultiRange extends IRange {

  asRanges: () => IRange[];
  // used to determine interface at runtime
  readonly isMultiRange: boolean;
}

export const sortRanges = function (ranges: IRange[]): IRange[] {
  if (!ranges || ranges.length === 1) return ranges;

  let retValue = [...ranges];
  retValue.sort(function (rangeA, rangeB) {
    if (rangeA.top < rangeB.top) return -1;
    else if (rangeA.top > rangeB.top) return 1;
    else if (rangeA.left < rangeB.left) return -1;
    else if (rangeA.left > rangeB.left) return 1;
    return 0; // top/left is the same.
  });
  return retValue;
};


const findOffset = function(index: number, lengths: number[]) {
  let offsetFound = -1;
  let runningLength = 0;
  // scan for range containing offset
  for (let i=0; offsetFound === -1 && i<lengths.length; i++) {
    if (runningLength + lengths[i] > index)
      offsetFound = i;
    else
    runningLength += lengths[i];
  }
  if (offsetFound === -1) return null;
  return {
    offsetFound,
    runningLength
  }
}

export class AlignedMultiRange implements IMultiRange {
  _aligned: {
    inputRanges: IRange[],
    ranges: IRange[][],
    width: number,
    height: number,
    left: number,
    top: number,
    bottom: number,
    right: number,
    columnOffsets: number[],
    columnLengths: number[],
    rowOffsets: number[],
    rowLengths: number[],
    sheetName?: string
  };

  constructor(input: IRange[] | IRange) {
    let ranges: IRange[];
    if (Array.isArray(input))
      ranges = input as IRange[];
    else
      ranges = [input as IRange];

    const notAlignedException = new Error('input is invalid');
    if (!ranges || ranges.length === 0)
      throw notAlignedException;

    let sortedRanges = sortRanges(ranges);

    this._aligned = {
      inputRanges: sortedRanges,
      ranges: [[sortedRanges[0]]],
      width: sortedRanges[0].width,
      height: sortedRanges[0].height,
      left: sortedRanges[0].left,
      top: sortedRanges[0].top,
      bottom: sortedRanges[0].bottom,
      right: sortedRanges[0].right,
      columnOffsets: [sortedRanges[0].left],
      columnLengths: [sortedRanges[0].width],
      rowOffsets: [sortedRanges[0].top],
      rowLengths: [sortedRanges[0].height],
      sheetName: sortedRanges[0].sheetName
    };

    if (sortedRanges.length === 1) return;

    // closure
    let _aligned = this._aligned;
    const alignsHorizontally = function (range: IRange, offset: number): boolean {
      return (
        range.top === _aligned.ranges[offset][0].top &&
        range.bottom === _aligned.ranges[offset][0].bottom
      );
    };

    const alignsVertically = function (range: IRange, offset: number): boolean {
      return (
        range.left === _aligned.ranges[0][offset].left &&
        range.right === _aligned.ranges[0][offset].right
      );
    };

    // This scans from top/left to bottom/right
    // The first row can have any number of spaces but the
    // following rows must match
    for (let i = 1; i < sortedRanges.length; i++) {
      let currentRow = this._aligned.ranges[this._aligned.ranges.length - 1];
      if (alignsHorizontally(sortedRanges[i], this._aligned.ranges.length - 1)) {
        // If first row
        if (sortedRanges[i].top === this._aligned.ranges[0][0].top) {
          this._aligned.width += sortedRanges[i].width;
          this._aligned.columnOffsets.push(sortedRanges[i].left);
          this._aligned.columnLengths.push(sortedRanges[i].width);
          this._aligned.right = Math.max(this._aligned.right, sortedRanges[i].right);
        }
        // must also match verticaly or be first row
        if (
          this._aligned.ranges.length === 1 ||
          alignsVertically(sortedRanges[i], i % this._aligned.ranges[0].length)
        ) {
          currentRow.push(sortedRanges[i]); // Add as column
        } else throw notAlignedException;
      } else if (
        alignsVertically(sortedRanges[i], 0) &&
        sortedRanges[i].top > currentRow[0].bottom
      ) {
        // aligns with the first verticial and is greater than the last height
        this._aligned.height += sortedRanges[i].height;
        this._aligned.rowOffsets.push(sortedRanges[i].top);
        this._aligned.rowLengths.push(sortedRanges[i].height);
        this._aligned.bottom = Math.max(this._aligned.bottom, sortedRanges[i].bottom);
        currentRow = [sortedRanges[i]];
        this._aligned.ranges.push(currentRow); // Add as row
      } else throw notAlignedException;
    }

    // If this is not a true 2 dimensional array then not a valid multirange
    if (sortedRanges.length % this._aligned.ranges.length !== 0) throw notAlignedException;
  }

  get width() {
    return this._aligned.width;
  }
  get height() {
    return this._aligned.height;
  }
  get left() {
    return this._aligned.left;
  }
  get leftFixed() {
    return this._aligned.inputRanges[0].leftFixed;
  }
  get top() {
    return this._aligned.top;
  }
  get topFixed() {
    return this._aligned.inputRanges[0].leftFixed;
  }
  get bottom() {
    return this._aligned.bottom;
  }
  get bottomFixed() {
    return this._aligned.inputRanges[this._aligned.inputRanges.length-1].bottomFixed;
  }
  get right() {
    return this._aligned.right;
  }
  get rightFixed() {
    return this._aligned.inputRanges[this._aligned.inputRanges.length-1].bottomFixed;
  }

  get sheetName() {
    return this._aligned.sheetName;
  }
  get isMultiRange() {
    return true;
  }
  get isCellRange() { // Only cell ranges align
    return true;
  }

  scan(visitor: RangeVisitor, bounds?: RangeBounds): void {
    let boundsEnriched = enrichBounds(this, bounds);
    let rowsVisited = 0;
    let columnsVisited = 0;

    let visits = [];

    for (
      let currentRowOffset = 0;
      currentRowOffset < this._aligned.rowOffsets.length;
      currentRowOffset++
    ) {
      let currentRowRanges = this._aligned.ranges[currentRowOffset];
      for (
        let currentRowIndex = currentRowRanges[0].top;
        currentRowIndex <= currentRowRanges[0].bottom && currentRowIndex <= boundsEnriched.bottom;
        currentRowIndex++
      ) {
        for (
          let currentColumnOffset = 0;
          currentColumnOffset < this._aligned.columnOffsets.length;
          currentColumnOffset++
        ) {
          let currentRange = currentRowRanges[currentColumnOffset];
          for (
            let currentColumnIndex = currentRange.left;
            currentColumnIndex <= currentRange.right && currentColumnIndex <= boundsEnriched.right;
            currentColumnIndex++
          ) {
            let continueVisit = undefined;
            // We don't visit when not in scan bounds (note - we still iterate top lefts to ensure offsets are correct)
            if (currentColumnIndex >= boundsEnriched.left && currentRowIndex >= boundsEnriched.top) {
              visits.push({
                columnsVisited,
                rowsVisited,
                currentColumnIndex,
                currentRowIndex,
                currentRange
              })
              columnsVisited++;
            }
            if (continueVisit !== undefined && continueVisit === false)
              return;
          }
        }
        if (columnsVisited > 0) {
          rowsVisited++;
          columnsVisited = 0;
        }
      }
    }

    if (boundsEnriched.reverse)
      visits = visits.reverse();

    let continueVisit = undefined;
    for (let i=0; continueVisit !== false && i<visits.length; i++) {
      continueVisit = visitor(
        this.sheetName,
        visits[i].columnsVisited,
        visits[i].rowsVisited,
        visits[i].currentColumnIndex,
        visits[i].currentRowIndex,
        visits[i].currentRange
      );
    }
  }

  subRange(left: number, top: number, right: number, bottom: number): IRange | null {
    if (isNaN(left) || isNaN(top) || isNaN(right) || isNaN(bottom))
      throw new Error('subrange must be provided valid ranges');

    let newRanges: IRange[] = [];
    for (let i = 0; i < this._aligned.inputRanges.length; i++) {
      let intersect = this._aligned.inputRanges[i].subRange(left, top, right, bottom);
      if (intersect && (!intersect.sheetName || intersect.sheetName === this.sheetName))
        newRanges.push(intersect);
    }
    if (newRanges.length === 0)
      return null;

    // ? Should we just have this throw an exception?
    try {
      return new AlignedMultiRange(newRanges);
    } catch (error) {
      return null;
    }
  }

  slice(index: number, direction?: RangeDirection): IRange | null {
    if (direction === undefined) {
      if (this.width === 1 && this.height > 1) direction = RangeDirection.row;
      else if (this.height === 1 && this.width > 1) direction = RangeDirection.column;
      else direction = this.height > this.width ? RangeDirection.column : RangeDirection.row;
    }

    if (direction === RangeDirection.column) {
      let found = findOffset(index, this._aligned.columnLengths);
      if (!found) return null;
      let absolute = this._aligned.ranges[0][found.offsetFound].left + (index - found.runningLength);
      let retValue = this.subRange(
        absolute,
        this.top,
        absolute,
        this.bottom);
      return retValue;
    } else {
      let found = findOffset(index, this._aligned.rowLengths);
      if (!found) return null;
      let absolute = this._aligned.ranges[found.offsetFound][0].top + (index - found.runningLength);
      let retValue = this.subRange(
        this.left,
        absolute,
        this.right,
        absolute);
      return retValue;
    }
  }

  asRanges(): IRange[] {
    return [...this._aligned.inputRanges];
  }

  toString(): string {
    return this._aligned.inputRanges.toString();
  };
}

const fixCell = (cell: CellRange): CellRange => {
  return new CellRange({
    left: cell.left,
    top: cell.top,
    right: cell.right,
    bottom: cell.bottom,
    leftFixed: true,
    topFixed: true,
    rightFixed: true,
    bottomFixed: true,
    sheetName: cell.sheetName,
  });
}

export const parseRangeSelection = (input: IRange[] | IRange | string | string[] | IMultiRange, forceFixed: boolean = true): IRange[] | IRange | null => {
  let parsing: IRange[] | IRange | string | string[] | IMultiRange = input;
  if (!parsing) {
    return [];
  }

  if (typeof parsing === 'string') {
    let parsedLiteral = stringToArray(parsing);
    if (parsedLiteral)
      return new LiteralRange(parsedLiteral);

    if ((parsing.startsWith('(') && parsing.endsWith(')')) ||
        (parsing.startsWith('[') && parsing.endsWith(']')))
        parsing = parsing.substring(1, parsing.length - 1);
    parsing = parsing.split(',');
  }

  let ranges: IRange[];

  if (Array.isArray(parsing)) {
    ranges = [];
    for (let i=0; i<parsing.length; i++) {
      let cellRange:CellRange = null;
      if (parsing[i] instanceof CellRange)
        cellRange = parsing[i] as CellRange;
      else {
        cellRange = new CellRange((parsing[i] as string).trim());
      }
      if (forceFixed)
        cellRange = fixCell(cellRange);
      ranges.push(cellRange);
    }
  } else if (parsing instanceof CellRange) {
    let cellRange = parsing as CellRange;
    if (forceFixed)
      cellRange = fixCell(cellRange);
    ranges = [cellRange];
  } else {
    throw new Error('Unable to parseRangeSelection from : ' + input);
  }

  let retValue: IRange | IRange[] | null = ranges;

  return retValue;
}

/*
 * Create range selection will take a a string or a an array of inputs and
 * try to compress these into a single logical range.
 * If this is not possible than an array of ranges will be returned.
*/
// TODO - pass in range parser (to support cell, literal, etc)
export const createRangeSelection = (input: IRange[] | IRange | string | string[] | IMultiRange, singleLength: boolean=false, alignRanges: boolean=false, forceFixed: boolean=false): IRange[] | IRange | null => {
  // hack to determine it's another ranged selection (how to do with instanceof)
  let parsing: IRange[] | IRange | string | string[] | IMultiRange = input;
  if (!parsing) {
    return [];
  }
  if ((parsing as IMultiRange).isMultiRange) {
    return parsing as IMultiRange;
  }

  let retValue: IRange | IRange[] | null = null;
  try {
    // Try to 'parse'.
    retValue = parseRangeSelection(input, forceFixed);
  } catch (error) {
    //console.log(error);
    throw new Error('Unable to createRangeSelection from : ' + input);
  }

  try {
    // Try to 'compress'.
    if (alignRanges && Array.isArray(retValue))
      retValue = new AlignedMultiRange(retValue);
  } catch (error) { } //console.log('error', error);

  if (singleLength) {
    const checkSingleLength = function(range: IRange) {
      if (range.width !== 1 && range.height !== 1)
        throw new Error("only a rangeSelection with a width or height of 1 is allowed");
    }
    if (Array.isArray(retValue)) {
      for (let i=0; i<retValue.length; i++) {
        checkSingleLength(retValue[i]);
      }
    } else
      checkSingleLength(retValue);
  }

  return retValue;
}

export const createRangeSelectionSetter = function (singleLength?: boolean, alignRanges?: boolean, forceFixed?: boolean) {
  return function (value: any) {
    if (value === undefined || value === null) return;

    value = createRangeSelection(value, singleLength, alignRanges, forceFixed);
    return value;
  }
}

export class RangeSelectionPersister extends PropertyPersister {
  constructor(path?: string) {
    super(path);
  }

  toJSON(value: any) {
    if (value && value.toString())
      return value.toString();

    return null;
  }

  fromJSON(jsonValue: any) {
    if (jsonValue === null)
        return null;

    return createRangeSelection(jsonValue);
  }
}