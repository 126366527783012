import AbstractModel from "./AbstractModel";
import PropertyPersister from "./PropertyPersister";
import FetchedPropertyValue from "./FetchedPropertyValue";
import ScopeContextStack from "./ScopeContextStack";
import UpdateContext from "./UpdateContext";

import CommonUtils from "../utils/CommonUtils";
import ObjectPath from "../utils/ObjectPath";

class RecursiveError extends Error {
  constructor(message, recursiveStack) {
    super(message); // (1)
    this.recursiveStack = recursiveStack;
    this.name = "RecursiveError"; // (2)
  }
}

let DEFAULT_PERSISTOR = new PropertyPersister();
let EMPTY_ARRAY = Object.freeze([]);
let EMPTY_FUNCTOR = function () {}; // console.log('empty functor called') };
let EMU_PER_POINT = 12700

class ModelProperty {
  constructor(instance, propertyName, options) {
    if (!(instance instanceof AbstractModel))
      throw new Error("instance must be of type AbstractModel");
    this._instance = instance;
    this._propertyName = propertyName;

//     this._key = this.genKey(this._instance, this._propertyName);

    this._options = Object.assign(
      {
        isReadOnly: false,
        inputs: [],
        persister: DEFAULT_PERSISTOR,
        setValue: function (explicitValue) {
          return explicitValue;
        },
        defaultValue: function () {},
      },
      options || {}
    );

    this._inputStrings = {};
    for (let i = 0; i < this._options.inputs.length; i++) {
      this._inputStrings[ObjectPath.simpleString(this._options.inputs[i])] = i;
    }
  }

  get isReadOnly() {
    return !!this._options.isReadOnly;
  }

  get isTransient() {
    return !!this._options.isTransient;
  }

  get inputs() {
    return this._options.inputs;
  }

  get defaultValue() {
    return this._options.defaultValue;
  }

  get instance() {
    return this._instance;
  }

  get propertyName() {
    return this._propertyName;
  }

  get persistKey() {
    return this._propertyName;
  }

  get lookupKey() {
    return this._lookupKey || this._propertyName;
  }

  getPropertyValue(propertyPath) {
    if (!this.evaled) return;

    let currentValue = this.evaled.resolved;
    if (
      !propertyPath ||
      (propertyPath.length === 1 && propertyPath[0] === ".") ||
      propertyPath[0] === "**"
    )
      return currentValue;
    // if (propertyPath[0] === "*" && currentValue.value instanceof AbstractModel) {
        // console.log('hmm', currentValue);
//         return currentValue;
    // }

    if (!currentValue) {
      throw new Error("unable to resolve: " + propertyPath);
    }

    if (currentValue.value === null || currentValue.value === undefined)
      return currentValue.value;

    if (!(currentValue.value instanceof AbstractModel)) {
      debugger;
      throw Error(
        "A property chain was specified for a type that is not a model property: [" +
          propertyPath +
          "] ."
      );
    }

    let propertyName = propertyPath[0];

    let propertyDef = currentValue.value.lookupPropertyDef(propertyName);
    if (!propertyDef) {
      return undefined;
    }
    if (propertyPath.length === 1) return propertyDef.getPropertyValue();

    let propertyPathFollow = [...propertyPath].splice(1);
    return propertyDef.getPropertyValue(propertyPathFollow);
  }

  get value() {
    if (!this.evaled) return undefined;
    let propValue = this.evaled.resolved;
    // let propValue = this._instance.getPropertyValue(this._propertyName);
    if (!propValue) return undefined;

    return propValue.value;
  }

  set value(explicitValue) {
    return this._instance.setPropertyValue(this._propertyName, explicitValue);
  }

  visitProperty(nodePath, visitedMap, visitor) {
    if (this.isTransient || !this.evaled) return;
    if (this.unresolvedProps) {
      let pathPrint = [...nodePath];
      pathPrint.push(this.propertyName);
      console.warn(
        "Found an unresolved proprety  during save. This probably indicates a modelling error.",
        ObjectPath.simpleString(pathPrint)
      );
    debugger;
      return;
    }

    if (this.evaled.explicit !== undefined) {
      visitor(this, this.evaled.explicit, nodePath);
    } else {
      visitor(this, this.evaled.defaulted, nodePath);
    }

    if (!this.evaled.resolved) {
      let pathPrint = [...nodePath];
      pathPrint.push(this.propertyName);
      console.warn(
        "Found an unresolved value during save. This probably indicates a modelling error.",
        ObjectPath.simpleString(pathPrint)
      );
      debugger;
      return;
    }
    let currentResolvedValue = this.evaled.resolved.value;
    if (currentResolvedValue instanceof AbstractModel) {
      let pathProp = [...nodePath];
      pathProp.push(this.persistKey);
      currentResolvedValue.visitModel(pathProp, visitedMap, visitor);
    }
  }

  /*  Note -
        Our graph is A DAG but allows for multiple nodes to point to
        to a child so we track all items that have been visited.
    */
  walkDependants(markedList, visitedMap, visitedStack, originalProp) {
    if (!markedList) throw new Error("markedList required");
    visitedMap = visitedMap || new Map();
    visitedStack = visitedStack || [];

    let currentStack = undefined;
    if (visitedStack) {
      currentStack = [...visitedStack];
    } else currentStack = [];

    if (currentStack.includes(this)) {
      // We want to return the resolved properties to make it easier to trouble shoot
      let errorStack = [];
      let description = this.propertyName;
      errorStack.push(this);
      for (let i = currentStack.length - 1; i > 0; i--) {
        let property = visitedMap[currentStack[i]].property;
        errorStack.push(property);
        description += " -> " + property.propertyName;
      }

      if (originalProp)
        description = originalProp.propertyName + " @ " + description;
      throw new RecursiveError(
        "Recursive depedencies detected " + description,
        errorStack
      );
    }
    currentStack.push(this);

    if (visitedMap.has(this)) return;

    let markInfo = { property: this, stack: currentStack };
    visitedMap.set(this, markInfo);

    if (this.instance._dependencies[this.propertyName]) {
      let iterDependants = this.instance._dependencies[this.propertyName].values();
      for (let dependant of iterDependants) {
          let propDependant = dependant.instance.lookupPropertyDef(
            dependant.propertyName
          );
          if (propDependant)
            propDependant.walkDependants(
              markedList,
              visitedMap,
              currentStack,
              originalProp
            );
      }
    }

    if (this.instance._dependencies["*"]) {
      let iterDependants = this.instance._dependencies["*"].values();
      for (let dependant of iterDependants) {

        let propDependant = dependant.instance.lookupPropertyDef(
          dependant.propertyName
        );
        if (propDependant)
          propDependant.walkDependants(
            markedList,
            visitedMap,
            currentStack,
            originalProp
          );
      }
    }

    markedList.push(this);
  }

  lookupPropertyDef(instance, propertyName) {
    return instance._properties[propertyName];
  }

  doPropertyCalc(propertyDef, _this, inputValues) {
    /**
     * Note -
     * This is a VERY DIRTY HACK for docusaurus and StaticSiteGeneratorPlugin
     * Somehow docusaurus is complaining about this not having access to self
     * Perhaps setting StaticSiteGeneratorPlugin.globals: 'self' would 'fix' the issue
     * but docusaurus doesn't give us access ot this. Another solution would be to have our own
     * plugin add self?
     */
    const SSR_DOCS = (process.env.SSR_DOCS === true);
    if (SSR_DOCS) {
      // console.log('process.env.SSR_DOCS', SSR_DOCS);
      return null;
    }

    return propertyDef.defaultValue.apply(_this, inputValues);
  }

  createPropertyValue(source, value, property, isExplicit, defaultValue) {
    if (this._options.valueCreator)
      return this._options.valueCreator(source, value, property, isExplicit, defaultValue);
    return new FetchedPropertyValue(source, value, property, isExplicit, defaultValue);
  }

  shouldForceRecalc(valueTest, input) {
    let isModel = valueTest instanceof AbstractModel;
    if (
      isModel &&
      Array.isArray(input) &&
      input.length > 0 &&
      input[input.length - 1] === "*"
    )
      return true;

    if (
      Array.isArray(valueTest) &&
      Array.isArray(input) &&
      input.length > 1 &&
      input[input.length - 1] === "*" &&
      input[input.length - 2] === "*"
    ) {
      if (valueTest.length > 0) {
        isModel = valueTest instanceof AbstractModel;
        return isModel;
      }
      return true;
    }

    return false;
  }

  /*
   * This creates a new property where the dependency is the current property.
   * This ensures that any updates to input values are calculated after the
   * currentProperty.
   */
  createScopedProperty(propCurrent, propOriginal) {
    let delegatePropName =
      propCurrent.propertyName + "->" + propOriginal.propertyName;
    if (this._scopedProperties && this._scopedProperties[delegatePropName]) {
      return this._scopedProperties[delegatePropName].delegateProp;
    }

    let options = {
      isReadOnly: true,
      isPrivate: true,
      isDelegate: true,
      persister: null,
      defaultValue: function () {
        // delegate.
        return propOriginal.value;
      },
    };
    options.propDelegateOrig = propOriginal;
    if (propOriginal.delegateProp)
      options.propDelegateOrig = delegateProp.delegateProp;
    options.isDelegateTransient = options.propDelegateOrig.isTransient;

    let delegateProp = new ModelProperty(
      this.instance,
      delegatePropName,
      options
    );
    delegateProp._lookupKey = propOriginal.propertyName;
    this.instance._properties[delegatePropName] = delegateProp;
    delegateProp.recalcProperty();

    // scope is really just ensure that the property gets calls after the current.
    // to do this we have current listen to the original and the delegate list to the current
    // original -> current -> delegate
    let depsAdded = [];
    propCurrent.registerDep(
      propOriginal.instance,
      propOriginal.propertyName,
      depsAdded
    );
    delegateProp.registerDep(
      propCurrent.instance,
      propCurrent.propertyName,
      depsAdded
    );

    if (!this._scopedProperties) this._scopedProperties = [];
    this._scopedProperties[delegatePropName] = {
      delegateProp: delegateProp,
      deps: depsAdded,
    };
    return delegateProp;
  }

  clearScopedProperties() {
    if (!this._scopedProperties) return;
    let keys = Object.keys(this._scopedProperties);
    for (let i = 0; i < keys.length; i++) {
      let del = this._scopedProperties[keys[i]];
      del.delegateProp.destroy();
      this.removeDependencies(del.deps);
    }
    delete this._scopedProperties;
  }

  destroy() {
    this._options.inputs = EMPTY_ARRAY;
    this.evaled = {
      defaulted: undefined,
      explicit: undefined,
      inputs: [],
      resolved: undefined,
    };
    this._options.defaultValue = EMPTY_FUNCTOR;
    this._isDestroyed = true;
  }

  get isDestroyed() {
    return this._isDestroyed;
  }

  recalcProperty() {
    // If there is no calculated value
    let needsRecalc = !this.evaled;

    let explicitValue = this._instance._explicitLookup.getExplictValue(this);

    let previousEval = this.evaled || {
      inputs: [],
    };
    if (!previousEval || this.isDestroyed) {
      needsRecalc = true;
    }

    if (!needsRecalc) needsRecalc = !!this._options.isDelegate;

    if (!needsRecalc) needsRecalc = explicitValue !== previousEval.explicit;

    let inputProps = [];
    this.unresolvedProps = false;
    for (let i = 0; i < this.inputs.length; i++) {
    let inputProp = undefined;
      if (this.inputs[i] instanceof ModelProperty) {
        inputProp = this.inputs[i];
      } else {
        inputProp = this.instance.getProperty(this.inputs[i]);
        // TODO - when propertyinput is implemented this will be removed
        // let pv = this.instance.getPropertyValue(this.inputs[i]);
        // if (pv)
        //     inputProp = pv.property;
        // }
      }
      inputProps.push(inputProp);
      this.unresolvedProps =
        this.unresolvedProps || !inputProp || !!inputProp.isDestroyed;
    }

    let unresolvedValueProps = [];
    let inputValues = new Array(inputProps.length);
    let inputsChanged = !previousEval || !previousEval.inputs;
    for (let i = 0; i < inputProps.length; i++) {
      inputValues[i] = undefined;
      if (inputProps[i] !== undefined) {
        if (inputProps[i].evaled && inputProps[i].evaled.calcing) {
          inputProps[i].recalcProperty();
        }
        inputValues[i] = inputProps[i].value;
      }
      if (
        !inputsChanged &&
        previousEval.inputs[i] !== inputValues[i] &&
        !CommonUtils.arrayEquals(previousEval.inputs[i], inputValues[i])
      ) {
        needsRecalc = true;
        inputsChanged = true;
      }
      let input = this.inputs[i];

      if (inputProps[i]) {
        if (inputValues[i] === undefined)
          unresolvedValueProps.push(inputProps[i]);
        if (!needsRecalc && this.shouldForceRecalc(inputValues[i], input)) {
          needsRecalc = true;
          inputsChanged = true;
        }
      }
    }

    // Update dependencies.
    if (this.instance._readonly) {
      if (unresolvedValueProps.length > 0) {
        throw new Error('Readonly models can not have unresolvable properties', this);
      }
    } else {
        // Update dependencies.
        // TODO - optimize where we put these checks
        this.removeDependencies(this.deps);
        this.deps = [];
        let adder = function (instance, propertyName) {
          this.registerDep(instance, propertyName, this.deps);
        }.bind(this);
        for (let i = 0; i < this.inputs.length; i++) {
          if (!this.inputs[i].isDestroyed)
            this.instance.addDependencies(this.inputs[i], adder);
        }

        // After we resolve dependencies look for cyclical values
        // If an input is unresolved it may be due to a cyclical dependency so we walk
        // to force a check
        if (process.env.NODE_ENV !== "production") {
          for (let i = 0; i < unresolvedValueProps.length; i++) {
            unresolvedValueProps[i].walkDependants([], new Map(), new Map(), this);
          }
        }
    }

    if (!needsRecalc) {
      this.evaled.calcing = false;
      return;
    }

    let newEval = {
      inputs: inputValues,
      explicit: explicitValue,
      defaulted: undefined,
      resolved: undefined,
      calcing: false,
    };

    this.clearScopedProperties();

    let lookupPropertyDef = function (lookupKey) {
      if (lookupKey instanceof ModelProperty) {
        return lookupKey;
      } else if (this._inputStrings[lookupKey] !== undefined) {
        return inputProps[this._inputStrings[lookupKey]];
      }
      // this catch $index and length
      for (let i = 0; i < inputProps.length; i++) {
        if (inputProps[i].lookupKey === lookupKey) {
          return inputProps[i];
        }
      }
      throw new Error("No input property [" + lookupKey + "] used");
    }.bind(this);

    // We don't try to resolve properties that have unresolved inputs
    if (!this.unresolvedProps && unresolvedValueProps.length === 0) {
      let scope = {
        _this: {
          getPropertyName: function () {
            return this.propertyName;
          },
          getInputLength: function () {
            return inputValues ? inputValues.length : 0;
          },
          getInput: function (index) {
            // TODO - use lookupPropertyDef
            if (!inputValues || index < 0 || index > inputValues.length - 1)
              return;
            return inputValues[index];
          },
          getPropertyValue: function (lookupKey) {
            let propDef = lookupPropertyDef(lookupKey);
            return propDef.evaled.resolved;
          }.bind(this),
          getProperty: function (lookupKey) {
            let propDef = lookupPropertyDef(lookupKey);
            return this.createScopedProperty(this, propDef);
          }.bind(this),
          getPreviousValue: function () {
            return previousEval.resolved;
          }.bind(this),
        },
      };

      // If the explicit value is an abstractmodel this was set programmatically.
      // If we preserved the defaulted value it would also preserve it's individual
      // explict values. This may or may not be a good behavior but for now
      // our unit tests expect clearing clearing an explicit model
      // will revert it to a completely clean state (not a state with explict values)
      if (newEval.explicit instanceof AbstractModel) {
        newEval.defaulted = undefined;
        // If not a delegate and the inputs haven't changed then don't recalc.
      } else if (
        !this._options.isDelegate &&
        !inputsChanged &&
        previousEval.defaulted !== undefined
      ) {
        newEval.defaulted = previousEval.defaulted;
      } else {
        let pushed;
        if (!this._options.isTransient && !this._options.isDelegate) {
          pushed = ScopeContextStack.pushScopeContext({
            //scope : scope,
            propertyFrom: this,
            explicitLookup: this.instance._explicitLookup,
          });
        }

        try {
          newEval.defaulted = this.doPropertyCalc(
            this,
            scope._this,
            inputValues
          );
        } catch (error) {
          // debugger;
          console.warn(
            "Unable to calculate '" +
              this.propertyName +
              "' is not defined. Unabled to override property for type " +
              this.instance.constructor.name +
              ".", error
          );

          newEval.defaulted = this.doPropertyCalc(
            this,
            scope._this,
            inputValues
          );
          throw error;
        } finally {
          if (pushed) ScopeContextStack.popScopeContext();
        }
      }

      // If this is not the first calc and the return value is the same return and stop evaling
      if (
        newEval.resolved === undefined ||
        previousEval.defaulted !== newEval.defaulted ||
        previousEval.explicit !== newEval.explicit
      ) {
        newEval.resolved = this.createPropertyValue(
          this.instance,
          newEval.explicit !== undefined ? newEval.explicit : newEval.defaulted,
          this,
          newEval.explicit !== undefined,
          newEval.defaulted
        );
      }

      if (newEval.defaulted instanceof AbstractModel && (
        newEval.explicit && !(newEval.explicit instanceof AbstractModel))) {
          console.warn('The default Model is returning a DAGMModel but the explict type is set to something else. This occurs if the persister is not explicitly set to null.', this._propertyName, newEval.explicit);
      }

      if (this.isDestroyed) {
        delete this.instance._properties[this.propertyName];
      }
    }

    this.evaled = newEval;

    // we don't notify delegates
    if (this._options.isDelegate) return;
    if (UpdateContext.hasUpdateContext()) {
      UpdateContext.getUpdateContext().push({
        property: this,
        previousValue: previousEval.resolved,
        newValue: newEval.resolved,
      });
    }
    return;
  }

  removeDependencies(deps) {
    if (!deps) return;
    for (let i = 0; i < deps.length; i++) {
      this.unregisterDep(deps[i].instance, deps[i].propertyName);
    }
  }

  registerDep(instance, propertyName, depsAdded) {
    let dep = { instance: instance, propertyName: propertyName };
    if (depsAdded) depsAdded.push(dep);


    if (!instance._dependencies[propertyName])
      instance._dependencies[propertyName] = new Map();
    let dependencies = instance._dependencies[propertyName];

    dependencies.set(this, {
      instance: this.instance,
      propertyName: this.propertyName,
    });
  }

  unregisterDep(instance, propertyName) {
    let dependencies = instance._dependencies[propertyName];
    if (!dependencies) return;

    dependencies.delete(this);
    if (dependencies.size === 0)
      delete instance._dependencies[propertyName];
  }
}

export default ModelProperty;
