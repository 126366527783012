class FetchedPropertyValue {
  constructor(source, value, property, isExplicit, defaultValue) {
    this._source = source;
    this._value = value;
    this._property = property;
    this._isExplicit = isExplicit;
    this._defaultValue = defaultValue;
  }

  get source() {
    return this._source;
  }

  get value() {
    return this._value;
  }

  get isExplicit() {
    return this._isExplicit;
  }

  get property() {
    return this._property;
  }

  get defaultValue() {
    return this._defaultValue;
  }
}

export default FetchedPropertyValue;
