class PropertyPersister {
  constructor(path) {
    if (path) this._pathParts = path.split(".");
  }

  load(property, json) {
    if (!this._pathParts) {
      let retValue = json[property.persistKey];
      if (retValue === undefined) return;
      return this.fromJSON(retValue, property.persistKey);
    }

    let node = json;
    for (let i = 0; i < this._pathParts.length; i++) {
      if (node === null) return null;
      if (node === undefined || node[this._pathParts[i]] === undefined) return;
      node = node[this._pathParts[i]];
    }
    if (node === undefined) return;
    return this.fromJSON(node, property.persistKey);
  }

  save(property, json, value) {
    if (!this._pathParts) {
      if (value === undefined) delete json[property.persistKey];
      else json[property.persistKey] = this.toJSON(value);
      return;
    }

    let node = json;
    for (let i = 0; i < this._pathParts.length - 1; i++) {
      if (node[this._pathParts[i]] === undefined) node[this._pathParts[i]] = {};
      node = node[this._pathParts[i]];
    }
    if (value === undefined)
      delete node[this._pathParts[this._pathParts.length - 1]];
    else node[this._pathParts[this._pathParts.length - 1]] = this.toJSON(value);
  }

  toJSON(value) {
    return value;
  }

  fromJSON(jsonValue, persistKey) {
    return jsonValue;
  }
}

export default PropertyPersister;
