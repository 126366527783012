
import {
  Cell,
  RemoveListener,
  SheetSourceData,
  create2DArrayFromString,
  createSheetDataFrom2DArray,
  createCellFromJavascriptValue,
  createCellFromStringValue
 } from './SheetSource';

import { encode_col, decode_col } from '../range/RangeUtils';

// TODO - what about changing sheet names? (will need to update all cell references in charts!, this is pretty huge)
// TODO - add/remove/update sheet

export type SheetUpdate = {
  sheetName: string;
  columnIndex: number;
  rowIndex: number;
};

export type ReferencedSheetCell = {
  sheetName: string;
  columnIndex: number;
  rowIndex: number;
  cell: Cell;
}

export type IMultiSheetSource = {
  getCellAt: (sheetName: string, columnIndex: number, rowIndex: number) => Cell | null;
  setCells?: (cells: ReferencedSheetCell[]) => void;

  getSheetNames(): string[]
};

  // TODO - add/remove/set sheet

export type SheetListener = (updates: SheetUpdate | SheetUpdate[] | null) => void;

export interface IUpdatableMultiSheetModel {
  addUpdateListener: (listener: SheetListener) => RemoveListener;
}

/**
 * Note - Each name must also be unique
 */
export type Sheet = SheetSourceData & {
};

export type Sheets = Record<string, Sheet>;

export type MultiSheetSourceData = {
  sheets:Sheets;
}

/**
 * This uses a single data structure.
 * The DelegatingMultiSheetSource passes to individual sources
 *
 * // TODO - update sheetNames?
 */
export default class SimpleMultiSheetSource implements IMultiSheetSource, IUpdatableMultiSheetModel {
  private _data: MultiSheetSourceData;
  private _options: any;
  private _listeners = new Set<SheetListener>(); //WeakSet();

  // private _sheetsNames = new Map<string, number>();

  constructor(data: MultiSheetSourceData | undefined | null = { sheets: {} }, options:any=null) {
    this._data = data;
    this._options = options;
    if (!this._data.sheets)
      this._data.sheets = {};
    // for (let i=0; i<this._data.sheets.length; i++)
    //   this._sheetsNames.set(this._data.sheets[i].sheetName, i);
  }

  private getSheetData(sheetName: string): SheetSourceData | null {
    let sheetFound = this._data.sheets[sheetName];
    if (!sheetFound || !sheetFound) return null;
    return sheetFound;
  }

  getCellAt(sheetName: string, columnIndex: number, rowIndex: number): Cell | null {
    let key = encode_col(columnIndex) + (rowIndex+1);
    let retValue = null;

    let sheetFound = this.getSheetData(sheetName || null);
    if (sheetFound) {
      let cellFound = sheetFound.cells[key];
      if (cellFound)
        retValue = cellFound;
    };

    if (this._options && this._options.onGetCellAt) {
      this._options.onGetCellAt(sheetName, key, retValue);
    }

    return retValue;
  }

  setData(data: any): void {
    this._data = data;
    if (!this._data.sheets)
      this._data.sheets = {};
    this.notifyUpdateListeners(null);
  }

  setCells(cells: ReferencedSheetCell[]): void {
    if (!cells)
      return;

    // Note - We currently update this inline. Should we move to an immutable model? - It's the way
    let updates:SheetUpdate[] = [];
    for (let i = 0; i < cells.length; i++) {
      updates.push({ columnIndex: cells[i].columnIndex, rowIndex: cells[i].rowIndex, sheetName: cells[i].sheetName || null });
      let sheetFound = this.getSheetData(cells[i].sheetName || null);
      if (!sheetFound) {
        console.warn('invalid setCells sheetName' + cells[i].sheetName);
        continue;
      }

      let key = encode_col(cells[i].columnIndex) + (cells[i].rowIndex+1);
      if (cells[i].cell) {
        sheetFound.cells[key] = cells[i].cell;
      } else {
        delete sheetFound.cells[key];
      }
    }

    if (updates.length > 0) this.notifyUpdateListeners(updates);
  }

  getSheetNames(): string[] {
    return Object.keys(this._data.sheets);

  }

  // This is public so that external classes can notify too
  notifyUpdateListeners(updates: SheetUpdate | SheetUpdate[] | undefined | null) {
    this._listeners.forEach(function (listener: SheetListener) {
      listener(updates);
    });
  }

  addUpdateListener(listener: SheetListener): RemoveListener {
    // This function takes a function that is called when a value is updated
    // The listener will have pass an array of (x,y values)
    // This function will return a listener that
    let _listeners = this._listeners;
    let unListener:RemoveListener = function () {
      _listeners.delete(listener);
    };
    _listeners.add(listener);
    return unListener;
  }

  toJSON(): MultiSheetSourceData {
    return this._data;
  }
}

export function createMultiSheetSourceFrom2DArray(data: any, sheetName: string=null, options?:any): SimpleMultiSheetSource {
  let multiSheet:MultiSheetSourceData = { sheets: {}};
  multiSheet.sheets[sheetName] = {
    cells: createSheetDataFrom2DArray(data, createCellFromJavascriptValue)
  };
  return new SimpleMultiSheetSource(multiSheet, options);
}

export function createMultiSheetSourceFrom2DStringArray(dataAsString: string, sheetName: string=null, options?:any): SimpleMultiSheetSource {
  let dataAsArray = create2DArrayFromString(dataAsString);
  return createMultiSheetSourceFrom2DArray(dataAsArray, sheetName, options);
}