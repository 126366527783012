import ChartPartShape from "../ChartPartShape";
import ChartTextShape from "../ChartTextShape";

class ChartAxisLabelShape extends ChartPartShape {
  constructor(options, styleKey, typeId) {
    super(options, styleKey);
    const _self = this;
    this._typeId = typeId;

    let _axisShape = options.axisShape;
    this.addProperty("axisShape", {
      isReadOnly: true,
      isTransient: true,
      defaultValue: function () {
        return _axisShape;
      },
    });

    let propDefaultText = this.addProperty("defaultText", {
      isReadOnly: true,
      isTransient: true,
      defaultValue: function () {
        return "Data Labels";
      },
    });

    this.addProperty("text", {
      defaultValue: function () {
        return new ChartTextShape(
          Object.assign({}, options, { parent: _self}),
          this.getProperty(propDefaultText),
          _self._styleKey,
          12
        );
      },
      persister: null /* we implicitly save models*/,
    });

    this.addProperty("sourceLinked", {
      defaultValue: function (grouping) {
        if (grouping === "percentStacked")
          return false; // note - if percentStacked then sourceLinked is false
        return true;
      },
      inputs: ["axisShape.chartType.grouping"],
    });


    let firstValuInputs = ["chartShape.sheet"];
    if (this.axisShape.direction === 'x') {
        firstValuInputs.push('axisShape.series[0].xRange');
    } else {
        firstValuInputs.push("axisShape.series[0].valRange");
    }

    // We have to listen to the enire point because we want formatting
    this.addProperty("firstValue", {
      defaultValue: function (sheet, range) {
        if (!sheet || !range || !range.length === 0)
          return null;
        let valRangeFirst = null;
        if (Array.isArray(range)) {
          valRangeFirst = range[0].slice(0);
        } else
          valRangeFirst = range.slice(0);
        return sheet.valuesFromRange(valRangeFirst);
      },
      inputs: firstValuInputs,
    });

    this.addProperty("formatCode", {
      defaultValue: function (defaultAxisFormat, sourceLinked, firstCell) {
        let z = defaultAxisFormat || "General";
        if (sourceLinked && firstCell) {
          if (firstCell && firstCell.length > 0 && firstCell[0].length > 0 && firstCell[0][0] && firstCell[0][0].z) {
            z = firstCell[0][0].z;
          }
        }
        return z;
      }, inputs: ["axisShape.defaultAxisFormat", "sourceLinked", "firstValue.asCells"]
    });
  }

  get className() {
    return "ChartAxisLabelShape";
  }

  get typeId() {
    return this.className + ":" + this._typeId;
  }
}

export default ChartAxisLabelShape;
