import PropertyPersister from "../../dagm/PropertyPersister";

import DateUtils from "../../utils/DateUtils";

import { ImmutableRectangle } from "./Coordinates";

import AdjustableColor, { resolveAdjustableColor } from '../colors/AdjustableColor';
import { SchemeColorLookup } from '../colors/AdjustableColor';

const rectSetter = function (value: any) {
  if (value === undefined) return value;
  return new ImmutableRectangle(value);
};

class RectPersister extends PropertyPersister {
  constructor(path: string) {
    super(path);
  }

  toJSON(value: any) {
    if (value && value.toJSON)
        return value.toJSON();
    return {
      x: value.x || 0,
      y: value.y || 0,
      width: value.width || 0,
      height: value.height || 0,
    };
  }

  fromJSON(jsonValue: any) {
    return new ImmutableRectangle(jsonValue);
  }
}

export { rectSetter, RectPersister };

function retIfValid(value: any) {
  if (!value) return;
  if (!DateUtils.isValidDate(value)) return;
  return value;
}

export function dateSetter(value: any) {
  if (value === undefined || value === null) return value;
  let retValue;
  if (typeof value === "string" || value instanceof String) {
    try {
      retValue = retIfValid(DateUtils.stringToLocalDate(value));
    } catch (error) {}
  } else if (Number.isInteger(value)) {
    // millis
    retValue = retIfValid(DateUtils.toLocalDate(new Date(value)));
  } else if (value instanceof Date) {
    // date
    retValue = retIfValid(DateUtils.toMidnight(new Date(value)));
  }

  if (!retValue) throw new Error("invalid date format: " + value);

  retValue.setHours(0, 0, 0, 0);
  return retValue;
}

export class DatePersister extends PropertyPersister {
  constructor(path: string) {
    super(path);
  }

  toJSON(value: any) {
    if (value instanceof Date) {
      // const persistFormat = 'YYYY-MM-DD';
      return value.toISOString().slice(0, 10);
    }

    return value;
  }

  fromJSON(jsonValue: any) {
    return dateSetter(jsonValue);
  }
}

export function dateOASetter(value: any) {
  if (value === null || value === undefined) return value;
  // We check to see if it's a number
  if (!isNaN(value)) {
    let intPart = Number(value);
    if (intPart > 0 && intPart <= 2958465)
      // excel math date
      return intPart;
  }

  // Try to get a date
  let dateValue = dateSetter(value);
  if (dateValue instanceof Date) {
    return DateUtils.toExcelDate(dateValue);
  }

  throw new Error("Invalid datevalue : " + value);
}

export class AdjustableColorPersister extends PropertyPersister {
  #schemeLookup:SchemeColorLookup;

  constructor(schemeLookup: SchemeColorLookup=null, path?: string) {
    super(path);
    this.#schemeLookup = schemeLookup;
  }

  toJSON(value: any) {
    if (value && value.toJSON) {
      return value.toJSON();
    }

    return value;
  }

  fromJSON(jsonValue: any) {
    return resolveAdjustableColor(jsonValue, this.#schemeLookup);
  }
}

export function createAdjustableColorSetter(schemeLookup: SchemeColorLookup=null) {
  return  function(value:any) {
      if (value === undefined || value === null) return value;
      return resolveAdjustableColor(value, schemeLookup);
  }
}