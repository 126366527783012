import AbstractModel from "../dagm/AbstractModel";
import PropertyPersister from "../dagm/PropertyPersister";
import FetchedPropertyValue from "../dagm/FetchedPropertyValue";

import Fill, { resolveFill } from "./primitives/Fills";
import { getPresetDashFromKey, dashSetter, DashPersister } from "./primitives/Dash";

/**
    Shapes are rendered artifacts
*/

class AbstractShape extends AbstractModel {
  constructor(shapeOptions) {
    super(shapeOptions);
    this._shapeOptions = shapeOptions;

    if (shapeOptions && shapeOptions.noDefaultProperties) return;
    this.addProperty("shown", { defaultValue: true });
    this.addProperty("rotation", { defaultValue: 0.0 });

    this.addProperty("fill", { defaultValue: { none: true } });

    this.addProperty("strokeFill", {
      defaultValue: { none: true },
      persister: new PropertyPersister("stroke.fill"),
    });

    this.addProperty("strokeWidth", {
      defaultValue: 0.75,
      persister: new PropertyPersister("stroke.width"),
    });
    // TODO PORT - lookup default line properties
    this.addProperty("strokeLineJoin", {
      defaultValue: "round",
      persister: new PropertyPersister("stroke.lineJoin"),
    });
    this.addProperty("strokeLineCap", {
      defaultValue: "butt",
      persister: new PropertyPersister("stroke.lineCap"),
    });
    this.addProperty("strokeDash", {
      defaultValue: getPresetDashFromKey("solid"),
      //persister: new PropertyPersister("stroke.dash"),
      setter: dashSetter,
      persister: new DashPersister("stroke.dash"),
    });
    // TODO - implement (complex rendering not supported)
    this.addProperty("strokeCompound", {
      defaultValue: "single",
      persister: new PropertyPersister("stroke.compound"),
    });
    // TODO - implement (complex rendering not supported)
    this.addProperty("strokeAlign", {
      defaultValue: 'ctr',
      persister: new PropertyPersister("stroke.algn"),
    });

    // TODO - implement (complex rendering not supported)
    this.addProperty("strokeMitter", {
      defaultValue: 0,
      persister: new PropertyPersister("stroke.miter"),
    });

    // TODO - implement (complex rendering not supported for charts)
    this.addProperty("strokeHeadType", {
      defaultValue: "none", // "arrow", "diamond", "none", "oval", "stealth", "triangle"
      persister: new PropertyPersister("stroke.headType"),
    });

    /**
     * In ooxml this is ths two values (w, len). We combine as there are 9 possible permutations.
     * https://c-rex.net/projects/samples/ooxml/e1/Part4/OOXML_P4_DOCX_headEnd_topic_ID0EQ2ZMB.html#topic_ID0EQ2ZMB
     */
    // TODO - implement (complex rendering not supported for charts)
    this.addProperty("strokeHeadSize", {
      defaultValue: "med-med",
      persister: new PropertyPersister("stroke.headSize"),
    });

    // TODO - implement (complex rendering not supported for charts)
    this.addProperty("strokeTailType", {
      defaultValue: "none", // "arrow", "diamond", "none", "oval", "stealth", "triangle"
      persister: new PropertyPersister("stroke.tailType"),
    });

    /**
     * In ooxml this is ths two values (w, len). We combine as there are 9 possible permutations.
     * https://c-rex.net/projects/samples/ooxml/e1/Part4/OOXML_P4_DOCX_headEnd_topic_ID0EQ2ZMB.html#topic_ID0EQ2ZMB
     */
    // TODO - implement (complex rendering not supported for charts)
    this.addProperty("strokeTailSize", {
      defaultValue: "med-med",
      persister: new PropertyPersister("stroke.tailSize"),
    });

  }

  get className() {
    return "AbstractShape";
  }

  get typeId() {
    return this.className;
  }

}

export default AbstractShape;

let globalColor = {};


export const createFillProperty = function(shape, styleKey, propertyName, stylePropertyName, defaultValueCallback, inputs, persistPath) {
  let explicitPropertyName = "explicit" + propertyName.charAt(0).toUpperCase() + propertyName.slice(1);
  if (persistPath === undefined)
    persistPath = propertyName;
  let inputsAll = inputs ? [...inputs] : [];
  inputsAll.unshift(stylePropertyName);
  inputsAll.unshift(explicitPropertyName);

  let addTypeExplicit = shape.lookupPropertyDef(explicitPropertyName) ? 'overrideProperty' : 'addProperty';
  shape[addTypeExplicit](explicitPropertyName, {
    isWritable: false,
    defaultValue: null,
    //setValue: setter,
    persister: new PropertyPersister(persistPath),
  });

  let addType = shape.lookupPropertyDef(propertyName) ? 'overrideProperty' : 'addProperty';
  let retValue = shape[addType](propertyName, {
    defaultValue: function (explicitFill, style) {
//         if (globalColor.resolved) {
//           return globalColor.resolved;
//         }

        let outlineFill;
        if (explicitFill instanceof Fill) {
            outlineFill = explicitFill.toJSON();
        } else if (explicitFill) // Note - If we implement the fill persister this will not occur
            outlineFill = {...explicitFill};
        else {
            let inputArgs = [style, styleKey];
            for (let i=2; i< this.getInputLength(); i++)
                inputArgs.push(this.getInput(i));
            outlineFill = defaultValueCallback.apply(this, inputArgs);
        }

        const schemeLookup = function(valLookup) {
            let themeColor = style.getThemeColor(valLookup);
            if (!themeColor)
                return null;
            return themeColor.toRGBAColor();
        };
        let retValue =  resolveFill(outlineFill, schemeLookup);
        if (!retValue === null) {
            let inputArgs = [style, styleKey];
            for (let i=2; i< this.getInputLength(); i++)
                inputArgs.push(this.getInput(i));
            outlineFill = defaultValueCallback.apply(this, inputArgs);
            retValue =  resolveFill(outlineFill, schemeLookup);
        }
//         if (retValue && retValue.type === 'solid')
//             globalColor.resolved = retValue;
        return retValue;
    },
    setValue: function (value, auxSetPropertyValue) {
        // This is done just to 'remap' the source property
        if (value !== undefined) {
          auxSetPropertyValue(explicitPropertyName, undefined);
        }

        auxSetPropertyValue(explicitPropertyName, value);
        return undefined;
      },
      valueCreator: function (source, value, property, _isExplicit, defaultValue) {
        // This is done just to 'remap' the source property
        let explicit = shape.getPropertyValue(explicitPropertyName);
        return new FetchedPropertyValue(
          source,
          value,
          property,
          explicit.isExplicit,
          defaultValue
        );
      },
    inputs: inputsAll,
    persister: null /* we persist explict values */,
  });

  return retValue;
}
