import AbstractModel from "../../dagm/AbstractModel";

import LiteralValues from "../range/LiteralValues";
import CellValues from "../range/CellValues";

/*
 This wraps a MultiSheetSource with a DAGM based MultiSheetModel to allow for wiring into model system
*/
class MultiSheetModel extends AbstractModel {
  constructor(sheetSource) {
    super();
    if (!sheetSource) throw new Error("sheetSource must not be empty");
    if (typeof sheetSource.getCellAt !== "function")
      throw new Error("sheetSource must have a getCellAt(name, columnIndex, rowIndex) function");

    let _self = this;

    let elementPropName = function (sheetName, x, y) {
      let retValue = y + "-" + x;
      if (sheetName !== undefined && sheetName !== null && sheetName.length > 0)
        retValue += "-" + sheetName;
      return retValue;
    };

    // TODO - how to release these if they change?
    this.__proto__.getPropertyAt = function (sheetName, x, y) {
      // look for a 'ondemand property. if it doesn't exist create it.
      let propName = elementPropName(sheetName, x, y);
      let propDef = this.lookupPropertyDef(propName);
      if (propDef) return propDef;

      propDef = this.addProperty(propName, {
        isTransient: true,
        isDelegate: true,
        isTableElement: true,
        defaultValue: function () {
          let retValue = sheetSource.getCellAt(sheetName, x, y);
          if (retValue === undefined) return null;
          return retValue;
        },
      });
      return propDef;
    };

    if (sheetSource.addUpdateListener) {
      // This must be a list of objects with an x,y property or have no argument
      let updateProperties = function (updates) {
        let visitedMap = new Map();
        let visitedStack = [];
        let markedList = [];

        if (
          updates === undefined || updates === null ||
          (Array.isArray(updates) && updates.length === 0)
        ) {
          let propNames = Object.keys(_self._properties);
          for (let i = 0; i < propNames.length; i++) {
            let propDef = _self._properties[propNames[i]];
            if (!propDef._options.isTableElement) continue;

            propDef.walkDependants(markedList, visitedMap, visitedStack);
          }
        } else if (Array.isArray(updates)) {
          for (let i = 0; i < updates.length; i++) {
            let update = updates[i];
            if (
              !Number.isInteger(Number(update.columnIndex)) ||
              !Number.isInteger(Number(update.rowIndex))
            )
              throw new Error(
                "an array was found but elements but be an object columnIndex and rowIndex (and optionally sheetName) properties"
              );

            let propDef = _self.getPropertyAt(
              update.sheetName || null,
              Number(update.columnIndex),
              Number(update.rowIndex)
            );
            if (!propDef) return;

            propDef.walkDependants(markedList, visitedMap, visitedStack);
          }
        } else {
          throw new Error(
            "updates must be an array of objects with columnIndex and rowIndex (and optionally sheetName) properties"
          );
        }

        _self.recalcCycle(markedList);
      };

      let unlistener = sheetSource.addUpdateListener(updateProperties);
      if (unlistener) this.addDispose(unlistener);
    }
  }

  valuesFromRange(range, cellTransformer) {
    if (!range)
      return null;
    let retValue = null;
    if (range.isCellRange) {
      retValue = new CellValues(this, range, cellTransformer);
    } else if (range.isLiteralRange) {
      retValue = new LiteralValues(range);
    } else if (Array.isArray(range)) {
      retValue = new CellValues(this, range, cellTransformer);
    } else {
      console.log('unrecognized range', range);
    }
    return retValue;
  }
}

export default MultiSheetModel;