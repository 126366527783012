import ArrayModel from "../../dagm/ArrayModel";
import PropertyPersister from "../../dagm/PropertyPersister";

import { IRange, RangeDirection } from "../range/Range";
import { IMultiRange } from "../range/MultiRange";
import { RangeSelectionPersister, createRangeSelectionSetter, createRangeSelection } from "../range/MultiRange";

import AbstractShape, { createFillProperty } from "../AbstractShape";

import DocumentTheme from "../colors/DocumentTheme";

import ChartOrdAxisShape from "./axis/ChartOrdAxisShape";
import ChartValAxisShape from "./axis/ChartValAxisShape";
import ChartDateAxisShape from "./axis/ChartDateAxisShape";
import ChartCatAxisPreferenceModel from "./axis/ChartCatAxisPreferenceModel";

import ChartSeriesShape from "./series/ChartSeriesShape";
import ChartScatterSeriesShape from "./series/ChartScatterSeriesShape";
import ChartPieSeriesShape from "./series/ChartPieSeriesShape";

import MultiSheetModel from "../sheet/MultiSheetModel";

import ChartType from "./type/ChartType";

import { isSingleSeries } from "../../utils/ChartUtils";

import ChartSelection, { collectRanges } from "./ChartSelection";

import ChartStyles from "./ChartStyles";

import ChartPlotAreaShape from "./ChartPlotAreaShape";
import ChartTitleShape from "./ChartTitleShape";
import ChartLegendShape from "./ChartLegendShape";


export interface IChartShapeOptions {
    range?: IRange[] | IRange | string | string[] | IMultiRange;
    sheet?: MultiSheetModel;
    docTheme?: DocumentTheme;
    appContext?: string; // defaults to Excel. TODO - make enum

    getDefaultChartTile?: (ChartShape: any) => string | null | undefined;
    getDefaultSeriesLabel?: (seriesRange: IRange[] | IRange | string | string[] | IMultiRange, seriesOffset: number, seriesCount: number) => string | null | undefined;
    getDefaultAxisLabel?: (sheetName: string, absoluteX: number, absoluteY: number, offset: number, count: number, seriesOrientation: RangeDirection) => string | null | undefined;
}

class ChartShape extends AbstractShape {
  constructor(chartShapeOptions?: IChartShapeOptions) {
    super();

    chartShapeOptions = Object.freeze((chartShapeOptions ? {...chartShapeOptions} : {}));
    let rangeInitial = chartShapeOptions.range;
    let sheetInital = chartShapeOptions.sheet;

    let docThemeInital = chartShapeOptions.docTheme || new DocumentTheme();

    const rangeSelectionSetter = createRangeSelectionSetter(false/*singleLength*/, true/*alignRanges*/, true/*forceFixed*/);
    let rangeProp = this.addProperty("range", {
      setValue: function (value, auxSetPropertyValue) {
        let retValue = rangeSelectionSetter(value);
        auxSetPropertyValue("series.length", undefined);
        return retValue;
      },
      defaultValue: function () {
        return createRangeSelection(rangeInitial || null, false/*singleLength*/, true/*alignRanges*/, true/*forceFixed*/);
      },
      inputs: [],
      persister: new RangeSelectionPersister(),
    });
    /*
      We actually want to always persist the range as part of the ChartShape definition
    */
    if (this._explicitLookup.getExplictValue(rangeProp) === undefined) {
      this.setPropertyValue('range', rangeInitial || null);
      // let rangeAsExplicitValue = rangeProp._options.setValue(rangeInitial || '$A$1');
      // this._explicitLookup.setExplictValue(rangeProp, rangeAsExplicitValue);
    }

    /**
     * this is sheet model.
     * @name sheet
     */
    this.addProperty("sheet", {
      isTransient: true,
      defaultValue: function () {
        return sheetInital || null;
      },
    });

    const _self = this;
    // this.addProperty("chartShape", {
    //   isTransient: true,
    //   isReadOnly: true,
    //   defaultValue: function () {
    //     return _self;
    //   },
    // });

    /**
     * this is docTheme.
     * @name docTheme
     */
    this.addProperty("docTheme", {
      isTransient: true,
      defaultValue: function () {
        return docThemeInital;
      },
    });

    this.addProperty("options", {
      isReadOnly: true,
      isTransient: true,
      defaultValue: function () {
        return chartShapeOptions;
      },
    });

    this.addProperty("description", {
      isReadOnly: true,
      isTransient: true,
      defaultValue: function () {
        return "Chart Area";
      },
      inputs: [],
    });

    this.addProperty("transpose", {
      defaultValue: function () {
        return false;
      },
      inputs: []
    });

    /**
     * Boolean: true, false
     * NOTE IMPLEMENTED -
     * This is a features that ties into the grid. If a row/column is hidden than
     * It will not be considered.
     * The implementation of this will be:
     * Building series ranges will not consider the hidden rows/columns
     * When plotting the series/rows these will also be removed
     */
    // TODO - implement
    this.addProperty("plotVisOnly", {
      defaultValue: function () {
        return true;
      },
      inputs: [],
    });

    /**
      Boolean: true, false
      If true all #NAs are replace with an empty value. (Then treated in accordionce with dispBlanksAs)
    */
    this.addProperty("dispNaAsBlank", {
      defaultValue: function () {
        return true;
      },
      inputs: [],
    });

    /**
      Choices: 'zero', 'gap', 'span'
        <zero> (Zero) Treated as a zero value
        <gap> (Gap) Treated as a gap when plotting (this means that lines and area will have gaps) Columns will not have displayLabels)
        <span> (Span) Applies to line only. This just skips the gap altogether.

      Note - scale of Type log with zero is treated as span for line and as gap for all other chartTypes
    */
    this.addProperty("dispBlanksAs", {
      defaultValue: function () {
        return "gap";
      },
      inputs: [],
    });

    /**
      Boolean: true, false
      Specifies data labels over the maximum of the chart shall be shown.
    */
    this.addProperty("showDLblsOverMax", {
      defaultValue: function () {
        return false;
      },
      inputs: [],
    });

    this.addProperty("styleId", { defaultValue: 2 });

    this.addProperty("roundedCorners", { defaultValue: false });

    // Set via styleId or theme
    this.addProperty("chartStyle", {
      isTransient: true,
      defaultValue: function (docTheme, styleId) {
        return new ChartStyles(docTheme, styleId, chartShapeOptions.appContext);
      },
      inputs: ["docTheme.*", "styleId"],
    });

    this.addProperty("plotArea", {
      defaultValue: function () {
        return new ChartPlotAreaShape({
          chartShape: _self,
          appContext: chartShapeOptions.appContext
        });
      },
      persister: null /* we implicitly save models*/,
    });

    // fill
    createFillProperty(this, 'chartArea', 'fill', "chartStyle", function(style, styleKey) {
      return {...style.createFillStyleProperties(styleKey)};
    }, null);

    // strokeFill;
    createFillProperty(this, 'chartArea', 'strokeFill', "chartStyle", function(style, styleKey) {
      return {...style.createStrokeStyleProperties(styleKey).fill};
    }, null, 'stroke.fill');

    this.overrideProperty("strokeWidth", {
      defaultValue: function (chartStyle) {
        return chartStyle.createStrokeStyleProperties("chartArea").width;
      },
      inputs: ["chartStyle"],
      persister: new PropertyPersister("stroke.width"),
    });

    this.addProperty("types", {
      defaultValue: function () {
        return new ArrayModel(1, {
          defaultValue: function () {
            return new ChartType({
              chartShape: _self,
              appContext: chartShapeOptions.appContext
            });
          },
          inputs: [],
          persister: null /* we implicitly save models*/,
        });
      },
      inputs: [],
      persister: null /* we implicitly save models*/,
    });

    this.addProperty("mainType", {
      defaultValue: function (typesType) {
        if (typesType.length === 0) return "column";
        return typesType[0];
      },
      inputs: ["types[*].type"],
    });

    this.addProperty("ranges", {
      isReadOnly: true,
      isTransient: true,
      defaultValue: (mainType, rangeSelection, sheet, transpose) => {
        return new ChartSelection(rangeSelection, sheet, transpose, mainType);
      },
      inputs: ["mainType", "range", "sheet", "transpose"],
    });

    this.addProperty("series", {
      defaultValue: function (sheetRanges, mainType) {
        let seriesLength = 0;
        if (sheetRanges)
            seriesLength = sheetRanges.seriesLength;

        return new ArrayModel(Math.min(seriesLength, 255), { // Office doesn't allow more than 255 series. Neither do we!
          defaultValue: function (index, length) {
            if (mainType === "scatter") {
              return new ChartScatterSeriesShape(
                Object.assign({ chartShape: _self }, chartShapeOptions),
                index,
                length
              );
            } else if (mainType === "pie") {
              return new ChartPieSeriesShape(
                Object.assign({ chartShape: _self }, chartShapeOptions),
                index,
                length
              );
            } else {
              return new ChartSeriesShape(
                Object.assign({ chartShape: _self }, chartShapeOptions),
                index,
                length
              );
            }
          },
          inputs: ["$index", "length"],
          persister: null /* we implicitly save models*/,
        });
      },
      inputs: ["ranges", "mainType"],
      persister: null /* we implicitly save models*/,
    });

    // TODO - make this also return a function that allows for seriesSelection and pointSelections
    this.addProperty("selectionSummary", {
      isReadOnly: true,
      isTransient: true,
      defaultValue: (seriesxRange, seriesValRange, seriesTitleRange, series) => {
        // Note - What if all xranges are not the same? Or there is a composite
        // This is mean to be an IChartSumary but we don't have the direction. We
        // Could derive this by looking at the first series.
        let seriesLength = (seriesValRange ? seriesValRange.length : 0);

        let retValue = {
          xRanges: collectRanges(seriesLength, function(offset) {
            return seriesxRange[offset];
          }),
          valRanges: collectRanges(seriesLength, function(offset) {
              return seriesValRange[offset];
          }),
          sizeRanges: null,
          titleRanges: collectRanges(seriesLength, function(offset) {
              return seriesTitleRange[offset];
          }),
          seriesLength: seriesLength,
          seriesDirection: 'c'
        }
        if (seriesLength > 0) {
           let firstValRange = seriesValRange[0];
           let firstTitleRange = seriesTitleRange.length > 0 ? seriesTitleRange[0] : null;
           let firstXRange = seriesxRange.length > 0 ? seriesxRange[0] : null;
           let isOneValue = firstValRange.height === 1 && firstValRange.width === 1;
           // We are row if the width is greater than one
           // or there is only one value
           // or we are on the same row as the title
           // or we are on the same col as the x
           if ((firstValRange.width > 1) ||
              (isOneValue && firstTitleRange === null && firstXRange === null) ||
              (isOneValue && firstTitleRange !== null &&
                firstTitleRange.top === firstValRange.top && firstTitleRange.bottom === firstValRange.bottom) ||
              (isOneValue && firstXRange !== null &&
                firstXRange.left === firstValRange.left && firstXRange.right === firstValRange.right)) {
                  retValue.seriesDirection = 'r';
           }
        }
        return retValue;
      },
      inputs: ["series[*].xRange", "series[*].valRange", "series[*].title.range", "series[*]"],
    });


    this.addProperty("catAxisPref", {
      // isTransient: true,
      defaultValue: function (xRanges) {
        return new ChartCatAxisPreferenceModel(_self, xRanges && xRanges.length > 0 ? xRanges[0] : null);
      },
      inputs: ["series[*].xRange", "series[*].xValues.*"],
      persister: null /* we implicitly save models*/,
    });

    // let addAxis = function (axesPair, arrayIndex) {
    //   if (!axesPair) return;

    //   let defaultOptions = {
    //     chartShape: _self,
    //     seriesReferences: axesPair.filteredValues,
    //   };
    //   let axisX = Object.assign(axesPair.axisX, defaultOptions);
    //   arrayIndex.push(axisX);

    //   let axisY = Object.assign(axesPair.axisY, defaultOptions);
    //   arrayIndex.push(axisY);
    // };

    /**
     * Generates just the xAxes
     */
    this.addProperty("xAxes", {
      isReadOnly: true,
      defaultValue: function (
        seriesList,
        catAxisPrefType,
        chartTypeTypes,
        offsetXAxes,
        seriesOffsetChart,
        types
      ) {
        const mapSeriesByChartOffset = [];

        // bucket all series by chart offset
        for (let i = 0; i < seriesList.length; i++) {
          let series = seriesList.getAt(i);
          let chartOffset = series.offsetChart;

          if (!mapSeriesByChartOffset[chartOffset]) {
            mapSeriesByChartOffset[chartOffset] = [];
          }
          mapSeriesByChartOffset[chartOffset].push(series);
        }
        const mapAxisByAxisOffset = [];
        // for every offset determine if we are going to create a val/ord/cat axis
        for (let i = 0; i < chartTypeTypes.length; i++) {
          let type = chartTypeTypes[i];
          if (isSingleSeries(type)) continue;

          let offsetXAxis = offsetXAxes[i];

          let axisInfo = mapAxisByAxisOffset[offsetXAxis];
          if (!axisInfo) {
            axisInfo = {
              axisType: "val",
              isHorizontal: type !== "bar",
            };
            mapAxisByAxisOffset[offsetXAxis] = axisInfo;
          }
          if (
            type === "bar" ||
            type === "column" ||
            type === "line" ||
            type === "area"
          ) {
            // TODO - what about other 3d items?
            axisInfo.axisType = catAxisPrefType; // TODO - this is wrong. It needs to be a list of these. Add to axis itself
          } // else if (type === 'scatter' || type === 'bubble' || type === 'bubble3d')

          mapAxisByAxisOffset[offsetXAxis] = axisInfo;
        }
        // TODO -sort the mapAxis

        return new ArrayModel(mapAxisByAxisOffset.length, {
          defaultValue: function (index) {
            let options = {
              chartShape: _self,
              chartType: types.getAt(index), // TODO remove this
              seriesReferences: mapSeriesByChartOffset[index] || [], // no series.
              direction: "x",
              isHorizontal: mapAxisByAxisOffset[index].isHorizontal,
              getDefaultAxisLabel: chartShapeOptions.getDefaultAxisLabel,
              appContext: chartShapeOptions.appContext
            };
            let axisType = mapAxisByAxisOffset[index].axisType;
            if (axisType === "ord") return new ChartOrdAxisShape(options);
            else if (axisType === "date")
              return new ChartDateAxisShape(options);
            else if (axisType === "val") return new ChartValAxisShape(options);
          },
          inputs: ["$index"],
          persister: null /* we implicitly save models*/,
        });
      },
      // TODO - can axespreftypes needs to be a list (can we 'map' these in the object model to the xAsis?)
      inputs: [
        "series",
        "catAxisPref.type",
        "types[*].type",
        "types[*].offsetXAxis",
        "series[*].offsetChart",
        "types",
      ],
      persister: null /* we implicitly save models*/,
    });

    /**
     * Generates just the yAxes
     */
    this.addProperty("yAxes", {
      defaultValue: function (xAxes) {
        return new ArrayModel(xAxes.length, {
          defaultValue: function (index) {
            let xAxis = xAxes.getAt(index);

            let options = { ...xAxis._shapeOptions };
            options.xAxis = xAxis;
            options.direction = options.direction === "x" ? "y" : "x";
            options.isHorizontal = !options.isHorizontal;
            options.appContext = chartShapeOptions.appContext;
            return new ChartValAxisShape(options);
          },
          inputs: ["$index"],
          persister: null /* we implicitly save models*/,
        });
      },
      inputs: ["xAxes"],
      persister: null /* we implicitly save models*/,
    });

    /**
     * Purely convience method
     */
    this.addProperty("axes", {
      isReadOnly: true,
      isTransient: true,
      defaultValue: function (xAxis, yAxis) {
        let xAxisLength = xAxis.length;
        return new ArrayModel(xAxisLength + yAxis.length, {
          defaultValue: function (index) {
            if (index < xAxisLength) return xAxis.getAt(index);
            else return yAxis.getAt(index - xAxisLength);
          },
          inputs: ["$index"],
          persister: null /* we implicitly save models*/,
        });
      },
      inputs: ["xAxes", "yAxes"],
      persister: null /* we implicitly save models*/,
    });

    this.addProperty("title", {
      defaultValue: function () {
        return new ChartTitleShape({
          chartShape: _self,
          appContext: chartShapeOptions.appContext
        });
      },
      persister: null /* we implicitly save models*/,
    });

    this.addProperty("legend", {
      defaultValue: function () {
        return new ChartLegendShape({
          chartShape: _self,
          appContext: chartShapeOptions.appContext
        });
      },
      persister: null /* we implicitly save models*/,
    });
  } // end constructor

  get className() {
    return "ChartShape";
  }

}

export default ChartShape;
