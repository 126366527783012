import { createFillProperty } from "../AbstractShape";

import { Font, fontSetter, FontPersister} from "../text/Font";

import TextShape from "../text/TextShape";

class ChartTextShape extends TextShape {
  constructor(options, propDefaultText, styleKey, defaultFontSize) {
    super(options, propDefaultText, styleKey, defaultFontSize);
    const _self = this;

    let _chartShape = options.chartShape;
    this.addProperty("chartShape", {
      isReadOnly: true,
      isTransient: true,
      defaultValue: function () {
        return _chartShape;
      },
    });

    let _parent = options.parent;
    this.addProperty("parent", {
      isReadOnly: true,
      isTransient: true,
      defaultValue: function () {
        return _parent;
      },
    });

    this.overrideProperty("font", {
      defaultValue: function (fontThemed) {
        return fontThemed;
      },
      setValue: fontSetter,
      persister: new FontPersister("font"),
      inputs: ['chartShape.docTheme.majorFontLatin']
    });

    // fill
    createFillProperty(this, styleKey, 'fill', "chartShape.chartStyle", function(style, styleKey) {
      return {...style.createFontFillStyle(styleKey) };
    }, null);

    // strokeFill;
    createFillProperty(this, styleKey, 'strokeFill', "chartShape.chartStyle", function(style, styleKey) {
      return style.createNoneFill();//{...style.createStrokeStyleProperties(styleKey).fill};
    }, null, 'stroke.fill');
  }

  get className() {
    return "ChartTextShape";
  }
}

export default ChartTextShape;
