import DAGM from "../dagm/Dagm";

import { createMultiSheetSourceFrom2DArray } from "../models/sheet/MultiSheetSource";
import MultiSheetModel from "../models/sheet/MultiSheetModel";

import ChartShape from "../models/chart/ChartShape";

/**
 * Given a persisted Json will return a list of all sheet & cell references required to render.
 */

export const findCellRefsForChart = function(jsonChart, data=[], defaultSheetName=null) {
  if (!jsonChart)
    throw new Error('Must specific a persisted Chart to find refs');

  const accessed = new Map();
  let sheetModel = new MultiSheetModel(createMultiSheetSourceFrom2DArray(data, defaultSheetName, {
    onGetCellAt : function(sheetName=null, key, _retValue) {
      // let ref = (sheetName ? sheetName + '!' : '') + key;
      let cells = accessed.get(sheetName);
      if (!cells) {
        cells = new Set();
        accessed.set(sheetName, cells);
      }
      cells.add(key);
    }
  }));

  DAGM.newFromJSON(jsonChart,
    () => {
      return new ChartShape({ range: null, sheet: sheetModel });
    }
  );

  let asRefs = {};
  accessed.forEach(function(cells, sheetName) {
    let cellsAsArray = asRefs[sheetName] = [];

    cells.forEach(function(cell) {
      cellsAsArray.push(cell);
    });
    cellsAsArray.sort(); // naturally sort
  });

  return asRefs;
}
