import { roundAccurately } from "../../utils/CommonUtils";

import { Font, fontSetter, FontPersister } from "../text/Font";

import AbstractShape from "../AbstractShape";

class TextShape extends AbstractShape {
  constructor(options, propDefaultText, styleKey, defaultFontSize) {
    super(options);
    this._styleKey = styleKey;

    this.addProperty("font", {
      defaultValue: function () {
        return new Font("Calibri");
      },
      setValue: fontSetter,
      persister: new FontPersister("font"),
    });

    this.addProperty("size", {
      defaultValue: function () {
        return roundAccurately((defaultFontSize || options.defaultFontSize || 12) * (options.appContext === 'powerpoint' ? 1 : 0.75), 1);
      },
    });

    this.addProperty("weight", {
      defaultValue: function () {
        return 400;
      },
    });

    this.addProperty("italic", {
      defaultValue: function () {
        return false;
      },
    });

    this.addProperty("strike", {
      defaultValue: function () {
        return "noStrike";
      },
    });

    this.addProperty("underline", {
      defaultValue: function () {
        return "none";
      },
    });

    this.addProperty("kern", {
      defaultValue: function () {
        return 1200;
      },
    });

    this.addProperty("spc", {
      defaultValue: function () {
        return 0;
      },
    });

    this.addProperty("baseline", {
      defaultValue: function () {
        return 0;
      },
    });

    this.addProperty("insets", {
      defaultValue: function () {
        return {
          left: 4,
          top: 4,
          bottom: 4,
          right: 4,
        };
      },
    });

    this.addProperty("simpleRun", {
      setValue: function (explicitValue) {
        if (explicitValue === null || explicitValue === undefined || explicitValue === "") explicitValue = undefined;
        return explicitValue;
      },
      defaultValue: function (defaultText) {
        return defaultText;
      },
      inputs: (propDefaultText ? [propDefaultText] : null),
    });
  }
}

export default TextShape;
