export function lineLength(x1, y1, x2, y2) {
  return Math.sqrt((x1 -= x2) * x1 + (y1 -= y2) * y1);
}

export function rotatePoint(x, y, deg) {
  let rad = (deg * Math.PI) / 180;
  let sin = Math.sin(rad);
  let cos = Math.cos(rad);
  return {
    x: x * cos - y * sin,
    y: x * sin + y * cos,
  };
}

function rotatePointSinCos(x, y, sin, cos) {
  return {
    x: x * cos - y * sin,
    y: x * sin + y * cos,
  };
}

export function rotateRect(x, y, width, height, deg) {
  const rad = (deg * Math.PI) / 180; /// angle in radians
  let sin = Math.sin(rad);
  let cos = Math.cos(rad);

  const cx = width / 2;
  const cy = height / 2;

  const tl = rotatePointSinCos(-cx, -cy, sin, cos);
  const tr = rotatePointSinCos(cx, -cy, sin, cos);
  const bl = rotatePointSinCos(-cx, cy, sin, cos);
  const br = rotatePointSinCos(cx, cy, sin, cos);

  let x1 = Math.min(tl.x, tr.x, bl.x, br.x) + cx;
  let x2 = Math.max(tl.x, tr.x, bl.x, br.x) + cx;
  let y1 = Math.min(tl.y, tr.y, bl.y, br.y) + cy;
  let y2 = Math.max(tl.y, tr.y, bl.y, br.y) + cy;

  let bounds = [x1 + x, y1 + y, x2 - x1, y2 - y1];
  return bounds;
}

// line intercept math by Paul Bourke http://paulbourke.net/geometry/pointlineplane/
// Determine the intersection point of two line segments
// Return FALSE if the lines don't intersect
export function intersect(x1, y1, x2, y2, x3, y3, x4, y4) {
  // Check if none of the lines are of length 0
  if ((x1 === x2 && y1 === y2) || (x3 === x4 && y3 === y4)) {
    return false;
  }

  var denominator = (y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1);

  // Lines are parallel
  if (denominator === 0) {
    return false;
  }

  var ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denominator;
  var ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denominator;

  // is the intersection along the segments
  if (ua < 0 || ua > 1 || ub < 0 || ub > 1) {
    return false;
  }

  // Return a object with the x and y coordinates of the intersection
  var x = x1 + ua * (x2 - x1);
  var y = y1 + ua * (y2 - y1);

  return {
    x: x,
    y: y,
  };
}

function intersetWithLength(px, py, cx, cy, x, y, x2, y2) {
  let pInter = intersect(px, py, cx, cy, x, y, x2, y2);
  if (pInter === false) return false;
  pInter.length = lineLength(pInter.x, pInter.y, cx, cy);
  return pInter;
}

function shortestLineInterestRect(px, py, cx, cy, x, y, width, height) {
  let shortest = 0;
  let closestPoint = {
    length: 0,
  };

  let p = intersetWithLength(px, py, cx, cy, x, y, x + width, y); // top edge
  if (p !== false && p.length > shortest) closestPoint = p;
  p = intersetWithLength(px, py, cx, cy, x + width, y, x + width, y + height); // right edge
  if (p !== false && p.length > shortest) closestPoint = p;
  p = intersetWithLength(px, py, cx, cy, x, y + height, x + width, y + height); // bottom edge
  if (p !== false && p.length > shortest) closestPoint = p;
  p = intersetWithLength(px, py, cx, cy, x, y + height, x, y + height); // left edge
  if (p !== false && p.length > shortest) closestPoint = p;
  return closestPoint;
}
/**
  Looks for the largest rotated rect that will fit within another rect.
  We use this for rotating labels within an draw area. A notable use 
  is chart titles.

    To find the largest fit within the parentBounds we:
    1. Create a rotatedVersion of the parentBounds.
    2. Look for the smallest intersect from the following lines:
    2a. the center to the rotated top/left or any unrotated edge
    2b. the center to the rotated top/right or any unrotated edge
    2c. using the shortest line:
    3   unrotated shortest line this will give you the top/left, top/right of the smallest rect.
    3a. find abs distrance for x/y relative to center and multiple x2
    this will give smallest unrotated bounds.

 */

export function fitRotatedRect(x, y, width, height, deg) {
  if (!deg)
    return {
      x: x,
      y: y,
      width: width,
      height: height,
    };
  const rad = (-deg * Math.PI) / 180; /// angle in radians
  let sin = Math.sin(rad);
  let cos = Math.cos(rad);

  // Note - Is this correct? What if x,y are NOT 0? check with unit tests
  const cx = width / 2;
  const cy = height / 2;

  const tl = rotatePointSinCos(-cx, -cy, sin, cos);
  const tr = rotatePointSinCos(cx, -cy, sin, cos);

  let shiftBounds = rotateRect(0, 0, width, height, deg);

  // Look for the shortest line from the top points within the rect.
  let distanceTL = shortestLineInterestRect(
    0,
    0,
    cy,
    cx,
    cy,
    0,
    0,
    shiftBounds.width,
    shiftBounds.height
  );
  let distanceTR = shortestLineInterestRect(
    0,
    width,
    cx,
    cy,
    0,
    0,
    shiftBounds.width,
    shiftBounds.height
  );

  let closestPoint;
  if (distanceTL.length <= distanceTR.length) {
    closestPoint = distanceTL;
  } else {
    closestPoint = distanceTR;
  }

  closestPoint.x -= cx;
  closestPoint.y -= cx;
  closestPoint = rotatePointSinCos(
    closestPoint.x,
    closestPoint.y,
    Math.sin(-rad),
    Math.cos(-rad)
  );
  closestPoint.x += cx;
  closestPoint.y += cx;

  let maxWidth = Math.abs(closestPoint.x - cx) * 2;
  let maxHeight = Math.abs(closestPoint.y - cy) * 2;

  // return centered with original rect
  return {
    x: (width - maxWidth) / 2 + x,
    y: (height - maxHeight) / 2 + y,
    width: maxWidth,
    height: maxHeight,
  };
}

export function findIntersectMath(p1, p2, min, max) {
  var i = intersect(p1.x, p1.y, p2.x, p2.y, 50000, 0, -50000, 0);
  if (i) {
    min = Math.min(min, i.x);
    max = Math.max(max, i.x);
  }

  return {
    min: min,
    max: max,
  };
}

const geomUtils = {
  rotatePoint: rotatePoint,
  rotateRect: rotateRect,
  intersect: intersect,
  findIntersectMath: findIntersectMath,
  fitRotatedRect: fitRotatedRect,
};
const GeomUtils = Object.freeze(geomUtils);
export default GeomUtils;
