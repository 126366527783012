import parse from "date-fns/parse";

const _MS_PER_DAY = 1000 * 60 * 60 * 24;
// yyyy-MM-dd
const _DATE_REG_EX = /^(\d{4})-(\d{2})-(\d{2})$/;
// ISO
const _DATE_ISO_FULL_REG_EX =
  /^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?(Z)?$/;



export function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

export function dayDiff(d1, d2) {
  return Math.floor(
    (Date.UTC(d2.getFullYear(), d2.getMonth(), d2.getDate()) -
      Date.UTC(d1.getFullYear(), d1.getMonth(), d1.getDate())) /
      (1000 * 60 * 60 * 24)
  );
}
export function monthDiff(d1, d2) {
  var months;
  if (d1 > d2) {
    var dt = d1;
    d1 = d2;
    d2 = dt;
  }
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth() + 1;
  months += d2.getMonth();
  return months;
}

export function yearDiff(d1, d2) {
  var years;
  years = Math.abs(d2.getFullYear() - d1.getFullYear());
  return years;
}

export function dateUnitDiff(d1, d2, unit) {
  if (unit === "month" || unit === "months" || unit === "m") {
    return monthDiff(d1, d2);
  } else if (unit === "year" || unit === "years" || unit === "y") {
    return yearDiff(d1, d2);
  } else if (unit === "day" || unit === "days" || unit === "d") {
    return dayDiff(d1, d2);
  } else {
    console.warn("invalid unit" + unit);
  }
}

export function addDateUnit(date, count, unit) {
  if (unit === "month" || unit === "months" || unit === "m") {
    let newDate = new Date(date.getTime());
    return new Date(newDate.setMonth(newDate.getMonth() + count));
  } else if (unit === "year" || unit === "years" || unit === "y") {
    let newDate = new Date(date.getTime());
    newDate.setFullYear(newDate.getFullYear() + count);
    return newDate;
  } else if (unit === "day" || unit === "days" || unit === "d") {
    let newDate = new Date(date.getTime());
    newDate.setDate(newDate.getDate() + count);
    return newDate;
  } else {
    console.warn("invalid unit" + unit);
  }

  return null;
}

/*
 * This will always return midnight in local time zone.
 */
export function stringToLocalDate(value) {
  if (value === null || value === undefined) return value;
  if (typeof value !== "string" && !(value instanceof String))
    throw new Error("must be of type string");

  let asDate;
  // If ISO we need to adjust for the time zone.
  if (isISODateString(value)) {
    asDate = new Date(value);
    asDate = toLocalDate(asDate);
  } else {
    asDate = new Date(value);
  }

  asDate.setHours(0, 0, 0, 0);
  return asDate;
}

/*
 * Note - only two formats are supported. A short date or a full iso date
 */
export function isISODateString(value) {
  if (typeof value !== "string" && !(value instanceof String)) return false;

  if (value.match(_DATE_REG_EX) || value.match(_DATE_ISO_FULL_REG_EX))
    return true;
}

export function toUTCDate(utc) {
  return new Date(utc.getTime() - utc.getTimezoneOffset() * 60000);
}

export function toLocalDate(utc) {
  return new Date(utc.getTime() + utc.getTimezoneOffset() * 60000);
}

export function toISODateString(date) {
  return date.toISOString().slice(0, 10);
}

export function toMidnight(date) {
  let asDate = new Date(date);
  asDate.setHours(0, 0, 0, 0);
  return asDate;
}

export function today() {
  let today = new Date();
  today.setHours(0, 0, 0, 0);
  return today;
}

/**
 * Decode an excel date
 * @param {number} excelDate Date value from excel (an int number)
 * @param {boolean} [date1904] Whether to use the 1904 Date System. See https://bettersolutions.com/excel/dates-times/1904-date-system.htm
 * @author Raschid JF Rafaelly <hello&commat;raschidjdr.dev>
 */
export function fromExcelDate(excelDate, date1904 = false, asUTC = false) {
  const daysIn4Years = 1461;
  const daysIn70years = Math.round(25567.5 + 1); // +1 because of the leap-year bug
  const daysFrom1900 = excelDate + (date1904 ? daysIn4Years + 1 : 0);
  const daysFrom1970 = daysFrom1900 - daysIn70years;
  const secondsFrom1970 = daysFrom1970 * (3600 * 24);
  const utc = secondsFrom1970 * 1000;
  if (asUTC) return utc;
  const date = new Date(utc);
  // Because we always use local time zones we adjust
  if (isNaN(date)) return null;
  return toLocalDate(date);
}

/**
 * Encode date to excel
 * @param {Date} date
 * @param {boolean} [date1904] Whether to use the 1904 Date System. See https://bettersolutions.com/excel/dates-times/1904-date-system.htm
 * https://docs.microsoft.com/en-US/office/troubleshoot/excel/wrongly-assumes-1900-is-leap-year
 * https://docs.microsoft.com/en-us/dotnet/api/system.datetime.tooadate?redirectedfrom=MSDN&view=net-5.0#System_DateTime_ToOADate
 *
 * @author Raschid JF Rafaelly <hello&commat;raschidjdr.dev>
 */
export function toExcelDate(date, date1904) {
  // see https://bettersolutions.com/excel/dates-times/1904-date-system.htm
  if (isNaN(date)) return null;
  const daysIn4Years = 1461;
  const daysIn70years = Math.round(25567.5 + 1); // +1 because of the leap-year bug
  const daysFrom1970 = date.getTime() / 1000 / 3600 / 24;
  const daysFrom1900 = daysFrom1970 + daysIn70years;
  const daysFrom1904Jan2nd = daysFrom1900 - daysIn4Years - 1;
  return Math.round(date1904 ? daysFrom1904Jan2nd : daysFrom1900);
}

/*
  This is a bit confusing.
  The are not date-fns format codes NOT excel format codes.
*/
const dateInputFormats = [
    'M-d-yyyy',
    'yyyy-M-d',
    'M-d',
    'M-yyyy',
    'MM-d',
    'MM-dd',
    'MM-yyyy',
    'MMMM-d',
    'MMMM-dd',
    'MMMM-yyyy',
    'MMMMM-d',
    'MMMMM-dd',
    'MMMMM-yyyy',
];

const dateInputFormatsAll = [];
for (let i=0;i<dateInputFormats.length;i++) {
    dateInputFormatsAll.push(dateInputFormats[i]);
    dateInputFormatsAll.push(dateInputFormats[i].replace(/-/g, '/'));
}

export function parseAsDate(strValue) {
  let optionsDates = {};
  
  for (let i=0; i<dateInputFormatsAll.length; i++) {
      let dateFound = parse(strValue, dateInputFormatsAll[i], new Date(), optionsDates);
      if (isValidDate(dateFound)) {
          // https://docs.microsoft.com/en-us/office/troubleshoot/excel/two-digit-year-numbers
          if (dateFound.getFullYear() < 100) {
              let windowsYear = dateFound.getFullYear() <= 29 ? 2000 + dateFound.getFullYear() : 1900 + dateFound.getFullYear();
              dateFound.setFullYear(windowsYear);
          }
          return dateFound;
      }
  }

  return null;
}

export function isDateFormatter(fmt) {
  if (!fmt)
    return false;
    // Note - We strip out h:mm because we want to test for month and not confuse it with minutes
    let fmtCandidate = fmt.replace(/h:mm/g, '');
    if (fmtCandidate.includes('d') || fmtCandidate.includes('m') || fmtCandidate.includes('y'))
      return true;
  return false;
}

const dateUtils = {
  isValidDate: isValidDate,
  dateUnitDiff: dateUnitDiff,
  dayDiff: dayDiff,
  monthDiff: monthDiff,
  yearDiff: yearDiff,
  addDateUnit: addDateUnit,
  stringToLocalDate: stringToLocalDate,
  today: today,
  toLocalDate: toLocalDate,
  toUTCDate: toUTCDate,
  isISODateString: isISODateString,
  toISODateString: toISODateString,
  toMidnight: toMidnight,
  fromExcelDate: fromExcelDate,
  toExcelDate: toExcelDate,
  parseAsDate: parseAsDate,
  isDateFormatter: isDateFormatter,
};
const DateUtils = Object.freeze(dateUtils);
export default DateUtils;
